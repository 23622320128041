import styled from 'styled-components'

export const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  background: ${({ theme: { palette } }) => palette.white};
  padding: 0 ${({ theme: { mp } }) => mp.md};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 20;
  box-shadow: 0px 1px 0px 0px #ebeef7;
  height: ${({
    theme: {
      dims: { standards },
    },
  }) => standards.navbar};
`

export const PageTitleWrapper = styled.div`
  flex: 1;
  height: ${({
    theme: {
      dims: { standards },
    },
  }) => standards.navbar};
`

export const PageTitle = styled.h1`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: ${({
    theme: {
      font: { weights },
    },
  }) => weights.normal};
  font-size: ${({
    theme: {
      font: { sizes },
    },
  }) => sizes.large};
  max-width: 80%;
`
