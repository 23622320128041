import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react'
import { registerShowModalCallback, IModalConfig } from './modalManager'

interface ModalContextType {
  isModalVisible: boolean
  modalConfig: IModalConfig | null
  hideModal: () => void
}

const ModalContext = createContext<ModalContextType | undefined>(undefined)

export const ModalProvider = ({ children }: { children: ReactNode }) => {
  const [isModalVisible, setModalVisible] = useState<boolean>(false)
  const [modalConfig, setModalConfig] = useState<IModalConfig | null>(null)

  useEffect(() => {
    registerShowModalCallback((config: IModalConfig) => {
      setModalConfig(config)
      setModalVisible(true)
    })
  }, [])

  const hideModal = (): void => {
    setModalVisible(false)
  }

  return (
    <ModalContext.Provider value={{ isModalVisible, modalConfig, hideModal }}>
      {children}
    </ModalContext.Provider>
  )
}

export const useModal = (): ModalContextType => {
  const context = useContext(ModalContext)
  if (context === undefined) {
    throw new Error('useModal must be used within a ModalProvider')
  }
  return context
}
