import { AxiosService } from '../config/config'
import { Endpoints } from '../config/endpoints'
import { IPayload } from 'models/Global'
import {
  IOnboardDocumentCategory,
  IGetOnboardDocumentCategoriesParams,
  IPutOnboardDocumentCategoryParams,
  IPostOnboardDocumentCategoryParams,
} from 'models/OnboardDocumentCategory'

export class OnboardDocumentCategoriesApi {
  constructor(private axios: AxiosService) {}

  getOnboardDocumentCategories = async (params?: IGetOnboardDocumentCategoriesParams) => {
    const response = await this.axios.get<IPayload<IOnboardDocumentCategory[]>>(
      Endpoints.getOnboardDocumentCategories,
      params
    )
    return response
  }

  getOnboardDocumentCategory = async (id: number, params?: IGetOnboardDocumentCategoriesParams) => {
    const response = await this.axios.get<IPayload<IOnboardDocumentCategory>>(
      Endpoints.getOnboardDocumentCategory(id),
      params
    )
    return response
  }

  postOnboardDocumentCategory = async (params: IPostOnboardDocumentCategoryParams) => {
    const response = await this.axios.post<IPayload<IOnboardDocumentCategory>>(
      Endpoints.postOnboardDocumentCategory,
      params
    )
    return response
  }

  putOnboardDocumentCategory = async (id: number, params?: IPutOnboardDocumentCategoryParams) => {
    const response = await this.axios.put<IPayload<IOnboardDocumentCategory>>(
      Endpoints.putOnboardDocumentCategory(id),
      params
    )
    return response
  }

  deleteOnboardDocumentCategory = async (id: number) => {
    const response = await this.axios.delete<{}>(Endpoints.deleteOnboardDocumentCategory(id))
    return response
  }
}
