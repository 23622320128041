import { IPropertyReducer, IProperty } from 'models/Property'
import { Action } from 'redux'
import { isType } from 'ts-action'
import {
  ClearPropertyReducer,
  GetProperty,
  PropertyErrors,
  PropertyFetching,
  UpdateProperty,
  UpdatePropertyFollowedByUser,
} from './actions'
import { FOLLOW_STATUS } from 'models/Follow'

const initialState: IPropertyReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: {} as IProperty,
}

export const propertyReducer = (state = initialState, action: Action): IPropertyReducer => {
  if (isType(action, GetProperty)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
    }
  }

  if (isType(action, PropertyErrors)) {
    const {
      payload: { code, message },
    } = action

    return {
      ...state,
      error: {
        code,
        message,
      },
    }
  }

  if (isType(action, PropertyFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }

  if (isType(action, UpdateProperty)) {
    const { payload } = action
    return {
      isFetching: false,
      ...payload,
    }
  }

  if (isType(action, UpdatePropertyFollowedByUser)) {
    const {
      payload: { data },
    } = action

    return {
      ...state,
      data: {
        ...state.data,
        followed_by_user: data.status === FOLLOW_STATUS.FOLLOW ? data : null,
      },
    }
  }

  if (isType(action, ClearPropertyReducer)) {
    return initialState
  }

  return state
}
