import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'
import { selectAuthIsAuth } from 'data/Auth/selectors'
import { OnboarderRouteWrapper } from './style'
import Header from '../../Onboarder/Header'

type TOnboarderRouteProps = {
  component: React.ComponentType<any>
  [key: string]: any
}

const OnboarderRoute = (props: TOnboarderRouteProps) => {
  const { component: Component, ...rest } = props
  const isAuth = useSelector(selectAuthIsAuth)

  const render = useCallback(
    (props) => {
      if (isAuth)
        return (
          <OnboarderRouteWrapper>
            <Header />
            <Component {...props} />
          </OnboarderRouteWrapper>
        )
      return <Redirect to='/' />
    },
    [Component, isAuth]
  )

  return <Route {...rest} render={render} />
}

export default OnboarderRoute
