import { action, payload } from 'ts-action'
import { IInvoice, IInvoiceData } from 'models/Invoice'
import { IError, IPayload } from 'models/Global'
import { IProperty } from 'models/Property'
import { ITimesheet } from 'models/Timesheet'
import { ICredit } from 'models/Credit'
import { IInvoiceGroup } from 'models/InvoiceGroup'
import { IInvoiceExpenseType } from 'models/InvoiceExpenseType'

export const GetInvoiceBatch = action('GetInvoiceBatch', payload<IPayload<IInvoiceData>>())
export const GetInvoice = action('GetInvoice', payload<IPayload<IInvoice>>())
export const GetInvoiceGroup = action('GetInvoiceGroup', payload<IPayload<IInvoiceGroup>>())
export const GetPropertiesForInvoice = action(
  'GetPropertiesForInvoice',
  payload<IPayload<IProperty[]>>()
)
export const GetTimesheetsForInvoice = action(
  'GetTimesheetsForInvoice',
  payload<IPayload<ITimesheet[]>>()
)
export const GetAdditionalExpenseTypesForInvoice = action(
  'GetAdditionalExpenseTypesForInvoice',
  payload<IPayload<IInvoiceExpenseType[]>>()
)
export const GetCreditsForInvoice = action('GetCreditsForInvoice', payload<IPayload<ICredit[]>>())
export const PayInvoiceLoading = action('PayInvoiceLoading', payload<boolean>())
export const InvoiceError = action('InvoiceError', payload<IError>())
export const InvoiceFetching = action('InvoiceFetching', payload<boolean>())
