import { action, payload } from 'ts-action'
import { IUser } from 'models/User'
import { ICertificate } from 'models/Certificate'
import { IError, IPayload } from 'models/Global'
import { IUserCertificate } from 'models/UserCertificate'
import { IBlock } from 'models/Block'
import { IBusinessEntity } from 'models/BusinessEntity'
import { IStrike } from 'models/Strike'
import { IReview } from 'models/Review'
import { IShift } from 'models/Shift'
import { IFavorite } from 'models/Favorite'
import { IDeduction } from 'models/Deduction'
import { IExperience } from 'models/Experience'
import { IVaccinationForm } from 'models/VaccinationForm'

// Upshifter Actions

export const GetUpshifter = action('GetUpshifter', payload<IPayload<IUser>>())

export const PutUpshifter = action('PutUpshifter', payload<IPayload<IUser>>())

export const UpshifterFetching = action('GetUpshifterFetching', payload<boolean>())

export const GetUpshifterErrors = action('GetUpshifterErrors', payload<IError>())

export const UpshifterUpdating = action('UpshifterUpdating', payload<boolean>())

export const ClearCurrentUpshifter = action('ClearCurrentUpshifter')

// Assigned Certificates Actions

export const GetAssignedCertificates = action(
  'GetAssignedCertificates',
  payload<IPayload<IUserCertificate[]>>()
)

export const ClearAssignedCertificates = action('ClearAssignedCertificates')

export const AssignedCertificatesFetching = action(
  'AssignedCertificatesFetching',
  payload<boolean>()
)

export const GetAssignedCertificatesErrors = action(
  'GetAssignedCertificatesErrors',
  payload<IError>()
)

export const AssignButtonLoading = action('AssignButtonLoading', payload<number>())
export const AssignButtonStopLoading = action('AssignButtonStopLoading', payload<number>())

// Unassigned Certificates Actions

export const GetUnassignedCertificates = action(
  'GetUnassignedCertificates',
  payload<IPayload<ICertificate[]>>()
)

export const UnassignedCertificatesFetching = action(
  'UnassignedCertificatesFetching',
  payload<boolean>()
)

export const ClearUnassignedCertificates = action('ClearUnassignedCertificates')

export const GetUnassignedCertificatesErrors = action(
  'GetUnassignedCertificatesErrors',
  payload<IError>()
)

export const UnassignButtonLoading = action('UnassignButtonLoading', payload<number>())
export const UnassignButtonStopLoading = action('UnassignButtonStopLoading', payload<number>())

// Blocks

export const UpshifterBlocksFetching = action('UpshifterBlocksFetching', payload<boolean>())

export const GetUpshifterBlocks = action(
  'GetUpshifterBlocks',
  payload<IPayload<IBlock[] | IBusinessEntity[]>>()
)

export const ShouldBlocksDataRefresh = action('ShouldBlocksDataRefresh', payload<boolean>())

export const ClearUpshifterBlocksData = action('ClearUpshifterBlocksData')

export const AddUpshifterBlock = action('AddUpshifterBlock', payload<IPayload<IBlock>>())

export const UpdateUpshifterBlocks = action('UpdateUpshifterBlocks', payload<IBlock>())

export const UpdateUpshifterBusinessesWithoutBlock = action(
  'UpdateUpshifterBusinessesWithoutBlock',
  payload<IBusinessEntity>()
)

export const DeleteUpshifterBlock = action('DeleteUpshifterBlock', payload<number>())

export const GetUpshifterBlocksErrors = action('GetUpshifterBlocksErrors', payload<IError>())

// Strikes actions

export const UpshifterStrikesFetching = action('UpshifterStrikesFetching', payload<boolean>())

export const GetUpshifterStrikes = action('GetUpshifterStrikes', payload<IPayload<IStrike[]>>())

export const GetUpshifterActiveStrikesTotal = action(
  'GetUpshifterActiveStrikesTotal',
  payload<number>()
)
export const UpshifterActiveStrikesTotalFetching = action(
  'UpshifterActiveStrikesTotalFetching',
  payload<boolean>()
)
export const GetUpshifterActiveStrikesTotalErrors = action(
  'GetUpshifterActiveStrikesTotalErrors',
  payload<IError>()
)

export const AddUpshifterStrike = action('AddUpshifterStrike', payload<IPayload<IStrike>>())

export const UpdateUpshifterStrike = action('UpdateUpshifterStrike', payload<IStrike>())

export const DeleteUpshifterStrike = action('DeleteUpshifterStrike', payload<number>())

export const GetUpshifterStrikesErrors = action('GetUpshifterStrikesErrors', payload<IError>())
export const BlockButtonLoading = action('BlockButtonLoading', payload<number>())

export const BlockButtonStopLoading = action('BlockButtonStopLoading', payload<number>())

// Reviews

export const UpshifterReviewsFetching = action('UpshifterReviewsFetching', payload<boolean>())

export const GetUpshifterReviews = action('GetUpshifterReviews', payload<IPayload<IReview[]>>())

export const AddUpshifterReview = action('AddUpshifterReview', payload<IPayload<IReview>>())

export const UpdateUpshifterReview = action('UpdateUpshifterReview', payload<IPayload<IReview>>())

export const DeleteUpshifterReview = action('DeleteUpshifterReview', payload<number>())

export const GetUpshifterReviewsErrors = action('GetUpshifterReviewsErrors', payload<IError>())

// Shifts

export const UpshifterShiftsFetching = action('UpshifterShiftsFetching', payload<boolean>())

export const GetUpshifterShifts = action('GetUpshifterShifts', payload<IPayload<IShift[]>>())

export const AddUpshifterShift = action('AddUpshifterShift', payload<IPayload<IShift>>())

export const UpdateUpshifterShift = action('UpdateUpshifterShift', payload<IPayload<IShift>>())

export const DeleteUpshifterShift = action('DeleteUpshifterShift', payload<number>())

export const GetUpshifterShiftsErrors = action('GetUpshifterShiftsErrors', payload<IError>())

// Favorites

export const UpshifterFavoritesFetching = action('UpshifterFavoritesFetching', payload<boolean>())

export const GetUpshifterFavorites = action(
  'GetUpshifterFavorites',
  payload<IPayload<IFavorite[]>>()
)

export const AddUpshifterFavorite = action('AddUpshifterFavorite', payload<IPayload<IFavorite>>())

export const UpdateUpshifterFavorite = action(
  'UpdateUpshifterFavorite',
  payload<IPayload<IFavorite>>()
)

export const DeleteUpshifterFavorite = action('DeleteUpshifterFavorite', payload<number>())

export const GetUpshifterFavoritesErrors = action('GetUpshifterFavoritesErrors', payload<IError>())

export const UpshifterFavoriteButtonLoading = action(
  'UpshifterFavoriteButtonLoading',
  payload<number>()
)

export const UpshifterFavoriteButtonStopLoading = action(
  'UpshifterFavoriteButtonStopLoading',
  payload<number>()
)

// Deductions

export const DeductionsFetching = action('DeductionsFetching', payload<boolean>())

export const GetDeductions = action('GetDeductions', payload<IPayload<IDeduction[]>>())

export const AddDeduction = action('AddDeduction', payload<IPayload<IDeduction>>())

export const UpdateDeduction = action('UpdateDeduction', payload<IPayload<IDeduction>>())

export const DeleteDeduction = action('DeleteDeduction', payload<number>())

export const GetDeductionsErrors = action('GetDeductionsErrors', payload<IError>())

// Experiences

export const GetExperiences = action('GetExperiences', payload<IPayload<IExperience[]>>())
export const ExperiencesFetching = action('ExperiencesFetching', payload<boolean>())
export const GetExperiencesError = action('GetExperiencesError', payload<IError>())

// Vaccinations
export const UpshifterVaccinationFormsFetching = action(
  'UpshifterVaccinationFormsFetching',
  payload<boolean>()
)

export const GetUpshifterVaccinationForms = action(
  'GetUpshifterVaccinationForms',
  payload<IPayload<IVaccinationForm[]>>()
)

export const AddUpshifterVaccinationForm = action(
  'AddUpshifterVaccinationForm',
  payload<IPayload<IVaccinationForm>>()
)

export const UpdateUpshifterVaccinationForm = action(
  'UpdateUpshifterVaccinationForm',
  payload<IPayload<IVaccinationForm>>()
)

export const DeleteUpshifterVaccinationForm = action(
  'DeleteUpshifterVaccinationForm',
  payload<number>()
)

export const GetUpshifterVaccinationFormsErrors = action(
  'GetUpshifterVaccinationFormsErrors',
  payload<IError>()
)
