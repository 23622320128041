import { REPORTS } from 'models/Permission'
import ROUTES from 'routing/adminRoutes'
import {
  AsyncAccountingReport,
  AsyncAccountManagersShiftsReport,
  AsyncAgingReport,
  AsyncFakePunchCardsReport,
  AsyncGrowthReport,
  AsyncMonthlySalesTaxReport,
  AsyncMultiplePunchCardsReport,
  AsyncPaymentApplicationReport,
  AsyncPayrollReport,
  AsyncReportsList,
  AsyncTerminatedAndReinstatedUpshiftersReport,
  AsyncAMSuccessReport,
  AsyncWeeklyEmployeeReport,
  AsyncARBillReport,
  AsyncCRBonusReport,
  AsyncHighLevelStatsReport,
  AsyncFoodbuyReport,
  AsyncCompassApprovalReport,
  AsyncReturnRateReport,
  AsyncUpshifterHoursReport,
  AsyncAMKPIReport,
  AsyncExpiringDocumentsReport,
  AsyncLocationsReport,
  AsyncAccountBreakdownReport,
  AsyncProcessedTimesheetReport,
} from 'ui/pages'
import AdminRouteLayout from '../../../Admin/AdminRouteLayout'
import AdminRoute from '../../AdminRoute'

const reportRoutes = [
  <AdminRoute
    exact
    path={ROUTES.REPORTS}
    layout={AdminRouteLayout}
    component={AsyncReportsList}
    requiredPermissions={[
      REPORTS.AGING_REPORT_DOWNLOAD,
      REPORTS.AGING_REPORT_SEND,
      REPORTS.DOWNLOAD_MULTIPLE_ACKNOWLEDGED_DOCS,
      REPORTS.EXPIRING_ONBOARD_DOCS_DOWNLOAD,
      REPORTS.EXPIRING_ONBOARD_DOCS_SEND,
      REPORTS.FAKE_PUNCHCARDS_DOWNLOAD,
      REPORTS.FINANCE_AGING_DOWNLOAD,
      REPORTS.GET_STATISTICS_BY_DATE,
      REPORTS.GROWTH_DOWNLOAD,
      REPORTS.INVOICES_DOWNLOAD,
      REPORTS.MULTIPLE_PUNCHCARD_REPORT,
      REPORTS.NO_SHOW_DOWNLOAD,
      REPORTS.PAYMENT_APPLICATION_REPORT_DOWNLOAD,
      REPORTS.PAYROLL_DOWNLOAD,
      REPORTS.PAYROLL_SEND,
      REPORTS.PROPERTIES_DOWNLOAD,
      REPORTS.PROPERTIES_SEND,
      REPORTS.SALES_TAXES_DOWNLOAD,
      REPORTS.TERMINATED_AND_REINSTATED_UPSHIFTERS,
      REPORTS.TIMESHEETS_DOWNLOAD,
      REPORTS.WEEKLY_EMPLOYEE_REPORT_DOWNLOAD,
      REPORTS.AM_SUCCESS_REPORT_DOWNLOAD,
      REPORTS.AM_SUCCESS_REPORT_SEND,
      REPORTS.UPSHIFTER_HOURS_REPORT,
      REPORTS.ACCOUNT_BREAKDOWN_REPORT,
      REPORTS.PROCESSED_TIMESHEET_REPORT,
    ]}
    method='any'
    key={ROUTES.REPORTS}
  />,
  <AdminRoute
    exact
    path={ROUTES.REPORTS_PAYROLL_REPORT}
    layout={AdminRouteLayout}
    component={AsyncPayrollReport}
    requiredPermissions={[REPORTS.PAYROLL_DOWNLOAD, REPORTS.PAYROLL_SEND]}
    method='any'
    key={ROUTES.REPORTS_PAYROLL_REPORT}
  />,
  <AdminRoute
    exact
    path={ROUTES.REPORTS_PAYMENT_APPLICATION_REPORT}
    layout={AdminRouteLayout}
    component={AsyncPaymentApplicationReport}
    requiredPermissions={[REPORTS.PAYMENT_APPLICATION_REPORT_DOWNLOAD]}
    key={ROUTES.REPORTS_PAYMENT_APPLICATION_REPORT}
  />,
  <AdminRoute
    exact
    path={[ROUTES.REPORTS_AM_SUCCESS_REPORT, ROUTES.REPORTS_AM_SUCCESS_REPORT_OLD]}
    layout={AdminRouteLayout}
    component={AsyncAMSuccessReport}
    requiredPermissions={[REPORTS.AM_SUCCESS_REPORT_DOWNLOAD, REPORTS.AM_SUCCESS_REPORT_SEND]}
    method='any'
    key={ROUTES.REPORTS_AM_SUCCESS_REPORT}
  />,
  <AdminRoute
    exact
    path={ROUTES.REPORTS_MONTHLY_SALES_TAX_REPORT}
    layout={AdminRouteLayout}
    component={AsyncMonthlySalesTaxReport}
    requiredPermissions={[REPORTS.SALES_TAXES_DOWNLOAD]}
    key={ROUTES.REPORTS_MONTHLY_SALES_TAX_REPORT}
  />,
  <AdminRoute
    exact
    path={ROUTES.REPORTS_WEEKLY_EMPLOYEE_REPORT}
    layout={AdminRouteLayout}
    component={AsyncWeeklyEmployeeReport}
    requiredPermissions={[REPORTS.WEEKLY_EMPLOYEE_REPORT_DOWNLOAD]}
    key={ROUTES.REPORTS_WEEKLY_EMPLOYEE_REPORT}
  />,
  <AdminRoute
    exact
    path={ROUTES.REPORTS_MULTIPLE_PUNCH_CARD_REPORT}
    layout={AdminRouteLayout}
    component={AsyncMultiplePunchCardsReport}
    requiredPermissions={[REPORTS.MULTIPLE_PUNCHCARD_REPORT]}
    key={ROUTES.REPORTS_MULTIPLE_PUNCH_CARD_REPORT}
  />,
  <AdminRoute
    exact
    path={ROUTES.REPORTS_AR_BILL_REPORT}
    layout={AdminRouteLayout}
    component={AsyncARBillReport}
    requiredPermissions={[REPORTS.AR_BILL_REPORT]}
    key={ROUTES.REPORTS_AR_BILL_REPORT}
  />,
  <AdminRoute
    exact
    path={ROUTES.REPORTS_CHURN_REPORT}
    layout={AdminRouteLayout}
    component={AsyncAgingReport}
    requiredPermissions={[REPORTS.AGING_REPORT_DOWNLOAD]}
    key={ROUTES.REPORTS_CHURN_REPORT}
  />,
  <AdminRoute
    exact
    path={ROUTES.REPORTS_TERMINATED_AND_REINSTATED_UPSHIFTERS_REPORT}
    layout={AdminRouteLayout}
    component={AsyncTerminatedAndReinstatedUpshiftersReport}
    requiredPermissions={[REPORTS.TERMINATED_AND_REINSTATED_UPSHIFTERS]}
    key={ROUTES.REPORTS_TERMINATED_AND_REINSTATED_UPSHIFTERS_REPORT}
  />,
  <AdminRoute
    exact
    path={ROUTES.REPORTS_FAKE_PUNCH_CARD_REPORT}
    layout={AdminRouteLayout}
    component={AsyncFakePunchCardsReport}
    requiredPermissions={[REPORTS.FAKE_PUNCHCARDS_DOWNLOAD]}
    key={ROUTES.REPORTS_FAKE_PUNCH_CARD_REPORT}
  />,
  <AdminRoute
    exact
    path={ROUTES.REPORTS_ACCOUNTING_REPORT}
    layout={AdminRouteLayout}
    component={AsyncAccountingReport}
    requiredPermissions={[REPORTS.ACCOUNTING_DOWNLOAD]}
    key={ROUTES.REPORTS_ACCOUNTING_REPORT}
  />,
  <AdminRoute
    exact
    path={ROUTES.REPORTS_GROWTH_REPORT}
    layout={AdminRouteLayout}
    component={AsyncGrowthReport}
    requiredPermissions={[REPORTS.GROWTH_DOWNLOAD]}
    key={ROUTES.REPORTS_GROWTH_REPORT}
  />,
  <AdminRoute
    exact
    path={ROUTES.REPORTS_AM_SHIFTS_REPORT}
    layout={AdminRouteLayout}
    component={AsyncAccountManagersShiftsReport}
    requiredPermissions={[REPORTS.ACCOUNT_MANAGERS_SHIFTS_REPORT_DOWNLOAD]}
    key={ROUTES.REPORTS_AM_SHIFTS_REPORT}
  />,
  <AdminRoute
    exact
    path={ROUTES.REPORTS_CR_BONUS_REPORT}
    layout={AdminRouteLayout}
    component={AsyncCRBonusReport}
    requiredPermissions={[REPORTS.CR_BONUS_REPORT_DOWNLOAD]}
    key={ROUTES.REPORTS_CR_BONUS_REPORT}
  />,
  <AdminRoute
    exact
    path={ROUTES.REPORTS_HIGH_LEVEL_STATS_REPORT}
    layout={AdminRouteLayout}
    component={AsyncHighLevelStatsReport}
    requiredPermissions={[REPORTS.HIGH_LEVEL_STATS_REPORT]}
    key={ROUTES.REPORTS_HIGH_LEVEL_STATS_REPORT}
  />,
  <AdminRoute
    exact
    path={ROUTES.REPORTS_FOODBUY_REPORT}
    layout={AdminRouteLayout}
    component={AsyncFoodbuyReport}
    requiredPermissions={[REPORTS.FOODBUY_REPORT]}
    key={ROUTES.REPORTS_FOODBUY_REPORT}
  />,
  <AdminRoute
    exact
    path={ROUTES.REPORTS_COMPASS_APPROVAL_REPORT}
    layout={AdminRouteLayout}
    component={AsyncCompassApprovalReport}
    requiredPermissions={[REPORTS.COMPASS_APPROVAL_REPORT]}
    key={ROUTES.REPORTS_COMPASS_APPROVAL_REPORT}
  />,
  <AdminRoute
    exact
    path={ROUTES.REPORTS_LOCATIONS_REPORT}
    layout={AdminRouteLayout}
    component={AsyncLocationsReport}
    requiredPermissions={[REPORTS.LOCATIONS_REPORT]}
    key={ROUTES.REPORTS_LOCATIONS_REPORT}
  />,
  <AdminRoute
    exact
    path={ROUTES.REPORTS_RETURN_RATE_REPORT}
    layout={AdminRouteLayout}
    component={AsyncReturnRateReport}
    requiredPermissions={[REPORTS.RETURN_RATE_REPORT]}
    key={ROUTES.REPORTS_RETURN_RATE_REPORT}
  />,
  <AdminRoute
    exact
    path={ROUTES.REPORTS_UPSHIFTER_HOURS_REPORT}
    layout={AdminRouteLayout}
    component={AsyncUpshifterHoursReport}
    requiredPermissions={[REPORTS.UPSHIFTER_HOURS_REPORT]}
    key={ROUTES.REPORTS_UPSHIFTER_HOURS_REPORT}
  />,
  <AdminRoute
    exact
    path={ROUTES.REPORTS_AM_KPI_REPORT}
    layout={AdminRouteLayout}
    component={AsyncAMKPIReport}
    requiredPermissions={[REPORTS.AM_KPI_REPORT]}
    key={ROUTES.REPORTS_AM_KPI_REPORT}
  />,
  <AdminRoute
    exact
    path={ROUTES.REPORTS_EXPIRING_DOCUMENTS_REPORT}
    layout={AdminRouteLayout}
    component={AsyncExpiringDocumentsReport}
    requiredPermissions={[REPORTS.EXPIRING_DOCUMENTS_REPORT]}
    key={ROUTES.REPORTS_EXPIRING_DOCUMENTS_REPORT}
  />,
  <AdminRoute
    exact
    path={ROUTES.REPORTS_ACCOUNT_BREAKDOWN_REPORT}
    layout={AdminRouteLayout}
    component={AsyncAccountBreakdownReport}
    requiredPermissions={[REPORTS.ACCOUNT_BREAKDOWN_REPORT]}
    key={ROUTES.REPORTS_ACCOUNT_BREAKDOWN_REPORT}
  />,
  <AdminRoute
    exact
    path={ROUTES.REPORTS_PROCESSED_TIMESHEET_REPORT}
    layout={AdminRouteLayout}
    component={AsyncProcessedTimesheetReport}
    requiredPermissions={[REPORTS.PROCESSED_TIMESHEET_REPORT]}
    key={ROUTES.REPORTS_PROCESSED_TIMESHEET_REPORT}
  />,
]

export default reportRoutes
