import {
  ATTACHMENTS,
  BUSINESSES,
  BUSINESS_NOTES,
  BUSINESS_USERS,
  INVOICES,
  INVOICE_GROUPS,
  PROPERTIES,
  REVIEWS,
  SHIFTS,
  TEMPLATES,
  TIMESHEETS,
  UPSHIFTER_USERS,
} from 'models/Permission'
import ROUTES from 'routing/adminRoutes'
import {
  AsyncAddLocation,
  AsyncAddTemplate,
  AsyncBusiness,
  AsyncBusinessEntity,
  AsyncBusinessEntityAddInvoiceGroup,
  AsyncBusinessEntityAreas,
  AsyncBusinessEntityAttachments,
  AsyncBusinessEntityBillingDetails,
  AsyncBusinessEntityChildrenCompanies,
  AsyncBusinessEntityAcknowledgements,
  AsyncBusinessEntityEditInvoiceGroup,
  AsyncBusinessEntityFinance,
  AsyncBusinessEntityLocations,
  AsyncBusinessEntityReviews,
  AsyncBusinessEntityShifts,
  AsyncBusinessEntityTimesheets,
  AsyncBusinessEntityUsers,
  AsyncBusinessUpshifters,
  AsyncBusinesses,
  AsyncCreateBusinessEntity,
  AsyncDocumentsAcknowledged,
  AsyncEditBusinessEntity,
  AsyncEditTemplate,
  AsyncNotes,
  AsyncTemplates,
} from 'ui/pages'
import AdminRouteLayout from '../../../Admin/AdminRouteLayout'
import AdminRoute from '../../AdminRoute'

const businessesRoutes = [
  <AdminRoute
    exact
    path={ROUTES.BUSINESSES_LIST}
    layout={AdminRouteLayout}
    component={AsyncBusinesses}
    requiredPermissions={[BUSINESSES.READ_BUSINESSES]}
    key={ROUTES.BUSINESSES_LIST}
  />,
  <AdminRoute
    exact
    path={ROUTES.BUSINESS_ENTITY_ADD}
    layout={AdminRouteLayout}
    component={AsyncCreateBusinessEntity}
    requiredPermissions={[BUSINESSES.CREATE_BUSINESSES]}
    key={ROUTES.BUSINESS_ENTITY_ADD}
  />,
  <AdminRoute
    exact
    path={ROUTES.BUSINESS_ENTITY_AREAS}
    layout={AdminRouteLayout}
    component={AsyncBusinessEntityAreas}
    requiredPermissions={[BUSINESSES.READ_BUSINESSES]}
    key={ROUTES.BUSINESS_ENTITY_AREAS}
  />,
  <AdminRoute
    path={ROUTES.BUSINESS_ENTITY_ADD_INVOICE_GROUP}
    layout={AdminRouteLayout}
    component={AsyncBusinessEntityAddInvoiceGroup}
    requiredPermissions={[INVOICE_GROUPS.READ_INVOICE_GROUPS, BUSINESSES.READ_BUSINESSES]}
    key={ROUTES.BUSINESS_ENTITY_ADD_INVOICE_GROUP}
  />,
  <AdminRoute
    path={ROUTES.BUSINESS_ENTITY_EDIT_INVOICE_GROUP}
    layout={AdminRouteLayout}
    component={AsyncBusinessEntityEditInvoiceGroup}
    requiredPermissions={[INVOICE_GROUPS.UPDATE_INVOICE_GROUPS, BUSINESSES.READ_BUSINESSES]}
    key={ROUTES.BUSINESS_ENTITY_EDIT_INVOICE_GROUP}
  />,
  <AdminRoute
    path={ROUTES.BUSINESS_ENTITY_FINANCE}
    layout={AdminRouteLayout}
    component={AsyncBusinessEntityFinance}
    requiredPermissions={[INVOICES.READ_INVOICES, BUSINESSES.READ_BUSINESSES]}
    key={ROUTES.BUSINESS_ENTITY_FINANCE}
  />,
  <AdminRoute
    exact
    path={ROUTES.BUSINESS_ENTITY_BILLING_DETAILS}
    layout={AdminRouteLayout}
    component={AsyncBusinessEntityBillingDetails}
    requiredPermissions={[INVOICES.READ_INVOICES, BUSINESSES.READ_BUSINESSES]}
    key={ROUTES.BUSINESS_ENTITY_BILLING_DETAILS}
  />,
  <AdminRoute
    path={ROUTES.BUSINESS_ENTITY_REVIEWS}
    layout={AdminRouteLayout}
    component={AsyncBusinessEntityReviews}
    requiredPermissions={[REVIEWS.READ_REVIEWS, BUSINESSES.READ_BUSINESSES]}
    key={ROUTES.BUSINESS_ENTITY_REVIEWS}
  />,
  <AdminRoute
    path={ROUTES.BUSINESS_ENTITY_ATTACHMENTS}
    layout={AdminRouteLayout}
    component={AsyncBusinessEntityAttachments}
    requiredPermissions={[ATTACHMENTS.READ_ATTACHMENTS, BUSINESSES.READ_BUSINESSES]}
    key={ROUTES.BUSINESS_ENTITY_ATTACHMENTS}
  />,
  <AdminRoute
    exact
    path={ROUTES.BUSINESS_ENTITY_BUSINESSES}
    layout={AdminRouteLayout}
    component={AsyncBusiness}
    requiredPermissions={[BUSINESSES.READ_BUSINESSES]}
    key={ROUTES.BUSINESS_ENTITY_BUSINESSES}
  />,
  <AdminRoute
    exact
    path={ROUTES.BUSINESS_ENTITY_DOCUMENTS_ACKNOWLEDGED}
    layout={AdminRouteLayout}
    component={AsyncDocumentsAcknowledged}
    key={ROUTES.BUSINESS_ENTITY_DOCUMENTS_ACKNOWLEDGED}
  />,
  <AdminRoute
    path={ROUTES.BUSINESS_ENTITY_USERS}
    layout={AdminRouteLayout}
    component={AsyncBusinessEntityUsers}
    requiredPermissions={[BUSINESS_USERS.READ_BUSINESS_USERS, BUSINESSES.READ_BUSINESSES]}
    key={ROUTES.BUSINESS_ENTITY_USERS}
  />,
  <AdminRoute
    path={ROUTES.BUSINESS_ENTITY_TIMESHEETS}
    layout={AdminRouteLayout}
    component={AsyncBusinessEntityTimesheets}
    requiredPermissions={[TIMESHEETS.READ_TIMESHEETS, BUSINESSES.READ_BUSINESSES]}
    key={ROUTES.BUSINESS_ENTITY_TIMESHEETS}
  />,
  <AdminRoute
    exact
    path={ROUTES.BUSINESS_ENTITY_MY_UPSHIFTERS}
    layout={AdminRouteLayout}
    component={AsyncBusinessUpshifters}
    requiredPermissions={[UPSHIFTER_USERS.READ_UPSHIFTER_USERS, BUSINESSES.READ_BUSINESSES]}
    key={ROUTES.BUSINESS_ENTITY_MY_UPSHIFTERS}
  />,
  <AdminRoute
    exact
    path={ROUTES.BUSINESS_ENTITY_TEMPLATES}
    layout={AdminRouteLayout}
    component={AsyncTemplates}
    requiredPermissions={[TEMPLATES.READ_TEMPLATES, BUSINESSES.READ_BUSINESSES]}
    key={ROUTES.BUSINESS_ENTITY_TEMPLATES}
  />,
  <AdminRoute
    exact
    path={ROUTES.BUSINESS_ENTITY_TEMPLATES_ADD}
    layout={AdminRouteLayout}
    component={AsyncAddTemplate}
    requiredPermissions={[TEMPLATES.CREATE_TEMPLATES, BUSINESSES.READ_BUSINESSES]}
    key={ROUTES.BUSINESS_ENTITY_TEMPLATES_ADD}
  />,
  <AdminRoute
    exact
    path={ROUTES.BUSINESS_ENTITY_TEMPLATES_EDIT}
    layout={AdminRouteLayout}
    component={AsyncEditTemplate}
    requiredPermissions={[TEMPLATES.UPDATE_TEMPLATES, BUSINESSES.READ_BUSINESSES]}
    key={ROUTES.BUSINESS_ENTITY_TEMPLATES_EDIT}
  />,
  <AdminRoute
    exact
    path={ROUTES.BUSINESS_ENTITY_LOCATIONS}
    layout={AdminRouteLayout}
    component={AsyncBusinessEntityLocations}
    requiredPermissions={[PROPERTIES.READ_PROPERTIES, BUSINESSES.READ_BUSINESSES]}
    key={ROUTES.BUSINESS_ENTITY_LOCATIONS}
  />,
  <AdminRoute
    exact
    path={ROUTES.BUSINESS_ENTITY_SHIFTS}
    layout={AdminRouteLayout}
    component={AsyncBusinessEntityShifts}
    requiredPermissions={[SHIFTS.READ_SHIFTS, BUSINESSES.READ_BUSINESSES]}
    key={ROUTES.BUSINESS_ENTITY_SHIFTS}
  />,
  <AdminRoute
    exact
    path={ROUTES.BUSINESS_ENTITY_NOTES}
    layout={AdminRouteLayout}
    component={AsyncNotes}
    requiredPermissions={[BUSINESS_NOTES.READ_BUSINESS_NOTES, BUSINESSES.READ_BUSINESSES]}
    key={ROUTES.BUSINESS_ENTITY_NOTES}
  />,
  <AdminRoute
    exact
    path={ROUTES.BUSINESS_ENTITY_CHILDREN_COMPANIES}
    layout={AdminRouteLayout}
    component={AsyncBusinessEntityChildrenCompanies}
    key={ROUTES.BUSINESS_ENTITY_CHILDREN_COMPANIES}
  />,
  <AdminRoute
    exact
    path={ROUTES.BUSINESS_ENTITY_ACKNOWLEDGEMENTS}
    layout={AdminRouteLayout}
    component={AsyncBusinessEntityAcknowledgements}
    key={ROUTES.BUSINESS_ENTITY_ACKNOWLEDGEMENTS}
  />,
  <AdminRoute
    exact
    path={ROUTES.BUSINESS_ENTITY_EDIT}
    layout={AdminRouteLayout}
    component={AsyncEditBusinessEntity}
    requiredPermissions={[BUSINESSES.UPDATE_BUSINESSES]}
    key={ROUTES.BUSINESS_ENTITY_EDIT}
  />,
  <AdminRoute
    exact
    path={ROUTES.BUSINESS_ENTITY_VIEW}
    layout={AdminRouteLayout}
    component={AsyncBusinessEntity}
    requiredPermissions={[BUSINESSES.READ_BUSINESSES]}
    key={ROUTES.BUSINESS_ENTITY_VIEW}
  />,
  <AdminRoute
    exact
    path={ROUTES.LOCATIONS_ADD_BUSINESS_ENTITY}
    layout={AdminRouteLayout}
    component={AsyncAddLocation}
    requiredPermissions={[PROPERTIES.CREATE_PROPERTIES]}
    key={ROUTES.LOCATIONS_ADD_BUSINESS_ENTITY}
  />,
].filter(Boolean)

export default businessesRoutes
