import {
  IBlockPropertyUser,
  IBlockProperty,
  IDeleteBlockPropertiesParams,
  IGetBlockPropertiesParams,
  IGetBlockPropertiesUsersParams,
  IPostBlockPropertiesParams,
} from 'models/BlockProperty'
import { AxiosService } from '../config/config'
import { Endpoints } from '../config/endpoints'
import { IPayload } from 'models/Global'

export class BlockPropertiesApi {
  constructor(private axios: AxiosService) {}

  getBlockProperties = async (params?: IGetBlockPropertiesParams) => {
    const response = await this.axios.get<IPayload<IBlockProperty[]>>(
      Endpoints.getBlockProperties,
      params
    )
    return response
  }

  getBlockPropertiesUsers = async (params?: IGetBlockPropertiesUsersParams) => {
    const response = await this.axios.get<IPayload<IBlockPropertyUser[]>>(
      Endpoints.getBlockPropertiesUsers,
      params
    )
    return response
  }

  postBlockProperties = async (params: IPostBlockPropertiesParams) => {
    const response = await this.axios.post<IPayload<IBlockProperty[]>>(
      Endpoints.postBlockProperties,
      params
    )
    return response
  }

  deleteBlockProperties = async (params: IDeleteBlockPropertiesParams) => {
    const response = await this.axios.delete<{}>(Endpoints.deleteBlockProperties, params)
    return response
  }
}
