import { action, payload } from 'ts-action'
import { IAttachment } from 'models/Attachment'
import { IError, IPayload } from 'models/Global'

export const GetAttachments = action('GetAttachments', payload<IPayload<IAttachment[]>>())

export const GetAttachmentsErrors = action('GetAttachmentsErrors', payload<IError>())

export const ClearAttachments = action('ClearAttachments')

export const AttachmentsFetching = action('AttachmentsFetching', payload<boolean>())

export const AttachmentUploading = action('AttachmentUploading', payload<boolean>())

export const AddAttachment = action('AddAttachment', payload<IPayload<IAttachment>>())

export const AddAttachmentErrors = action('AddAttachmentErrors', payload<IError>())

export const DeleteAttachment = action('DeleteAttachment', payload<number>())
