import { Dispatch } from 'redux'
import {
  GetPunchCards as GetPunchCardsAction,
  GetPunchCardsErrors,
  PunchCardsFetching,
  AddPunchCard as AddPunchCardAction,
  DeletePunchCard as DeletePunchCardAction,
  UpdatePunchCard as UpdatePunchCardAction,
  ResetPunchCardsState,
} from './actions'
import { NotificationManager } from 'helpers/NotificationsManager'
import {
  IPostPunchCardParams,
  IGetPendingVerificationPunchCardsParam,
  IDeletePunchCardParams,
  IPutPendingVerificationPunchCardParams,
} from 'models/PunchCard'

import { API } from 'network'
import { handleErrorNotification } from 'services/ErrorHandlingService'
import { SetApprovePendingVerificationsLoadingId } from '../Loadings/actions'
import { CODE_481_ACTIONS } from 'network/config/error'

export type PendingVerificationPunchCardsActions =
  | ReturnType<typeof GetPunchCardsAction>
  | ReturnType<typeof GetPunchCardsErrors>
  | ReturnType<typeof PunchCardsFetching>
  | ReturnType<typeof AddPunchCardAction>
  | ReturnType<typeof UpdatePunchCardAction>
  | ReturnType<typeof DeletePunchCardAction>
  | ReturnType<typeof ResetPunchCardsState>
  | ReturnType<typeof SetApprovePendingVerificationsLoadingId>

export const getPendingVerificationPunchCards =
  (params?: IGetPendingVerificationPunchCardsParam) =>
  async (dispatch: Dispatch<PendingVerificationPunchCardsActions>) => {
    dispatch(PunchCardsFetching(true))
    try {
      const response = await API.PendingVerificationPunchCards.getPendingVerificationPunchCards(
        params
      )
      dispatch(GetPunchCardsAction(response))
      return response
    } catch (error) {
      dispatch(
        GetPunchCardsErrors({
          code: error.code,
          message: error.message,
        })
      )
      handleErrorNotification(error)
      throw Error(error)
    } finally {
      dispatch(PunchCardsFetching(false))
    }
  }

export const addPendingVerificationPunchCard =
  (params: IPostPunchCardParams) =>
  async (dispatch: Dispatch<PendingVerificationPunchCardsActions>) => {
    dispatch(PunchCardsFetching(true))
    try {
      const response = await API.PendingVerificationPunchCards.postPendingVerificationPunchCard(
        params
      )
      dispatch(AddPunchCardAction(response))
      NotificationManager.success('Pending Verification Punch Card created successfully.')
      return response
    } catch (error) {
      dispatch(GetPunchCardsErrors({ code: error.code, message: error.message }))
      if (error.action !== CODE_481_ACTIONS.OVERLAPPING_PUNCH_CARD) {
        handleErrorNotification(error)
      }
      throw error
    } finally {
      dispatch(PunchCardsFetching(false))
    }
  }

export const updatePendingVerificationPunchCard =
  (id: number, params: IPutPendingVerificationPunchCardParams) =>
  async (dispatch: Dispatch<PendingVerificationPunchCardsActions>) => {
    //   dispatch(PunchCardsFetching(true));
    let response = undefined
    try {
      response = await API.PendingVerificationPunchCards.putPendingVerificationPunchCard(id, params)
      dispatch(UpdatePunchCardAction(response))
      NotificationManager.success('PunchCard updated successfully.')
      return response
    } catch (error) {
      dispatch(
        GetPunchCardsErrors({
          code: error.code,
          message: error.message,
        })
      )
      if (error.action !== CODE_481_ACTIONS.OVERLAPPING_PUNCH_CARD) {
        handleErrorNotification(error)
      }
      throw error
    } finally {
      // dispatch(PunchCardsFetching(false));
    }
  }

export const approvePendingVerificationPunchCard =
  (id: number, params: IPutPendingVerificationPunchCardParams) =>
  async (dispatch: Dispatch<PendingVerificationPunchCardsActions>) => {
    dispatch(
      SetApprovePendingVerificationsLoadingId({
        id,
        isLoading: true,
      })
    )
    let response = undefined
    try {
      response = await API.PendingVerificationPunchCards.putPendingVerificationPunchCard(id, params)
      dispatch(DeletePunchCardAction(id))
      NotificationManager.success('PunchCard approved successfully.')
      return response
    } catch (error) {
      dispatch(
        GetPunchCardsErrors({
          code: error.code,
          message: error.message,
        })
      )
      if (error.action !== CODE_481_ACTIONS.OVERLAPPING_PUNCH_CARD) {
        handleErrorNotification(error)
      }
      throw error
    } finally {
      dispatch(
        SetApprovePendingVerificationsLoadingId({
          id,
          isLoading: false,
        })
      )
    }
  }

export const deletePendingVerificationPunchCard =
  (id: number, params: IDeletePunchCardParams) =>
  async (dispatch: Dispatch<PendingVerificationPunchCardsActions>) => {
    //   dispatch(PunchCardsFetching(true));
    try {
      const response = await API.PendingVerificationPunchCards.deletePendingVerificationPunchCard(
        id,
        params
      )
      dispatch(DeletePunchCardAction(id))
      NotificationManager.success('Punch Card deleted successfully.')
      return response
    } catch (error) {
      dispatch(
        GetPunchCardsErrors({
          code: error.code,
          message: error.message,
        })
      )
      handleErrorNotification(error)
      throw new Error(error)
    } finally {
      // dispatch(PunchCardsFetching(false));
    }
  }

export const getDeniedPendingVerificationPunchCards =
  (params?: IGetPendingVerificationPunchCardsParam) =>
  async (dispatch: Dispatch<PendingVerificationPunchCardsActions>) => {
    dispatch(PunchCardsFetching(true))
    try {
      const response =
        await API.PendingVerificationPunchCards.getDeniedPendingVerificationPunchCards(params)
      dispatch(GetPunchCardsAction(response))
      return response
    } catch (error) {
      dispatch(
        GetPunchCardsErrors({
          code: error.code,
          message: error.message,
        })
      )
      handleErrorNotification(error)
      throw Error(error)
    } finally {
      dispatch(PunchCardsFetching(false))
    }
  }

export const reinstatePendingVerificationPunchCard =
  (id: number) => async (dispatch: Dispatch<PendingVerificationPunchCardsActions>) => {
    dispatch(PunchCardsFetching(true))
    try {
      const response =
        await API.PendingVerificationPunchCards.reinstatePendingVerificationPunchCard(id)
      dispatch(DeletePunchCardAction(id))
      NotificationManager.success('Punch Card reinstated successfully.')
      return response
    } catch (error) {
      dispatch(
        GetPunchCardsErrors({
          code: error.code,
          message: error.message,
        })
      )
      handleErrorNotification(error)
      throw new Error(error)
    } finally {
      dispatch(PunchCardsFetching(false))
    }
  }
