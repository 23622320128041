import { IBaseReducer } from '../BaseReducer'
import { IBaseGetParams } from '../Global'
import { IInvoiceGroup } from '../InvoiceGroup'
import { IProperty } from '../Property'
import { ITimesheet } from '../Timesheet'
import { ICredit } from '../Credit'
import { IBusinessEntity } from '../BusinessEntity'
import { IUser } from '../User'
import { INote } from '../Note'
import { IInvoiceExpenseType } from '../InvoiceExpenseType'
import { INetOption } from 'models/NetOption'
import { AGING_REPORT_DUE_DATE } from 'models/Report'
import { PAYMENT_TYPE_ID } from 'helpers/constants/constants'
import { IPayment, PAYMENT_GATEWAY_ENUM, PAYMENT_STATUS } from 'models/Payment'

export enum PAYMENT_TYPE_NAME {
  LOW_CREDIT_CARD = 'low_credit_card',
  NET = 'net_30',
}

export enum INVOICE_PAID_STATUS {
  NOT_PAID,
  FULLY_PAID,
  PARTIALLY_PAID,
  OVERPAID,
  PROCESSING,
}

export enum INVOICE_SENT_STATUS {
  NOT_SENT,
  SENT,
}

export enum INVOICE_STATUS {
  PENDING,
  PROCESSED,
  DECLINED, // deprecated
  ACTIVE,
}

export interface IInvoiceIncludeParams {
  include?:
    | {
        business?: Record<any, any>
        net_option?: Record<any, any>
        payment_type?: Record<any, any>
        invoice_group?: Record<any, any>
        last_payment?: Record<any, any>
      }
    | ('business' | 'net_option' | 'payment_type' | 'invoice_group' | 'last_payment')[]
}

export interface IInvoice {
  id: number
  invoice_group_id: number
  business_id: number
  property_id: number
  property_name: string
  property_address: string
  invoice_number: number | null
  total: string | null
  // TODO we need to use this property in future
  // pay_total: string
  pay_total_2: string
  // TODO we need to use this property in future
  // sub_total: string
  sub_total_2: string
  // TODO we need to use this property in future
  // tax_total: string
  tax_total_2: string
  // TODO we need to use this property in future
  // commission_total: string
  commission_total_2: string
  from: Date
  to: Date
  paid: false
  paid_status: INVOICE_PAID_STATUS
  note: string | null
  pdf_url: string | null
  processed_at: string | null
  created_at: Date
  updated_at: Date
  status: INVOICE_STATUS
  total_difference: number
  amount_paid: string
  due_date: Date
  can_be_marked_as_sent: boolean
  remaining_invoice_value_total: number
  sent: boolean
  po_number: string
  sent_at: Date
  payment_status: PAYMENT_STATUS
  number_of_days_overdue: number
  business: IBusinessEntity
  payment_type: IPaymentType
  invoice_group: IInvoiceGroup
  is_pay_button_enabled: boolean
  payment_fee?: string
  total_with_payment_fee?: string
  net_option: INetOption
  last_payment?: IPayment
  payment_type_name: string

  /**
   * Start Deprecated
   */

  owner_id: number
  owner_full_name: string
  owner_company_name: string
  business_name: string
  net_option_name: string

  /**
   * End Deprecated
   */
}

export interface ILedgerInvoice {
  business: IBusinessEntity
  credits: ICredit[]
  expense_types: IInvoiceExpenseType[]
  invoice: IInvoice
  invoice_group: IInvoiceGroup
  properties: IProperty[]
  timesheets: ITimesheet[]
  user: IUser
  payment_type: IPaymentType
  net_option: INetOption
  last_payment?: IPayment
}

export interface IGetInvoicesParamsBase extends IBaseGetParams {
  invoice_group_id?: number
  business_id?: number
  payment_type?: string
  regions?: number[]
  status?: INVOICE_STATUS
  due_status?: AGING_REPORT_DUE_DATE
  order_by?: TInvoiceOrderBy
  can_be_marked_as_sent?: boolean
  overdue?: boolean
  date_end?: string
  date_start?: string
  processed_at_end_date?: string
  processed_at_start_date?: string
  payment_status?: PAYMENT_STATUS
  paid_status?: INVOICE_PAID_STATUS
}

export type IGetInvoicesParams = IGetInvoicesParamsBase & IInvoiceIncludeParams
export interface IGetInvoiceParamsBase {
  ledger?: boolean
}

export type IGetInvoiceParams = IGetInvoiceParamsBase & IInvoiceIncludeParams

export interface IGetInvoiceBatch {
  invoice_id: number
  invoice_group_id: number
}

export interface IPostInvoiceParamsBase {
  property_id: number
  from: Date
  to: Date
  status?: INVOICE_STATUS
  note?: string
  paid_status?: INVOICE_PAID_STATUS
}

export type IPostInvoiceParams = IPostInvoiceParamsBase & IInvoiceIncludeParams
export interface IPostInvoiceByEmailParams {
  sent: boolean
  email: IPostInvoiceEmail[]
}

interface IPostInvoiceEmail {
  title: string
  body: string
  encoded: boolean
  cc: string
  bcc: string
  attachments: IPostInvoiceEmailAttachment[]
}

interface IPostInvoiceEmailAttachment {
  name: string
  attachment: File
}

export interface IPutInvoiceParamsBase {
  status?: INVOICE_STATUS
  note?: string
  amount_paid?: number
  paid_status?: INVOICE_PAID_STATUS
  po_number?: string
}

export type IPutInvoiceParams = IPutInvoiceParamsBase & IInvoiceIncludeParams
export interface IPutProcessAlInvoices {
  invoice_group_id?: number
  business_id?: number
  payment_type?: string
  regions?: number[]
  status?: INVOICE_STATUS
}

export interface IInvoiceData {
  business?: IBusinessEntity
  invoice: IInvoice
  invoiceGroup: IInvoiceGroup
  properties: IProperty[]
  timesheets: ITimesheet[]
  credits: ICredit[]
  note: INote[]
  additionalExpenseTypes: IInvoiceExpenseType[]
  pre_ledger_invoice_payment_type_name?: string
  /**
   * [PAYMENT FLOW] For Processed Invoices, ledger invoices are fetched.
   * regularInvoice state is used to store the regular invoice alongside ledger invoice data.
   * Needed for PaymentFlow.
   */
  regularInvoice?: IInvoice
}

export interface IPostSendBulkEmail {
  ids: number[]
  payment_type?: string
  regions?: number[]
  status?: INVOICE_STATUS
}

export interface IGetTotalOpenBalanceParams extends IBaseGetParams {
  ids?: number[]
  payment_type?: string
  business_id?: number
  invoice_group_id?: number
  status?: INVOICE_STATUS
  paid?: boolean
  date_start?: string
  date_end?: string
  regions?: number[] | string[]
  paid_status?: INVOICE_PAID_STATUS
  sent?: boolean
  can_be_marked_as_sent?: boolean
}

export interface ITotalOpenBalance {
  total_amount: string
  open_balance: string
}

export type TInvoiceOrderBy = 'due_date' | 'business_name'

export interface IGetInvoicesStatement {
  business_id: number
}

export interface IInvoicesReducer extends IBaseReducer<IInvoice[]> {}

export interface IInvoiceReducer extends IBaseReducer<IInvoiceData> {
  payInvoiceLoading: boolean
}

export interface IPaymentType {
  id: PAYMENT_TYPE_ID
  name: PAYMENT_TYPE_NAME
  status: number
}

export interface IPayInvoiceParams {
  payment_gateway_enum: PAYMENT_GATEWAY_ENUM
}
