import {
  IPunchCard,
  IPutPunchCardParams,
  PUNCHCARD_REASON,
  PUNCHCARD_REASON_HOW,
} from 'models/PunchCard'
import { FEATURE_TOGGLE_PENDING_VERIFICATIONS } from 'config/featureToggles'
import { object, string, number, ValidationError } from 'yup'

export interface IDropdownData {
  value: PUNCHCARD_REASON_HOW
  label: string
}

export enum AreHoursApproved {
  No,
  Yes,
}

export type Values = {
  reasonForEdit: PUNCHCARD_REASON
  reasonForEditHow: IDropdownData | null
  zendeskTicketNumber: string
  note: string
}

export const mapEditFormValues = (punchCard: IPunchCard, values: Values) => {
  const { reasonForEdit, reasonForEditHow, zendeskTicketNumber } = values
  const params: IPutPunchCardParams = {}

  params.break = punchCard.break
  params.pay_value = Number(punchCard.pay_value)
  params.timestamp_start = punchCard.timestamp_start.toString()
  // If the user is still clocked in we shouldn't send the timestamp end param
  params.timestamp_end =
    !punchCard.isNoEndPunchCard && punchCard.timestamp_end
      ? punchCard.timestamp_end.toString()
      : undefined
  params.reason_for_edit = reasonForEdit
  params.note = values.note
  if (reasonForEditHow && reasonForEdit !== PUNCHCARD_REASON.REQUEST_BY_UPSHIFTER) {
    params.reason_for_edit_how = reasonForEditHow.value
  } else {
    params.reason_for_edit_how = PUNCHCARD_REASON_HOW.INTERCOM
  }
  if (
    reasonForEdit === PUNCHCARD_REASON.REQUEST_BY_UPSHIFTER ||
    (reasonForEditHow && reasonForEditHow.value === PUNCHCARD_REASON_HOW.INTERCOM)
  ) {
    params.zendesk_id = zendeskTicketNumber
  } else {
    params.zendesk_id = null
  }

  return params
}

export const reasonHowDropdownData: IDropdownData[] = [
  {
    value: PUNCHCARD_REASON_HOW.EMAIL,
    label: 'Email',
  },
  {
    value: PUNCHCARD_REASON_HOW.TEXT_MESSAGE,
    label: 'Text Message',
  },
  {
    value: PUNCHCARD_REASON_HOW.INTERCOM,
    label: 'Intercom',
  },
  {
    value: PUNCHCARD_REASON_HOW.CALL,
    label: 'Call',
  },
  {
    value: PUNCHCARD_REASON_HOW.TIMESHEET,
    label: 'Timesheet',
  },
]

export const editFormValidationSchema = (
  areHoursApproved?: AreHoursApproved,
  isTimesheetProcessed?: boolean
) => {
  if (
    isTimesheetProcessed &&
    areHoursApproved === AreHoursApproved.No &&
    FEATURE_TOGGLE_PENDING_VERIFICATIONS
  ) {
    return object().shape({
      note: string()
        .required('Required')
        .trim()
        .test('note', 'Please enter a valid value', (value: string) => {
          if (value && value.includes('intercom.com')) {
            return true
          }
          return new ValidationError('Please enter a valid intercom link.', null, 'note')
        }),
    })
  } else {
    return object().shape({
      zendeskTicketNumber: string()
        .when('reasonForEdit', {
          is: (val) => val && val === PUNCHCARD_REASON.REQUEST_BY_UPSHIFTER,
          then: string()
            .required('Required')
            .trim()
            .test('is-valid-url', 'Must be a valid URL with www. or https://', (value) =>
              /^(https?:\/\/|www\.)/.test(value)
            ),
        })
        .when('reasonForEditHow', {
          is: (val) => val && val.value === PUNCHCARD_REASON_HOW.INTERCOM,
          then: string()
            .required('Required')
            .trim()
            .test('is-valid-url', 'Must be a valid URL with www. or https://', (value) =>
              /^(https?:\/\/|www\.)/.test(value)
            ),
        }),
      reasonForEditHow: object()
        .when('reasonForEdit', {
          is: (val) => val === PUNCHCARD_REASON.REQUEST_BY_BUSINESS,
          then: object()
            .required('Required')
            .shape({
              value: number(),
              label: string(),
            })
            .nullable(),
        })
        .nullable(),
      reasonForEdit: number().required('Reason for edit is required!').nullable(),
    })
  }
}
