import styled from 'styled-components'

export const LoaderContainer = styled.div<{ offset: string }>`
  height: 100vh;
  top: 0;
  left: ${({ offset }) => offset};
  right: 0;
  position: fixed;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 2;
`
