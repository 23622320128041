import { IRegionsReducer, IRegion } from 'models/Region'
import { Action } from 'redux'
import { isType } from 'ts-action'
import { GetRegions, RegionsFetching, UpdateRegion } from './actions'
import { updateArray } from 'helpers/helperFunctions'

const initialState: IRegionsReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: [],
}

export const regionsReducer = (state = initialState, action: Action): IRegionsReducer => {
  if (isType(action, GetRegions)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
    }
  }
  if (isType(action, RegionsFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }
  if (isType(action, UpdateRegion)) {
    const { payload } = action
    const { data } = state
    const formattedArray = updateArray<IRegion>(data, payload.data)
    return {
      ...state,
      data: formattedArray,
    }
  }
  return state
}
