import { Flex, Text } from '@pinchenterprisesnpm/friday-ui'
import styled from 'styled-components'

export const FooterWrapper = styled(Flex)`
  background: ${({ theme: { palette } }) => palette.white};
  padding: ${({ theme: { mp } }) => mp.md};
`

export const ContentText = styled(Text)`
  font-size: ${({
    theme: {
      font: { sizes },
    },
  }) => sizes.medium};
`
