import { IChangePasswordParams, IForgotPasswordParams, IResetPasswordParams } from 'models/Password'
import { AxiosService } from '../config/config'
import { Endpoints } from '../config/endpoints'

export class PasswordApi {
  constructor(private axios: AxiosService) {}

  forgotPassword = async (params: IForgotPasswordParams) => {
    const response = await this.axios.post<{}>(Endpoints.forgotPassword, params)
    return response
  }

  resetPassword = async (params: IResetPasswordParams) => {
    const response = await this.axios.post<{}>(Endpoints.resetPassword, params)
    return response
  }

  changePassword = async (params: IChangePasswordParams) => {
    const response = await this.axios.post<{}>(Endpoints.changePassword, params)
    return response
  }
}
