import { action, payload } from 'ts-action'
import { IError, IPayload } from 'models/Global'
import { IShift } from 'models/Shift'

export const GetFeaturedShiftCampaignAllShifts = action(
  'GetFeaturedShiftCampaignAllShifts',
  payload<IPayload<(IShift & { selected: boolean })[]>>()
)

export const GetFeaturedShiftCampaignAllShift = action(
  'GetFeaturedShiftCampaignAllShift',
  payload<IPayload<IShift>>()
)

export const AddFeaturedShiftCampaignAllShift = action(
  'AddFeaturedShiftCampaignAllShift',
  payload<IPayload<IShift>>()
)

export const DeleteFeaturedShiftCampaignAllShift = action(
  'DeleteFeaturedShiftCampaignAllShift',
  payload<number>()
)

export const FakeDeleteFeaturedShiftCampaignAllShift = action(
  'FakeDeleteFeaturedShiftCampaignAllShift',
  payload<number>()
)

export const FakeDeleteFeaturedShiftCampaignAllShifts = action(
  'FakeDeleteFeaturedShiftCampaignAllShifts'
)

export const UpdateFeaturedShiftCampaignAllShift = action(
  'UpdateFeaturedShiftCampaignAllShift',
  payload<IPayload<IShift>>()
)

export const GetFeaturedShiftCampaignAllShiftsErrors = action(
  'GetFeaturedShiftCampaignAllShiftsErrors',
  payload<IError>()
)

export const FeaturedShiftCampaignAllShiftsFetching = action(
  'FeaturedShiftCampaignAllShiftsFetching',
  payload<boolean>()
)
