import { IBaseGetParams } from '../Global'
import { IBaseReducer } from '../BaseReducer'

export interface INotificationPreference {
  notification_type_id: number
  notification_type_name: string
  notification_type_description: string
  notification_channel_id: number
  notification_channel_name: string
  notification_type_position: number
  id: number
  status: NOTIFICATION_PREFERENCE_STATUS
  notification_type?: INotificationType
  notification_channel?: INotificationChannel
}

export enum NOTIFICATION_TYPE_STATUS {
  INACTIVE,
  ACTIVE,
}

export enum NOTIFICATION_CHANNEL_STATUS {
  INACTIVE,
  ACTIVE,
}

export interface INotificationType {
  description: string
  id: number
  name: string
  status: NOTIFICATION_TYPE_STATUS
}

export interface INotificationChannel {
  description: string
  id: number
  name: string
  status: NOTIFICATION_CHANNEL_STATUS
}

export interface IGetNotificationPreferencesParams extends IBaseGetParams {
  status?: NOTIFICATION_PREFERENCE_STATUS
  user_id?: number
  role_id?: number
  visible_by_admin_only?: boolean
}

export interface IPostNotificationPreferenceParams {
  notification_channel_id: number
  notification_type_id: number
  status?: NOTIFICATION_PREFERENCE_STATUS
}

export interface IPutNotificationPreferenceParams extends INotificationPreferenceIncludeParams {
  status: NOTIFICATION_PREFERENCE_STATUS
}

export interface INotificationPreferenceIncludeParams {
  include?:
    | {
        notification_type?: Record<any, any>
        notification_channel?: Record<any, any>
      }
    | ('notification_type' | 'notification_channel')[]
}

export interface INotificationPreferenceReducer extends IBaseReducer<INotificationPreference[]> {
  notificationPreference?: INotificationPreference
}

export enum NOTIFICATION_CHANNEL_NAME {
  TEXT = 'Text',
  SMS = 'Sms',
  EMAIL = 'Email',
  PUSH = 'Push',
}

export enum NOTIFICATION_PREFERENCE_STATUS {
  DISABLED,
  ENABLED,
}
