import { IInviteApplicant, IInviteApplicantsReducer } from 'models/InviteApplicants'
import { Action } from 'redux'
import { isType } from 'ts-action'
import { ClearInviteApplicants, GetInviteApplicants, InviteApplicantsFetching } from './actions'
import { FavoriteUpshifter, UnfavoriteUpshifter } from 'data/Favorite/actions'

const initialState: IInviteApplicantsReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: [],
}

export const inviteApplicantsReducer = (
  state = initialState,
  action: Action
): IInviteApplicantsReducer => {
  if (isType(action, GetInviteApplicants)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
    }
  }

  if (isType(action, InviteApplicantsFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }

  // Favorite and Unfavorite Upshifter
  if (isType(action, FavoriteUpshifter)) {
    const { payload } = action
    const newApplications = state.data.map((application) => {
      if (application.id === action.payload.data.model_favored.id) {
        const modifiedApplication = application
        modifiedApplication.favorite_id_by_business = payload.data.id
        return modifiedApplication
      }
      return application
    })
    return {
      ...state,
      data: newApplications,
    }
  }

  if (isType(action, UnfavoriteUpshifter)) {
    const { payload } = action
    const modifiedApplications = state.data.map((application) => {
      if (application.favorite_id_by_business === payload) {
        const modifiedApplication: IInviteApplicant = {
          ...application,
          favorite_id_by_business: null,
        }
        return modifiedApplication
      }
      return application
    })
    return {
      ...state,
      data: modifiedApplications,
    }
  }

  if (isType(action, ClearInviteApplicants)) {
    return {
      ...state,
      data: [],
    }
  }

  return state
}
