import { AxiosService } from '../config/config'
import { Endpoints } from '../config/endpoints'
import { IPayload } from 'models/Global'
import {
  IGetInstructionsParams,
  IInstruction,
  IPostInstructionParams,
  IPutInstructionParams,
} from 'models/Instruction'

export class InstructionsApi {
  constructor(private axios: AxiosService) {}

  getInstructions = async (params?: IGetInstructionsParams) => {
    const response = await this.axios.get<IPayload<IInstruction[]>>(
      Endpoints.getInstructions,
      params
    )
    return response
  }

  getInstruction = async (id: number) => {
    const response = await this.axios.get<IPayload<IInstruction>>(Endpoints.getInstruction(id))
    return response
  }

  postInstruction = async (params?: IPostInstructionParams) => {
    const response = await this.axios.post<IPayload<IInstruction>>(
      Endpoints.postInstruction,
      params
    )
    return response
  }

  putInstruction = async (id: number, params: IPutInstructionParams) => {
    const response = await this.axios.put<IPayload<IInstruction>>(
      Endpoints.putInstruction(id),
      params
    )
    return response
  }

  deleteInstruction = async (id: number) => {
    const response = await this.axios.delete<{}>(Endpoints.deleteInstruction(id))
    return response
  }
}
