import { IValidationErrors, TCode481Action } from 'models/Global'

export class Error {
  code: number
  message: string
  validationErrors?: IValidationErrors | string
  action?: TCode481Action // Defined only when code is 481
  constructor(
    code: number,
    message: string,
    validationErrors?: IValidationErrors | string,
    action?: TCode481Action
  ) {
    this.code = code
    this.message = message
    this.validationErrors = validationErrors
    this.action = action
  }
}

export const STATUS_CODES = {
  INVALID_CREDENTIALS: 401,
  NOT_FOUND: 404,
  UNPROCESSABLE_ENTITY: 422,
  SPECIAL_ACTIONS: 481,
} as const

// Object containing all special 481 code actions
// Each action has a comment with the code it used to respond with
export const CODE_481_ACTIONS = {
  UPSHIFTER_NOT_ACCEPTED_FOR_GIG_DAY: 'business_add_punch_card_on_non_accepted_day', // 469
  // Action returned when creating/updating multi level achievement with only one level and activate=true
  // The user can create/update multi level achievement with only one level, but cannot activate it
  ACHIEVEMENT_HAS_ONE_LEVEL: 'achievement_has_one_level', // 473
  ADMIN_TRIES_TO_CREATE_APPLICATION_FOR_UPSHIFTER_WITHOUT_ACKNOWLEDGED_DOCS:
    'add_application_acknowledged_documents_missing_error', // 480
  BUSINESS_SUSPENDED: 'business_suspended', // 482
  FLAGGED_WORDS: 'flagged_words', // 486
  // Action returned when transferring a punch card to a multiday shift without specifying gig_day_id
  PUNCH_CARD_TRANSFER_MULTIDAY_GIG: 'punch_card_transfer_to_multiday_shift', // 487
  // Action returned when punch card transfer causes overtime between multiple businesses for the Upshifter
  PUNCH_CARD_TRANSFER_LEADS_TO_OVERTIME_MULTIPLE_BUSINESSES:
    'punch_card_transfer_leads_to_overtime_multiple_businesses', // 488
  OVERLAPPING_PUNCH_CARD: 'overlapping_punch_card', // 489
  TRAINING_MODE: 'add_application_training_shift_already_completed_error', // 490
  WILL_NOT_PASS_BACKGROUND_CHECK: 'add_application_no_background_check_error', // 492
  ADMIN_TRIES_TO_CREATE_APPLICATION_FOR_GIG_WITH_UNMET_SCREENING_REQUIREMENTS:
    'add_application_no_screening_badges_error', // 494
  USER_ONE_BUSINESS_OVERTIME: 'add_application_overtime_business_error', // 495
  GIG_OVERTIME: 'overtime_validation', // 496
  USER_MULTIPLE_BUSINESS_OVERTIME: 'add_application_overtime_businesses_error', // 497
  TOKEN_EXPIRED: 'token_expired', // 498
  TOKEN_INVALID: 'token_invalid', // 499
  RESTRICT_LAST_MINUTE_ADDITIONS_OVERRIDE: 'restrict_last_minute_additions_override', // 468
  //
  // The following actions are not used in the app
  //
  CHALLENGE_HAS_PROGRESS: 'challenge_has_progress', // 471
  WRONG_MOBILE_APPLICATION_LOG_IN: 'wrong_mobile_application_log_in', // 472
  USER_CANT_VIEW_GIG: 'user_cant_view_shift_error', // 474
  GEOFENCING_LOCATION_OUT_OF_RANGE: 'geofencing_location_out_of_range', // 475
  GEOFENCING_LOCATION_IS_NOT_SENT: 'geofencing_location_is_not_sent', // 476
  UPSHIFTER_HAS_EXCUSED_ABSENCE: 'upshifter_excused_absence_validation', // 477
  SHORT_GIG_UNPAID_BREAK: 'short_gig_unpaid_break', // 478
  MISSING_COVID_VACCINE_CERTIFICATE: 'missing_covid_vaccine_certificate', // 479
  GIG_IS_FULL: 'gig_is_full', // 485
  TIMESHEET_LOCKED_BY_OTHER_USER: 'timesheet_locked_by_other_user', // 491
  PENDING_SCREENING_APPLICATION_EXISTS: 'pending_screening_application_exists', // 493
  GROUP_CREATOR_IS_MISSING_BG_CHECK: 'group_creator_pending_bg_check',
} as const

export const POST_APPLICATION_481_ACTIONS: TCode481Action[] = [
  CODE_481_ACTIONS.TRAINING_MODE,
  CODE_481_ACTIONS.WILL_NOT_PASS_BACKGROUND_CHECK,
  CODE_481_ACTIONS.ADMIN_TRIES_TO_CREATE_APPLICATION_FOR_GIG_WITH_UNMET_SCREENING_REQUIREMENTS,
  CODE_481_ACTIONS.USER_ONE_BUSINESS_OVERTIME,
  CODE_481_ACTIONS.USER_MULTIPLE_BUSINESS_OVERTIME,
  CODE_481_ACTIONS.ADMIN_TRIES_TO_CREATE_APPLICATION_FOR_UPSHIFTER_WITHOUT_ACKNOWLEDGED_DOCS,
  CODE_481_ACTIONS.RESTRICT_LAST_MINUTE_ADDITIONS_OVERRIDE,
]

export const UNPROCESSABLE_ENTITY_GENERAL_MESSAGE = 'Unprocessable entity'
