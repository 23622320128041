import {
  LoginErrors,
  LoginFetching,
  Login as LoginAction,
  SetIsAuthUser,
  SetUserData,
  ClearAuthMeta,
  TwoFactorVerifying,
  ResendingTwoFactorCode,
  ClearLoginErrors,
  ClearAuthReducer,
  ClearMeta,
} from './actions'
import { IAuthLogin, IVerifyTwoFactorCodeParams } from 'models/Auth'
import { Dispatch } from 'redux'
import { API } from 'network'
import { history } from 'helpers/history'
import { NotificationManager } from 'helpers/NotificationsManager'
import AuthService from 'services/AuthService'
import { isAdminUserByRoleName, isUserRoleAllowed } from 'helpers/authHelpers'
import { handleErrorNotification } from 'services/ErrorHandlingService'
import { TrackingService } from 'helpers/TrackingService'

export type LoginActions =
  | ReturnType<typeof LoginAction>
  | ReturnType<typeof LoginErrors>
  | ReturnType<typeof LoginFetching>
  | ReturnType<typeof SetIsAuthUser>
  | ReturnType<typeof SetUserData>
  | ReturnType<typeof ClearAuthMeta>
  | ReturnType<typeof TwoFactorVerifying>
  | ReturnType<typeof ResendingTwoFactorCode>
  | ReturnType<typeof ClearLoginErrors>
  | ReturnType<typeof ClearAuthReducer>
  | ReturnType<typeof ClearMeta>

export const login = (params: IAuthLogin) => async (dispatch: Dispatch<LoginActions>) => {
  dispatch(LoginFetching(true))
  try {
    const response = await API.Auth.login(params)
    if (response.meta.auth.tfa_status) {
      // If the user is required to two factor authenticate, then write the whole response to the reducer (The meta contains only a boolean, so no token)
      dispatch(LoginAction(response))
      dispatch(ClearMeta())
      history.push('/2fa')
    } else {
      // Else just Set the user isAuth boolean to true and set the logged in user data (Only if the user role is allowed for this app)
      const userRole = response.data.roles[0].name
      if (isUserRoleAllowed(userRole)) {
        AuthService.setAuthAndUserDataInLocalStorage(response)
        dispatch(SetUserData(response.data))
        dispatch(SetIsAuthUser())
        if (isAdminUserByRoleName(userRole)) {
          history.push('/shifts')
        } else {
          history.push('/onboarder/upshifter_list')
        }
      }
    }
  } catch (error) {
    dispatch(
      LoginErrors({
        code: error.code,
        message: error.title,
      })
    )
    handleErrorNotification(error)
  } finally {
    dispatch(LoginFetching(false))
  }
}

export const logout = () => async (dispatch: Dispatch<LoginActions>) => {
  try {
    await API.Auth.logout()
  } catch (error) {
  } finally {
    dispatch(ClearAuthReducer())
    AuthService.ClearLocalStorage()
    TrackingService.reset()
    history.push('/logout')
  }
}

export const verifyTwoFactorCode =
  (id: number, params: IVerifyTwoFactorCodeParams) => async (dispatch: Dispatch<LoginActions>) => {
    dispatch(TwoFactorVerifying(true))
    try {
      const response = await API.Auth.verifyTwoFactorCode(id, params)
      const userRole = response.data.roles[0].name
      if (isUserRoleAllowed(userRole) && response.meta.auth.token) {
        AuthService.setAuthAndUserDataInLocalStorage(response)
      }
      dispatch(SetUserData(response.data))
      dispatch(ClearAuthMeta())
      dispatch(SetIsAuthUser())
      if (isAdminUserByRoleName(userRole)) {
        history.push('/shifts')
      } else {
        history.push('/onboarder/upshifter_list')
      }
    } catch (error) {
      dispatch(
        LoginErrors({
          code: error.code,
          message: error.message,
        })
      )
      handleErrorNotification(error)
    } finally {
      dispatch(TwoFactorVerifying(false))
    }
  }

export const resendVerifyCode = (id: number) => async (dispatch: Dispatch<LoginActions>) => {
  dispatch(ResendingTwoFactorCode(true))
  dispatch(ClearMeta())
  try {
    await API.Auth.resendVerifyCode(id)
    NotificationManager.success('A new verification code was sent successfully.')
  } catch (error) {
    dispatch(
      LoginErrors({
        code: error.code,
        message: error.message,
      })
    )
    handleErrorNotification(error)
  } finally {
    dispatch(ResendingTwoFactorCode(false))
  }
}

export const clearMeta = () => async (dispatch: Dispatch<LoginActions>) => {
  dispatch(ClearMeta())
}
