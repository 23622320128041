import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const HeaderWrapper = styled.div`
  padding: ${({ theme: { mp } }) => mp.xs} ${({ theme: { mp } }) => mp.sm};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${({ theme: { palette } }) => palette.light};
  min-height: 50px;
  max-height: 60px;
  position: relative;
  background-color: ${(props) => props.theme.palette.white};
`

export const HeaderLogo = styled.div``

export const HeaderUser = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  cursor: pointer;

  &:before {
    position: absolute;
    content: '';
    height: 100%;
    background-color: ${({ theme: { palette } }) => palette.primary};
    width: 1px;
  }
`

export const HeaderUserName = styled.div`
  margin: 0 ${({ theme: { mp } }) => mp.sm};
`

export const HeaderUserAvatar = styled.div``

export const HeaderLogoImg = styled.img`
  max-height: 45px;
  &:hover {
    cursor: pointer;
  }
`

export const HeaderMenu = styled.div`
  width: 100%;
  height: auto;
  position: absolute;
  top: 110%;
  right: 0;
  background: white;
  cursor: auto;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 10;
`

export const HeaderMenuItem = styled.div`
  text-align: left;
  padding: ${({ theme: { mp } }) => mp.sm};
  cursor: pointer;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  &:hover {
    background-color: ${({ theme: { palette } }) => palette.light};
  }
`

export const DrawerContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const UserInfoEmail = styled.div`
  font-weight: ${({
    theme: {
      font: { weights },
    },
  }) => weights.thin};
  margin-bottom: ${({ theme: { mp } }) => mp.sm};
`

export const UserInfoName = styled.div`
  margin: ${({ theme: { mp } }) => mp.sm} 0;
`
const ButtonResetStyles = styled.button`
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
  vertical-align: middle;

  &:focus,
  &:hover {
    outline: none;
  }

  /* Remove excess padding and border in Firefox 4+ */
  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
`

export const MenuButton = styled(ButtonResetStyles)`
  display: inline-flex;
  justify-content: center;
  align-items: center;
`

export const HeaderTitle = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${({
    theme: {
      font: { sizes },
    },
  }) => sizes.medium};
  font-weights: ${({
    theme: {
      font: { weights },
    },
  }) => weights.normal};
  pointer-events: none;
`

export const MobileMenuItem = styled(Link)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: ${({ theme: { mp } }) => mp.md} 0;
  color: ${({ color }) => color};
  text-decoration: none;

  &:first-child {
    margin-top: 0;
  }
`

export const MenuImg = styled.img`
  width: 20px;
  height: 20px;
  margin-right: ${({ theme: { mp } }) => mp.sm};
`

export const MenuIconWrapper = styled.div`
  margin-right: ${({ theme: { mp } }) => mp.sm};
`

export const MobileMenu = styled.div`
  width: 100%;
`
