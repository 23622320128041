import { action, payload } from 'ts-action'
import { IError, IPayload } from '../../models/Global'
import { IGroup } from '../../models/Group'

export const GetGroups = action('GetGroups', payload<IPayload<IGroup[]>>())

export const GetGroupsErrors = action('GetGroupsErrors', payload<IError>())

export const GroupsFetching = action('GroupsFetching', payload<boolean>())

export const DeleteGroup = action('DeleteGroup', payload<number>())
