import { action, payload } from 'ts-action'
import { IError, IPayload } from 'models/Global'
import { ICountry } from 'models/Country'

export const GetCountries = action('GetCountries', payload<IPayload<ICountry[]>>())

export const UpdateCountry = action('UpdateCountry', payload<IPayload<ICountry>>())

export const GetCountriesErrors = action('GetCountriesErrors', payload<IError>())

export const CountriesFetching = action('CountriesFetching', payload<boolean>())

export const UpdateCountryId = action('UpdateCountryId', payload<number>())
