import { AxiosService } from '../config/config'
import { Endpoints } from '../config/endpoints'
import { IPayload } from 'models/Global'
import {
  ITemplate,
  IGetTemplatesParams,
  IPutTemplateParams,
  IPostTemplateParams,
} from 'models/Template'

export class TemplatesApi {
  constructor(private axios: AxiosService) {}

  getTemplates = async (params?: IGetTemplatesParams) => {
    const response = await this.axios.get<IPayload<ITemplate[]>>(Endpoints.getTemplates, params)
    return response
  }

  getTemplate = async (id: number, params?: IGetTemplatesParams) => {
    const response = await this.axios.get<IPayload<ITemplate>>(Endpoints.getTemplate(id), params)
    return response
  }

  postTemplate = async (params: IPostTemplateParams) => {
    const response = await this.axios.post<IPayload<ITemplate>>(Endpoints.postTemplate, params)
    return response
  }

  putTemplate = async (id: number, params?: IPutTemplateParams) => {
    const response = await this.axios.put<IPayload<ITemplate>>(Endpoints.putTemplate(id), params)
    return response
  }

  deleteTemplate = async (id: number) => {
    const response = await this.axios.delete<{}>(Endpoints.deleteTemplate(id))
    return response
  }
}
