import { Dispatch } from 'redux'
import {
  GetSelectedShiftGigDays as GetSelectedShiftGigDaysAction,
  GetSelectedShiftGigDaysErrors,
  SelectedShiftGigDaysFetching,
} from './actions'
import { API } from 'network'
import { IGetGigDaysParams, IGigDay } from 'models/GigDay'
import { IPayload } from 'models/Global'

export type GetSelectedShiftGigDaysActions =
  | ReturnType<typeof GetSelectedShiftGigDaysAction>
  | ReturnType<typeof GetSelectedShiftGigDaysErrors>
  | ReturnType<typeof SelectedShiftGigDaysFetching>

export const getSelectedShiftGigDays =
  (params?: IGetGigDaysParams) => async (dispatch: Dispatch<GetSelectedShiftGigDaysActions>) => {
    dispatch(SelectedShiftGigDaysFetching(true))
    try {
      const response = await API.GigDays.getGigDays(params)
      dispatch(GetSelectedShiftGigDaysAction(response as IPayload<IGigDay[]>))
    } catch (error) {
      dispatch(
        GetSelectedShiftGigDaysErrors({
          code: error.code,
          message: error.message,
        })
      )
    } finally {
      dispatch(SelectedShiftGigDaysFetching(false))
    }
  }
