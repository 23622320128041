import { IPayload } from 'models/Global'
import {
  IAutoAcceptCertificate,
  IGetAutoAcceptCertificatesParams,
  IPutAutoAcceptCertificateParams,
  IPostAutoAcceptCertificateParams,
} from 'models/AutoAcceptCertificate'
import { Endpoints } from 'network/config/endpoints'
import { AxiosService } from 'network/config/config'

export class TemplateAutoAcceptCertificatesApi {
  constructor(private axios: AxiosService) {}

  getTemplateAutoAcceptCertificates = async (params?: IGetAutoAcceptCertificatesParams) => {
    const response = await this.axios.get<IPayload<IAutoAcceptCertificate[]>>(
      Endpoints.getTemplateAutoAcceptCertificates,
      params
    )
    return response
  }

  getTemplateAutoAcceptCertificate = async (
    id: number,
    params?: IGetAutoAcceptCertificatesParams
  ) => {
    const response = await this.axios.get<IPayload<IAutoAcceptCertificate>>(
      Endpoints.getTemplateAutoAcceptCertificate(id),
      params
    )
    return response
  }

  postTemplateAutoAcceptCertificate = async (params: IPostAutoAcceptCertificateParams) => {
    const response = await this.axios.post<IPayload<IAutoAcceptCertificate>>(
      Endpoints.postTemplateAutoAcceptCertificate,
      params
    )
    return response
  }

  putTemplateAutoAcceptCertificate = async (
    id: number,
    params?: IPutAutoAcceptCertificateParams
  ) => {
    const response = await this.axios.put<IPayload<IAutoAcceptCertificate>>(
      Endpoints.putTemplateAutoAcceptCertificate(id),
      params
    )
    return response
  }

  deleteTemplateAutoAcceptCertificate = async (id: number) => {
    const response = await this.axios.delete<{}>(Endpoints.deleteTemplateAutoAcceptCertificate(id))
    return response
  }
}
