import { IChallenge, IChallengeReducer } from '../../models/Challenge'
import { Action } from 'redux'
import { isType } from 'ts-action'
import { GetChallenge, ChallengeFetching, ResetChallenge } from './actions'

const initialState: IChallengeReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: {} as IChallenge,
}

export const challengeReducer = (state = initialState, action: Action): IChallengeReducer => {
  if (isType(action, GetChallenge)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
    }
  }
  if (isType(action, ChallengeFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }
  if (isType(action, ResetChallenge)) {
    return initialState
  }
  return state
}
