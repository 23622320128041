import { IPayload } from '../../models/Global'
import {
  IUpshifterExperience,
  IGetUpshifterExperienceParams,
} from '../../models/UpshifterExperience'
import { AxiosService } from '../config/config'
import { Endpoints } from '../config/endpoints'

export class UpshifterExperiencesApi {
  constructor(private axios: AxiosService) {}

  getUpshifterExperiences = async (params?: IGetUpshifterExperienceParams) => {
    const response = await this.axios.get<IPayload<IUpshifterExperience[]>>(
      Endpoints.getUpshifterExperiences,
      params
    )
    return response
  }
}
