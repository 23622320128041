import { AxiosService } from '../config/config'
import { Endpoints } from '../config/endpoints'
import { IPayload } from 'models/Global'
import {
  IAttachmentTitle,
  IGetAttachmentTitlesParams,
  IPostAttachmentTitleParams,
  IPutAttachmentTitleParams,
} from 'models/AttachmentTitle'

export class AttachmentTitlesApi {
  constructor(private axios: AxiosService) {}

  getAttachmentTitles = async (params?: IGetAttachmentTitlesParams) => {
    const response = await this.axios.get<IPayload<IAttachmentTitle[]>>(
      Endpoints.getAttachmentTitles,
      params
    )
    return response
  }

  getAttachmentTitle = async (id: number) => {
    const response = await this.axios.get<IPayload<IAttachmentTitle>>(
      Endpoints.getAttachmentTitle(id)
    )
    return response
  }

  postAttachmentTitle = async (params: IPostAttachmentTitleParams) => {
    const response = await this.axios.post<IPayload<IAttachmentTitle>>(
      Endpoints.postAttachmentTitle,
      params
    )
    return response
  }

  putAttachmentTitle = async (id: number, params: IPutAttachmentTitleParams) => {
    const response = await this.axios.put<IPayload<IAttachmentTitle>>(
      Endpoints.putAttachmentTitle(id),
      params
    )
    return response
  }

  deleteAttachmentTitle = async (id: number) => {
    const response = await this.axios.delete<{}>(Endpoints.deleteAttachmentTitle(id))
    return response
  }
}
