import styled from 'styled-components'

export const FooterWrapper = styled.div`
  background: ${(props) => props.theme.palette.white};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: ${(props) => `${props.theme.mp.md}`};
`

export const CustomValidationError = styled.span`
  color: #eb4d4b;
  font-size: ${(props) => props.theme.font.sizes.small};
`

export const FormInputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  padding-bottom: ${(props) => `${props.theme.mp.md}`};
`

export const FormInputLabel = styled.span`
  font-size: ${(props) => props.theme.font.sizes.default};
  font-weight: ${(props) => props.theme.font.weights.regular};
  padding-bottom: ${(props) => `${props.theme.mp.xs}`};
`

export const RadioGroupWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`

export const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`
