import { IFeaturedShiftCampaignShiftsReducer } from 'models/FeaturedShiftCampaign'
import { Action } from 'redux'
import { isType } from 'ts-action'
import { updateArray, deleteFromArray } from 'helpers/helperFunctions'
import {
  GetFeaturedShiftCampaignShifts,
  FeaturedShiftCampaignShiftsFetching,
  UpdateFeaturedShiftCampaignShift,
  DeleteFeaturedShiftCampaignShift,
  AddFeaturedShiftCampaignShift,
  FakeDeleteFeaturedShiftCampaignShifts,
  MarkFetchedFeaturedShiftCampaignShifts,
  ResetFeaturedShiftCampaignShifts,
} from './actions'
import { IShift } from 'models/Shift'

const initialState: IFeaturedShiftCampaignShiftsReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: true,
  areFetched: false,
  data: [],
  // Shifts that are removed in the UI, but the campaign is not yet saved (no API request sent).
  removedShifts: [],
  // Shifts that are added in the UI, but the campaign is not yet saved (no API request sent).
  addedShifts: [],
}

export const FeaturedShiftCampaignShiftsReducer = (
  state = initialState,
  action: Action
): IFeaturedShiftCampaignShiftsReducer => {
  if (isType(action, GetFeaturedShiftCampaignShifts)) {
    const { payload } = action
    // User may have removed some shifts in the UI, but navigates to another page in the table (GET API request).
    // This server data may contain some shifts that the user has marked as removed from the campaign in the UI, so we filter the received data to remove those.
    let filteredData = payload.data.filter((shift) => !state.removedShifts.includes(shift.id))
    // User may have added some shifts in the UI, but navigates back and forth in the table receiving server data (GET API request).
    // To maintain previous behavior, if the user navigates back to the first page, all the added shifts by the user in the UI will be appended on top.
    if (
      payload.meta.pagination &&
      payload.meta.pagination.current_page === 1 &&
      state.addedShifts.length
    ) {
      filteredData = [...state.addedShifts, ...filteredData]
    }
    return {
      ...state,
      meta: payload.meta,
      data: filteredData,
    }
  }

  if (isType(action, AddFeaturedShiftCampaignShift)) {
    const { payload } = action
    const { data } = state
    const newArray = [payload, ...data]
    return {
      ...state,
      data: newArray,
      // When adding a shift, we make sure that is isn't present in the removedShifts array
      addedShifts: [payload, ...state.addedShifts],
      removedShifts: state.removedShifts.filter((id) => id !== payload.id),
    }
  }

  if (isType(action, UpdateFeaturedShiftCampaignShift)) {
    const { payload } = action
    const { data } = state
    const formattedArray = updateArray<IShift>(data, payload.data)
    return {
      ...state,
      data: formattedArray,
    }
  }

  if (isType(action, DeleteFeaturedShiftCampaignShift)) {
    const { payload } = action
    const { data } = state
    const formattedArray = deleteFromArray<IShift>(data, payload)
    return {
      ...state,
      data: formattedArray,
      // When removing a shift, we make sure it isn't present in the addedShifts array.
      addedShifts: state.addedShifts.filter((shift) => shift.id !== payload),
      removedShifts: [payload, ...state.removedShifts],
    }
  }

  if (isType(action, FakeDeleteFeaturedShiftCampaignShifts)) {
    return {
      ...state,
      data: [],
      removedShifts: [],
      addedShifts: [],
    }
  }

  if (isType(action, FeaturedShiftCampaignShiftsFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }

  if (isType(action, MarkFetchedFeaturedShiftCampaignShifts)) {
    return {
      ...state,
      areFetched: true,
    }
  }

  if (isType(action, ResetFeaturedShiftCampaignShifts)) {
    return initialState
  }

  return state
}
