import { IOnboardingWorkflowsReducer, IOnboardingWorkflow } from 'models/OnboardingWorkflow'
import { Action } from 'redux'
import { isType } from 'ts-action'
import { updateArray, deleteFromArray } from 'helpers/helperFunctions'
import {
  GetOnboardingWorkflows,
  OnboardingWorkflowsFetching,
  UpdateOnboardingWorkflow,
  DeleteOnboardingWorkflow,
  GetOnboardingWorkflow,
  ResetOnboardingWorkflow,
} from './actions'

const initialState: IOnboardingWorkflowsReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: [],
}

export const onboardingWorkflowsReducer = (
  state = initialState,
  action: Action
): IOnboardingWorkflowsReducer => {
  if (isType(action, GetOnboardingWorkflows)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
    }
  }
  if (isType(action, GetOnboardingWorkflow)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: [payload.data, ...state.data],
    }
  }
  if (isType(action, UpdateOnboardingWorkflow)) {
    const { payload } = action
    const { data } = state
    const formattedArray = updateArray<IOnboardingWorkflow>(data, payload.data)
    return {
      ...state,
      data: formattedArray,
    }
  }
  if (isType(action, DeleteOnboardingWorkflow)) {
    const { payload } = action
    const { data } = state
    const formattedArray = deleteFromArray<IOnboardingWorkflow>(data, payload)
    return {
      ...state,
      data: formattedArray,
    }
  }

  if (isType(action, OnboardingWorkflowsFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }
  if (isType(action, ResetOnboardingWorkflow)) {
    return {
      ...state,
      isFetching: false,
      data: [],
    }
  }
  return state
}
