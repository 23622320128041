import { ACH_ACCOUNT_ACCOUNT_TYPE, ACH_ACCOUNT_AUTHORIZATION_METHOD } from 'models/AchAccount'
import { IDropdownData, TypeOrNull } from 'models/Common'

export enum ACH_DEBIT_AUTHORIZATION_STEP {
  CUSTOMER_DETAILS,
  BANK_DETAILS,
  CONFIRM_DETAILS,
}

export interface IPreferredAuthorizationMethodDetails {
  authorizationMethod?: ACH_ACCOUNT_AUTHORIZATION_METHOD
  offlineAccountPdf: TypeOrNull<File>
}

export interface ICustomerDetails {
  companyName: string
  email: string
  billingAddress: string
  billingAddress2: string
  city: string
  zipCode: string
  state: TypeOrNull<IDropdownData>
}

export interface IBankDetails {
  accountType: ACH_ACCOUNT_ACCOUNT_TYPE
  accountHolderName: string
}

export interface IAchDebitAuthorizationFlowReducer
  extends IPreferredAuthorizationMethodDetails,
    ICustomerDetails,
    IBankDetails {
  activeStep: ACH_DEBIT_AUTHORIZATION_STEP
  loadingBillingRequestId: boolean
  billingRequestId: string
  accountNumberMasked: string
  routingNumberMasked: string
  bankName: string
}

export type TPreferredAuthorizationMethodFormValues = {
  authorization_method?: ACH_ACCOUNT_AUTHORIZATION_METHOD
  offline_account_pdf: TypeOrNull<File>
}

export type TCustomerDetailsStepFormValues = {
  company_name: string
  email: string
  billing_address: string
  billing_address2: string
  city: string
  zip_code: string
  state: TypeOrNull<IDropdownData>
}

export type TBankDetailsStepFormValues = {
  account_number: string
  account_type: ACH_ACCOUNT_ACCOUNT_TYPE
  routing_number: string
  account_holder_name: string
}

export type TBankDetailsStepFormValuesExtended = TBankDetailsStepFormValues & {
  bank_name: string
}

export const maskAccountNumber = (accountNumber: string) => {
  const lastTwoDigits = accountNumber.substring(accountNumber.length - 2, accountNumber.length)
  return `******${lastTwoDigits}`
}

export const maskRoutingNumber = (routingNumber: string) => {
  const lastThreeDigits = routingNumber.substring(routingNumber.length - 3, routingNumber.length)
  return `******${lastThreeDigits}`
}
