import { AxiosService } from '../config/config'
import { Endpoints } from '../config/endpoints'
import { IPayload } from 'models/Global'
import {
  IPointOfContact,
  IGetPointsOfContactParams,
  IPutPointOfContactParams,
  IPostPointOfContactParams,
} from 'models/PointOfContact'

export class PointsOfContactApi {
  constructor(private axios: AxiosService) {}

  getPointsOfContact = async (params?: IGetPointsOfContactParams) => {
    const response = await this.axios.get<IPayload<IPointOfContact[]>>(
      Endpoints.getPointsOfContact,
      params
    )
    return response
  }

  getPointOfContact = async (id: number, params?: IGetPointsOfContactParams) => {
    const response = await this.axios.get<IPayload<IPointOfContact>>(
      Endpoints.getPointOfContact(id),
      params
    )
    return response
  }

  postPointOfContact = async (params: IPostPointOfContactParams) => {
    const response = await this.axios.post<IPayload<IPointOfContact>>(
      Endpoints.postPointOfContact,
      params
    )
    return response
  }

  putPointOfContact = async (id: number, params?: IPutPointOfContactParams) => {
    const response = await this.axios.put<IPayload<IPointOfContact>>(
      Endpoints.putPointOfContact(id),
      params
    )
    return response
  }

  deletePointOfContact = async (id: number) => {
    const response = await this.axios.delete<{}>(Endpoints.deletePointOfContact(id))
    return response
  }
}
