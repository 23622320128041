import { action, payload } from 'ts-action'

export type LoadingPayloadType = {
  id: number
  value: boolean
}

export const AcceptedApplicationLoading = action(
  'AcceptedApplicationLoading',
  payload<LoadingPayloadType>()
)

export const PendingApplicationLoading = action(
  'PendingApplicationLoading',
  payload<LoadingPayloadType>()
)

export const DeclineApplicationLoading = action(
  'DeclineApplicationLoading',
  payload<LoadingPayloadType>()
)
