import { action, payload } from 'ts-action'
import { IError, IPayload } from 'models/Global'
import { IAccruedSickHour } from 'models/AccruedSickHour'

export const GetAccruedSickHours = action(
  'GetAccruedSickHours',
  payload<IPayload<IAccruedSickHour[]>>()
)
export const GetAccruedSickHoursErrors = action('GetAccruedSickHoursErrors', payload<IError>())
export const AccruedSickHoursFetching = action('AccruedSickHoursFetching', payload<boolean>())
