import { Action } from 'redux'
import { isType } from 'ts-action'
import {
  GetAssignedCertificates,
  AssignedCertificatesFetching,
  GetAssignedCertificatesErrors,
  ClearAssignedCertificates,
  UnassignButtonLoading,
  UnassignButtonStopLoading,
  AddAssignedCertificate,
  RemoveUnassignedCertificate,
} from './actions'
import { IBusinessEntityAssignedCertificatesReducer } from 'models/BusinessEntity'
import { deleteFromArray } from 'helpers/helperFunctions'
import { IAutoAcceptCertificate } from 'models/AutoAcceptCertificate'

const initialState: IBusinessEntityAssignedCertificatesReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: [],
  unassignButtonLoadingIds: [],
}

export const businessEntityAssignedCertificatesReducer = (
  state = initialState,
  action: Action
): IBusinessEntityAssignedCertificatesReducer => {
  if (isType(action, GetAssignedCertificates)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: [...state.data, ...payload.data],
    }
  }
  if (isType(action, AssignedCertificatesFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }
  if (isType(action, AddAssignedCertificate)) {
    const { payload } = action
    return {
      ...state,
      data: [payload, ...state.data],
    }
  }
  if (isType(action, GetAssignedCertificatesErrors)) {
    const { payload } = action
    return {
      ...state,
      error: payload,
    }
  }
  if (isType(action, UnassignButtonLoading)) {
    const { payload } = action
    return {
      ...state,
      unassignButtonLoadingIds: [...state.unassignButtonLoadingIds, payload],
    }
  }
  if (isType(action, UnassignButtonStopLoading)) {
    const { payload } = action
    const filteredUnassignButtonLoadingIds = state.unassignButtonLoadingIds.filter(
      (id) => id !== payload
    )
    return {
      ...state,
      unassignButtonLoadingIds: filteredUnassignButtonLoadingIds,
    }
  }
  if (isType(action, RemoveUnassignedCertificate)) {
    const { payload } = action
    const filteredData = deleteFromArray<IAutoAcceptCertificate>(state.data, payload)
    return {
      ...state,
      data: filteredData,
    }
  }
  if (isType(action, ClearAssignedCertificates)) {
    return initialState
  }
  return state
}
