import { AxiosService } from '../config/config'
import { Endpoints } from '../config/endpoints'
import { IPayload } from 'models/Global'
import {
  IGetApplicationsParams,
  IApplication,
  IPostApplicationParams,
  IPutApplicationParams,
  IGetConfirmedApplicationsPdfParams,
} from 'models/Application'

export class ApplicationsApi {
  constructor(private axios: AxiosService) {}

  getApplications = async (params?: IGetApplicationsParams) => {
    const response = await this.axios.get<IPayload<IApplication[]>>(
      Endpoints.getApplications,
      params
    )
    return response
  }

  getApplication = async (id: number) => {
    const response = await this.axios.get<IPayload<IApplication>>(Endpoints.getApplication(id))
    return response
  }

  postApplication = async (params?: IPostApplicationParams) => {
    const response = await this.axios.post<IPayload<IApplication>>(
      Endpoints.postApplication,
      params
    )
    return response
  }

  putApplication = async (id: number, params: IPutApplicationParams) => {
    const response = await this.axios.put<IPayload<IApplication>>(
      Endpoints.putApplication(id),
      params
    )
    return response
  }

  deleteApplication = async (id: number) => {
    const response = await this.axios.delete<{}>(Endpoints.deleteApplication(id))
    return response
  }

  getConfirmedApplicationsPdf = async (params: IGetConfirmedApplicationsPdfParams) => {
    const response = await this.axios.get<Blob>(Endpoints.getConfirmedApplicationsPdf, params, {
      responseType: 'blob',
    })
    return response
  }
}
