import { action, payload } from 'ts-action'
import { IChallenge } from '../../models/Challenge'
import { IError, IPayload } from '../../models/Global'

export const ChallengesFetching = action('ChallengesFetching', payload<boolean>())

export const GetChallenges = action('GetChallenges', payload<IPayload<IChallenge[]>>())

export const AddChallenge = action('AddChallenge', payload<IPayload<IChallenge>>())

export const UpdateChallenge = action('UpdateChallenge', payload<IPayload<IChallenge>>())

export const DeleteChallenge = action('DeleteChallenge', payload<number>())

export const GetChallengesErrors = action('GetChallengesErrors', payload<IError>())
