import { useCallback, useState, useEffect } from 'react'
import {
  Modal,
  Form,
  Flex,
  Text,
  Loader,
  Spacer,
  Divider,
  Colors,
} from '@pinchenterprisesnpm/friday-ui'
import { faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons'
import { useModal } from '../../../../hooks/useModal'
import { PowerSearchInputField } from '../PowerSearchInputField'
import { TypeOrNull } from '../../../../models/Common'
import {
  POWER_SEARCH_RESOURCE_TYPE,
  TPowerSearchData,
  handleFetchingDataByResourceType,
  redirectOnClick,
  checkIfInputTextSeparatorCharacter,
  mappedResourceTypes,
  DEFAULT_POWER_SEARCH_PLACEHOLDER,
} from './utils'
import { BoldText, ModalContentWrapper, FormWrapper, LoaderWrapper } from './style'
import { useDebouncedSearch } from '../../../../hooks/useDebouncedSearch'
import { PowerSearchResourceTypes } from '../PowerSearchResourceTypes'
import { PowerSearchFilteredList } from '../PowerSearchFilteredList'
import { RecentSearchHistory } from '../PowerSearchRecentHistory'
import { capitalizeString } from '../../../../helpers/helperFunctions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const { Header } = Modal

type TPowerSearchProps = {
  /**
   * Depending on this prop we show different elements for toggling the modal. Default value is false.
   */
  isCollapsed?: boolean
}

export const PowerSearch = ({ isCollapsed = false }: TPowerSearchProps) => {
  const [isOpen, toggleModal, closeModal] = useModal()

  const [resourceType, setResourceType] = useState<TypeOrNull<POWER_SEARCH_RESOURCE_TYPE>>(null)

  const [placeholder, setPlaceholder] = useState(DEFAULT_POWER_SEARCH_PLACEHOLDER)

  const [inputValue, setInputValue] = useState('')
  const [pasteString, setPasteString] = useState('')

  const [errorMessage, setErrorMessage] = useState(false)

  const handleFetchingData = useCallback(
    async (input: string) => {
      return handleFetchingDataByResourceType(input, resourceType as POWER_SEARCH_RESOURCE_TYPE)
    },
    [resourceType]
  )

  const { inputText, setInputText, data, setData, isLoading } =
    useDebouncedSearch<TPowerSearchData[]>(handleFetchingData)

  const handleToggleModal = useCallback(() => {
    setInputValue('')
    setInputText('')
    setResourceType(null)
    setPlaceholder(DEFAULT_POWER_SEARCH_PLACEHOLDER)
    toggleModal()
  }, [setInputText, toggleModal])

  const handleHotkey = useCallback(
    (event: KeyboardEvent) => {
      if (event.ctrlKey && event.code === 'KeyK') {
        event.preventDefault()
        handleToggleModal()
      }
    },
    [handleToggleModal]
  )

  useEffect(() => {
    window.addEventListener('keydown', handleHotkey)

    return () => {
      window.removeEventListener('keydown', handleHotkey)
    }
  }, [handleHotkey])

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    setPasteString(event.clipboardData.getData('text'))
  }

  useEffect(() => {
    if (!resourceType || !inputText) {
      setData([])
    }
  }, [resourceType, inputText, setData])

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value

    if (value.length && errorMessage) {
      setErrorMessage(false)
    }

    // If the user is trying to delete the '/' character or set the input value to an empty string, or if the last character of the input value is '/' and the input value is being changed to a string that does not end with '/', reset the input value to an empty string
    if (
      value === '' ||
      (checkIfInputTextSeparatorCharacter(inputValue, inputValue.length - 1) &&
        !checkIfInputTextSeparatorCharacter(value, value.length - 2) &&
        !pasteString.length)
    ) {
      setInputValue('')
      setInputText('')
      setResourceType(null)
    } else {
      // If the user is typing after the '/' character, update the word after slash state variable
      if (inputValue.includes('/') || value.includes('/')) {
        const values = value.split('/')
        if (
          mappedResourceTypes
            .map((resourceType) => resourceType.toLowerCase())
            .includes(values[0].toLowerCase())
        ) {
          setData([])
          setResourceType(capitalizeString(values[0]) as POWER_SEARCH_RESOURCE_TYPE)
        }
        setInputText(values[1])
      }
      setInputValue(value)
      setPasteString('')
    }
  }

  const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Tab') {
      event.preventDefault()
      // Check if the input value matches a resource type
      const resourceType = Object.values(POWER_SEARCH_RESOURCE_TYPE).find((type) =>
        type
          .toLowerCase()
          .startsWith(inputValue ? inputValue.toLowerCase() : placeholder.toLowerCase())
      )

      if (resourceType) {
        setInputValue(resourceType + '/')
        setResourceType(resourceType)
      }
    }
    if (event.key === 'Enter') {
      event.preventDefault()
      if (resourceType && inputText) {
        redirectOnClick(resourceType as POWER_SEARCH_RESOURCE_TYPE, inputText)
      }
    }

    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      event.preventDefault()
    }
  }

  const renderModalContent = () => {
    if (isLoading) {
      return (
        <LoaderWrapper justifyContent='center'>
          <Loader />
        </LoaderWrapper>
      )
    }
    if (inputText && data) {
      return (
        <PowerSearchFilteredList
          resourceType={resourceType as POWER_SEARCH_RESOURCE_TYPE}
          data={data}
        />
      )
    }
    return (
      <PowerSearchResourceTypes
        searchTerm={inputText}
        inputValue={inputValue}
        resourceType={resourceType as POWER_SEARCH_RESOURCE_TYPE}
        setResourceType={setResourceType}
        setPlaceholder={setPlaceholder}
        setInputValue={setInputValue}
        setErrorMessage={setErrorMessage}
      />
    )
  }

  return (
    <>
      <PowerSearchInputField
        name='searchTerm'
        type='text'
        placeholder='Power Search'
        leftElement={<FontAwesomeIcon icon={faMagnifyingGlass} color={Colors.dark} size='lg' />}
        onClick={handleToggleModal}
        isCollapsed={isCollapsed}
      />
      <Modal closeModal={closeModal} isOpen={isOpen} width='700' height='800'>
        {({ closeModal: closeModalProvided }: { closeModal: () => void }) => (
          <ModalContentWrapper flexDirection='column' justifyContent='space-between'>
            <Form width={700}>
              <Header title='Power Search' closeModal={closeModalProvided} />
              <FormWrapper>
                <Flex>
                  <BoldText mr={5}>Tips:</BoldText>
                  <Text>Please make sure to select one filter only before you can search.</Text>
                </Flex>
                <Flex>
                  <PowerSearchInputField
                    name='searchTerm'
                    type='text'
                    placeholder={placeholder}
                    width='660px'
                    value={inputValue}
                    onChange={handleInputChange}
                    onKeyDown={handleInputKeyDown}
                    leftElement={
                      <FontAwesomeIcon icon={faMagnifyingGlass} color={Colors.dark} size='lg' />
                    }
                    autoComplete='off'
                    onPaste={handlePaste}
                    errorMessage={
                      (inputText && !resourceType) || errorMessage
                        ? ' Invalid resource selected'
                        : undefined
                    }
                    autoFocus
                    onBlur={({ target }) => target.focus()}
                  />
                </Flex>
              </FormWrapper>
              <Spacer />
              <Flex flexDirection='column'>{renderModalContent()}</Flex>
            </Form>
            <Divider type='horizontal' />
            <RecentSearchHistory />
          </ModalContentWrapper>
        )}
      </Modal>
    </>
  )
}
