import { faArrowRotateLeft, faXmarkCircle } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Flex } from '@pinchenterprisesnpm/friday-ui'
import { TPowerSearchRecentData, redirectOnClick } from '../PowerSearch/utils'
import { TextWrapper, InfoWrapper, IconWrapper, FlexWrapper, FlexItemWrapper } from './style'

type TRecentHistorySearch = TPowerSearchRecentData & {
  removeRecentSearchHistoryItem: (item: TPowerSearchRecentData) => void
}

export const RecentSearchHistoryItem = ({
  search,
  resourceType,
  removeRecentSearchHistoryItem,
}: TRecentHistorySearch) => {
  return (
    <FlexWrapper justifyContent='space-between' alignItems='center'>
      <FlexItemWrapper
        justifyContent='space-between'
        alignItems='center'
        onClick={() => redirectOnClick(resourceType, search)}
      >
        <Flex alignItems='center'>
          <FontAwesomeIcon size='lg' icon={faArrowRotateLeft} />
          <InfoWrapper flexDirection='column'>
            <TextWrapper m='0' mb={4} bold>
              {search}
            </TextWrapper>
            <TextWrapper m='0' isPrimary>
              In {resourceType}
            </TextWrapper>
          </InfoWrapper>
        </Flex>
      </FlexItemWrapper>
      <IconWrapper
        size='lg'
        icon={faXmarkCircle}
        onClick={() => removeRecentSearchHistoryItem({ search, resourceType })}
      />
    </FlexWrapper>
  )
}
