import { PUNCH_CARDS, SHIFTS } from 'models/Permission'
import ROUTES from 'routing/adminRoutes'
import { AsyncActiveShifts, AsyncClockedInUpshifters } from 'ui/pages'
import AdminRouteLayout from '../../../Admin/AdminRouteLayout'
import AdminRoute from '../../AdminRoute'

const activeShiftsRoutes = [
  <AdminRoute
    exact
    path={ROUTES.ACTIVE_SHIFTS}
    layout={AdminRouteLayout}
    component={AsyncActiveShifts}
    requiredPermissions={[SHIFTS.READ_SHIFTS]}
    key={ROUTES.ACTIVE_SHIFTS}
  />,
  <AdminRoute
    exact
    path={ROUTES.CLOCKED_IN}
    layout={AdminRouteLayout}
    component={AsyncClockedInUpshifters}
    requiredPermissions={[PUNCH_CARDS.READ_PUNCH_CARDS]}
    key={ROUTES.CLOCKED_IN}
  />,
]

export default activeShiftsRoutes
