import { FEATURED_GIG_CAMPAIGNS } from 'models/Permission'
import ROUTES from 'routing/adminRoutes'
import {
  AsyncEditFeaturedShiftsCampaign,
  AsyncFeaturedShiftsCampaignAdd,
  AsyncFeaturedShiftsCampaignCreate,
  AsyncFeaturedShiftsCampaignCreateStep,
  AsyncFeaturedShiftsCampaigns,
} from 'ui/pages'
import AdminRouteLayout from '../../../Admin/AdminRouteLayout'
import AdminRoute from '../../AdminRoute'

const featuredShiftsRoutes = [
  <AdminRoute
    exact
    path={ROUTES.FEATURED_SHIFTS_CAMPAIGNS_LIST}
    layout={AdminRouteLayout}
    component={AsyncFeaturedShiftsCampaigns}
    requiredPermissions={[FEATURED_GIG_CAMPAIGNS.READ_FEATURED_GIG_CAMPAIGNS]}
    key={ROUTES.FEATURED_SHIFTS_CAMPAIGNS_LIST}
  />,
  <AdminRoute
    exact
    path={ROUTES.FEATURED_SHIFTS_CAMPAIGNS_STEP}
    layout={AdminRouteLayout}
    component={AsyncFeaturedShiftsCampaignCreateStep}
    requiredPermissions={[FEATURED_GIG_CAMPAIGNS.CREATE_FEATURED_GIG_CAMPAIGNS]}
    key={ROUTES.FEATURED_SHIFTS_CAMPAIGNS_STEP}
  />,
  <AdminRoute
    exact
    path={ROUTES.FEATURED_SHIFTS_CAMPAIGNS_CREATE}
    layout={AdminRouteLayout}
    component={AsyncFeaturedShiftsCampaignCreate}
    requiredPermissions={[FEATURED_GIG_CAMPAIGNS.CREATE_FEATURED_GIG_CAMPAIGNS]}
    key={ROUTES.FEATURED_SHIFTS_CAMPAIGNS_CREATE}
  />,
  <AdminRoute
    exact
    path={ROUTES.FEATURED_SHIFTS_CAMPAIGNS_VIEW}
    layout={AdminRouteLayout}
    component={AsyncEditFeaturedShiftsCampaign}
    requiredPermissions={[FEATURED_GIG_CAMPAIGNS.READ_FEATURED_GIG_CAMPAIGNS]}
    key={ROUTES.FEATURED_SHIFTS_CAMPAIGNS_VIEW}
  />,
  <AdminRoute
    exact
    path={ROUTES.FEATURED_SHIFTS_CAMPAIGNS_ADD_SHIFTS}
    layout={AdminRouteLayout}
    component={AsyncFeaturedShiftsCampaignAdd}
    requiredPermissions={[FEATURED_GIG_CAMPAIGNS.UPDATE_FEATURED_GIG_CAMPAIGNS]}
    key={ROUTES.FEATURED_SHIFTS_CAMPAIGNS_ADD_SHIFTS}
  />,
]

export default featuredShiftsRoutes
