import { IShiftsReducer } from 'models/Shift'
import { isType } from 'ts-action'
import { Action } from 'redux'
import { GetActiveShifts, ActiveShiftsFetching, GetActiveShiftsErrors } from './actions'

const initialState: IShiftsReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: [],
}

export const activeShiftsReducer = (state = initialState, action: Action): IShiftsReducer => {
  if (isType(action, GetActiveShifts)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
    }
  }
  if (isType(action, GetActiveShiftsErrors)) {
    const { payload } = action
    return {
      ...state,
      error: {
        code: payload.code,
        message: payload.message,
      },
    }
  }
  if (isType(action, ActiveShiftsFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }
  return state
}
