export enum PAYMENT_GATEWAY_ENUM {
  CREDIT_CARD = 1,
  CREDIT_CARD_WITH_FEE = 2,
  ACH = 3,
}

export enum PAYMENT_STATUS {
  FAILED = 0,
  SUCCESSFUL = 1,
  PENDING = 2,
  PROCESSING = 3,
}

export interface IPayment {
  failure_reason: string
  id: number
  invoice_value: string
  payment_gateway_enum: PAYMENT_GATEWAY_ENUM
  status: PAYMENT_STATUS
}
