import { action, payload } from 'ts-action'
import { IDocument } from 'models/Document'
import { IError, IPayload } from 'models/Global'

export const GetDocuments = action('GetDocuments', payload<IPayload<IDocument[]>>())
export const UpdateDocument = action('UpdateDocument', payload<IPayload<IDocument>>())
export const AddDocument = action('AddDocument', payload<IPayload<IDocument>>())
export const GetDocumentsErrors = action('GetDocumentsErrors', payload<IError>())
export const DocumentsFetching = action('DocumentsFetching', payload<boolean>())
export const ResetDocuments = action('ResetDocuments')
