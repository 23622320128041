import { action, payload } from 'ts-action'
import { ICertificate } from 'models/Certificate'
import { IError, IPayload } from 'models/Global'

export const CertificatesFetching = action('CertificatesFetching', payload<boolean>())

export const GetCertificates = action('GetCertificates', payload<IPayload<ICertificate[]>>())

export const AddCertificate = action('AddCertificate', payload<IPayload<ICertificate>>())

export const UpdateCertificate = action('UpdateCertificate', payload<IPayload<ICertificate>>())

export const DeleteCertificate = action('DeleteCertificate', payload<number>())

export const GetCertificatesErrors = action('GetCertificatesErrors', payload<IError>())
