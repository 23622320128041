import { AxiosService } from '../config/config'
import { Endpoints } from '../config/endpoints'
import { IPayload } from 'models/Global'
import { ICredit, IGetCreditsParams, IPostCreditParams, IPutCreditParams } from 'models/Credit'

export class CreditsApi {
  constructor(private axios: AxiosService) {}

  getCredits = async (params?: IGetCreditsParams) => {
    const response = await this.axios.get<IPayload<ICredit[]>>(Endpoints.getCredits, params)
    return response
  }

  getCredit = async (id: number) => {
    const response = await this.axios.get<IPayload<ICredit>>(Endpoints.getCredit(id))
    return response
  }

  postCredit = async (params: IPostCreditParams) => {
    const response = await this.axios.post<IPayload<ICredit>>(Endpoints.postCredit, params)
    return response
  }

  putCredit = async (id: number, params: IPutCreditParams) => {
    const response = await this.axios.put<IPayload<ICredit>>(Endpoints.putCredit(id), params)
    return response
  }

  deleteCredit = async (id: number) => {
    const response = await this.axios.delete<{}>(Endpoints.deleteCredit(id))
    return response
  }
}
