import { ContentProps } from '@pinchenterprisesnpm/friday-ui'
import React from 'react'
import { ContentElement } from './style'

export class Content extends React.PureComponent<ContentProps, {}> {
  render() {
    const { children } = this.props
    const isRenderProp = React.Children.count(children) === 0 && typeof children === 'function'
    return (
      <ContentElement>
        {isRenderProp ? (children as (...args: any[]) => React.ReactNode)() : children}
      </ContentElement>
    )
  }
}
