import { action, payload } from 'ts-action'
import { IVaccinationType } from 'models/VaccinationType'
import { IError, IPayload } from 'models/Global'

export const VaccinationTypesFetching = action('VaccinationTypesFetching', payload<boolean>())

export const GetVaccinationTypes = action(
  'GetVaccinationTypes',
  payload<IPayload<IVaccinationType[]>>()
)

export const AddVaccinationType = action(
  'AddVaccinationType',
  payload<IPayload<IVaccinationType>>()
)

export const UpdateVaccinationType = action(
  'UpdateVaccinationType',
  payload<IPayload<IVaccinationType>>()
)

export const DeleteVaccinationType = action('DeleteVaccinationType', payload<number>())

export const GetVaccinationTypesErrors = action('GetVaccinationTypesErrors', payload<IError>())
