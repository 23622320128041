import { createSelector } from 'reselect'
import { AppState } from 'store/store'

/**
 * @param state - the application state
 * @returns Auth - the Auth reducer
 */
export const selectAuthReducer = (state: AppState) => state.auth

/**
 * Gets the Auth data from the Auth reducer
 */
export const selectAuthUserData = createSelector(
  selectAuthReducer,
  (AuthReducer) => AuthReducer.data
)

/**
 * Gets the Auth meta from the Auth reducer
 */
export const selectAuthMeta = createSelector(selectAuthReducer, (AuthReducer) => AuthReducer.meta)

/**
 * Gets the Auth isFetching from the Auth reducer
 */
export const selectAuthIsFetching = createSelector(
  selectAuthReducer,
  (AuthReducer) => AuthReducer.isFetching
)

/**
 * Gets the Auth isAuth field from the Auth reducer
 */
export const selectAuthIsAuth = createSelector(
  selectAuthReducer,
  (AuthReducer) => AuthReducer.isAuth
)

/**
 * Gets the Auth userRoles from the Auth reducer
 */
export const selectAuthUserRole = createSelector(selectAuthUserData, (AuthUserData) =>
  AuthUserData.roles ? AuthUserData.roles[0].name : ''
)
