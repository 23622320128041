import { action, payload } from 'ts-action'
import { IError, IPayload } from 'models/Global'
import { IShift } from 'models/Shift'

export const GetSelectedShift = action('GetSelectedShift', payload<IPayload<IShift>>())

export const UpdateSelectedShift = action('UpdateSelectedShift', payload<IPayload<IShift>>())

export const DeleteSelectedShift = action('DeleteSelectedShift')

export const SelectedShiftFetching = action('SelectedShiftFetching', payload<boolean>())

export const ResetSelectedShift = action('ResetSelectedShift')

export const GetSelectedShiftErrors = action('GetSelectedShiftErrors', payload<IError>())
