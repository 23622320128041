import { ISickLeaveTemplateReducer } from 'models/SickLeaveTemplate'
import { Action } from 'redux'
import { isType } from 'ts-action'
import { GetSickLeaveTemplates, SickLeaveTemplatesFetching } from './actions'

const initialState: ISickLeaveTemplateReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: [],
}

export const sickLeaveTemplatesReducer = (
  state = initialState,
  action: Action
): ISickLeaveTemplateReducer => {
  if (isType(action, GetSickLeaveTemplates)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
    }
  }
  if (isType(action, SickLeaveTemplatesFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }
  return state
}
