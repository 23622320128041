import { AxiosService } from '../config/config'
import { Endpoints } from '../config/endpoints'
import { IPayload } from 'models/Global'
import {
  IExpenseType,
  IGetExpenseTypesParams,
  IPostExpenseTypeParams,
  IPutExpenseTypeParams,
} from 'models/ExpenseType'

export class ExpenseTypesApi {
  constructor(private axios: AxiosService) {}

  getExpenseTypes = async (params?: IGetExpenseTypesParams) => {
    const response = await this.axios.get<IPayload<IExpenseType[]>>(
      Endpoints.getExpenseTypes,
      params
    )
    return response
  }

  getExpenseType = async (id: number) => {
    const response = await this.axios.get<IPayload<IExpenseType>>(Endpoints.getExpenseType(id))
    return response
  }

  postExpenseType = async (params: IPostExpenseTypeParams) => {
    const response = await this.axios.post<IPayload<IExpenseType>>(
      Endpoints.postExpenseType,
      params
    )
    return response
  }

  putExpenseType = async (id: number, params: IPutExpenseTypeParams) => {
    const response = await this.axios.put<IPayload<IExpenseType>>(
      Endpoints.putExpenseType(id),
      params
    )
    return response
  }

  deleteExpenseType = async (id: number) => {
    const response = await this.axios.delete<{}>(Endpoints.deleteExpenseType(id))
    return response
  }
}
