import { IAchievementsReducer, IAchievement } from '../../models/Achievement'
import { Action } from 'redux'
import { isType } from 'ts-action'
import { updateArray, deleteFromArray } from '../../helpers/helperFunctions'
import {
  GetAchievements,
  AchievementsFetching,
  UpdateAchievement,
  DeleteAchievement,
} from './actions'

const initialState: IAchievementsReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: [],
}

export const achievementsReducer = (state = initialState, action: Action): IAchievementsReducer => {
  if (isType(action, GetAchievements)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
    }
  }
  if (isType(action, UpdateAchievement)) {
    const { payload } = action
    const { data } = state
    const formattedArray = updateArray<IAchievement>(data, payload.data)
    return {
      ...state,
      data: formattedArray,
    }
  }
  if (isType(action, DeleteAchievement)) {
    const { payload } = action
    const { data } = state
    const formattedArray = deleteFromArray<IAchievement>(data, payload)
    return {
      ...state,
      data: formattedArray,
    }
  }

  if (isType(action, AchievementsFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }
  return state
}
