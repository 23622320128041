import { createSelector } from 'reselect'
import { AppState } from 'store/store'

/**
 * @param state - the application state
 * @returns selectedShiftGigDays - the selectedShiftGigDays reducer
 */
export const selectSelectedShiftGigDaysReducer = (state: AppState) => state.selectedShiftGigDays

/**
 * Gets the selectedShiftGigDays' data from the selectedShiftGigDays reducer
 */
export const selectSelectedShiftGigDays = createSelector(
  selectSelectedShiftGigDaysReducer,
  (selectedShiftGigDaysReducer) => selectedShiftGigDaysReducer.data
)

/**
 * Gets the selectedShiftGigDays' meta from the selectedShiftGigDays reducer
 */
export const selectSelectedShiftGigDaysMeta = createSelector(
  selectSelectedShiftGigDaysReducer,
  (selectedShiftGigDaysReducer) => selectedShiftGigDaysReducer.meta
)

/**
 * Gets the selectedShiftGigDays' isFetching from the selectedShiftGigDays reducer
 */
export const selectSelectedShiftGigDaysIsFetching = createSelector(
  selectSelectedShiftGigDaysReducer,
  (selectedShiftGigDaysReducer) => selectedShiftGigDaysReducer.isFetching
)
