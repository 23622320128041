import { action, payload } from 'ts-action'
import { ITimetracker } from 'models/Timetracker'
import { IError, IPayload } from 'models/Global'

export const GetTimetrackers = action('GetTimetrackers', payload<IPayload<ITimetracker[]>>())

export const GetTimetrackersErrors = action('GetTimetrackersErrors', payload<IError>())

export const ClearTimetrackers = action('ClearTimetrackers')

export const TimetrackersFetching = action('TimetrackersFetching', payload<boolean>())

export const DeleteTimetracker = action('DeleteTimetracker', payload<number>())

export const DeleteTimetrackerErrors = action('DeleteTimetrackersErrors', payload<IError>())

export const UpdateTimetracker = action('UpdateTimetracker', payload<ITimetracker>())

export const UpdateTimetrackerErrors = action('UpdateTimetrackerErrors', payload<IError>())
