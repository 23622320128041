import React, { Component } from 'react'
import { Formik } from 'formik'
import { Form, DropdownV5, Modal, Button } from '@pinchenterprisesnpm/friday-ui'
import {
  FooterWrapper,
  CustomValidationError,
  FormInputWrapper,
  FormInputLabel,
  MainContainer,
} from '../EditPunchCardInfoModal/style'
import {
  IPostPunchCardParams,
  IPunchCard,
  PUNCHCARD_REASON,
  PUNCHCARD_REASON_HOW,
} from 'models/PunchCard'
import { FEATURE_TOGGLE_PENDING_VERIFICATIONS } from 'config/featureToggles'
import { getBypassParams, isObjectEmpty } from 'helpers/helperFunctions'
import { AreHoursApproved, IDropdownData, Values } from '../EditPunchCardInfoModal/utils'
import {
  formatTimestampUtc,
  savePunchCardParamsMapper,
  TPredefinedModalValues,
} from '../ApplicationPunchCards/utils'
import ComponentFullSizeLoader from 'ui/components/common/ComponentFullSizeLoader'
import { reasonHowDropdownData, editFormValidationSchema } from '../EditPunchCardInfoModal/utils'
import { TypeOrNull } from 'models/Common'
import {
  addPendingVerificationPunchCard,
  PendingVerificationPunchCardsActions,
} from 'data/PunchCard/pendingVerificationThunks'
import { ThunkDispatch } from 'redux-thunk'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { confirmModal } from 'helpers/GenericConfirmModal'

const { Input, TextArea, Radio } = Form

const Header = Modal.Header
const Content = Modal.Content

type OwnProps = {
  isOpen: boolean
  isLoading: boolean
  punchCard: IPunchCard
  closeModal: () => void
  saveSinglePunchCard: (params: IPostPunchCardParams, note: string) => void
  predefinedValues: TypeOrNull<TPredefinedModalValues>
  resetModalPredefinedFields: () => void
  shiftTimezone: string
  isTimesheetProcessed?: boolean
  handleCancel?: () => void
}

type CreatePunchCardReasonModalProps = OwnProps & ILinkDispatchProps

type CreatePunchCardReasonModalState = {
  initialValues: Values
  areInitialValuesSet: boolean
  initialNoteContent: string
  areHoursApproved?: AreHoursApproved
}

const initialState: CreatePunchCardReasonModalState = {
  initialValues: {
    reasonForEdit: PUNCHCARD_REASON.REQUEST_BY_BUSINESS,
    reasonForEditHow: null as IDropdownData | null,
    zendeskTicketNumber: '',
    note: '',
  },
  areHoursApproved: undefined,
  initialNoteContent: '',
  areInitialValuesSet: false,
}

class CreatePunchCardReasonModal extends Component<
  CreatePunchCardReasonModalProps,
  CreatePunchCardReasonModalState
> {
  state = initialState

  componentDidUpdate(prevProps: CreatePunchCardReasonModalProps) {
    const { punchCard, predefinedValues, resetModalPredefinedFields, isOpen } = this.props

    if (isOpen && prevProps.isOpen !== isOpen) {
      if (!isObjectEmpty(punchCard)) {
        const initialValuesWithoutNote = this.mapInitialValues(punchCard, predefinedValues)
        this.setState<never>((prevState) => ({
          initialValues: {
            ...prevState.initialValues,
            ...initialValuesWithoutNote,
          },
        }))
      }

      if (predefinedValues && predefinedValues.hasOwnProperty('note')) {
        this.setState<never>((prevState) => ({
          initialValues: {
            ...prevState.initialValues,
            note: predefinedValues.note,
          },
        }))
      }
      this.setState({ areInitialValuesSet: true })
    }
    // On modal close
    if (!isOpen && isOpen !== prevProps.isOpen) {
      this.setState(initialState)
      // Reset the predefinedFields in the parent component's state
      resetModalPredefinedFields()
    }
  }

  mapInitialValues = (
    punchCard: IPunchCard,
    predefinedValues: TypeOrNull<TPredefinedModalValues>
  ) => {
    const initialValues = {
      reasonForEdit: punchCard.reason_for_edit,
      reasonForEditHow:
        punchCard.reason_for_edit_how !== null
          ? reasonHowDropdownData.find((reason) => reason.value === punchCard.reason_for_edit_how)
          : null,
      zendeskTicketNumber: punchCard.zendesk_id ? punchCard.zendesk_id : '',
    }

    if (predefinedValues) {
      Object.keys(predefinedValues).forEach((value) => {
        if (value === 'reasonForEditHow') {
          ;(initialValues as any)[value] = reasonHowDropdownData.find(
            (reason) => reason.value === (predefinedValues as any)[value]
          )
        } else {
          ;(initialValues as any)[value] = (predefinedValues as any)[value]
        }
      })
    }

    return initialValues
  }

  getPunchCardParams = (values: Values) => {
    const { reasonForEdit, reasonForEditHow, zendeskTicketNumber } = values
    const { punchCard } = this.props

    const reasonForEditHowForMapper = reasonForEditHow ? reasonForEditHow.value : null

    const punchCardParams: IPostPunchCardParams = savePunchCardParamsMapper(
      punchCard,
      reasonForEdit,
      reasonForEditHowForMapper,
      zendeskTicketNumber
    )

    return punchCardParams
  }

  submitHandler = async (punchCardParams: IPostPunchCardParams, values: Values) => {
    const {
      saveSinglePunchCard,
      handleCancel,
      addPendingVerificationPunchCard,
      shiftTimezone,
      closeModal,
    } = this.props
    const { areHoursApproved } = this.state
    const { note } = values

    if (!FEATURE_TOGGLE_PENDING_VERIFICATIONS || areHoursApproved !== AreHoursApproved.No) {
      saveSinglePunchCard(punchCardParams, note)
    } else {
      const {
        zendesk_id,
        reason_for_create,
        reason_for_create_how,
        ...pendingVerificationPunchCardParams
      } = punchCardParams
      // We convert the timestamps to UTC here because we dispatch the thunk directly.
      // If the punch card is not saved for pending verification, then the UTC conversion is handled within the "saveSinglePunchCard" method
      const params: IPostPunchCardParams = {
        ...pendingVerificationPunchCardParams,
        timestamp_start: formatTimestampUtc(
          punchCardParams.timestamp_start as string,
          shiftTimezone
        ),
        ...(!punchCardParams.isNoEndPunchCard && {
          timestamp_end: formatTimestampUtc(punchCardParams.timestamp_end as string, shiftTimezone),
        }),
        reason_for_create_how: PUNCHCARD_REASON_HOW.INTERCOM_URL,
        note,
      }
      try {
        await addPendingVerificationPunchCard(params)
        handleCancel && handleCancel()
        Modal.removeAll()
        closeModal()
      } catch (error) {
        const bypassParams = getBypassParams(error)
        if (bypassParams) {
          confirmModal({
            onConfirm: async () => {
              await addPendingVerificationPunchCard({ ...params, ...bypassParams })
              handleCancel && handleCancel()
              Modal.removeAll()
              closeModal()
            },
            message: error.message,
            width: 400,
          })
        }
      }
    }
  }

  editForm = () => {
    const { isLoading, closeModal, isTimesheetProcessed } = this.props
    const { initialValues, areInitialValuesSet, areHoursApproved } = this.state
    const shouldDisplayApprovedHoursChoice =
      FEATURE_TOGGLE_PENDING_VERIFICATIONS && isTimesheetProcessed
    let shouldDisplayReason = false

    // The pending verifications feature is disabled
    if (!FEATURE_TOGGLE_PENDING_VERIFICATIONS) {
      shouldDisplayReason = true
    }
    // The pending verifications feature is enabled
    else {
      // The timesheet is processed
      if (isTimesheetProcessed) {
        // The hours are approved
        if (areHoursApproved === AreHoursApproved.Yes) {
          shouldDisplayReason = true
        }
      }
      // The timesheet is not processed
      else {
        shouldDisplayReason = true
      }
    }

    const shouldDisplayIntercomUrl =
      shouldDisplayApprovedHoursChoice && areHoursApproved === AreHoursApproved.No
    return (
      <MainContainer>
        {areInitialValuesSet ? (
          <Formik
            enableReinitialize
            onSubmit={async (values: Values) => {
              const params = this.getPunchCardParams(values)
              await this.submitHandler(params, values)
            }}
            initialValues={initialValues}
            validationSchema={editFormValidationSchema(areHoursApproved, isTimesheetProcessed)}
          >
            {(formikProps) => {
              const {
                errors,
                handleChange,
                handleSubmit,
                values,
                submitForm,
                touched,
                isSubmitting,
                setFieldValue,
              } = formikProps

              return (
                <>
                  <Header title='Create Punch Card' closeModal={closeModal} />
                  <Content>
                    <Form onSubmit={handleSubmit}>
                      {shouldDisplayApprovedHoursChoice && (
                        <FormInputWrapper>
                          <FormInputLabel>Are these hours approved?</FormInputLabel>
                          <Radio.Group
                            onChange={(value) =>
                              this.setState({
                                areHoursApproved: Number(value) as AreHoursApproved,
                              })
                            }
                            value={areHoursApproved}
                          >
                            <Radio.Button value={AreHoursApproved.Yes}>Yes</Radio.Button>
                            <Radio.Button value={AreHoursApproved.No}>No</Radio.Button>
                          </Radio.Group>
                        </FormInputWrapper>
                      )}
                      {shouldDisplayReason && (
                        <>
                          <FormInputWrapper>
                            <FormInputLabel>Why</FormInputLabel>
                            <Radio.Group
                              onChange={(value) => setFieldValue('reasonForEdit', Number(value))}
                              value={values.reasonForEdit}
                            >
                              <Radio.Button value={PUNCHCARD_REASON.REQUEST_BY_BUSINESS}>
                                Business
                              </Radio.Button>
                              <Radio.Button value={PUNCHCARD_REASON.REQUEST_BY_UPSHIFTER}>
                                Upshifter
                              </Radio.Button>
                            </Radio.Group>
                          </FormInputWrapper>
                          {touched.reasonForEdit && errors.reasonForEdit ? (
                            <CustomValidationError>{errors.reasonForEdit}</CustomValidationError>
                          ) : null}
                          {values.reasonForEdit === PUNCHCARD_REASON.REQUEST_BY_BUSINESS ? (
                            <FormInputWrapper>
                              <FormInputLabel>Reason for the change</FormInputLabel>
                              <DropdownV5
                                options={reasonHowDropdownData}
                                isOutlined
                                type='dark'
                                rounded={false}
                                onChange={(data) => setFieldValue('reasonForEditHow', data)}
                                value={values.reasonForEditHow}
                                placeholder='Select'
                              />
                            </FormInputWrapper>
                          ) : null}
                          {touched.reasonForEditHow && errors.reasonForEditHow ? (
                            <CustomValidationError>{errors.reasonForEditHow}</CustomValidationError>
                          ) : null}
                          {(values.reasonForEdit === PUNCHCARD_REASON.REQUEST_BY_BUSINESS &&
                            values.reasonForEditHow?.value === PUNCHCARD_REASON_HOW.INTERCOM) ||
                          values.reasonForEdit === PUNCHCARD_REASON.REQUEST_BY_UPSHIFTER ? (
                            <FormInputWrapper>
                              <FormInputLabel>Intercom ticket number</FormInputLabel>
                              <Input
                                type='text'
                                onChange={handleChange}
                                value={values.zendeskTicketNumber}
                                name='zendeskTicketNumber'
                                errorMessage={
                                  touched.zendeskTicketNumber && errors.zendeskTicketNumber
                                    ? errors.zendeskTicketNumber
                                    : undefined
                                }
                              />
                            </FormInputWrapper>
                          ) : null}
                          <FormInputWrapper>
                            <FormInputLabel>Note</FormInputLabel>
                            <TextArea
                              onChange={handleChange}
                              value={values.note}
                              note={true}
                              minRows={5}
                              maxRows={10}
                              name='note'
                              errorMessage={touched.note && errors.note ? errors.note : undefined}
                            />
                          </FormInputWrapper>
                        </>
                      )}

                      {shouldDisplayIntercomUrl && (
                        <FormInputWrapper>
                          <FormInputLabel>Please enter the Intercom URL</FormInputLabel>
                          <TextArea
                            onChange={handleChange}
                            value={values.note}
                            note={true}
                            minRows={5}
                            maxRows={10}
                            name='note'
                            errorMessage={touched.note && errors.note ? errors.note : undefined}
                          />
                        </FormInputWrapper>
                      )}
                    </Form>
                  </Content>
                  <FooterWrapper>
                    <Button
                      type='primary'
                      onClick={submitForm}
                      isLoading={isLoading || isSubmitting}
                      isDisabled={isLoading || isSubmitting}
                    >
                      Save
                    </Button>
                  </FooterWrapper>
                </>
              )
            }}
          </Formik>
        ) : (
          <ComponentFullSizeLoader />
        )}
      </MainContainer>
    )
  }

  render() {
    const { isOpen, closeModal } = this.props
    return (
      <Modal isOpen={isOpen} closeModal={closeModal} height='auto' width='300'>
        {this.editForm()}
      </Modal>
    )
  }
}

interface ILinkDispatchProps {
  addPendingVerificationPunchCard: typeof addPendingVerificationPunchCard
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, PendingVerificationPunchCardsActions>
): ILinkDispatchProps => ({
  addPendingVerificationPunchCard: bindActionCreators(addPendingVerificationPunchCard, dispatch),
})

export default connect(null, mapDispatchToProps)(CreatePunchCardReasonModal)
