import { action, payload } from 'ts-action'
import { IExpenseTypeTax } from 'models/ExpenseTypeTax'
import { IError, IPayload } from 'models/Global'

export const ExpenseTypeTaxesFetching = action('ExpenseTypeTaxesFetching', payload<boolean>())

export const GetExpenseTypeTaxes = action(
  'GetExpenseTypeTaxes',
  payload<IPayload<IExpenseTypeTax[]>>()
)

export const GetExpenseTypeTax = action('GetExpenseTypeTaxes', payload<IPayload<IExpenseTypeTax>>())

export const AddExpenseTypeTax = action('AddExpenseTypeTax', payload<IPayload<IExpenseTypeTax>>())

export const UpdateExpenseTypeTax = action(
  'UpdateExpenseTypeTax',
  payload<IPayload<IExpenseTypeTax>>()
)

export const UpdateExpenseTypeTaxes = action('UpdateExpenseTypeTaxes')

export const DeleteExpenseTypeTax = action('DeleteExpenseTypeTax', payload<number>())

export const GetExpenseTypeTaxesErrors = action('GetExpenseTypeTaxesErrors', payload<IError>())

export const ResetExpenseTypeTaxes = action('ResetExpenseTypeTaxes')
