import { IBaseReducer } from '../BaseReducer'
import { IResponse, IAuth } from '../Global'
import { IRegion } from 'models/Region'
import { USER_ROLE, USER_STATUS } from 'models/User'
import { STATUS_CODES } from 'network/config/error'

export interface IRoleData {
  id: number
  name: USER_ROLE
}

interface IStatData {
  notifications_count: number
  active_punch_cards_count: number
  active_strikes_count: number
  expired_strikes_count: number
  pending_gigs_count: number
  accepted_gigs_count: number
  confirmed_gigs_count: number
  active_gigs_count: number
  finished_gigs_count: number
  finished_punch_cards_count: number
  hours_worked: number
  total_hours_worked: number
}

export interface IAuthData {
  id: number
  uuid: string
  email: string
  full_name: string
  first_name: string
  last_name: string
  zip_code: string
  phone: string
  description: string
  timezone: string
  avatar_url: string
  avatar_thumb_url: string
  date_of_birth: string | boolean
  age: number
  rating: string
  confirmed: boolean
  status: USER_STATUS
  ec_full_name: string
  ec_phone: number
  ec_relationship: string
  created_at: Date
  password_last_changed_at: Date
  changed_password: boolean
  new_app: boolean
  roles: IRoleData[]
  regions: IRegion[]
  can_login: boolean
  ssn_last_four: boolean
  stats: IStatData[]
}

export interface IAuthLogin {
  email: string
  password: string
}

export interface IMetaAuth extends IResponse {
  auth: IAuth
}

export interface IAuthPayload {
  data: IAuthData
  meta: IMetaAuth
}

export interface IVerifyTwoFactorCodeParams {
  verification_code: number
}

export class InvalidCredentialsError extends Error {
  code: number
  title: string

  constructor(title: string, code = STATUS_CODES.INVALID_CREDENTIALS) {
    super(title)
    this.code = code
    this.title = title
  }
}

export interface IAuthReducer extends IBaseReducer<IAuthData> {
  isAuth: boolean
  authMeta: IMetaAuth
  isTwoFactorVerifying: boolean
  isResendingTwoFactorCode: boolean
}
