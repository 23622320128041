import { IFeaturedShiftCampaignCreateShiftsReducer } from 'models/FeaturedShiftCampaign'
import { Action } from 'redux'
import { isType } from 'ts-action'
import { updateArray, deleteFromArray } from 'helpers/helperFunctions'
import {
  GetFeaturedShiftCampaignCreateShifts,
  FeaturedShiftCampaignCreateShiftsFetching,
  UpdateFeaturedShiftCampaignCreateShift,
  DeleteFeaturedShiftCampaignCreateShift,
  AddFeaturedShiftCampaignCreateShift,
  GetFeaturedShiftCampaignCreateShift,
  FakeDeleteFeaturedShiftCampaignCreateShift,
  FakeDeleteFeaturedShiftCampaignCreateShifts,
  UpdateFeaturedShiftCampaignCreateAllShifts,
} from './actions'
import { IShift } from 'models/Shift'

const initialState: IFeaturedShiftCampaignCreateShiftsReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: true,
  data: [],
}

export const FeaturedShiftCampaignCreateShiftsReducer = (
  state = initialState,
  action: Action
): IFeaturedShiftCampaignCreateShiftsReducer => {
  if (isType(action, GetFeaturedShiftCampaignCreateShifts)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
    }
  }

  if (isType(action, GetFeaturedShiftCampaignCreateShift)) {
    const { payload } = action
    const { data } = state
    const newArray = [...data, payload.data]

    return {
      ...state,
      meta: payload.meta,
      data: newArray,
    }
  }

  if (isType(action, AddFeaturedShiftCampaignCreateShift)) {
    const { payload } = action
    const { data } = state
    const newArray = [payload.data, ...data]
    return {
      ...state,
      data: newArray,
    }
  }

  if (isType(action, UpdateFeaturedShiftCampaignCreateShift)) {
    const { payload } = action
    const { data } = state
    const formattedArray = updateArray<IShift>(data, payload.data)
    return {
      ...state,
      data: formattedArray,
    }
  }

  if (isType(action, UpdateFeaturedShiftCampaignCreateAllShifts)) {
    const { payload } = action
    const { data } = state
    const formattedArray = data.map((shift) => ({ ...shift, selected: payload }))
    return {
      ...state,
      data: formattedArray,
    }
  }

  if (isType(action, DeleteFeaturedShiftCampaignCreateShift)) {
    const { payload } = action
    const { data } = state
    const formattedArray = deleteFromArray<IShift>(data, payload)
    return {
      ...state,
      data: formattedArray,
    }
  }

  if (isType(action, FakeDeleteFeaturedShiftCampaignCreateShift)) {
    const { payload } = action
    const { data } = state
    const formattedArray = deleteFromArray<IShift>(data, payload)
    return {
      ...state,
      data: formattedArray,
    }
  }

  if (isType(action, FakeDeleteFeaturedShiftCampaignCreateShifts)) {
    return {
      ...state,
      data: [],
    }
  }

  if (isType(action, FeaturedShiftCampaignCreateShiftsFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }

  return state
}
