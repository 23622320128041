import { AxiosService } from '../config/config'
import { Endpoints } from '../config/endpoints'
import { IPayload } from 'models/Global'
import {
  IGetUserAttachmentsParams,
  IPostUserAttachmentParams,
  IPutUserAttachmentParams,
} from 'models/UserAttachment'
import { IAttachment } from 'models/Attachment'

export class UserAttachmentsApi {
  constructor(private axios: AxiosService) {}

  getUserAttachments = async (params?: IGetUserAttachmentsParams) => {
    const response = await this.axios.get<IPayload<IAttachment[]>>(
      Endpoints.getUserAttachments,
      params
    )
    return response
  }

  getUserAttachment = async (id: number) => {
    const response = await this.axios.get<IPayload<IAttachment>>(Endpoints.getUserAttachment(id))
    return response
  }

  postUserAttachment = async (params: IPostUserAttachmentParams) => {
    const response = await this.axios.post<IPayload<IAttachment>>(
      Endpoints.postUserAttachment,
      params
    )
    return response
  }

  putUserAttachment = async (id: number, params: IPutUserAttachmentParams) => {
    const response = await this.axios.put<IPayload<IAttachment>>(
      Endpoints.putUserAttachment(id),
      params
    )
    return response
  }

  deleteUserAttachment = async (id: number) => {
    const response = await this.axios.delete<{}>(Endpoints.deleteUserAttachment(id))
    return response
  }
}
