import { Text } from '@pinchenterprisesnpm/friday-ui'
import ROUTES from '../../../../routing/adminRoutes'
import { API } from '../../../../network'
import { IUser, USER_ROLE } from '../../../../models/User'
import { IBusinessEntity } from '../../../../models/BusinessEntity'
import { ITimesheet } from '../../../../models/Timesheet'
import { IInvoice } from '../../../../models/Invoice'
import { IProperty } from '../../../../models/Property'
import { handleErrorNotification } from '../../../../services/ErrorHandlingService'

export const DEFAULT_POWER_SEARCH_PLACEHOLDER = 'Power Search'

export type TPowerSearchData = IUser | IBusinessEntity | ITimesheet | IInvoice | IProperty

export enum POWER_SEARCH_RESOURCE_TYPE {
  UPSHIFTER = 'Upshifter',
  BUSINESS = 'Business User',
  BUSINESS_ENTITY = 'Business Entity',
  TIMESHEET = 'Timesheet',
  INVOICE = 'Invoice',
  LOCATION = 'Location',
}

export const mappedResourceTypes = Object.values(POWER_SEARCH_RESOURCE_TYPE)

export interface IPowerSearchFilterType {
  type: string
  redirectToPage: string
}

export type TPowerSearchRecentData = {
  search: string
  resourceType: POWER_SEARCH_RESOURCE_TYPE
}

export const POWER_SEARCH_LOCAL_STORAGE_ITEM = 'powerSearchRecentData'

// Function for storing power search recent data in the localStorage
const storePowerSearchRecentData = (item: TPowerSearchRecentData) => {
  // Get the current list of items from local storage
  const currentList = localStorage.getItem(POWER_SEARCH_LOCAL_STORAGE_ITEM)

  // If the list does not exist in local storage, create an empty list
  const list = (currentList ? JSON.parse(currentList) : []) as TPowerSearchRecentData[]

  // Remove any duplicate items from the list
  const filteredList = list.length
    ? list?.filter((x) => !(x.search === item.search && x.resourceType === item.resourceType))
    : []

  // Add the new item to the beginning of the list
  const updatedList = [item, ...filteredList]

  // Keep only the first 5 items in the list
  const shortenedList = updatedList.slice(0, 5)

  // Store the updated list in local storage
  localStorage.setItem(POWER_SEARCH_LOCAL_STORAGE_ITEM, JSON.stringify(shortenedList))
}

// Function for removing power search recent data from localStorage
export const removePowerSearchRecentData = ({ search, resourceType }: TPowerSearchRecentData) => {
  // Get the current list of items from local storage
  const currentList = localStorage.getItem(POWER_SEARCH_LOCAL_STORAGE_ITEM)

  // If the list does not exist in local storage, create an empty list
  const list = (currentList ? JSON.parse(currentList) : []) as TPowerSearchRecentData[]

  // Create a new list that does not include the item to remove
  const updatedList = list.filter((x) => !(x.search === search && x.resourceType === resourceType))

  // Store the updated list in local storage
  localStorage.setItem('powerSearchRecentData', JSON.stringify(updatedList))
}

// Function that redirects the user to the specific page depending on the resource type
export const redirectToPage = (resourceType: POWER_SEARCH_RESOURCE_TYPE) => {
  switch (resourceType) {
    case POWER_SEARCH_RESOURCE_TYPE.UPSHIFTER:
      return ROUTES.UPSHIFTERS_LIST
    case POWER_SEARCH_RESOURCE_TYPE.BUSINESS:
      return ROUTES.BUSINESS_USERS_LIST
    case POWER_SEARCH_RESOURCE_TYPE.BUSINESS_ENTITY:
      return ROUTES.BUSINESSES_LIST
    case POWER_SEARCH_RESOURCE_TYPE.TIMESHEET:
      return ROUTES.TIMESHEETS_LIST
    case POWER_SEARCH_RESOURCE_TYPE.INVOICE:
      return ROUTES.INVOICES_LIST
    case POWER_SEARCH_RESOURCE_TYPE.LOCATION:
      return ROUTES.LOCATIONS_LIST
    default:
      return ROUTES.ROOT
  }
}

// Function that redirects the user to the specific page depending on the resource ty
export const redirectToPageById = (
  resourceType: POWER_SEARCH_RESOURCE_TYPE,
  id: string | number
) => {
  switch (resourceType) {
    case POWER_SEARCH_RESOURCE_TYPE.UPSHIFTER:
      return ROUTES.UPSHIFTERS_VIEW.replace(':id', id.toString())
    case POWER_SEARCH_RESOURCE_TYPE.BUSINESS:
      return ROUTES.EDIT_BUSINESS_USER.replace(':id', id.toString())
    case POWER_SEARCH_RESOURCE_TYPE.BUSINESS_ENTITY:
      return ROUTES.BUSINESS_ENTITY_VIEW.replace(':id', id.toString())
    case POWER_SEARCH_RESOURCE_TYPE.TIMESHEET:
      return ROUTES.TIMESHEETS_VIEW.replace(':id', id.toString())
    case POWER_SEARCH_RESOURCE_TYPE.INVOICE:
      return ROUTES.INVOICE_VIEW.replace(':id', id.toString())
    case POWER_SEARCH_RESOURCE_TYPE.LOCATION:
      return ROUTES.LOCATION.replace(':id', id.toString())
    default:
      return ROUTES.ROOT
  }
}

// Function for fetching data depending on the resource type
export const fetchDataByResourceType = async (
  resourceType: POWER_SEARCH_RESOURCE_TYPE,
  search: string
) => {
  // We need to show up to 5 results so we set per_page parametar to be 5
  const params = { search, per_page: 5 }
  switch (resourceType) {
    case POWER_SEARCH_RESOURCE_TYPE.UPSHIFTER:
      return await API.Users.getUsers({ role: USER_ROLE.LABOR, ...params })
    case POWER_SEARCH_RESOURCE_TYPE.BUSINESS:
      return await API.Users.getUsers({ role: USER_ROLE.BUSINESS, ...params })
    case POWER_SEARCH_RESOURCE_TYPE.BUSINESS_ENTITY:
      return await API.BusinessEntities.getBusinessEntities({ ...params })
    case POWER_SEARCH_RESOURCE_TYPE.TIMESHEET:
      return await API.Timesheets.getTimesheets({ include: ['business'], ...params })
    case POWER_SEARCH_RESOURCE_TYPE.INVOICE:
      return await API.Invoices.getInvoices({ ...params })
    case POWER_SEARCH_RESOURCE_TYPE.LOCATION:
      return await API.Properties.getProperties({ ...params })
  }
}

/* Function for handling fetched data depending on the resource type.
We give this function as an input to the useDebouncedSearch hook
After receiving data from the BND, we store the search param in the local storage */
export const handleFetchingDataByResourceType = async (
  search: string,
  resourceType: POWER_SEARCH_RESOURCE_TYPE
) => {
  try {
    const response = await fetchDataByResourceType(
      resourceType as POWER_SEARCH_RESOURCE_TYPE,
      search
    )
    if (search && response?.data && resourceType) {
      storePowerSearchRecentData({ search, resourceType })
    }
    return response?.data
  } catch (error) {
    handleErrorNotification(error)
    throw Error(error)
  }
}

/* Return different property for showing in the list depending on the resource type. 
Upshifters and Business User should display their first name, Business Entity and Properties (Location) their name property, 
for Timesheets we need to show ID, Location, Company Name and for Invoices we should display their id and company name. */
export const getResourceTypeProperty = (
  item: TPowerSearchData,
  resourceType: POWER_SEARCH_RESOURCE_TYPE
) => {
  switch (resourceType) {
    case POWER_SEARCH_RESOURCE_TYPE.UPSHIFTER:
      const upshifterFullName = 'full_name' as keyof TPowerSearchData
      return item[upshifterFullName]
    case POWER_SEARCH_RESOURCE_TYPE.BUSINESS:
      const businessUserFullName = 'full_name' as keyof TPowerSearchData
      const companyName = 'company_name' as keyof TPowerSearchData
      return item[companyName] ? (
        <>
          {item[businessUserFullName]}
          <Text color='primary' fontWeight={'bold'} mb={0} mt={3}>
            {item[companyName]}
          </Text>
        </>
      ) : (
        item[businessUserFullName]
      )
    case POWER_SEARCH_RESOURCE_TYPE.BUSINESS_ENTITY:
    case POWER_SEARCH_RESOURCE_TYPE.LOCATION:
      const name = 'name' as keyof TPowerSearchData
      return item[name]
    case POWER_SEARCH_RESOURCE_TYPE.TIMESHEET:
      const timesheetCompanyName = 'owner_company_name' as keyof TPowerSearchData
      const timesheetLocation = 'property_name' as keyof TPowerSearchData
      const timesheetFromDate = 'from' as keyof TPowerSearchData
      const timesheetToDate = 'to' as keyof TPowerSearchData
      return `${item.id} ${item[timesheetCompanyName]} ${item[timesheetLocation]} ${item[timesheetFromDate]}-${item[timesheetToDate]}`
    case POWER_SEARCH_RESOURCE_TYPE.INVOICE:
      const invoiceCompanyName = 'owner_company_name' as keyof TPowerSearchData
      const invoiceFromDate = 'from' as keyof TPowerSearchData
      const invoiceToDate = 'to' as keyof TPowerSearchData
      return `${item.id} ${item[invoiceCompanyName]} ${item[invoiceFromDate]}-${item[invoiceToDate]}`
  }
}

export const redirectOnClick = (resourceType: POWER_SEARCH_RESOURCE_TYPE, searchTerm: string) => {
  if (searchTerm) {
    window.location.assign(redirectToPage(resourceType) + `?search=${searchTerm}`)
  } else {
    window.location.assign(redirectToPage(resourceType))
  }
}
export const checkIfInputTextSeparatorCharacter = (text: string, index: number) => {
  if (index < 0 || index >= text.length) {
    return false
  }
  return text[index] === '/'
}
