import { FOLLOW } from 'models/Permission'
import ROUTES from 'routing/adminRoutes'
import { AsyncFollowManagement } from 'ui/pages'
import AdminRouteLayout from '../../../Admin/AdminRouteLayout'
import AdminRoute from '../../AdminRoute'

const followManagementRoutes = [
  <AdminRoute
    exact
    path={ROUTES.FOLLOW_MANAGEMENT}
    layout={AdminRouteLayout}
    component={AsyncFollowManagement}
    requiredPermissions={[FOLLOW.READ_FOLLOW, FOLLOW.UPDATE_FOLLOW, FOLLOW.CREATE_FOLLOW]}
    key={ROUTES.FOLLOW_MANAGEMENT}
  />,
]

export default followManagementRoutes
