import { IBusinessEntitiesReducer } from 'models/BusinessEntity'
import { Action } from 'redux'
import { GetBusinessEntities, BusinessEntitiesFetching, GetBusinessEntitiesErrors } from './actions'
import { isType } from 'ts-action'

const initialState: IBusinessEntitiesReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: [],
}

export const businessEntitiesReducer = (
  state = initialState,
  action: Action
): IBusinessEntitiesReducer => {
  if (isType(action, GetBusinessEntities)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
    }
  }
  if (isType(action, GetBusinessEntitiesErrors)) {
    const { payload } = action
    return {
      ...state,
      error: {
        code: payload.code,
        message: payload.message,
      },
    }
  }
  if (isType(action, BusinessEntitiesFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }
  return state
}
