import { Action } from 'redux'
import {
  PendingApplicationLoading,
  AcceptedApplicationLoading,
  DeclineApplicationLoading,
} from './actions'
import { isType } from 'ts-action'

export interface ISelectedShiftApplicationLoading {
  [key: number]: boolean
}

export interface ISelectedShiftApplicationLoadingsReducer {
  acceptedApplicationsLoading: ISelectedShiftApplicationLoading
  pendingApplicationsLoading: ISelectedShiftApplicationLoading
  declineApplicationsLoading: ISelectedShiftApplicationLoading
}

const initialState = {
  acceptedApplicationsLoading: {},
  pendingApplicationsLoading: {},
  declineApplicationsLoading: {},
}

export const selectedShiftApplicationLoadingsReducer = (
  state = initialState,
  action: Action
): ISelectedShiftApplicationLoadingsReducer => {
  if (isType(action, PendingApplicationLoading)) {
    const {
      payload: { id, value },
    } = action

    return {
      ...state,
      pendingApplicationsLoading: {
        ...state.pendingApplicationsLoading,
        [id]: value,
      },
    }
  }

  if (isType(action, AcceptedApplicationLoading)) {
    const {
      payload: { id, value },
    } = action

    return {
      ...state,
      acceptedApplicationsLoading: {
        ...state.acceptedApplicationsLoading,
        [id]: value,
      },
    }
  }

  if (isType(action, DeclineApplicationLoading)) {
    const {
      payload: { id, value },
    } = action

    return {
      ...state,
      declineApplicationsLoading: {
        ...state.declineApplicationsLoading,
        [id]: value,
      },
    }
  }

  return state
}
