import { IAcknowledgedDocumentsReducer } from 'models/AcknowledgedDocument'
import { Action } from 'redux'
import { isType } from 'ts-action'
import { GetAcknowledgedDocuments, AcknowledgedDocumentsFetching } from './actions'

const initialState: IAcknowledgedDocumentsReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: [],
}

export const acknowledgedDocumentsReducer = (
  state = initialState,
  action: Action
): IAcknowledgedDocumentsReducer => {
  if (isType(action, GetAcknowledgedDocuments)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
    }
  }
  if (isType(action, AcknowledgedDocumentsFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }
  return state
}
