import { ISuggestedPayRateReducer, ISuggestedPayRate } from 'models/SuggestedPayRate'
import { Action } from 'redux'
import { isType } from 'ts-action'
import { updateArray, deleteFromArray } from 'helpers/helperFunctions'
import {
  GetSuggestedPayRates,
  SuggestedPayRatesFetching,
  UpdateSuggestedPayRate,
  DeleteSuggestedPayRate,
  UpdateSuggestedPayRateLoadingIds,
} from './actions'

const initialState: ISuggestedPayRateReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: [],
  suggestedPayRatesLoadingIds: {},
}

export const suggestedPayRatesReducer = (
  state = initialState,
  action: Action
): ISuggestedPayRateReducer => {
  if (isType(action, GetSuggestedPayRates)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
    }
  }
  if (isType(action, UpdateSuggestedPayRate)) {
    const { payload } = action
    const { data } = state
    const formattedArray = updateArray<ISuggestedPayRate>(data, payload.data)
    return {
      ...state,
      data: formattedArray,
    }
  }
  if (isType(action, DeleteSuggestedPayRate)) {
    const { payload } = action
    const { data } = state
    const formattedArray = deleteFromArray<ISuggestedPayRate>(data, payload)
    return {
      ...state,
      data: formattedArray,
    }
  }
  if (isType(action, UpdateSuggestedPayRateLoadingIds)) {
    const { payload: id } = action
    const newSuggestedPayRateLoadingIds = { ...state.suggestedPayRatesLoadingIds }
    if (newSuggestedPayRateLoadingIds[id]) {
      delete newSuggestedPayRateLoadingIds[id]
    } else {
      newSuggestedPayRateLoadingIds[id] = true
    }
    return {
      ...state,
      suggestedPayRatesLoadingIds: newSuggestedPayRateLoadingIds,
    }
  }
  if (isType(action, SuggestedPayRatesFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }
  return state
}
