import { AxiosService } from '../config/config'
import { Endpoints } from '../config/endpoints'
import { IPayload } from 'models/Global'
import { IGetShiftsParams, IShift, IPutShiftParams, IPostShiftParams } from 'models/Shift'

export class ShiftsApi {
  constructor(private axios: AxiosService) {}

  getShifts = async (params?: IGetShiftsParams) => {
    const response = await this.axios.get<IPayload<IShift[]>>(Endpoints.getShifts, params)
    return response
  }

  getShift = async (id: number, params?: IGetShiftsParams) => {
    const response = await this.axios.get<IPayload<IShift>>(Endpoints.getShift(id), params)
    return response
  }

  postShift = async (params: IPostShiftParams) => {
    const response = await this.axios.post<IPayload<IShift>>(Endpoints.postShift, params)
    return response
  }

  putShift = async (id: number, params: IPutShiftParams) => {
    const reponse = await this.axios.put<IPayload<IShift>>(Endpoints.putShift(id), params)
    return reponse
  }

  deleteShift = async (id: number) => {
    const response = await this.axios.delete(Endpoints.deleteShift(id))
    return response
  }
}
