import { action, payload } from 'ts-action'
import { IFollow } from 'models/Follow'
import { IError, IPayload } from 'models/Global'
import { IShift } from 'models/Shift'

export const GetShifts = action('GetShifts', payload<IPayload<IShift[]>>())

export const GetShiftsErrors = action('GetShiftsErrors', payload<IError>())

export const ShiftsFetching = action('ShiftsFetching', payload<boolean>())

export const AddShift = action('AddShift', payload<IPayload<IShift>>())

export const UpdateShift = action('UpdateShift', payload<IPayload<IShift>>())

export const DeleteShift = action('DeleteShift', payload<number>())

export const ResetShiftsReducer = action('ResetShiftsReducer')

export const FollowShift = action('FollowShift', payload<IFollow>())

export const UnfollowShift = action('UnfollowShift', payload<IFollow>())
