import {
  INotificationPreferenceReducer,
  INotificationPreference,
  NOTIFICATION_CHANNEL_NAME,
  NOTIFICATION_PREFERENCE_STATUS,
} from 'models/NotificationPreference'
import { Action } from 'redux'
import { isType } from 'ts-action'
import { updateArray, deleteFromArray } from 'helpers/helperFunctions'
import {
  GetNotificationPreferences,
  NotificationPreferencesFetching,
  UpdateNotificationPreference,
  DeleteNotificationPreference,
  EnableTextNotificationPreferences,
  ClearNotificationPreferencesReducer,
} from './actions'

const initialState: INotificationPreferenceReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: [],
}

export const notificationPreferencesReducer = (
  state = initialState,
  action: Action
): INotificationPreferenceReducer => {
  if (isType(action, GetNotificationPreferences)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
    }
  }
  if (isType(action, UpdateNotificationPreference)) {
    const { payload } = action
    const { data } = state
    const formattedArray = updateArray<INotificationPreference>(data, payload.data)
    return {
      ...state,
      data: formattedArray,
    }
  }
  if (isType(action, EnableTextNotificationPreferences)) {
    const { data } = state
    const formattedArray = data.map((element) => {
      if (
        element.notification_channel_name === NOTIFICATION_CHANNEL_NAME.TEXT &&
        element.status !== null
      ) {
        return {
          ...element,
          status: NOTIFICATION_PREFERENCE_STATUS.ENABLED,
        }
      } else return element
    })
    return {
      ...state,
      data: formattedArray,
    }
  }
  if (isType(action, DeleteNotificationPreference)) {
    const { payload } = action
    const { data } = state
    const formattedArray = deleteFromArray<INotificationPreference>(data, payload)
    return {
      ...state,
      data: formattedArray,
    }
  }
  if (isType(action, NotificationPreferencesFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }
  if (isType(action, ClearNotificationPreferencesReducer)) {
    return initialState
  }
  return state
}
