import { action, payload } from 'ts-action'
import { IBusinessEntity } from 'models/BusinessEntity'
import { IError, IPayload } from 'models/Global'
import { IBusinessSetting } from 'models/BusinessSetting'

export const GetSelectedShiftBusinessEntityAction = action(
  'GetSelectedShiftBusinessEntity',
  payload<IPayload<IBusinessEntity>>()
)

export const GetSelectedShiftBusinessEntityErrors = action(
  'GetSelectedShiftBusinessEntityErrors',
  payload<IError>()
)

export const SelectedShiftBusinessEntityFetching = action(
  'SelectedShiftBusinessEntityFetching',
  payload<boolean>()
)

export const UpdateSelectedShiftBusinessEntity = action(
  'UpdateSelectedShiftBusinessEntity',
  payload<IPayload<IBusinessEntity>>()
)

export const ClearSelectedShiftBusinessEntity = action('ClearSelectedShiftBusinessEntity')

export const UpdateSelectedShiftBusinessEntitySetting = action(
  'UpdateSelectedShiftBusinessEntitySetting',
  payload<IBusinessSetting>()
)
