import { action, payload } from 'ts-action'
import { IPositionCategory } from '../../models/PositionCategory'
import { IError, IPayload } from '../../models/Global'

export const PositionCategoriesFetching = action('PositionCategoriesFetching', payload<boolean>())

export const GetPositionCategories = action(
  'GetPositionCategories',
  payload<IPayload<IPositionCategory[]>>()
)

export const AddPositionCategory = action(
  'AddPositionCategory',
  payload<IPayload<IPositionCategory>>()
)

export const UpdatePositionCategory = action(
  'UpdatePositionCategory',
  payload<IPayload<IPositionCategory>>()
)

export const GetPositionCategoriesErrors = action('GetPositionCategoriesErrors', payload<IError>())
