import { IFeaturedShiftCampaignCreateSelectedShiftsReducer } from 'models/FeaturedShiftCampaign'
import { Action } from 'redux'
import { isType } from 'ts-action'
import { updateArray, deleteFromArray } from 'helpers/helperFunctions'
import {
  GetFeaturedShiftCampaignCreateSelectedShifts,
  FeaturedShiftCampaignCreateSelectedShiftsFetching,
  UpdateFeaturedShiftCampaignCreateSelectedShift,
  DeleteFeaturedShiftCampaignCreateSelectedShift,
  AddFeaturedShiftCampaignCreateSelectedShift,
  GetFeaturedShiftCampaignCreateSelectedShift,
  FakeDeleteFeaturedShiftCampaignCreateSelectedShift,
  FakeDeleteFeaturedShiftCampaignCreateSelectedShifts,
  DeleteFeaturedShiftCampaignCreateSelectedShifts,
  AddFeaturedShiftCampaignCreateAllSelectedShifts,
} from './actions'
import { IShift } from 'models/Shift'

const initialState: IFeaturedShiftCampaignCreateSelectedShiftsReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: true,
  data: [],
}

export const FeaturedShiftCampaignCreateSelectedShiftsReducer = (
  state = initialState,
  action: Action
): IFeaturedShiftCampaignCreateSelectedShiftsReducer => {
  if (isType(action, GetFeaturedShiftCampaignCreateSelectedShifts)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
    }
  }

  if (isType(action, GetFeaturedShiftCampaignCreateSelectedShift)) {
    const { payload } = action
    const { data } = state
    const newArray = [...data, payload.data]

    return {
      ...state,
      meta: payload.meta,
      data: newArray,
    }
  }

  if (isType(action, AddFeaturedShiftCampaignCreateSelectedShift)) {
    const { payload } = action
    const { data } = state
    const newArray = [payload, ...data]
    return {
      ...state,
      data: newArray,
    }
  }

  if (isType(action, AddFeaturedShiftCampaignCreateAllSelectedShifts)) {
    const { payload } = action
    return {
      ...state,
      data: payload,
    }
  }

  if (isType(action, UpdateFeaturedShiftCampaignCreateSelectedShift)) {
    const { payload } = action
    const { data } = state
    const formattedArray = updateArray<IShift>(data, payload.data)
    return {
      ...state,
      data: formattedArray,
    }
  }

  if (isType(action, DeleteFeaturedShiftCampaignCreateSelectedShift)) {
    const { payload } = action
    const { data } = state
    const formattedArray = deleteFromArray<IShift>(data, payload)
    return {
      ...state,
      data: formattedArray,
    }
  }

  if (isType(action, DeleteFeaturedShiftCampaignCreateSelectedShifts)) {
    return {
      ...state,
      data: [],
    }
  }

  if (isType(action, FakeDeleteFeaturedShiftCampaignCreateSelectedShift)) {
    const { payload } = action
    const { data } = state
    const formattedArray = deleteFromArray<IShift>(data, payload)
    return {
      ...state,
      data: formattedArray,
    }
  }

  if (isType(action, FakeDeleteFeaturedShiftCampaignCreateSelectedShifts)) {
    return {
      ...state,
      data: [],
    }
  }

  if (isType(action, FeaturedShiftCampaignCreateSelectedShiftsFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }

  return state
}
