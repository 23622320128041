import {
  IPunchCard,
  IPostPunchCardParams,
  IPutPunchCardParams,
  PUNCHCARD_REASON,
  PUNCHCARD_REASON_HOW,
} from 'models/PunchCard'
import moment from 'moment'
import 'moment-timezone'
import {
  NOTIFICATION_TYPE,
  NOTIFICATION_INSERTION,
  NOTIFICATION_CONTAINER,
  NotificationManager,
} from 'helpers/NotificationsManager'
import { history } from 'helpers/history'
import { PUNCHCARD_REASON_MESSAGE, PUNCHCARD_REASON_HOW_MESSAGE } from 'helpers/constants/constants'
import { TypeOrNull } from 'models/Common'
import { ITimesheetShift, ITimesheet } from 'models/Timesheet'
import Timesheets from 'data/Timesheets'
import { PunchCardsActions } from 'data/PunchCard/thunks'
import { bindActionCreators } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { confirmModal, TConfirmModalConfig } from 'helpers/GenericConfirmModal'
import { getOffset } from 'hooks/useAvailableWidthForWebMenu'
import { AppState } from 'store/store'
import { selectIsDisabledTimesheetHotkeys } from 'data/TimesheetHotkeys/selectors'
import { differenceInHours, differenceInMinutes } from 'date-fns'
import { URLManager } from 'helpers/URLManager'

export const PUNCH_CARD_LONGER_THAN_15_HOURS =
  'The punchcard is longer than 15 hours. Are you sure you want to save?'
export const PUNCH_CARD_END_TIME_EARLIER_THAN_START =
  'The end time is earlier than the start time. Please edit and attempt save again.'
export const PUNCH_CARD_END_TIME_EQUALS_START =
  'The end time is the same as the start time. Please edit and attempt save again.'
export const PUNCH_CARD_SAVE = 'PUNCH_CARD_SAVE'

export const punchCardChecker = (punchCard: IPunchCard) => {
  const { timestamp_start, timestamp_end } = punchCard
  const momentTimestampStart = new Date(timestamp_start)

  // We check if timestamp_end is null. If it is, we immediately return PUNCH_CARD_SAVE
  // We do this because in that case we cannot calulate the difference in hours.
  // Which means it will fail all the following conditions and return PUNCH_CARD_SAVE
  // In fact, if we do not do this conditional, TS will show an error, because Date cannot accept string | null type as parameter.
  if (timestamp_end === null) {
    return PUNCH_CARD_SAVE
  }
  const momentTimestampEnd = new Date(timestamp_end)
  const diffHours = differenceInHours(momentTimestampEnd, momentTimestampStart)

  if (diffHours > 15) {
    return PUNCH_CARD_LONGER_THAN_15_HOURS
  }
  if (diffHours < 0) {
    return PUNCH_CARD_END_TIME_EARLIER_THAN_START
  }
  if (diffHours === 0) {
    const momentTimestampStart = new Date(timestamp_start)
    const momentTimestampEnd = new Date(timestamp_end)
    const diffMinutes = differenceInMinutes(momentTimestampEnd, momentTimestampStart)
    if (diffMinutes >= 1) {
      return PUNCH_CARD_SAVE
    } else if (diffMinutes === 0) {
      return PUNCH_CARD_END_TIME_EQUALS_START
    } else {
      return PUNCH_CARD_END_TIME_EARLIER_THAN_START
    }
  } else {
    return PUNCH_CARD_SAVE
  }
}

type TPunchCardCheckerHandlerParams = {
  punchCard: IPunchCard
  savePunchCard: () => void
  resetLoadingState?: () => void
}

export const punchCardCheckerHandler = ({
  punchCard,
  savePunchCard,
  resetLoadingState,
}: TPunchCardCheckerHandlerParams) => {
  const saveGuardAction = punchCardChecker(punchCard)

  const confirmModalConfig: Partial<TConfirmModalConfig> = {
    message: saveGuardAction,
    shouldWaitPromise: false,
    width: 400,
  }
  if (saveGuardAction === PUNCH_CARD_LONGER_THAN_15_HOURS) {
    resetLoadingState && resetLoadingState()
    confirmModalConfig.onConfirm = savePunchCard
    confirmModal(confirmModalConfig)
  } else if (saveGuardAction === PUNCH_CARD_END_TIME_EARLIER_THAN_START) {
    resetLoadingState && resetLoadingState()
    confirmModal(confirmModalConfig)
  } else if (saveGuardAction === PUNCH_CARD_END_TIME_EQUALS_START) {
    resetLoadingState && resetLoadingState()
    confirmModal(confirmModalConfig)
  } else if (saveGuardAction === PUNCH_CARD_SAVE) {
    savePunchCard()
  }
}

export const formatTimestampUtc = (timestamp: string, timezone: string) => {
  const format = 'MM/DD/YYYY hh:mm A'
  const formattedTimestamp = moment.tz(timestamp, format, timezone)
  return formattedTimestamp.tz('utc').format()
}

//If we have a search query in the URL, the page will scroll down to the respective punch card
export const scrollToPunchCard = () => {
  const urlManager = new URLManager()
  const punchCardIdParam = urlManager.getParam('punch_card_id')
  if (punchCardIdParam) {
    const element = document.getElementById(punchCardIdParam)
    element?.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }
}

export const handleProcessedTimesheetRedirect = (timesheetId: number) => {
  const customConfig = {
    type: NOTIFICATION_TYPE.WARNING,
    insert: NOTIFICATION_INSERTION.TOP,
    container: NOTIFICATION_CONTAINER.TOP_CENTER,
    width: 300,
    dismiss: {
      duration: 6000,
      onScreen: true,
      showIcon: true,
    },
  }

  NotificationManager.warning(
    `You will be redirected to new timesheet with ID-${timesheetId} because you are adding punchcard on processed timesheet.`,
    '',
    customConfig
  )

  setTimeout(() => {
    history.push(`/timesheets/${timesheetId}`)
    window.location.reload()
  }, 6500)
}

type TToastMessageValues = {
  reason: PUNCHCARD_REASON_MESSAGE
  reason_how: PUNCHCARD_REASON_HOW_MESSAGE
  note?: string
}

// Hotkey params objects

export const hotkeyAltParams: TPredefinedModalValues = {
  reasonForEdit: PUNCHCARD_REASON.REQUEST_BY_BUSINESS,
  reasonForEditHow: PUNCHCARD_REASON_HOW.TIMESHEET,
  note: 'Timesheet submission',
}

export const hotkeyAltMessage: TToastMessageValues = {
  reason: PUNCHCARD_REASON_MESSAGE.REQUEST_BY_BUSINESS,
  reason_how: PUNCHCARD_REASON_HOW_MESSAGE.TIMESHEET,
  note: 'Timesheet submission',
}

export const hotkeyShiftParams: TPredefinedModalValues = {
  reasonForEdit: PUNCHCARD_REASON.REQUEST_BY_UPSHIFTER,
}

export const hotkeyEParams: TPredefinedModalValues = {
  reasonForEdit: PUNCHCARD_REASON.REQUEST_BY_BUSINESS,
  reasonForEditHow: PUNCHCARD_REASON_HOW.EMAIL,
}

export const hotkeyEMessage: TToastMessageValues = {
  reason: PUNCHCARD_REASON_MESSAGE.REQUEST_BY_BUSINESS,
  reason_how: PUNCHCARD_REASON_HOW_MESSAGE.EMAIL,
}

export const hotkeyPParams: TPredefinedModalValues = {
  reasonForEdit: PUNCHCARD_REASON.REQUEST_BY_BUSINESS,
  reasonForEditHow: PUNCHCARD_REASON_HOW.CALL,
}

export const hotkeyPMessage: TToastMessageValues = {
  reason: PUNCHCARD_REASON_MESSAGE.REQUEST_BY_BUSINESS,
  reason_how: PUNCHCARD_REASON_HOW_MESSAGE.CALL,
}

export const hotkeyZParams: TPredefinedModalValues = {
  reasonForEdit: PUNCHCARD_REASON.REQUEST_BY_BUSINESS,
  reasonForEditHow: PUNCHCARD_REASON_HOW.INTERCOM,
}

export type TPredefinedModalValues = {
  reasonForEdit?: PUNCHCARD_REASON
  reasonForEditHow?: PUNCHCARD_REASON_HOW
  zendeskTicketNumber?: string
  note?: string
}

type TBypassModalResponse = {
  punchCardId: number
  punchCardParams: IPutPunchCardParams
}

/**
 * Function used to map parameters needed to bypass the Edit Punch Card Modal
 * @param punchCard Edited punch card object
 * @param hotkeyParams Params needed to update the punch card
 */
export const bypassEditModalMapper = async (
  punchCard: IPunchCard,
  hotkeyParams: TPredefinedModalValues
) => {
  const {
    reasonForEdit: reason_for_edit,
    reasonForEditHow: reason_for_edit_how,
    zendeskTicketNumber: zendesk_id,
    note,
  } = hotkeyParams
  // response object prepared with arguments for updateSinglePunchCard func
  const response: TBypassModalResponse = {
    punchCardId: punchCard.id,
    punchCardParams: updatePunchCardParamsMapper(
      punchCard,
      reason_for_edit!,
      reason_for_edit_how!,
      note || '',
      zendesk_id
    ),
  }

  return response
}

/**
 * Mapping the params needed for a PUT request on punch card
 * @param punchCard
 * @param reasonForEdit
 * @param reasonForEditHow
 * @param zendeskId
 */
export const updatePunchCardParamsMapper = (
  punchCard: IPunchCard,
  reasonForEdit: PUNCHCARD_REASON,
  reasonForEditHow: PUNCHCARD_REASON_HOW,
  note: string,
  zendeskId?: string
) => {
  const punchCardParams: IPutPunchCardParams = {}

  punchCardParams.break = punchCard.break
  punchCardParams.pay_value = Number(punchCard.pay_value)
  punchCardParams.timestamp_start = punchCard.timestamp_start.toString()
  punchCardParams.note = note
  // If the user is still clocked in we shouldn't send the timestamp end param
  punchCardParams.timestamp_end = undefined
  // If the punchcard is NoEndPunchCard we should send timesheet_end as undefined
  if (!punchCard.isNoEndPunchCard && punchCard.timestamp_end) {
    punchCardParams.timestamp_end = punchCard.timestamp_end
  }
  punchCardParams.reason_for_edit = reasonForEdit

  if (reasonForEditHow !== null && reasonForEdit !== PUNCHCARD_REASON.REQUEST_BY_UPSHIFTER) {
    punchCardParams.reason_for_edit_how = reasonForEditHow
  } else {
    punchCardParams.reason_for_edit_how = PUNCHCARD_REASON_HOW.INTERCOM
  }
  if (
    reasonForEdit === PUNCHCARD_REASON.REQUEST_BY_UPSHIFTER ||
    (reasonForEditHow !== null && reasonForEditHow === PUNCHCARD_REASON_HOW.INTERCOM)
  ) {
    punchCardParams.zendesk_id = zendeskId
  } else {
    punchCardParams.zendesk_id = null
  }

  return punchCardParams
}

export const savePunchCardParamsMapper = (
  values: IPunchCard,
  reasonForEdit: PUNCHCARD_REASON,
  reasonForEditHow: PUNCHCARD_REASON_HOW | null,
  zendeskId?: string
): IPostPunchCardParams => {
  const params: IPostPunchCardParams = {
    application_id: values.application_id,
    break: values.break,
    pay_value: Number(values.pay_value),
    ...(!values.isNoEndPunchCard && { timestamp_end: (values.timestamp_end as string).toString() }), // Casting seems foolish, but is done to preserve behavior after IPunchCard timestamp_end changes to string | null
    timestamp_start: values.timestamp_start.toString(),
    note: '',
  }
  if (values.gig_day_id) {
    params.gig_day_id = values.gig_day_id
  }
  params.reason_for_create = reasonForEdit
  if (reasonForEditHow !== null && reasonForEdit !== PUNCHCARD_REASON.REQUEST_BY_UPSHIFTER) {
    params.reason_for_create_how = reasonForEditHow
  } else {
    params.reason_for_create_how = PUNCHCARD_REASON_HOW.INTERCOM
  }
  if (
    reasonForEdit === PUNCHCARD_REASON.REQUEST_BY_UPSHIFTER ||
    (reasonForEditHow !== null && reasonForEditHow === PUNCHCARD_REASON_HOW.INTERCOM)
  ) {
    params.zendesk_id = zendeskId
  } else {
    params.zendesk_id = null
  }

  return params
}

// Object that stores used keys and their codes
export const keyCodes = {
  Alt: 18,
  Shift: 16,
  Enter: 13,
  T: 84,
  F: 70,
  E: 69,
  Z: 90,
  Slash: 191,
}

export interface IConfirmModal {
  isOpen: boolean
  punchCard: TypeOrNull<IPunchCard>
}

export interface ILongPunchCard extends IConfirmModal {
  message?: string
}

export type TOwnProps = {
  punchCards: IPunchCard[]
  timesheet: ITimesheet
  shift: ITimesheetShift
  addedPunchCard: IPunchCard
  addTablePunchcard: () => void
  removeAddedPunchCard: () => void
  isApplicationAcceptedAndConfirmed: boolean
  height: string
  theme: any
}

export type TApplicationPunchCardsProps = TOwnProps & ILinkDispatchProps & ILinkStateProps

export type TApplicationPunchCardsState = {
  editing: IPunchCard
  workingCopy: IPunchCard
  isDeletePunchCardRequestFinished: boolean
  extraPunchCard: boolean
  isLoading: boolean
  isEditPunchCardModalOpen: boolean
  isCreatePunchCardModalOpen: boolean
  availableWidth: number
  punchCardModalPredefinedFields: TypeOrNull<TPredefinedModalValues>
  isHotkeyModalOpen: boolean
  isNoTermActionModalOpened: boolean
}

export const columnPercentages = {
  0: 5,
  1: 5,
  2: 5,
  3: 18,
  4: 18,
  5: 8,
  6: 8,
  7: 5,
  8: 5,
  9: 5,
  10: 5,
  11: 12,
}

export const calculateAvailableWidth = (offset: number) => {
  const minWidth = 1116
  const availableWidth = window.innerWidth - offset
  if (availableWidth > minWidth) return availableWidth
  else {
    return minWidth
  }
}

export const setInitialAvailableWidth = () => {
  const offset = getOffset()
  return calculateAvailableWidth(offset)
}

interface ILinkDispatchProps {
  addPunchCard: typeof Timesheets.thunks.addPunchCard
  updatePunchCard: typeof Timesheets.thunks.updatePunchCard
  deletePunchCard: typeof Timesheets.thunks.deletePunchCard
  getTimesheet: typeof Timesheets.thunks.getTimesheet
  getPunchCards: typeof Timesheets.thunks.getPunchCards
}

export const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, PunchCardsActions>
): ILinkDispatchProps => ({
  addPunchCard: bindActionCreators(Timesheets.thunks.addPunchCard, dispatch),
  getPunchCards: bindActionCreators(Timesheets.thunks.getPunchCards, dispatch),
  updatePunchCard: bindActionCreators(Timesheets.thunks.updatePunchCard, dispatch),
  deletePunchCard: bindActionCreators(Timesheets.thunks.deletePunchCard, dispatch),
  getTimesheet: bindActionCreators(Timesheets.thunks.getTimesheet, dispatch),
})

interface ILinkStateProps {
  isDisabledTimesheetHotkeys: boolean
}

export const mapStateToProps = (state: AppState): ILinkStateProps => ({
  isDisabledTimesheetHotkeys: selectIsDisabledTimesheetHotkeys(state),
})
