import { MenuItem } from '@pinchenterprisesnpm/friday-ui'
import styled from 'styled-components'

export const AdminRouteLayoutContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
`

export const AdminRouteLayoutSidebar = styled.div`
  width: 240px;
  min-width: 240px;
  background: #ccc;
  min-height: 100vh;
  align-self: stretch;
  overflow: hidden;
  overflow-y: scroll;
`

export const Content = styled.div<{ menuWidth: string }>`
  width: 100%;
  transition: all 0.3s ease-in;
  flex: 1;
  background-color: #f5f5f5;
  margin-top: 50px;
  min-height: ${() => `calc(100vh - ${'50px'})`};

  @media screen and (min-width: ${(props) => `${props.theme.breakpoints.sm}px`}) {
    width: calc(100% - ${({ menuWidth }) => menuWidth});
    margin-left: ${({ menuWidth }) => menuWidth};
    margin-top: 0;
    min-height: 100vh;
  }
`

export const EnvironmentContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  border-top: 3px solid #f7b731;
  display: flex;
  z-index: 103;
  justify-content: center;
`

export const EnvironmentContainerData = styled.span`
  background: #f7b731;
  color: #fff;
  text-transform: capitalize;
  padding: 0px 5px;
  z-index: 10;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
`

export const AddPunchCardMenuItem = styled(MenuItem)<{ isMenuCollapsed?: boolean }>`
  ${({ isMenuCollapsed }) =>
    // These values are being used directly to align with the
    // same ones used in friday-ui
    !isMenuCollapsed &&
    `& > div {
      margin-left: 42px;
      display: inline-block;
      position: relative;
    }

    & > div:before {
      content: '\xB7';
      position: absolute;
      left: -15px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 34px;
    }`}
`
