import { AxiosService } from '../config/config'
import { Endpoints } from '../config/endpoints'
import { IPayload } from 'models/Global'
import { IShortLink, IPostShortLinkParams } from 'models/ShortLink'

export class ShortLinksApi {
  constructor(private axios: AxiosService) {}

  postShortLink = async (params: IPostShortLinkParams) => {
    const response = await this.axios.post<IPayload<IShortLink>>(Endpoints.postShortLink, params)
    return response
  }
}
