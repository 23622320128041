import { Action } from 'redux'
import { isType } from 'ts-action'
import {
  UpdateSelectedBadge,
  UpdateSelectedCertificateOrganization,
  ResetCertificationRequestReviewFlow,
  UpdateExpiryDate,
} from './actions'
import { TypeOrNull } from 'models/Common'
import { ICertificate } from 'models/Certificate'
import { ICertificateOrganization } from 'models/CertificateOrganization'

interface ICertificationRequestReviewFlowReducer {
  selectedBadge: TypeOrNull<ICertificate>
  selectedCertificateOrganization: TypeOrNull<ICertificateOrganization>
  selectedExpiryDate: TypeOrNull<string>
}

const initialState: ICertificationRequestReviewFlowReducer = {
  selectedBadge: null,
  selectedCertificateOrganization: null,
  selectedExpiryDate: null,
}

export const certificationRequestReviewFlowReducer = (
  state = initialState,
  action: Action
): ICertificationRequestReviewFlowReducer => {
  if (isType(action, UpdateSelectedBadge)) {
    const { payload } = action
    return {
      ...state,
      selectedBadge: payload,
    }
  }

  if (isType(action, UpdateSelectedCertificateOrganization)) {
    const { payload } = action
    return {
      ...state,
      selectedCertificateOrganization: payload,
    }
  }

  if (isType(action, UpdateExpiryDate)) {
    const { payload } = action
    return {
      ...state,
      selectedExpiryDate: payload,
    }
  }

  if (isType(action, ResetCertificationRequestReviewFlow)) {
    return initialState
  }

  return state
}
