import { IAuthReducer, IAuthData } from 'models/Auth'
import { Action } from 'redux'
import {
  Login,
  LoginFetching,
  LoginErrors,
  SetIsAuthUser,
  SetUserData,
  ClearAuthMeta,
  TwoFactorVerifying,
  ClearAuthReducer,
  ClearMeta,
  ResendingTwoFactorCode,
} from './actions'
import { isType } from 'ts-action'

const initialState: IAuthReducer = {
  meta: {
    code: 0,
    message: '',
  },
  authMeta: {
    code: 0,
    message: '',
    auth: {
      token: '',
      expires_at: '',
      tfa_status: false,
    },
  },
  isFetching: false,
  isTwoFactorVerifying: false,
  isResendingTwoFactorCode: false,
  data: {} as IAuthData,
  isAuth: false,
}

export const authReducer = (state = initialState, action: Action): IAuthReducer => {
  if (isType(action, Login)) {
    const { payload } = action
    return {
      ...state,
      data: payload.data,
      authMeta: payload.meta,
    }
  }
  if (isType(action, SetUserData)) {
    const { payload } = action
    return {
      ...state,
      data: payload,
    }
  }
  if (isType(action, ClearAuthMeta)) {
    return {
      ...state,
      authMeta: {
        code: 0,
        message: '',
        auth: {
          token: '',
          expires_at: '',
          tfa_status: false,
        },
      },
    }
  }
  if (isType(action, ClearMeta)) {
    return {
      ...state,
      meta: {
        code: 0,
        message: '',
      },
    }
  }
  if (isType(action, ClearAuthReducer)) {
    return initialState
  }
  if (isType(action, SetIsAuthUser)) {
    return {
      ...state,
      isAuth: true,
    }
  }
  if (isType(action, LoginFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }
  if (isType(action, TwoFactorVerifying)) {
    const { payload } = action
    return {
      ...state,
      isTwoFactorVerifying: payload,
    }
  }
  if (isType(action, ResendingTwoFactorCode)) {
    const { payload } = action
    return {
      ...state,
      isResendingTwoFactorCode: payload,
    }
  }
  if (isType(action, LoginErrors)) {
    const { payload } = action
    return {
      ...state,
      meta: payload,
    }
  }
  return state
}
