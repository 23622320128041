import { action, payload } from 'ts-action'
import { IError, IPayload } from 'models/Global'
import { IAcknowledgedDocument } from 'models/AcknowledgedDocument'

export const GetAcknowledgedDocuments = action(
  'GetAcknowledgedDocuments',
  payload<IPayload<IAcknowledgedDocument[]>>()
)
export const GetAcknowledgedDocumentsErrors = action(
  'GetAcknowledgedDocumentsErrors',
  payload<IError>()
)
export const AcknowledgedDocumentsFetching = action(
  'AcknowledgedDocumentsFetching',
  payload<boolean>()
)
