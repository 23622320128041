import {
  EXPENSE_TYPES,
  INVOICES,
  PENDING_VERIFICATION_PUNCH_CARDS,
  PUNCH_CARDS,
  REPORTS,
  TIMESHEETS,
} from 'models/Permission'
import ROUTES from 'routing/adminRoutes'
import {
  AsyncFinanceAgingReport,
  AsyncFinanceExpenseTypes,
  AsyncFinanceInvoice,
  AsyncFinanceInvoices,
  AsyncFinanceInvoiceSendMail,
  AsyncPendingDeletionPunchcards,
  AsyncTimesheet,
  AsyncTimesheets,
  AsyncFinanceAddEditExpenseType,
  AsyncPendingVerificationPunchcards,
} from 'ui/pages'
import AdminRouteLayout from '../../../Admin/AdminRouteLayout'
import AdminRoute from '../../AdminRoute'

const financeRoutes = [
  <AdminRoute
    exact
    path={ROUTES.TIMESHEETS_LIST}
    layout={AdminRouteLayout}
    component={AsyncTimesheets}
    requiredPermissions={[TIMESHEETS.READ_TIMESHEETS]}
    key={ROUTES.TIMESHEETS_LIST}
  />,
  <AdminRoute
    exact
    path={ROUTES.TIMESHEETS_VIEW}
    layout={AdminRouteLayout}
    component={AsyncTimesheet}
    requiredPermissions={[TIMESHEETS.READ_TIMESHEETS]}
    key={ROUTES.TIMESHEETS_VIEW}
  />,
  <AdminRoute
    exact
    path={ROUTES.INVOICES_LIST}
    layout={AdminRouteLayout}
    component={AsyncFinanceInvoices}
    requiredPermissions={[INVOICES.READ_INVOICES]}
    key={ROUTES.INVOICES_LIST}
  />,
  <AdminRoute
    exact
    path={ROUTES.INVOICE_VIEW}
    layout={AdminRouteLayout}
    component={AsyncFinanceInvoice}
    requiredPermissions={[INVOICES.READ_INVOICES]}
    key={ROUTES.INVOICE_VIEW}
  />,
  <AdminRoute
    exact
    path={ROUTES.INVOICE_SEND_EMAIL}
    layout={AdminRouteLayout}
    component={AsyncFinanceInvoiceSendMail}
    key={ROUTES.INVOICE_SEND_EMAIL}
  />,
  <AdminRoute
    exact
    path={ROUTES.AGING_REPORT_LIST}
    layout={AdminRouteLayout}
    component={AsyncFinanceAgingReport}
    requiredPermissions={[REPORTS.AGING_REPORT_DOWNLOAD]}
    key={ROUTES.AGING_REPORT_LIST}
  />,
  <AdminRoute
    exact
    path={ROUTES.PUNCHCARDS_PENDING_DELETION}
    layout={AdminRouteLayout}
    component={AsyncPendingDeletionPunchcards}
    requiredPermissions={[PUNCH_CARDS.READ_PUNCH_CARDS, PUNCH_CARDS.UPDATE_PUNCH_CARDS]}
    key={ROUTES.PUNCHCARDS_PENDING_DELETION}
  />,
  <AdminRoute
    exact
    path={ROUTES.ADD_EXPENSE_TYPE}
    layout={AdminRouteLayout}
    component={AsyncFinanceAddEditExpenseType}
    requiredPermissions={[EXPENSE_TYPES.CREATE_EXPENSE_TYPES]}
    key={ROUTES.ADD_EXPENSE_TYPE}
  />,
  <AdminRoute
    path={ROUTES.EDIT_EXPENSE_TYPE}
    layout={AdminRouteLayout}
    component={AsyncFinanceAddEditExpenseType}
    requiredPermissions={[EXPENSE_TYPES.UPDATE_EXPENSE_TYPES]}
    key={ROUTES.EDIT_EXPENSE_TYPE}
  />,
  <AdminRoute
    exact
    path={ROUTES.EXPENSE_TYPES}
    layout={AdminRouteLayout}
    component={AsyncFinanceExpenseTypes}
    requiredPermissions={[EXPENSE_TYPES.READ_EXPENSE_TYPES]}
    key={ROUTES.EXPENSE_TYPES}
  />,
  <AdminRoute
    exact
    path={ROUTES.PUNCHCARDS_PENDING_VERIFICATION}
    layout={AdminRouteLayout}
    component={AsyncPendingVerificationPunchcards}
    requiredPermissions={[PENDING_VERIFICATION_PUNCH_CARDS.READ_PENDING_VERIFICATION_PUNCH_CARDS]}
    key={ROUTES.PUNCHCARDS_PENDING_VERIFICATION}
  />,
]

export default financeRoutes
