import { AxiosService } from '../config/config'
import { Endpoints } from '../config/endpoints'
import { IPayload } from '../../models/Global'
import {
  IWorkerCompensationRate,
  IGetWorkerCompensationRatesParams,
  IPutWorkerCompensationRateParams,
  IPostWorkerCompensationRateParams,
} from '../../models/WorkerCompensationRate'

export class WorkerCompensationRatesApi {
  constructor(private axios: AxiosService) {}

  getWorkerCompensationRates = async (params?: IGetWorkerCompensationRatesParams) => {
    const response = await this.axios.get<IPayload<IWorkerCompensationRate[]>>(
      Endpoints.getWorkerCompensationRates,
      params
    )
    return response
  }

  getWorkerCompensationRate = async (id: number, params?: IGetWorkerCompensationRatesParams) => {
    const response = await this.axios.get<IPayload<IWorkerCompensationRate>>(
      Endpoints.getWorkerCompensationRate(id),
      params
    )
    return response
  }

  postWorkerCompensationRate = async (params: IPostWorkerCompensationRateParams) => {
    const response = await this.axios.post<IPayload<IWorkerCompensationRate>>(
      Endpoints.postWorkerCompensationRate,
      params
    )
    return response
  }

  putWorkerCompensationRate = async (id: number, params?: IPutWorkerCompensationRateParams) => {
    const response = await this.axios.put<IPayload<IWorkerCompensationRate>>(
      Endpoints.putWorkerCompensationRate(id),
      params
    )
    return response
  }

  deleteWorkerCompensationRate = async (id: number) => {
    const response = await this.axios.delete<{}>(Endpoints.deleteWorkerCompensationRate(id))
    return response
  }
}
