import { action, payload } from 'ts-action'
import { IFollow } from 'models/Follow'
import { IError, IPayload } from 'models/Global'
import { IShift } from 'models/Shift'

export const GetFollowManagementShifts = action(
  'GetFollowManagementShifts',
  payload<IPayload<IShift[]>>()
)

export const GetFollowManagementShiftsErrors = action(
  'GetFollowManagementShiftsErrors',
  payload<IError>()
)

export const FollowManagementShiftsFetching = action(
  'FollowManagementShiftsFetching',
  payload<boolean>()
)

export const UpdateFollowManagementShift = action(
  'UpdateFollowManagementShift',
  payload<IPayload<IFollow>>()
)

export const ResetFollowManagementShiftsReducer = action('ResetFollowManagementShiftsReducer')
