import { AxiosService } from '../config/config'
import { Endpoints } from '../config/endpoints'
import { IPayload } from 'models/Global'
import {
  IUserCertificate,
  IGetUserCertificatesParams,
  IPostUserCertificateParams,
  IPutUserCertificateParams,
} from 'models/UserCertificate'

export class UserCertificatesApi {
  constructor(private axios: AxiosService) {}

  getUserCertificates = async (params?: IGetUserCertificatesParams) => {
    const response = await this.axios.get<IPayload<IUserCertificate[]>>(
      Endpoints.getUserCertificates,
      params
    )
    return response
  }

  getUserCertificate = async (id: number, params?: IGetUserCertificatesParams) => {
    const response = await this.axios.get<IPayload<IUserCertificate>>(
      Endpoints.getUserCertificate(id),
      params
    )
    return response
  }

  postUserCertificate = async (params: IPostUserCertificateParams) => {
    const response = await this.axios.post<IPayload<IUserCertificate>>(
      Endpoints.postUserCertificate,
      params
    )
    return response
  }

  putUserCertificate = async (id: number, params: IPutUserCertificateParams) => {
    const response = await this.axios.put<IPayload<IUserCertificate>>(
      Endpoints.putUserCertificate(id),
      params
    )
    return response
  }

  deleteUserCertificate = async (id: number) => {
    const response = await this.axios.delete<{}>(Endpoints.deleteUserCertificate(id))
    return response
  }
}
