import { IBusinessEntity } from './../BusinessEntity/index'
import { IBaseReducer } from '../BaseReducer'
import { IBaseGetParams } from '../Global'
import { IShift } from '../Shift'
import { IApplication } from '../Application'
import { IPunchCard } from '../PunchCard'
import { IProperty } from '../Property'
import { IBonus } from '../Bonus'
import { IAttachmentsData } from '../Attachment'
import { IAcaSurcharge } from 'models/AcaSurcharge'
import { TypeOrNull } from 'models/Common'
import { INVOICE_STATUS, PAYMENT_TYPE_NAME } from 'models/Invoice'
import { IGigDayExtended } from 'models/GigDay'

enum TIMESHEET_NOTE_STATUS {
  INACTIVE,
  ACTIVE,
}

export enum TIMESHEET_STATUS {
  PENDING,
  PROCESSED,
  DECLINED, // deprecated
  ACTIVE,
  STATUS_ALL,
}

export interface ITimesheetIncludeParams {
  include?:
    | {
        business?: Record<any, any>
        property?: Record<any, any>
        properties?: Record<any, any>
        payment_type?: Record<any, any>
        attachments_count?: Record<any, any>
        notes?: Record<any, any>
        aca_surcharge?: Record<any, any>
      }
    | (
        | 'business'
        | 'property'
        | 'properties'
        | 'payment_type'
        | 'attachments_count'
        | 'notes'
        | 'business.instruction'
        | 'aca_surcharge'
      )[]
}
export interface ITimesheetNote {
  id: number
  note: string
  subject?: string
  created_by_id: number
  created_by_full_name: string
  created_at: string
  status: TIMESHEET_NOTE_STATUS
}
export interface ITimesheet {
  id: number
  property_id: number
  invoice_id: number
  timesheet_number: string
  total: string
  sub_total: string
  pay_total: string
  commission_total: string
  tax: string
  tax_percent: string
  credit: string
  from: string
  to: string
  paid: boolean
  pdf_url: string
  processed_at: string | null
  created_at: string
  updated_at: string
  status: TIMESHEET_STATUS
  locked: boolean
  locked_by: {
    reason: string
    user_id: number
    locked_at: string
    user_full_name: string
  } | null
  invoice_value: string
  invoice_value_total: string
  invoice_value_sub_total: string
  business: IBusinessEntity
  attachments?: IAttachmentsData
  attachments_count?: number
  notes?: ITimesheetNote[]
  aca_surcharge?: TypeOrNull<IAcaSurcharge>

  /**
   * Start Deprecated
   */

  owner_full_name: string
  owner_id: number
  business_industry_id: number
  business_name: string
  owner_company_name: string
  property_name: string
  property_address: string
  property_timezone: string
  payment_type_name: PAYMENT_TYPE_NAME
  sales_taxes_only_commission: boolean

  /**
   * End Deprecated
   */
}

export interface IGetTimesheetsParamsBase extends IBaseGetParams {
  status?: TIMESHEET_STATUS
  payment_type?: string
  owner_id?: number
  business_id?: number
  property_id?: number
  invoice_id?: number
  paid?: boolean
  date_start?: string
  date_end?: string
  gig_id?: number
}

export type IGetTimesheetsParams = IGetTimesheetsParamsBase & ITimesheetIncludeParams

export interface IPostTimesheetParamsBase {
  property_id: number
  from: Date
  to: Date
  status?: INVOICE_STATUS
  note?: string
}

export type IPostTimesheetParams = IPostTimesheetParamsBase & ITimesheetIncludeParams

export interface IPutTimesheetParamsBase {
  status?: INVOICE_STATUS
  locked?: boolean
  reason?: string
  note?: ITimesheetNote
}

export type IPutTimesheetParams = IPutTimesheetParamsBase & ITimesheetIncludeParams

export interface ITimesheetShift extends IShift {
  applications: { [key: number]: ITimesheetApplication }
  gigDays?: {
    [key: number]: IGigDayExtended
  }
}

export interface ITimesheetApplication extends IApplication {
  punchcards: { [key: number]: ITimesheetIPunchCard }
  bonuses: { [key: number]: IBonus }
}

export interface ITimesheetIPunchCard extends IPunchCard {}
export interface ITimesheetData {
  timesheet: ITimesheet
  shifts: { [key: number]: ITimesheetShift }
  property: IProperty
}

export interface ITimesheetsReducer extends IBaseReducer<ITimesheet[]> {}

export interface ITimesheetReducer extends IBaseReducer<ITimesheetData> {}

export interface IUpdateApplicationBlockPropertiesCountPayload {
  applicantId: number
  blockPropertiesCount: number
}
