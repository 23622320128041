import { IUsersReducer, IUser } from 'models/User'
import { Action } from 'redux'
import { isType } from 'ts-action'
import { updateArray, deleteFromArray } from 'helpers/helperFunctions'
import {
  GetBusinessEntityUsers,
  BusinessEntityUsersFetching,
  UpdateBusinessEntityUser,
  UpdateBusinessEntityUsersActivateSuspendButtonLoadingIds,
  DeleteBusinessEntityUser,
  AddBusinessEntityUser,
} from './actions'

const initialState: IUsersReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: [],
  isFavoriteButtonLoadingIds: [],
  isApproveButtonLoadingIds: [],
  isActivateSuspendButtonLoadingIds: {},
}

export const businessEntityUsersReducer = (state = initialState, action: Action): IUsersReducer => {
  if (isType(action, GetBusinessEntityUsers)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
    }
  }
  if (isType(action, AddBusinessEntityUser)) {
    const { payload } = action
    const { data } = state
    const newArray = [payload.data, ...data]
    return {
      ...state,
      data: newArray,
    }
  }
  if (isType(action, UpdateBusinessEntityUser)) {
    const { payload } = action
    const { data } = state
    const formattedArray = updateArray<IUser>(data, payload.data)
    return {
      ...state,
      data: formattedArray,
    }
  }
  if (isType(action, UpdateBusinessEntityUsersActivateSuspendButtonLoadingIds)) {
    const { payload: id } = action
    const newActivateSuspendButtonLoadingIds = { ...state.isActivateSuspendButtonLoadingIds }
    if (newActivateSuspendButtonLoadingIds[id]) {
      delete newActivateSuspendButtonLoadingIds[id]
    } else {
      newActivateSuspendButtonLoadingIds[id] = true
    }
    return {
      ...state,
      isActivateSuspendButtonLoadingIds: newActivateSuspendButtonLoadingIds,
    }
  }
  if (isType(action, DeleteBusinessEntityUser)) {
    const { payload } = action
    const { data } = state
    const formattedArray = deleteFromArray<IUser>(data, payload)
    return {
      ...state,
      data: formattedArray,
    }
  }

  if (isType(action, BusinessEntityUsersFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }
  return state
}
