import { useCallback, useEffect } from 'react'
import { useSidebarUpdateContext } from '../App'

const COLLAPSE_EXPAND_HOTKEY = '\\'

/**
 * Collapse/Expand the sidebar menu upon clicking a hotkey
 */
export const useMenuHotkey = (isCollapsible = true) => {
  const { forceUpdate } = useSidebarUpdateContext()

  const keydownHandler = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === COLLAPSE_EXPAND_HOTKEY) {
        const menuState = localStorage.getItem('menuState')

        localStorage.setItem(
          'menuState',
          menuState && menuState === 'collapsed' ? 'expanded' : 'collapsed'
        )

        forceUpdate()
      }
    },
    [forceUpdate]
  )

  const addListener = useCallback(
    () => document.addEventListener('keydown', keydownHandler),
    [keydownHandler]
  )

  const removeListener = useCallback(
    () => document.removeEventListener('keydown', keydownHandler),
    [keydownHandler]
  )

  useEffect(() => {
    if (isCollapsible) addListener()

    return isCollapsible ? removeListener : () => {}
  }, [addListener, removeListener, isCollapsible])
}
