import { action, payload } from 'ts-action'
import { IShift } from 'models/Shift'
import { IError, IPayload } from 'models/Global'

export const BulkEditShiftsFetching = action('BulkEditShiftsFetching', payload<boolean>())

export const GetBulkEditShifts = action('GetBulkEditShifts', payload<IPayload<IShift[]>>())

export const AddBulkEditShift = action('AddBulkEditShift', payload<IPayload<IShift>>())

export const UpdateBulkEditShift = action('UpdateBulkEditShift', payload<IPayload<IShift>>())

export const DeleteBulkEditShift = action('DeleteBulkEditShift', payload<number>())

export const GetBulkEditShiftsErrors = action('GetBulkEditShiftsErrors', payload<IError>())

export const ResetBulkEditShifts = action('ResetBulkEditShifts')
