import { Action } from 'redux'
import { isType } from 'ts-action'
import {
  GetUnassignedCertificates,
  UnassignedCertificatesFetching,
  GetUnassignedCertificatesErrors,
  ClearUnassignedCertificates,
  AssignButtonLoading,
  AssignButtonStopLoading,
  RemoveUnassignedCertificate,
  AddUnassignedCertificate,
} from './actions'
import { IBusinessEntityUnassignedCertificatesReducer } from 'models/BusinessEntity'
import { deleteFromArray } from 'helpers/helperFunctions'
import { ICertificate } from 'models/Certificate'

const initialState: IBusinessEntityUnassignedCertificatesReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: [],
  assignButtonLoadingIds: [],
}

export const businessEntityUnassignedCertificatesReducer = (
  state = initialState,
  action: Action
): IBusinessEntityUnassignedCertificatesReducer => {
  if (isType(action, GetUnassignedCertificates)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: [...state.data, ...payload.data],
    }
  }
  if (isType(action, UnassignedCertificatesFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }
  if (isType(action, GetUnassignedCertificatesErrors)) {
    const { payload } = action
    return {
      ...state,
      error: payload,
    }
  }
  if (isType(action, AssignButtonLoading)) {
    const { payload } = action
    return {
      ...state,
      assignButtonLoadingIds: [...state.assignButtonLoadingIds, payload],
    }
  }
  if (isType(action, AssignButtonStopLoading)) {
    const { payload } = action
    const filteredAssignButtonLoadingIds = state.assignButtonLoadingIds.filter(
      (id) => id !== payload
    )
    return {
      ...state,
      assignButtonLoadingIds: filteredAssignButtonLoadingIds,
    }
  }
  if (isType(action, RemoveUnassignedCertificate)) {
    const { payload } = action
    const filteredData = deleteFromArray<ICertificate>(state.data, payload)
    return {
      ...state,
      data: filteredData,
    }
  }
  if (isType(action, AddUnassignedCertificate)) {
    const { payload } = action
    return {
      ...state,
      data: [payload, ...state.data],
    }
  }
  if (isType(action, ClearUnassignedCertificates)) {
    return initialState
  }
  return state
}
