import { AxiosService } from '../config/config'
import { Endpoints } from '../config/endpoints'
import { IPayload } from 'models/Global'
import {
  ICreditCategoryType,
  IGetCreditCategoryTypesParams,
  IPutCreditCategoryTypeParams,
  IPostCreditCategoryTypeParams,
} from 'models/CreditCategoryType'

export class CreditCategoryTypesApi {
  constructor(private axios: AxiosService) {}

  getCreditCategoryTypes = async (params?: IGetCreditCategoryTypesParams) => {
    const response = await this.axios.get<IPayload<ICreditCategoryType[]>>(
      Endpoints.getCreditCategoryTypes,
      params
    )
    return response
  }

  getCreditCategoryType = async (id: number, params?: IGetCreditCategoryTypesParams) => {
    const response = await this.axios.get<IPayload<ICreditCategoryType>>(
      Endpoints.getCreditCategoryType(id),
      params
    )
    return response
  }

  postCreditCategoryType = async (params: IPostCreditCategoryTypeParams) => {
    const response = await this.axios.post<IPayload<ICreditCategoryType>>(
      Endpoints.postCreditCategoryType,
      params
    )
    return response
  }

  putCreditCategoryType = async (id: number, params?: IPutCreditCategoryTypeParams) => {
    const response = await this.axios.put<IPayload<ICreditCategoryType>>(
      Endpoints.putCreditCategoryType(id),
      params
    )
    return response
  }

  deleteCreditCategoryType = async (id: number) => {
    const response = await this.axios.delete<{}>(Endpoints.deleteCreditCategoryType(id))
    return response
  }
}
