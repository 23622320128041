import { action, payload } from 'ts-action'
import { IReview } from 'models/Review'
import { IError, IPayload } from 'models/Global'

export const ReviewsFetching = action('ReviewsFetching', payload<boolean>())

export const GetReviews = action('GetReviews', payload<IPayload<IReview[]>>())

export const AddReview = action('AddReview', payload<IPayload<IReview>>())

export const UpdateReview = action('UpdateReview', payload<IPayload<IReview>>())

export const DeleteReview = action('DeleteReview', payload<number>())

export const GetReviewsErrors = action('GetReviewsErrors', payload<IError>())
