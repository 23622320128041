import { AxiosService } from '../config/config'
import { Endpoints } from '../config/endpoints'
import { IPayload } from 'models/Global'
import {
  IGroupApplication,
  IPostGroupApplicationAcceptDeclineParams,
} from 'models/GroupApplication'

export class GroupApplicationsApi {
  constructor(private axios: AxiosService) {}

  postGroupApplicationAccept = async (params: IPostGroupApplicationAcceptDeclineParams) => {
    const response = await this.axios.post<IPayload<IGroupApplication>>(
      Endpoints.postGroupApplicationAccept,
      params
    )
    return response
  }

  postGroupApplicationDecline = async (params: IPostGroupApplicationAcceptDeclineParams) => {
    const response = await this.axios.post<IPayload<IGroupApplication>>(
      Endpoints.postGroupApplicationDecline,
      params
    )
    return response
  }
}
