import { action, payload } from 'ts-action'
import { IInviteApplicant } from 'models/InviteApplicants'
import { IError, IPayload } from 'models/Global'

export const InviteApplicantsFetching = action('InviteApplicantsFetching', payload<boolean>())

export const GetInviteApplicants = action(
  'GetInviteApplicants',
  payload<IPayload<IInviteApplicant[]>>()
)

export const AddInviteApplicants = action(
  'AddInviteApplicants',
  payload<IPayload<IInviteApplicant[]>>()
)

export const GetInviteApplicantsErrors = action('GetInviteApplicantsErrors', payload<IError>())

export const ClearInviteApplicants = action('ClearInviteApplicants')
