import { useSelector } from 'react-redux'
import { selectSelectedShiftGigDays } from 'data/SelectedShiftGigDays/selectors'
import { DATE_FORMAT_STRING } from 'helpers/constants/constants'
import { breakDropdownData } from 'helpers/shiftHelpers'
import { IShift, SHIFT_TYPE } from 'models/Shift'
import { SpaceBetweenFlexWrapper } from 'ui/components/common/style'
import { AddExtraPunchCardShiftDetailsWrapper } from './style'
import { format } from 'date-fns'

type TAddExtraPunchCardShiftDetailsProps = {
  shift: IShift
  upshifterName: string
  selectedDate: Date | undefined
}

const AddExtraPunchCardShiftDetails = ({
  shift,
  upshifterName,
  selectedDate,
}: TAddExtraPunchCardShiftDetailsProps) => {
  const shiftDays = useSelector(selectSelectedShiftGigDays)
  const selectedShiftDate = format(
    selectedDate ? selectedDate : new Date(),
    DATE_FORMAT_STRING.MM_dd_yyyy
  )
  const selectedShiftDay = shiftDays.find((shiftDay) => shiftDay.date_start === selectedShiftDate)
  const isSingleDay = Number(shift.type) === SHIFT_TYPE.SINGLEDAY

  return (
    <AddExtraPunchCardShiftDetailsWrapper>
      <SpaceBetweenFlexWrapper>
        <b>Upshifter:</b>
        <div>{upshifterName}</div>
      </SpaceBetweenFlexWrapper>
      <SpaceBetweenFlexWrapper>
        <b>Shift ID:</b>
        <div>#{shift.id}</div>
      </SpaceBetweenFlexWrapper>
      <SpaceBetweenFlexWrapper>
        <b>Shift Date Range:</b>
        <div>
          {shift.time_start} - {shift.time_end}
        </div>
      </SpaceBetweenFlexWrapper>
      <SpaceBetweenFlexWrapper>
        <b>{isSingleDay ? '' : 'Day'} Shift Time:</b>
        <div>
          {isSingleDay
            ? `${shift.hour_start} - ${shift.hour_end}`
            : `${selectedShiftDay?.hour_start} - ${selectedShiftDay?.hour_end}`}
        </div>
      </SpaceBetweenFlexWrapper>
      <SpaceBetweenFlexWrapper>
        <b>{isSingleDay ? 'Shift' : 'Day'} Pay Value:</b>
        <div>{isSingleDay ? `$${shift.pay_value}` : `$${selectedShiftDay?.pay_value}`}</div>
      </SpaceBetweenFlexWrapper>
      <SpaceBetweenFlexWrapper>
        <b>{isSingleDay ? 'Shift' : 'Day'} Break:</b>
        <div>
          {isSingleDay
            ? breakDropdownData.find((option) => option.value === shift.break)?.label
            : selectedShiftDay?.break}
        </div>
      </SpaceBetweenFlexWrapper>
      <SpaceBetweenFlexWrapper>
        <b>Shift Type:</b>
        <div>{isSingleDay ? 'Single' : 'Multiday'}</div>
      </SpaceBetweenFlexWrapper>
    </AddExtraPunchCardShiftDetailsWrapper>
  )
}

export default AddExtraPunchCardShiftDetails
