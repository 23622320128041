import { action, payload } from 'ts-action'
import { IExpenseType } from 'models/ExpenseType'
import { IError, IPayload } from 'models/Global'

export const ExpenseTypesFetching = action('ExpenseTypesFetching', payload<boolean>())

export const GetExpenseTypes = action('GetExpenseTypes', payload<IPayload<IExpenseType[]>>())

export const GetExpenseType = action('GetExpenseType', payload<IPayload<IExpenseType>>())

export const AddExpenseType = action('AddExpenseType', payload<IPayload<IExpenseType>>())

export const UpdateExpenseType = action('UpdateExpenseType', payload<IPayload<IExpenseType>>())

export const DeleteExpenseType = action('DeleteExpenseType', payload<number>())

export const GetExpenseTypesErrors = action('GetExpenseTypesErrors', payload<IError>())

export const ResetExpenseType = action('ResetExpenseType')
