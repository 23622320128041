// Note: Not sure if this is the correct place for this enum
export enum SHIFT_FOLLOW_TYPE_MODEL {
  SHIFT = 'id',
  LOCATION = 'property_id',
  BUSINESS = 'business_id',
}

export interface ILoading {
  id: number
  isLoading: boolean
}

export interface ILoadingIds {
  [key: number]: boolean
}

export type TShiftFollowLoadingIds = {
  [key in SHIFT_FOLLOW_TYPE_MODEL]: number
}
export interface ILoadingsReducer {
  approvePendingVerificationLoadingIds: ILoadingIds
  shiftFollowLoadingIds: TShiftFollowLoadingIds
  favoriteLoadingIds: ILoadingIds
  pendingSuspensionLoadingIds: ILoadingIds
}
