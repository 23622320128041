import { AxiosService } from '../config/config'
import { Endpoints } from '../config/endpoints'
import { IPayload } from '../../models/Global'
import { IGetWebSocketDataParams } from 'models/WebsocketData'

export class WebSocketDataApi {
  constructor(private axios: AxiosService) {}

  getWebSocketData = async (params?: IGetWebSocketDataParams) => {
    const response = await this.axios.get<IPayload<any>>(Endpoints.getWebSocketData, params)
    return response
  }
}
