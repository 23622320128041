import { AxiosService } from '../config/config'
import { Endpoints } from '../config/endpoints'
import { IPayload } from 'models/Global'
import {
  IPropertyCategory,
  IGetPropertyCategoriesParams,
  IPutPropertyCategoryParams,
  IPostPropertyCategoryParams,
} from 'models/PropertyCategory'

export class PropertyCategoriesApi {
  constructor(private axios: AxiosService) {}

  getPropertyCategories = async (params?: IGetPropertyCategoriesParams) => {
    const response = await this.axios.get<IPayload<IPropertyCategory[]>>(
      Endpoints.getPropertyCategories,
      params
    )
    return response
  }

  getPropertyCategory = async (id: number, params?: IGetPropertyCategoriesParams) => {
    const response = await this.axios.get<IPayload<IPropertyCategory>>(
      Endpoints.getPropertyCategory(id),
      params
    )
    return response
  }

  postPropertyCategory = async (params: IPostPropertyCategoryParams) => {
    const response = await this.axios.post<IPayload<IPropertyCategory>>(
      Endpoints.postPropertyCategory,
      params
    )
    return response
  }

  putPropertyCategory = async (id: number, params?: IPutPropertyCategoryParams) => {
    const response = await this.axios.put<IPayload<IPropertyCategory>>(
      Endpoints.putPropertyCategory(id),
      params
    )
    return response
  }

  deletePropertyCategory = async (id: number) => {
    const response = await this.axios.delete<{}>(Endpoints.deletePropertyCategory(id))
    return response
  }
}
