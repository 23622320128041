import styled from 'styled-components'

export const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  background-color: ${(props) => `${props.theme.palette.white}`};
  z-index: 2;
`
