import { action, payload } from 'ts-action'
import { ITemplate } from 'models/Template'
import { IError, IPayload } from 'models/Global'

export const TemplatesFetching = action('TemplatesFetching', payload<boolean>())

export const GetTemplates = action('GetTemplates', payload<IPayload<ITemplate[]>>())

export const AddTemplate = action('AddTemplate', payload<IPayload<ITemplate>>())

export const UpdateTemplate = action('UpdateTemplate', payload<IPayload<ITemplate>>())

export const DeleteTemplate = action('DeleteTemplate', payload<number>())

export const GetTemplatesErrors = action('GetTemplatesErrors', payload<IError>())
