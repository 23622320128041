import { action, payload } from 'ts-action'
import { IError, IPayload } from '../../models/Global'
import { IGroup } from '../../models/Group'

export const GetGroup = action('GetGroup', payload<IPayload<IGroup>>())

export const GetGroupErrors = action('GetGroupErrors', payload<IError>())

export const GroupFetching = action('GroupFetching', payload<boolean>())

export const ResetGroup = action('ResetGroup')

export const DeleteGroupMember = action('DeleteGroupMember', payload<number>())

export const UpdateGroup = action('UpdateGroup', payload<IPayload<IGroup>>())
