import { AxiosService } from '../config/config'
import { Endpoints } from '../config/endpoints'
import { IPayload } from 'models/Global'
import {
  IGetPropertyParams,
  IGetPropertiesParams,
  IPutPropertyParams,
  IProperty,
  IPostPropertyParams,
  IGetPropertyAddressInfo,
  IAddressInfo,
} from 'models/Property'

export class PropertiesApi {
  constructor(private axios: AxiosService) {}

  getProperties = async (params?: IGetPropertiesParams) => {
    const response = await this.axios.get<IPayload<IProperty[]>>(Endpoints.getProperties, params)
    return response
  }

  getProperty = async (id: number, params?: IGetPropertyParams) => {
    const response = await this.axios.get<IPayload<IProperty>>(Endpoints.getProperty(id), params)
    return response
  }

  getPropertyAddressInfo = async (params: IGetPropertyAddressInfo) => {
    const response = await this.axios.get<IPayload<IAddressInfo>>(
      Endpoints.getPropertyAddressInfo,
      params
    )
    return response
  }

  postProperty = async (params: IPostPropertyParams | FormData) => {
    const response = await this.axios.post<IPayload<IProperty>>(Endpoints.postProperty, params)
    return response
  }

  putProperty = async (id: number, params: IPutPropertyParams) => {
    const response = await this.axios.put<IPayload<IProperty>>(Endpoints.putProperty(id), params)
    return response
  }

  putPropertyWithAttachment = async (id: number, params: FormData) => {
    const response = await this.axios.post<IPayload<IProperty>>(Endpoints.putProperty(id), params)
    return response
  }

  deleteProperty = async (id: number) => {
    const response = await this.axios.delete<{}>(Endpoints.deleteProperty(id))
    return response
  }
}
