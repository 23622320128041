import { action, payload } from 'ts-action'
import { IError, IPayload } from 'models/Global'
import { IAutoAcceptCertificate } from 'models/AutoAcceptCertificate'

export const GetAssignedCertificates = action(
  'GetAssignedCertificates',
  payload<IPayload<IAutoAcceptCertificate[]>>()
)

export const ClearAssignedCertificates = action('ClearAssignedCertificates')

export const AssignedCertificatesFetching = action(
  'AssignedCertificatesFetching',
  payload<boolean>()
)

export const GetAssignedCertificatesErrors = action(
  'GetAssignedCertificatesErrors',
  payload<IError>()
)

export const RemoveUnassignedCertificate = action('RemoveUnassignedCertificate', payload<number>())
export const AddAssignedCertificate = action(
  'AddAssignedCertificate',
  payload<IAutoAcceptCertificate>()
)

export const UnassignButtonLoading = action('UnassignButtonLoading', payload<number>())
export const UnassignButtonStopLoading = action('UnassignButtonStopLoading', payload<number>())
