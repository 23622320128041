export const businessEntityRouteMappings = {
  '/business-entity/create': '/businesses/create',
  '/business-entity/:id/areas': '/businesses/:id/areas',
  '/business-entity/:id/business': '/businesses/:id/business',
  '/business-entity/:id/documents/:documentId/acknowledged':
    '/businesses/:id/documents/:documentId/acknowledged',
  '/business-entity/:id/documents': '/businesses/:id/documents',
  '/business-entity/:id/locations': '/businesses/:id/locations',
  '/business-entity/:id/users': '/businesses/:id/users',
  '/business-entity/:id/invoices': '/businesses/:id/invoices',
  '/business-entity/:id/my-upshifters': '/businesses/:id/my-upshifters',
  '/business-entity/:id/templates': '/businesses/:id/templates',
  '/business-entity/:id/templates/add': '/businesses/:id/templates/add',
  '/business-entity/:id/templates/:templateId': '/businesses/:id/templates/:templateId',
  '/business-entity/:id/shifts': '/businesses/:id/shifts',
  '/business-entity/:id/notes': '/businesses/:id/notes',
  '/business-entity/:id/edit': '/businesses/:id/edit',
  '/business-entity/:id/selective-claim': '/businesses/:id/selective-claim',
  '/business-entity/:id/children_companies': '/businesses/:id/children_companies',
  '/business-entity/:id/acknowledgements': '/businesses/:id/acknowledgements',
  '/business-entity/:id/invoice-groups': '/businesses/:id/invoice-groups',
  '/business-entity/:id/finance/invoice_groups/add': '/businesses/:id/finance/invoice_groups/add',
  '/business-entity/:id/finance/invoice_groups/:invoice_group_id':
    '/businesses/:id/finance/invoice_groups/:invoice_group_id',
  '/business-entity/:id/finance': '/businesses/:id/finance',
  '/business-entity/:id/reviews': '/businesses/:id/reviews',
  '/business-entity/:id/attachments': '/businesses/:id/attachments',
  '/business-entity/:id/timesheets': '/businesses/:id/timesheets',
  '/business-entity/:id': '/businesses/:id',
  '/business-entity/:id/settings': '/businesses/:id/settings',
}
