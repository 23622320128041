import { useState, useCallback, useEffect } from 'react'
import { Text, Flex } from '@pinchenterprisesnpm/friday-ui'
import { faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { POWER_SEARCH_RESOURCE_TYPE, TPowerSearchData } from '../PowerSearch/utils'
import { PowerSearchFilteredListItem } from './PowerSearchFilteredListItem'
import { DEFAULT_HOVER_VALUE, redirectOnClick } from './utils'
import { NoResultsWrapper } from './style'

type TPowerSearchFilteredListProps = {
  resourceType: POWER_SEARCH_RESOURCE_TYPE
  data?: TPowerSearchData[]
}

export const PowerSearchFilteredList = ({ data, resourceType }: TPowerSearchFilteredListProps) => {
  const [hoveredItemIndex, setHoveredItemIndex] = useState<number>(DEFAULT_HOVER_VALUE)

  const handleMouseOver = (index: number) => {
    setHoveredItemIndex(index)
  }

  const handleMouseOut = () => {
    setHoveredItemIndex(DEFAULT_HOVER_VALUE)
  }

  const handleKeyDown = useCallback(
    (event: any) => {
      const hoveredIndex = hoveredItemIndex === null ? 0 : hoveredItemIndex
      if (data && data.length) {
        if (event.key === 'ArrowUp') {
          setHoveredItemIndex((hoveredIndex - 1 + data.length) % data.length)
        }
        if (event.key === 'ArrowDown') {
          setHoveredItemIndex((hoveredIndex + 1 + data.length) % data.length)
        }
        if (event.key === 'Enter') {
          redirectOnClick(resourceType, data[hoveredIndex].id)
        }
      }
    },
    [hoveredItemIndex, resourceType, data]
  )

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [handleKeyDown])

  return (
    <Flex flexDirection='column'>
      {data && data.length ? (
        <>
          {data?.map((item, index) => {
            return (
              <PowerSearchFilteredListItem
                item={item}
                resourceType={resourceType}
                key={item.id}
                onMouseOver={() => handleMouseOver(index)}
                onMouseOut={handleMouseOut}
                onClick={() => redirectOnClick(resourceType, item.id)}
                isActive={hoveredItemIndex === index}
                onKeyDown={handleKeyDown}
              />
            )
          })}
        </>
      ) : (
        <NoResultsWrapper alignItems='center'>
          <FontAwesomeIcon size='lg' icon={faMagnifyingGlass} />
          <Text ml={15}>No results matched your search.</Text>
        </NoResultsWrapper>
      )}
    </Flex>
  )
}
