import { AxiosService } from '../config/config'
import { Endpoints } from '../config/endpoints'
import { IPayload } from '../../models/Global'
import {
  IChallenge,
  IGetChallengesParams,
  IPutChallengeParams,
  IPostChallengeParams,
} from '../../models/Challenge'

export class ChallengesApi {
  constructor(private axios: AxiosService) {}

  getChallenges = async (params?: IGetChallengesParams) => {
    const response = await this.axios.get<IPayload<IChallenge[]>>(Endpoints.getChallenges, params)
    return response
  }

  getChallenge = async (id: number, params?: IGetChallengesParams) => {
    const response = await this.axios.get<IPayload<IChallenge>>(Endpoints.getChallenge(id), params)
    return response
  }

  postChallenge = async (params: IPostChallengeParams) => {
    const response = await this.axios.post<IPayload<IChallenge>>(Endpoints.postChallenge, params)
    return response
  }

  putChallenge = async (id: number, params?: IPutChallengeParams) => {
    const response = await this.axios.put<IPayload<IChallenge>>(Endpoints.putChallenge(id), params)
    return response
  }

  deleteChallenge = async (id: number) => {
    const response = await this.axios.delete<{}>(Endpoints.deleteChallenge(id))
    return response
  }
}
