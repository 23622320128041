import { action, payload } from 'ts-action'
import { IError, IPayload } from 'models/Global'
import { IFeaturedShiftCampaign } from 'models/FeaturedShiftCampaign'

export const GetFeaturedShiftCampaigns = action(
  'GetFeaturedShiftCampaigns',
  payload<IPayload<IFeaturedShiftCampaign[]>>()
)

export const AddFeaturedShiftCampaign = action(
  'AddFeaturedShiftCampaign',
  payload<IPayload<IFeaturedShiftCampaign>>()
)

export const DeleteFeaturedShiftCampaign = action('DeleteFeaturedShiftCampaign', payload<number>())

export const UpdateFeaturedShiftCampaign = action(
  'UpdateFeaturedShiftCampaign',
  payload<IPayload<IFeaturedShiftCampaign>>()
)

export const GetFeaturedShiftCampaignsErrors = action(
  'GetFeaturedShiftCampaignsErrors',
  payload<IError>()
)

export const FeaturedShiftCampaignsFetching = action(
  'FeaturedShiftCampaignsFetching',
  payload<boolean>()
)
