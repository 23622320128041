import { action, payload } from 'ts-action'
import { IAchievement } from '../../models/Achievement'
import { IError, IPayload } from '../../models/Global'

export const AchievementsFetching = action('AchievementsFetching', payload<boolean>())

export const GetAchievements = action('GetAchievements', payload<IPayload<IAchievement[]>>())

export const AddAchievement = action('AddAchievement', payload<IPayload<IAchievement>>())

export const UpdateAchievement = action('UpdateAchievement', payload<IPayload<IAchievement>>())

export const DeleteAchievement = action('DeleteAchievement', payload<number>())

export const GetAchievementsErrors = action('GetAchievementsErrors', payload<IError>())

export const UpdateAchievementAttachment = action(
  'UpdateAchievementAttachment',
  payload<IPayload<IAchievement>>()
)
