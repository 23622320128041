import { AxiosService } from '../config/config'
import { Endpoints } from '../config/endpoints'
import { IPayload } from 'models/Global'
import {
  IAccruedSickHour,
  IGetAccruedSickHoursParams,
  IPostSickHourForUserParams,
  IPostAccruedSickHourParams,
  IPutAccruedSickHourParams,
} from 'models/AccruedSickHour'

export class AccruedSickHoursApi {
  constructor(private axios: AxiosService) {}

  getAccruedSickHours = async (params?: IGetAccruedSickHoursParams) => {
    const response = await this.axios.get<IPayload<IAccruedSickHour[]>>(
      Endpoints.getAccruedSickHours,
      params
    )
    return response
  }

  getAccruedSickHour = async (id: number) => {
    const response = await this.axios.get<IPayload<IAccruedSickHour>>(
      Endpoints.getAccruedSickHour(id)
    )
    return response
  }

  postAccruedSickHour = async (params: IPostAccruedSickHourParams) => {
    const response = await this.axios.post<IPayload<IAccruedSickHour>>(
      Endpoints.postAccruedSickHour,
      params
    )
    return response
  }

  putAccruedSickHour = async (id: number, params: IPutAccruedSickHourParams) => {
    const response = await this.axios.put<IPayload<IAccruedSickHour>>(
      Endpoints.putAccruedSickHour(id),
      params
    )
    return response
  }

  deleteAccruedSickHour = async (id: number) => {
    const response = await this.axios.delete<{}>(Endpoints.deleteAccruedSickHour(id))
    return response
  }

  updateAccruedHoursForUser = async (params: IPostSickHourForUserParams) => {
    const response = await this.axios.post<IPayload<IAccruedSickHour>>(
      Endpoints.updateSickHoursForUser,
      params
    )
    return response
  }
}
