import { IPropertyCategoryReducer, IPropertyCategory } from 'models/PropertyCategory'
import { Action } from 'redux'
import { isType } from 'ts-action'
import { updateArray, deleteFromArray } from 'helpers/helperFunctions'
import {
  GetPropertyCategories,
  PropertyCategoriesFetching,
  UpdatePropertyCategory,
  DeletePropertyCategory,
  AddPropertyCategory,
  UpdatePropertyCategoryLoadingIds,
} from './actions'

const initialState: IPropertyCategoryReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: [],
  propertyCategoriesLoadingIds: {},
}

export const PropertyCategoriesReducer = (
  state = initialState,
  action: Action
): IPropertyCategoryReducer => {
  if (isType(action, GetPropertyCategories)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
    }
  }
  if (isType(action, UpdatePropertyCategory)) {
    const { payload } = action
    const { data } = state
    const formattedArray = updateArray<IPropertyCategory>(data, payload.data)
    return {
      ...state,
      data: formattedArray,
    }
  }
  if (isType(action, DeletePropertyCategory)) {
    const { payload } = action
    const { data } = state
    const formattedArray = deleteFromArray<IPropertyCategory>(data, payload)
    return {
      ...state,
      data: formattedArray,
    }
  }

  if (isType(action, AddPropertyCategory)) {
    const { payload } = action
    return {
      ...state,
      data: [payload.data, ...state.data],
    }
  }
  if (isType(action, UpdatePropertyCategoryLoadingIds)) {
    const { payload: id } = action
    const newPropertyCategoryLoadingIds = { ...state.propertyCategoriesLoadingIds }
    if (newPropertyCategoryLoadingIds[id]) {
      delete newPropertyCategoryLoadingIds[id]
    } else {
      newPropertyCategoryLoadingIds[id] = true
    }
    return {
      ...state,
      propertyCategoriesLoadingIds: newPropertyCategoryLoadingIds,
    }
  }
  if (isType(action, PropertyCategoriesFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }
  return state
}
