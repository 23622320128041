import { NotificationManager } from 'helpers/NotificationsManager'
import { Error as ApplicationError, STATUS_CODES } from 'network/config/error'

export const handleErrorNotification = (error: ApplicationError) => {
  if (error && error.code === STATUS_CODES.UNPROCESSABLE_ENTITY) {
    if (typeof error.validationErrors === 'string' && error.validationErrors.length > 1) {
      NotificationManager.error(error.validationErrors, error.message)
    } else {
      NotificationManager.error(error.message, 'A problem occurred. Please try again.')
    }
  }
  if (error && error.code !== STATUS_CODES.UNPROCESSABLE_ENTITY) {
    NotificationManager.error(error.message, 'Oops, something happened!')
  }
}
