import { AxiosService } from '../config/config'
import { Endpoints } from '../config/endpoints'
import { IPayload } from 'models/Global'
import {
  IBusinessEntity,
  IGetBusinessEntitiesParams,
  IPutBusinessEntityParams,
  IPostBusinessEntityParams,
  IGetBusinessEntityParams,
  IPutBusinessEntitySalesReactivationParams,
} from 'models/BusinessEntity'

export class BusinessEntitiesApi {
  constructor(private axios: AxiosService) {}

  getBusinessEntities = async (params?: IGetBusinessEntitiesParams) => {
    const response = await this.axios.get<IPayload<IBusinessEntity[]>>(
      Endpoints.getBusinessEntities,
      params
    )
    return response
  }

  getBusinessEntity = async (id: number, params?: IGetBusinessEntityParams) => {
    const response = await this.axios.get<IPayload<IBusinessEntity>>(
      Endpoints.getBusinessEntity(id),
      params
    )
    return response
  }

  postBusinessEntity = async (params: IPostBusinessEntityParams) => {
    const response = await this.axios.post<IPayload<IBusinessEntity>>(
      Endpoints.postBusinessEntity,
      params
    )
    return response
  }

  putBusinessEntity = async (id: number, params: IPutBusinessEntityParams) => {
    const response = await this.axios.put<IPayload<IBusinessEntity>>(
      Endpoints.updateBusinessEntity(id),
      params
    )
    return response
  }

  putBusinessEntitySalesReactivation = async (
    id: number,
    params: IPutBusinessEntitySalesReactivationParams
  ) => {
    const response = await this.axios.put<IPayload<IBusinessEntity>>(
      Endpoints.putBusinessEntitySalesReactivation(id),
      params
    )
    return response
  }

  updateBusinessEntity = async (id: number, params: IPutBusinessEntityParams) => {
    const response = await this.axios.post<IPayload<IBusinessEntity>>(
      Endpoints.updateBusinessEntity(id),
      params
    )
    return response
  }

  deleteBusinessEntity = async (id: number) => {
    const response = await this.axios.delete<{}>(Endpoints.deleteBusinessEntity(id))
    return response
  }
}
