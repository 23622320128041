import { AxiosService } from '../config/config'
import { Endpoints } from '../config/endpoints'
import { IPayload } from 'models/Global'
import {
  IScreeningType,
  IGetScreeningTypesParams,
  IPutScreeningTypeParams,
  IPostScreeningTypeParams,
} from 'models/ScreeningType'

export class ScreeningTypesApi {
  constructor(private axios: AxiosService) {}

  getScreeningTypes = async (params?: IGetScreeningTypesParams) => {
    const response = await this.axios.get<IPayload<IScreeningType[]>>(
      Endpoints.getScreeningTypes,
      params
    )
    return response
  }

  getScreeningType = async (id: number, params?: IGetScreeningTypesParams) => {
    const response = await this.axios.get<IPayload<IScreeningType>>(
      Endpoints.getScreeningType(id),
      params
    )
    return response
  }

  postScreeningType = async (params: IPostScreeningTypeParams) => {
    const response = await this.axios.post<IPayload<IScreeningType>>(
      Endpoints.postScreeningType,
      params
    )
    return response
  }

  putScreeningType = async (id: number, params?: IPutScreeningTypeParams) => {
    const response = await this.axios.put<IPayload<IScreeningType>>(
      Endpoints.putScreeningType(id),
      params
    )
    return response
  }

  deleteScreeningType = async (id: number) => {
    const response = await this.axios.delete<{}>(Endpoints.deleteScreeningType(id))
    return response
  }
}
