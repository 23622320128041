import { action, payload } from 'ts-action'
import { IUserSickPayDayHourStats } from 'models/User'
import { IError, IPayload } from 'models/Global'

export const GetUserSickHourStats = action(
  'GetUserSickHourStats',
  payload<IPayload<IUserSickPayDayHourStats>>()
)
export const GetUserSickHourStatsErrors = action('GetUserSickHourStatsErrors', payload<IError>())
export const UserSickHourStatsFetching = action('UserSickHourStatsFetching', payload<boolean>())
export const ClearUserSickHourStats = action('ClearUserSickHourStats')
