import { AxiosService } from '../config/config'
import { Endpoints } from '../config/endpoints'
import { IPayload } from 'models/Global'
import {
  IGetFeaturedShiftCampaignsParams,
  IFeaturedShiftCampaign,
  IPostFeaturedShiftCampaignParams,
  IPutFeaturedShiftCampaignParams,
} from 'models/FeaturedShiftCampaign'

export class FeaturedShiftCampaignsApi {
  constructor(private axios: AxiosService) {}

  getFeaturedShiftCampaigns = async (params?: IGetFeaturedShiftCampaignsParams) => {
    const response = await this.axios.get<IPayload<IFeaturedShiftCampaign[]>>(
      Endpoints.getFeaturedShiftCampaigns,
      params
    )
    return response
  }

  getFeaturedShiftCampaign = async (id: number, params?: IGetFeaturedShiftCampaignsParams) => {
    const response = await this.axios.get<IPayload<IFeaturedShiftCampaign>>(
      Endpoints.getFeaturedShiftCampaign(id),
      params
    )
    return response
  }

  postFeaturedShiftCampaign = async (params: IPostFeaturedShiftCampaignParams) => {
    const response = await this.axios.post<IPayload<IFeaturedShiftCampaign>>(
      Endpoints.postFeaturedShiftCampaign,
      params
    )
    return response
  }

  putFeaturedShiftCampaign = async (id: number, params: IPutFeaturedShiftCampaignParams) => {
    const response = await this.axios.put<IPayload<IFeaturedShiftCampaign>>(
      Endpoints.putFeaturedShiftCampaign(id),
      params
    )
    return response
  }

  deleteFeaturedShiftCampaign = async (id: number) => {
    const response = await this.axios.delete<{}>(Endpoints.deleteFeaturedShiftCampaign(id), {})
    return response
  }
}
