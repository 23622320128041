import { action, payload } from 'ts-action'
import { INetOption } from 'models/NetOption'
import { IError, IPayload } from 'models/Global'

export const NetOptionsFetching = action('NetOptionsFetching', payload<boolean>())

export const GetNetOptions = action('GetNetOptions', payload<IPayload<INetOption[]>>())

export const AddNetOption = action('AddNetOption', payload<IPayload<INetOption>>())

export const UpdateNetOption = action('UpdateNetOption', payload<IPayload<INetOption>>())

export const DeleteNetOption = action('DeleteNetOption', payload<number>())

export const GetNetOptionsErrors = action('GetNetOptionsErrors', payload<IError>())
