import { IInvoiceGroupPropertiesReducer, IProperty } from 'models/Property'
import { Action } from 'redux'
import { isType } from 'ts-action'
import { updateArray, deleteFromArray } from 'helpers/helperFunctions'
import {
  GetInvoiceGroupProperties,
  UpdateInvoiceGroupProperty,
  DeleteInvoiceGroupProperty,
  InvoiceGroupPropertiesFetching,
  AddInvoiceGroupProperty,
} from './actions'

const initialState: IInvoiceGroupPropertiesReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: [],
}

export const invoiceGroupPropertiesReducer = (
  state = initialState,
  action: Action
): IInvoiceGroupPropertiesReducer => {
  if (isType(action, GetInvoiceGroupProperties)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
    }
  }
  if (isType(action, AddInvoiceGroupProperty)) {
    const { payload } = action
    return {
      ...state,
      data: [payload.data, ...state.data],
    }
  }
  if (isType(action, UpdateInvoiceGroupProperty)) {
    const { payload } = action
    const { data } = state
    const formattedArray = updateArray<IProperty>(data, payload.data)
    return {
      ...state,
      data: formattedArray,
    }
  }
  if (isType(action, DeleteInvoiceGroupProperty)) {
    const { payload } = action
    const { data } = state
    const formattedArray = deleteFromArray<IProperty>(data, payload)
    return {
      ...state,
      data: formattedArray,
    }
  }

  if (isType(action, InvoiceGroupPropertiesFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }
  return state
}
