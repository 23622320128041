import { action, payload } from 'ts-action'
import { IAttachmentTitle } from 'models/AttachmentTitle'
import { IError, IPayload } from 'models/Global'

export const AttachmentTitlesFetching = action('AttachmentTitlesFetching', payload<boolean>())

export const GetAttachmentTitles = action(
  'GetAttachmentTitles',
  payload<IPayload<IAttachmentTitle[]>>()
)

export const AddAttachmentTitle = action(
  'AddAttachmentTitle',
  payload<IPayload<IAttachmentTitle>>()
)

export const UpdateAttachmentTitle = action(
  'UpdateAttachmentTitle',
  payload<IPayload<IAttachmentTitle>>()
)

export const DeleteAttachmentTitle = action('DeleteAttachmentTitle', payload<number>())

export const GetAttachmentTitlesErrors = action('GetAttachmentTitlesErrors', payload<IError>())
