import { useSidebarMenuContext } from '@pinchenterprisesnpm/friday-ui'
import React from 'react'
import { isMobile, isProduction } from 'helpers/helperFunctions'
import { EnvironmentContainerData, EnvironmentContainer } from './style'
import { useSidebarUpdateContext } from '../../../../App'
import { useMenuHotkey } from 'hooks/useMenuHotkey'
import MenuDesktop from '../AdminNavigation/MenuDesktop'
import Header from '../AdminNavigation/HeaderMobile'
import { Content, AdminRouteLayoutContainer } from './style'
import { ConfettiOverlay } from 'ui/components/common/ConfettiOverlay'
import { useLocation } from 'react-router'
import ROUTES from 'routing/adminRoutes'
import { MilestoneAlert } from 'ui/components/common/MilestoneAlert'
import { ENV_NAME } from 'config/envVariables'
import { FEATURE_TOGGLE_CONFETTI } from 'config/featureToggles'

type TAdminRouteLayoutProps = {
  children: React.ReactNode
}

const AdminRouteLayout = (props: TAdminRouteLayoutProps) => {
  const location = useLocation()
  const sidebarContext = useSidebarMenuContext()
  const { forceUpdate } = useSidebarUpdateContext()
  const isInvoicesPage = location.pathname === ROUTES.INVOICES_LIST

  useMenuHotkey()

  const mobile = isMobile()

  return (
    <>
      <AdminRouteLayoutContainer>
        {!isProduction() ? (
          <EnvironmentContainer>
            <EnvironmentContainerData> {ENV_NAME} Data</EnvironmentContainerData>
          </EnvironmentContainer>
        ) : null}
        {isInvoicesPage && FEATURE_TOGGLE_CONFETTI && (
          <EnvironmentContainer>
            <MilestoneAlert />
          </EnvironmentContainer>
        )}
        {FEATURE_TOGGLE_CONFETTI && <ConfettiOverlay />}
        {mobile && <Header />}
        {!mobile && <MenuDesktop forceUpdate={forceUpdate} />}
        <Content menuWidth={sidebarContext.menuWidth}>{props.children}</Content>
      </AdminRouteLayoutContainer>
    </>
  )
}

export default AdminRouteLayout
