import { FC, ReactNode, Fragment } from 'react'
import { checkPermissions } from 'helpers/permissionsHelpers/permissionsChecker'

type AccessControlProps = {
  children: ReactNode
  requiredPermissions?: string[]
  renderNoAccess?: () => ReactNode
  accessCheck?: (extraAccessData: any) => boolean
  extraAccessData?: any
  method?: 'all' | 'any'
}

const AccessControl: FC<AccessControlProps> = ({
  requiredPermissions,
  children,
  renderNoAccess,
  accessCheck,
  extraAccessData,
  method,
}) => {
  let permitted = true
  if (requiredPermissions) {
    permitted = checkPermissions(requiredPermissions, undefined, method)
  }
  if (accessCheck) {
    permitted = permitted && accessCheck(extraAccessData)
  }
  let contentToRender = renderNoAccess ? renderNoAccess() : null

  if (permitted) {
    contentToRender = children
  }
  return <Fragment>{contentToRender}</Fragment>
}

export default AccessControl
