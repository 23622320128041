import { action, payload } from 'ts-action'
import { IGigAutoAcceptCertificate } from 'models/GigAutoAcceptCertificate'
import { IError, IPayload } from 'models/Global'

export const SelectedShiftGigAutoAcceptCertificatesFetching = action(
  'SelectedShiftGigAutoAcceptCertificatesFetching',
  payload<boolean>()
)
export const GetSelectedShiftGigAutoAcceptCertificates = action(
  'GetSelectedShiftGigAutoAcceptCertificates',
  payload<IPayload<IGigAutoAcceptCertificate[]>>()
)
export const GetSelectedShiftGigAutoAcceptCertificatesErrors = action(
  'GetSelectedShiftGigAutoAcceptCertificatesErrors',
  payload<IError>()
)
export const RemoveSelectedShiftGigAutoAcceptCertificate = action(
  'RemoveSelectedShiftGigAutoAcceptCertificate',
  payload<number>()
)
export const AddSelectedShiftGigAutoAcceptCertificate = action(
  'AddSelectedShiftGigAutoAcceptCertificate',
  payload<IGigAutoAcceptCertificate>()
)

export const ClearSelectedShiftGigAutoAcceptCertificates = action(
  'ClearSelectedShiftGigAutoAcceptCertificates'
)
