import styled from 'styled-components'
import { Flex } from '@pinchenterprisesnpm/friday-ui'

export const FlexWrapper = styled(Flex)<{ isActive?: boolean }>`
  background: ${({ isActive }) => (isActive ? 'rgba(3, 169, 244, 0.1)' : 'white')};
  cursor: pointer;
  height: 50px;
`

export const FormattedText = styled.pre`
  border: 1px solid ${({ theme: { palette } }) => palette.dark};
  color: ${({ theme: { palette } }) => palette.dark};
  border-radius: ${({ theme: { mp } }) => mp.xs};
  padding: ${({ theme: { mp } }) => mp.xs};
  font-size: ${({
    theme: {
      font: { sizes },
    },
  }) => sizes.xsmall};
`
