import { IDeductionTypeReducer, IDeductionType } from 'models/DeductionType'
import { Action } from 'redux'
import { isType } from 'ts-action'
import { updateArray, deleteFromArray } from 'helpers/helperFunctions'
import {
  GetDeductionTypes,
  DeductionTypesFetching,
  UpdateDeductionType,
  DeleteDeductionType,
} from './actions'

const initialState: IDeductionTypeReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: true,
  data: [],
}

export const deductiontypesReducer = (
  state = initialState,
  action: Action
): IDeductionTypeReducer => {
  if (isType(action, GetDeductionTypes)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
    }
  }
  if (isType(action, UpdateDeductionType)) {
    const { payload } = action
    const { data } = state
    const formattedArray = updateArray<IDeductionType>(data, payload.data)
    return {
      ...state,
      data: formattedArray,
    }
  }
  if (isType(action, DeleteDeductionType)) {
    const { payload } = action
    const { data } = state
    const formattedArray = deleteFromArray<IDeductionType>(data, payload)
    return {
      ...state,
      data: formattedArray,
    }
  }

  if (isType(action, DeductionTypesFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }
  return state
}
