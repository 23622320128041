import { IFeaturedShiftCampaignAllShiftsReducer } from 'models/FeaturedShiftCampaign'
import { Action } from 'redux'
import { isType } from 'ts-action'
import { updateArray, deleteFromArray } from 'helpers/helperFunctions'
import {
  GetFeaturedShiftCampaignAllShifts,
  FeaturedShiftCampaignAllShiftsFetching,
  UpdateFeaturedShiftCampaignAllShift,
  DeleteFeaturedShiftCampaignAllShift,
  AddFeaturedShiftCampaignAllShift,
  GetFeaturedShiftCampaignAllShift,
  FakeDeleteFeaturedShiftCampaignAllShift,
  FakeDeleteFeaturedShiftCampaignAllShifts,
} from './actions'
import { IShift } from 'models/Shift'

const initialState: IFeaturedShiftCampaignAllShiftsReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: true,
  data: [],
}

export const FeaturedShiftCampaignAllShiftsReducer = (
  state = initialState,
  action: Action
): IFeaturedShiftCampaignAllShiftsReducer => {
  if (isType(action, GetFeaturedShiftCampaignAllShifts)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
    }
  }

  if (isType(action, GetFeaturedShiftCampaignAllShift)) {
    const { payload } = action
    const { data } = state
    const newArray = [...data, payload.data]

    return {
      ...state,
      meta: payload.meta,
      data: newArray,
    }
  }

  if (isType(action, AddFeaturedShiftCampaignAllShift)) {
    const { payload } = action
    const { data } = state
    const newArray = [payload.data, ...data]
    return {
      ...state,
      data: newArray,
    }
  }

  if (isType(action, UpdateFeaturedShiftCampaignAllShift)) {
    const { payload } = action
    const { data } = state
    const formattedArray = updateArray<IShift>(data, payload.data)
    return {
      ...state,
      data: formattedArray,
    }
  }

  if (isType(action, DeleteFeaturedShiftCampaignAllShift)) {
    const { payload } = action
    const { data } = state
    const formattedArray = deleteFromArray<IShift>(data, payload)
    return {
      ...state,
      data: formattedArray,
    }
  }

  if (isType(action, FakeDeleteFeaturedShiftCampaignAllShift)) {
    const { payload } = action
    const { data } = state
    const formattedArray = deleteFromArray<IShift>(data, payload)
    return {
      ...state,
      data: formattedArray,
    }
  }

  if (isType(action, FakeDeleteFeaturedShiftCampaignAllShifts)) {
    return {
      ...state,
      data: [],
    }
  }

  if (isType(action, FeaturedShiftCampaignAllShiftsFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }

  return state
}
