import { ISelectedShiftGigDaysReducer } from 'models/GigDay'
import { Action } from 'redux'
import { isType } from 'ts-action'
// import { updateArray, deleteFromArray } from '../../helpers/helperFunctions'
import {
  GetSelectedShiftGigDays,
  GetSelectedShiftGigDaysErrors,
  ResetSelectedShiftGigDays,
  SelectedShiftGigDaysFetching,
} from './actions'
import { IError } from 'models/Global'

const initialState: ISelectedShiftGigDaysReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  error: {} as IError,
  data: [],
}

export const selectedShiftGigDaysReducer = (
  state = initialState,
  action: Action
): ISelectedShiftGigDaysReducer => {
  if (isType(action, GetSelectedShiftGigDays)) {
    const {
      payload: { meta, data },
    } = action
    return {
      ...state,
      meta,
      data,
    }
  }

  if (isType(action, SelectedShiftGigDaysFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }

  if (isType(action, GetSelectedShiftGigDaysErrors)) {
    const { payload } = action
    return {
      ...state,
      error: payload,
    }
  }

  if (isType(action, ResetSelectedShiftGigDays)) {
    return initialState
  }

  return state
}
