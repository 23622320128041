import { action, payload } from 'ts-action'
import { IPunchCard } from 'models/PunchCard'
import { IError, IPayload } from 'models/Global'

export const PunchCardsFetching = action('PunchCardsFetching', payload<boolean>())

// GET

export const GetPunchCards = action('GetPunchCards', payload<IPayload<IPunchCard[]>>())

export const GetPunchCardsForTimesheet = action(
  'GetPunchCardsForTimesheet',
  payload<IPayload<IPunchCard[]>>()
)

export const GetPunchCardsErrors = action('GetPunchCardsErrors', payload<IError>())

// POST

export const AddPunchCardErrors = action('AddPunchCardErrors', payload<IError>())

export const AddPunchCard = action('AddPunchCard', payload<IPayload<IPunchCard>>())

// PUT

export const UpdatePunchCard = action('UpdatePunchCard', payload<IPayload<IPunchCard>>())

export const UpdatePunchCardErrors = action('UpdatePunchCardErrors', payload<IError>())

// DELETE

export const DeletePunchCard = action('DeletePunchCard', payload<number>())

export const DeletePunchCardErrors = action('DeletePunchCardErrors', payload<IError>())

// RESET

export const ResetPunchCardsState = action('ResetPunchCardsState')
