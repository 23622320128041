import { SALES } from './salesPermissions'
import { ADMIN } from './adminPermissions'
import { ACCOUNT_MANAGER_LEVEL_1 } from './accountManagerOnePermissions'
import { ACCOUNT_MANAGER_LEVEL_2 } from './accountManagerTwoPermissions'
import { USER_ROLE } from 'models/User'

export const permissionsPerRole = {
  [USER_ROLE.ADMIN]: ADMIN,
  [USER_ROLE.SALES]: SALES,
  [USER_ROLE.ACCOUNT_MANAGER_LEVEL_1]: ACCOUNT_MANAGER_LEVEL_1,
  [USER_ROLE.ACCOUNT_MANAGER_LEVEL_2]: ACCOUNT_MANAGER_LEVEL_2,
}
