import { AxiosService } from '../config/config'
import { Endpoints } from '../config/endpoints'
import { IPayload } from '../../models/Global'
import {
  IAchievement,
  IGetAchievementsParams,
  IPutAchievementParams,
  IPostAchievementParams,
} from '../../models/Achievement'

export class AchievementsApi {
  constructor(private axios: AxiosService) {}

  getAchievements = async (params?: IGetAchievementsParams) => {
    const response = await this.axios.get<IPayload<IAchievement[]>>(
      Endpoints.getAchievements,
      params
    )
    return response
  }

  getAchievement = async (id: number, params?: IGetAchievementsParams) => {
    const response = await this.axios.get<IPayload<IAchievement>>(
      Endpoints.getAchievement(id),
      params
    )
    return response
  }

  postAchievement = async (params: IPostAchievementParams | FormData) => {
    const response = await this.axios.post<IPayload<IAchievement>>(
      Endpoints.postAchievement,
      params
    )
    return response
  }

  putAchievement = async (id: number, params?: IPutAchievementParams) => {
    const response = await this.axios.put<IPayload<IAchievement>>(
      Endpoints.putAchievement(id),
      params
    )
    return response
  }

  putAchievementWithAttachment = async (id: number, params: FormData) => {
    const response = await this.axios.post<IPayload<IAchievement>>(
      Endpoints.putAchievement(id),
      params
    )
    return response
  }

  deleteAchievement = async (id: number) => {
    const response = await this.axios.delete<{}>(Endpoints.deleteAchievement(id))
    return response
  }
}
