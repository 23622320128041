import { IDocumentsReducer, IDocument } from 'models/Document'
import { Action } from 'redux'
import {
  GetDocuments,
  DocumentsFetching,
  UpdateDocument,
  AddDocument,
  ResetDocuments,
} from './actions'
import { isType } from 'ts-action'
import { updateArray } from 'helpers/helperFunctions'

const initialState: IDocumentsReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: [],
}

export const documentsReducer = (state = initialState, action: Action): IDocumentsReducer => {
  if (isType(action, GetDocuments)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
    }
  }
  if (isType(action, UpdateDocument)) {
    const { payload } = action
    const { data } = state
    const formattedArray = updateArray<IDocument>(data, payload.data)
    return {
      ...state,
      data: formattedArray,
    }
  }
  if (isType(action, AddDocument)) {
    const { payload } = action
    return {
      ...state,
      data: [...state.data, payload.data],
    }
  }
  if (isType(action, DocumentsFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }
  if (isType(action, ResetDocuments)) {
    return initialState
  }
  return state
}
