import { action, payload } from 'ts-action'
import { IError, IPayload } from 'models/Global'
import { ISickLeaveTemplate } from 'models/SickLeaveTemplate'

export const GetSickLeaveTemplates = action(
  'GetSickLeaveTemplates',
  payload<IPayload<ISickLeaveTemplate[]>>()
)
export const GetSickLeaveTemplatesErrors = action('GetSickLeaveTemplatesErrors', payload<IError>())
export const SickLeaveTemplatesFetching = action('SickLeaveTemplatesFetching', payload<boolean>())
