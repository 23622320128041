import { AxiosService } from '../config/config'
import { Endpoints } from '../config/endpoints'
import { IPayload } from 'models/Global'
import {
  IGetInvoicesParams,
  IPutInvoiceParams,
  IInvoice,
  IGetInvoiceParams,
  IPostInvoiceParams,
  ILedgerInvoice,
  IPutProcessAlInvoices,
  IPostInvoiceByEmailParams,
  IPostSendBulkEmail,
  ITotalOpenBalance,
  IGetTotalOpenBalanceParams,
  IGetInvoicesStatement,
  IPayInvoiceParams,
} from 'models/Invoice'

export class InvoicesApi {
  constructor(private axios: AxiosService) {}

  getInvoices = async (params?: IGetInvoicesParams) => {
    const response = await this.axios.get<IPayload<IInvoice[]>>(Endpoints.getInvoices, params)
    return response
  }

  getInvoice = async (id: number, params?: IGetInvoiceParams) => {
    const response = await this.axios.get<IPayload<IInvoice | ILedgerInvoice>>(
      Endpoints.getInvoice(id),
      params
    )
    return response
  }

  postInvoice = async (params: IPostInvoiceParams) => {
    const response = await this.axios.post<IPayload<IInvoice>>(Endpoints.postInvoice, params)
    return response
  }

  putInvoice = async (id: number, params: IPutInvoiceParams) => {
    const response = await this.axios.put<IPayload<IInvoice>>(Endpoints.putInvoice(id), params)
    return response
  }

  deleteInvoice = async (id: number) => {
    const response = await this.axios.delete<{}>(Endpoints.deleteInvoice(id))
    return response
  }

  processAllInvoices = async (params: IPutProcessAlInvoices) => {
    const response = await this.axios.put(Endpoints.processAllInvoices, params)
    return response
  }

  sendInvoiceByEmail = async (id: number, params: IPostInvoiceByEmailParams) => {
    const response = await this.axios.post(Endpoints.sendInvoiceByEmail(id), params)
    return response
  }

  sendBulkEmail = async (params: IPostSendBulkEmail) => {
    const response = await this.axios.put(Endpoints.sendBulkEmail, params)
    return response
  }

  getTotalOpenBalance = async (params: IGetTotalOpenBalanceParams) => {
    const response = await this.axios.get<IPayload<ITotalOpenBalance>>(
      Endpoints.getTotalOpenBalance,
      params
    )
    return response
  }

  getInvoicesStatement = async (params: IGetInvoicesStatement) => {
    const response = await this.axios.get<Blob>(Endpoints.getInvoicesStatement, params, {
      responseType: 'blob',
    })
    return response
  }

  payInvoice = async (id: number, params: IPayInvoiceParams) => {
    const response = await this.axios.post<IPayload<{}>>(Endpoints.payInvoice(id), params)
    return response
  }
}
