import { AxiosService } from '../config/config'
import { Endpoints } from '../config/endpoints'
import { IPayload } from 'models/Global'
import {
  ICountry,
  IGetCountriesParams,
  IPutCountryParams,
  IPostCountryParams,
} from 'models/Country'

export class CountriesApi {
  constructor(private axios: AxiosService) {}

  getCountries = async (params?: IGetCountriesParams) => {
    const response = await this.axios.get<IPayload<ICountry[]>>(Endpoints.getCountries, params)
    return response
  }

  getCountry = async (id: number, params?: IGetCountriesParams) => {
    const response = await this.axios.get<IPayload<ICountry>>(Endpoints.getCountry(id), params)
    return response
  }

  postCountry = async (params: IPostCountryParams) => {
    const response = await this.axios.post<IPayload<ICountry>>(Endpoints.postCountry, params)
    return response
  }

  putCountry = async (id: number, params?: IPutCountryParams) => {
    const response = await this.axios.put<IPayload<ICountry>>(Endpoints.putCountry(id), params)
    return response
  }

  deleteCountry = async (id: number) => {
    const response = await this.axios.delete<{}>(Endpoints.deleteCountry(id))
    return response
  }
}
