import { Colors, InputProps } from '@pinchenterprisesnpm/friday-ui'
import { SearchInput, InputWrapper, IconWrapper } from './style'
import { InputHTMLAttributes } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons'

type TPowerSearchInputFieldProps = InputHTMLAttributes<HTMLInputElement> &
  InputProps & {
    width?: string
    onClick?: () => void
    isCollapsed?: boolean
  }

export const PowerSearchInputField = ({
  isCollapsed = false,
  onClick,
  ...props
}: TPowerSearchInputFieldProps) => {
  if (isCollapsed) {
    return (
      <IconWrapper justifyContent='center' alignItems='center' onClick={onClick}>
        <FontAwesomeIcon size='lg' icon={faMagnifyingGlass} color={Colors.dark} />
      </IconWrapper>
    )
  }
  return (
    <InputWrapper alignItems='center'>
      <SearchInput {...props} tabIndex={0} onClick={onClick} />
    </InputWrapper>
  )
}
