import { action, payload } from 'ts-action'
import { IUniversalTemplate } from 'models/UniversalTemplate'
import { IError, IPayload } from 'models/Global'

export const UniversalTemplatesFetching = action('UniversalTemplatesFetching', payload<boolean>())

export const GetUniversalTemplates = action(
  'GetUniversalTemplates',
  payload<IPayload<IUniversalTemplate[]>>()
)

export const AddUniversalTemplate = action(
  'AddUniversalTemplate',
  payload<IPayload<IUniversalTemplate>>()
)

export const UpdateUniversalTemplate = action(
  'UpdateUniversalTemplate',
  payload<IPayload<IUniversalTemplate>>()
)

export const DeleteUniversalTemplate = action('DeleteUniversalTemplate', payload<number>())

export const GetUniversalTemplatesErrors = action('GetUniversalTemplatesErrors', payload<IError>())

export const ClearUniversalTemplates = action('ClearUniversalTemplates')
