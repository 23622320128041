import { SHIFTS } from 'models/Permission'
import ROUTES from 'routing/adminRoutes'
import {
  AsyncBulkEditShifts,
  AsyncShiftFullDuplicate,
  AsyncShiftFullEdit,
  AsyncShifts,
} from 'ui/pages'
import AdminRouteLayout from '../../../Admin/AdminRouteLayout'
import AdminRoute from '../../AdminRoute'

const shiftRoutes = [
  <AdminRoute
    exact
    path={ROUTES.SHIFTS}
    layout={AdminRouteLayout}
    component={AsyncShifts}
    requiredPermissions={[SHIFTS.READ_SHIFTS]}
    key={ROUTES.SHIFTS}
  />,
  <AdminRoute
    exact
    path={ROUTES.DUPLICATE_SHIFT}
    layout={AdminRouteLayout}
    component={AsyncShiftFullDuplicate}
    requiredPermissions={[SHIFTS.CREATE_SHIFTS]}
    key={ROUTES.DUPLICATE_SHIFT}
  />,
  <AdminRoute
    exact
    path={ROUTES.EDIT_SHIFT}
    layout={AdminRouteLayout}
    component={AsyncShiftFullEdit}
    requiredPermissions={[SHIFTS.UPDATE_SHIFTS]}
    key={ROUTES.EDIT_SHIFT}
  />,
  <AdminRoute
    exact
    path={ROUTES.BULK_EDIT_SHIFTS}
    layout={AdminRouteLayout}
    component={AsyncBulkEditShifts}
    requiredPermissions={[SHIFTS.UPDATE_SHIFTS]}
    key={ROUTES.BULK_EDIT_SHIFTS}
  />,
]

export default shiftRoutes
