import { IPropertyTypeReducer, IPropertyType } from 'models/PropertyType'
import { Action } from 'redux'
import { isType } from 'ts-action'
import { updateArray, deleteFromArray } from 'helpers/helperFunctions'
import {
  GetPropertyTypes,
  PropertyTypesFetching,
  UpdatePropertyType,
  DeletePropertyType,
  AddPropertyType,
  UpdatePropertyTypeLoadingIds,
} from './actions'

const initialState: IPropertyTypeReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: [],
  propertyTypesLoadingIds: {},
}

export const propertyTypesReducer = (
  state = initialState,
  action: Action
): IPropertyTypeReducer => {
  if (isType(action, GetPropertyTypes)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
    }
  }
  if (isType(action, AddPropertyType)) {
    const { payload } = action
    return {
      ...state,
      data: [payload.data, ...state.data],
    }
  }
  if (isType(action, UpdatePropertyType)) {
    const { payload } = action
    const { data } = state
    const formattedArray = updateArray<IPropertyType>(data, payload.data)
    return {
      ...state,
      data: formattedArray,
    }
  }
  if (isType(action, DeletePropertyType)) {
    const { payload } = action
    const { data } = state
    const formattedArray = deleteFromArray<IPropertyType>(data, payload)
    return {
      ...state,
      data: formattedArray,
    }
  }
  if (isType(action, UpdatePropertyTypeLoadingIds)) {
    const { payload: id } = action
    const newPropertyTypeLoadingIds = { ...state.propertyTypesLoadingIds }
    if (newPropertyTypeLoadingIds[id]) {
      delete newPropertyTypeLoadingIds[id]
    } else {
      newPropertyTypeLoadingIds[id] = true
    }
    return {
      ...state,
      propertyTypesLoadingIds: newPropertyTypeLoadingIds,
    }
  }
  if (isType(action, PropertyTypesFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }
  return state
}
