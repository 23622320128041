import { IBulkEditShiftsReducer, IShift } from 'models/Shift'
import { Action } from 'redux'
import { isType } from 'ts-action'
import { updateArray, deleteFromArray } from 'helpers/helperFunctions'
import {
  GetBulkEditShifts,
  BulkEditShiftsFetching,
  UpdateBulkEditShift,
  DeleteBulkEditShift,
  ResetBulkEditShifts,
} from './actions'

const initialState: IBulkEditShiftsReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: [],
}

export const bulkEditShiftsReducer = (
  state = initialState,
  action: Action
): IBulkEditShiftsReducer => {
  if (isType(action, GetBulkEditShifts)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
    }
  }
  if (isType(action, UpdateBulkEditShift)) {
    const { payload } = action
    const { data } = state
    const formattedArray = updateArray<IShift>(data, payload.data)
    return {
      ...state,
      data: formattedArray,
    }
  }
  if (isType(action, DeleteBulkEditShift)) {
    const { payload } = action
    const { data } = state
    const formattedArray = deleteFromArray<IShift>(data, payload)
    return {
      ...state,
      data: formattedArray,
    }
  }

  if (isType(action, BulkEditShiftsFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }

  if (isType(action, ResetBulkEditShifts)) {
    return {
      ...initialState,
    }
  }
  return state
}
