import { action, payload } from 'ts-action'
import { IUser } from 'models/User'
import { IError, IPayload } from 'models/Global'

export const GetAdminUser = action('GetAdminUser', payload<IPayload<IUser>>())

export const GetAdminUserErrors = action('GetAdminUserErrors', payload<IError>())

export const AdminUserFetching = action('AdminUserFetching', payload<boolean>())

export const UpdateAdminUser = action('UpdateAdminUser', payload<IPayload<IUser>>())

export const ClearAdminUserReducer = action('ClearAdminUserReducer')
