/* eslint-disable react/no-unused-state */
import { Component } from 'react'
import { SomethingWentWrong } from 'ui/components/common/SomethingWentWrong'
import MonitoringService from 'helpers/monitoringService'

// Functional components does not support the componentDidCatch lifecycle
class ErrorBoundary extends Component {
  state = { error: null, errorInfo: null }

  componentDidCatch(error: any, errorInfo: any) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error,
      errorInfo,
    })
    MonitoringService.logError(error)
  }

  render() {
    const { errorInfo } = this.state
    const { children } = this.props
    if (errorInfo) {
      // Error path
      return <SomethingWentWrong />
    }
    // Normally, just render children
    return children
  }
}

export default ErrorBoundary
