import { IDropdownData } from 'models/Common'
import { SHIFT_FILLED } from './constants/constants'
import {
  IShift,
  IShiftIncludeParams,
  SHIFT_AUTO_ACCEPT_STATUS,
  SHIFT_FOLLOWED,
  SHIFT_PROGRESS,
  SHIFT_STATUS,
  SHIFT_TYPE,
} from 'models/Shift'
import moment from 'moment'
import { differenceInMinutes } from 'date-fns'
import { FEATURE_TOGGLE_RESTRICT_LAST_MINUTE_CANDIDATE_ADDITIONS_IN_THE_VMS } from 'config/featureToggles'
import AuthService from 'services/AuthService'
import { LAST_MINUTE_ADDITIONS_ALLOWED_USERS } from 'config/envVariables'

export const progressDropdownOptions = [
  { value: SHIFT_PROGRESS.NOT_STARTED, label: 'Not Started', isDefault: true },
  { value: SHIFT_PROGRESS.ACTIVE, label: 'Started' },
  { value: SHIFT_PROGRESS.FINISHED, label: 'Finished' },
]

export const filledDropdownOptions = [
  { value: -1, label: 'Filled/Not filled', isDefault: true },
  { value: SHIFT_FILLED.NOT_FILLED, label: 'Not filled' },
  { value: SHIFT_FILLED.FILLED, label: 'Filled' },
]

export const initialFilledOptionMapper = [
  { value: SHIFT_FILLED.NOT_FILLED, label: 'Not filled' },
  { value: SHIFT_FILLED.FILLED, label: 'Filled' },
]

export const followDropdownOptions = [
  { value: -1, label: 'Followed/Not followed', isDefault: true },
  { value: SHIFT_FOLLOWED.FOLLOWED, label: 'Followed' },
]

export const initialFollowedOptionMapper = [
  { value: SHIFT_FOLLOWED.NOT_FOLLOWED, label: 'Not followed' },
  { value: SHIFT_FOLLOWED.FOLLOWED, label: 'Followed' },
]

export const shiftTypeDropdownOptions = [
  { value: -1, label: 'Single/Multiday', isDefault: true },
  { value: SHIFT_TYPE.SINGLEDAY, label: 'Single' },
  { value: SHIFT_TYPE.MULTIDAY, label: 'Multiday' },
]

export const initialShiftTypeOptionMapper = [
  { value: SHIFT_TYPE.SINGLEDAY, label: 'Single' },
  { value: SHIFT_TYPE.MULTIDAY, label: 'Multiday' },
]

export const breakDropdownData: IDropdownData[] = [
  {
    value: 0,
    label: 'No Break',
  },
  {
    value: 30,
    label: '30 min',
  },
  {
    value: 45,
    label: '45 min',
  },
  {
    value: 60,
    label: '60 min',
  },
  {
    value: 75,
    label: '75 min',
  },
]

export const autoAcceptStatusOptions: IDropdownData[] = [
  {
    label: 'All',
    value: -1,
    isDefault: true,
  },
  {
    label: 'Accept',
    value: SHIFT_AUTO_ACCEPT_STATUS.ACCEPT,
  },
  {
    label: 'Auto Accept',
    value: SHIFT_AUTO_ACCEPT_STATUS.AUTO_ACCEPT,
  },
  {
    label: 'Selective Auto Accept',
    value: SHIFT_AUTO_ACCEPT_STATUS.SELECTIVE_AUTO_ACCEPT,
  },
]

export const getUpdateShiftSuccessMessageByStatus = (status?: SHIFT_STATUS) => {
  if (status === SHIFT_STATUS.DRAFT)
    return 'Your shift has been successfully saved as a draft. It will not be visible to Upshifters until you post it live.'

  return 'Shift updated successfully.'
}

export const checkIfShiftIsWithin24hours = (shift: IShift) => {
  const currentBrowserTimezone = moment.tz.guess()
  const currentBrowserTime = moment().format()

  const timeStart = shift.time_start
  const shiftTimezone = shift.property_timezone
  const timeStartWithTimeZone = moment(timeStart).tz(shiftTimezone)

  const timeStartInShiftTimeZone = moment(timeStartWithTimeZone).tz(currentBrowserTimezone)
  const duration = moment.duration(timeStartInShiftTimeZone.diff(currentBrowserTime))
  const hoursDifference = duration.asHours()

  return hoursDifference <= 24
}

/**
 * Determines the minutes until shift starts
 * @param shift
 * @returns number
 */
export const getMinutesUntilShiftStarts = (shift: IShift) => {
  const shiftDateInTimezone = new Date().toLocaleString('en-US', {
    timeZone: shift.property_timezone,
  })
  return differenceInMinutes(new Date(shift.time_start), new Date(shiftDateInTimezone))
}

/**
 * Returns whether the logged in user can by pass the last minute additions restriction on a shift
 * @returns boolean
 */
export const canUserByPassLastMinuteAdditionsRestriction = () => {
  const loggedInUser = AuthService.getLoggedInUser()
  if (!loggedInUser) return false

  return (LAST_MINUTE_ADDITIONS_ALLOWED_USERS ?? '').split(',').includes(loggedInUser.email)
}

/**
 * Returns whether Last Minute Candidate Additions are restricted for the shift
 * @param shift
 * @returns boolean
 */
export const areLastMinuteAdditionsRestricted = (shift: IShift) => {
  if (!FEATURE_TOGGLE_RESTRICT_LAST_MINUTE_CANDIDATE_ADDITIONS_IN_THE_VMS) return false

  const minutesUntilShiftStarts = getMinutesUntilShiftStarts(shift)
  const restrictLastMinuteAdditions =
    shift.business?.vendor_management_system?.restrict_last_minute_additions ?? false

  const shiftFreezeTimeHours = shift.business?.vendor_management_system?.shift_freeze_hours || 3

  return restrictLastMinuteAdditions && minutesUntilShiftStarts < shiftFreezeTimeHours * 60
}

/**
 * Returns includes needed for SelectedShift - getSelectedShift, updateSelectedShift
 * @returns IShiftIncludeParams['include']
 */
export const getSelectedShiftIncludes = () => {
  const shiftIncludes: IShiftIncludeParams['include'] = [
    'followed_by_user',
    'business.business_setting',
    'gig_type.gig_type_categories',
  ]
  if (FEATURE_TOGGLE_RESTRICT_LAST_MINUTE_CANDIDATE_ADDITIONS_IN_THE_VMS) {
    shiftIncludes.push('business.vendor_management_system')
  }
  return shiftIncludes
}

/**
 * Checks whether Selective Claim Mode (Selective Auto Accept/SAA) is enabled for a shift.
 * We consider Selective Claim Mode enabled for a shift, when one of the following settings are enabled for a shift:
 * "Auto-Accept By Favorited", "Auto-Accept by Worked before", "Auto-Accept by Certificates", "Auto-Accept by Onboarded"
 * @param shift
 * @returns boolean
 */
export const hasShiftSelectiveClaimModeEnabled = (shift: IShift) => {
  if (!shift) return false
  // In order to enable Selective Claim Mode for a shift, we need to enable Claim Mode (Auto Accept/AUTO) for the shift first.
  // We consider Claim Mode enabled for a shift, when "Auto Accept" setting is enabled for a shift.
  if (!shift.auto_accept) return false
  return (
    shift.auto_accept_by_certificates ||
    shift.auto_accept_by_favored ||
    shift.auto_accept_by_worked_before ||
    shift.auto_accept_by_onboarded
  )
}

/**
 * Function for checking whether a shift is a multiday shift
 * @param {IShift} shift
 * @returns {boolean}
 */
export const isMultidayShift = (shift: IShift) => Number(shift.type) === SHIFT_TYPE.MULTIDAY

/**
 * Function for checking whether a shift is a singleday shift
 * @param {IShift} shift
 * @returns {boolean}
 */
export const isSingledayShift = (shift: IShift) => Number(shift.type) === SHIFT_TYPE.SINGLEDAY
