import { AxiosService } from '../config/config'
import { Endpoints } from '../config/endpoints'
import { IPayload } from '../../models/Global'
import {
  IVendorManagementSystem,
  TGetVendorManagementSystemsParams,
  IPutVendorManagementSystemParams,
  IPostVendorManagementSystemParams,
  IVendorManagementSystemsEnums,
  IGetVmsPunchCards,
  IVMSPunchCard,
  ISubmitVMSPunchCardsParams,
  IUnsubmitVMSPunchCardsParams,
  IPostExportVmsPunchCards,
  IGetVmsUpcomingShiftUpshifters,
  IGetExportVmsUpcomingShiftUpshifters,
  IGetVMSOnboardingManagementParams,
  IPostVMSOnboardingManagementOffboardParams,
  IPostVMSOnboardingManagementOnboardParams,
  IVMSOnboardingManagementUser,
} from '../../models/VendorManagementSystem'

export class VendorManagementSystemsApi {
  constructor(private axios: AxiosService) {}

  getVendorManagementSystems = async (params?: TGetVendorManagementSystemsParams) => {
    const response = await this.axios.get<IPayload<IVendorManagementSystem[]>>(
      Endpoints.getVendorManagementSystems,
      params
    )
    return response
  }

  getVendorManagementSystem = async (id: number, params?: TGetVendorManagementSystemsParams) => {
    const response = await this.axios.get<IPayload<IVendorManagementSystem>>(
      Endpoints.getVendorManagementSystem(id),
      params
    )
    return response
  }

  postVendorManagementSystem = async (params: IPostVendorManagementSystemParams) => {
    const response = await this.axios.post<IPayload<IVendorManagementSystem>>(
      Endpoints.postVendorManagementSystem,
      params
    )
    return response
  }

  putVendorManagementSystem = async (id: number, params?: IPutVendorManagementSystemParams) => {
    const response = await this.axios.put<IPayload<IVendorManagementSystem>>(
      Endpoints.putVendorManagementSystem(id),
      params
    )
    return response
  }

  deleteVendorManagementSystem = async (id: number) => {
    const response = await this.axios.delete(Endpoints.deleteVendorManagementSystem(id))
    return response
  }

  getVendorManagementSystemsEnums = async () => {
    const response = await this.axios.get<IPayload<IVendorManagementSystemsEnums>>(
      Endpoints.getVendorManagementSystemsEnums
    )
    return response
  }

  getVmsPunchCards = async (id: number, params?: IGetVmsPunchCards) => {
    const response = await this.axios.get<IPayload<IVMSPunchCard[]>>(
      Endpoints.getVmsPunchCards(id),
      params
    )
    return response
  }

  postSubmitVMSPunchCards = async (id: number, params: ISubmitVMSPunchCardsParams) => {
    const response = await this.axios.post<IPayload<IVMSPunchCard[]>>(
      Endpoints.postSubmitVMSPunchCards(id),
      params
    )
    return response
  }

  postUnsubmitVMSPunchCards = async (id: number, params: IUnsubmitVMSPunchCardsParams) => {
    const response = await this.axios.post<IPayload<IVMSPunchCard[]>>(
      Endpoints.postUnsubmitVMSPunchCards(id),
      params
    )
    return response
  }

  getExportVMSPunchCards = async (id: number, params?: IPostExportVmsPunchCards) => {
    const response = await this.axios.get<Blob>(Endpoints.getExportVMSPunchCards(id), params)
    return response
  }

  getVMSUpcomingShiftUpshifters = async (id: number, params?: IGetVmsUpcomingShiftUpshifters) => {
    const response = await this.axios.get<IPayload<IVMSPunchCard[]>>(
      Endpoints.getVMSUpcomingShiftUpshifters(id),
      params
    )
    return response
  }

  getExportVMSUpcomingShiftUpshifters = async (
    id: number,
    params?: IGetExportVmsUpcomingShiftUpshifters
  ) => {
    const response = await this.axios.get<Blob>(
      Endpoints.getExportVMSUpcomingShiftUpshifters(id),
      params
    )
    return response
  }

  getVendorManagementSystemOnboardings = async (
    id: number,
    params?: IGetVMSOnboardingManagementParams
  ) => {
    const response = await this.axios.get<IPayload<IVMSOnboardingManagementUser[]>>(
      Endpoints.getVendorManagementSystemOnboardings(id),
      params
    )
    return response
  }

  postVMSOnboardingManagementOnboard = async (
    id: number,
    params?: IPostVMSOnboardingManagementOnboardParams
  ) => {
    const response = await this.axios.post<IPayload<{}>>(
      Endpoints.postVMSOnboardingManagementOnboard(id),
      params
    )
    return response
  }

  postVMSOnboardingManagementOffboard = async (
    id: number,
    params?: IPostVMSOnboardingManagementOffboardParams
  ) => {
    const response = await this.axios.post<IPayload<{}>>(
      Endpoints.postVMSOnboardingManagementOffboard(id),
      params
    )
    return response
  }
}
