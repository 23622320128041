import { AxiosService } from '../config/config'
import { Endpoints } from '../config/endpoints'
import { IPayload } from 'models/Global'
import {
  IGigAutoAcceptCertificate,
  IGetGigAutoAcceptCertificatesParams,
  IPutGigAutoAcceptCertificateParams,
  IPostGigAutoAcceptCertificateParams,
} from 'models/GigAutoAcceptCertificate'

export class GigAutoAcceptCertificatesApi {
  constructor(private axios: AxiosService) {}

  getGigAutoAcceptCertificates = async (params?: IGetGigAutoAcceptCertificatesParams) => {
    const response = await this.axios.get<IPayload<IGigAutoAcceptCertificate[]>>(
      Endpoints.getGigAutoAcceptCertificates,
      params
    )
    return response
  }

  getGigAutoAcceptCertificate = async (
    id: number,
    params?: IGetGigAutoAcceptCertificatesParams
  ) => {
    const response = await this.axios.get<IPayload<IGigAutoAcceptCertificate>>(
      Endpoints.getGigAutoAcceptCertificate(id),
      params
    )
    return response
  }

  postGigAutoAcceptCertificate = async (params: IPostGigAutoAcceptCertificateParams) => {
    const response = await this.axios.post<IPayload<IGigAutoAcceptCertificate>>(
      Endpoints.postGigAutoAcceptCertificate,
      params
    )
    return response
  }

  putGigAutoAcceptCertificate = async (id: number, params?: IPutGigAutoAcceptCertificateParams) => {
    const response = await this.axios.put<IPayload<IGigAutoAcceptCertificate>>(
      Endpoints.putGigAutoAcceptCertificate(id),
      params
    )
    return response
  }

  deleteGigAutoAcceptCertificate = async (id: number) => {
    const response = await this.axios.delete<{}>(Endpoints.deleteGigAutoAcceptCertificate(id))
    return response
  }
}
