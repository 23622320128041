import { action, payload } from 'ts-action'
import { IBusinessEntity } from 'models/BusinessEntity'
import { IFollow } from 'models/Follow'
import { IError, IPayload } from 'models/Global'
import { IBusinessSetting } from 'models/BusinessSetting'

export const GetBusinessEntityAction = action(
  'GetBusinessEntity',
  payload<IPayload<IBusinessEntity>>()
)

export const GetBusinessEntityErrors = action('GetBusinessEntityErrors', payload<IError>())

export const BusinessEntityFetching = action('BusinessEntityFetching', payload<boolean>())

export const UpdateBusinessEntity = action(
  'UpdateBusinessEntity',
  payload<IPayload<IBusinessEntity>>()
)

export const UpdateBusinessEntityFollowedByUser = action(
  'UpdateBusinessEntityFollowedByUser',
  payload<IPayload<IFollow>>()
)

export const ResetBusinessEntity = action('ResetBusinessEntity')

export const UpdateBusinessEntitySetting = action(
  'UpdateBusinessEntitySetting',
  payload<IBusinessSetting>()
)
