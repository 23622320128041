import { AxiosService } from '../config/config'
import { Endpoints } from '../config/endpoints'
import { IPayload } from 'models/Global'
import {
  IGetPunchCardsParams,
  IPunchCard,
  IPutPunchCardParams,
  IPostPunchCardParams,
  ITransferPunchCardParams,
} from 'models/PunchCard'

export class PunchCardsApi {
  constructor(private axios: AxiosService) {}

  getPunchCards = async <TPunchCard = IPunchCard>(params?: IGetPunchCardsParams) => {
    const response = await this.axios.get<IPayload<TPunchCard[]>>(Endpoints.getPunchCards, params)
    return response
  }

  getPunchCard = async (id: number, params?: IGetPunchCardsParams) => {
    const response = await this.axios.get<IPayload<IPunchCard>>(Endpoints.getPunchCard(id), params)
    return response
  }

  postPunchCard = async (params: IPostPunchCardParams) => {
    const response = await this.axios.post<IPayload<IPunchCard>>(Endpoints.postPunchCard, params)
    return response
  }

  putPunchCard = async (id: number, params?: IPutPunchCardParams) => {
    const response = await this.axios.put<IPayload<IPunchCard>>(Endpoints.putPunchCard(id), params)
    return response
  }

  deletePunchCard = async (id: number) => {
    const response = await this.axios.delete<{}>(Endpoints.deletePunchCard(id))
    return response
  }

  transferPunchCard = async (id: number, params: ITransferPunchCardParams) => {
    const response = await this.axios.post<IPayload<IPunchCard>>(
      Endpoints.transferPunchCard(id),
      params
    )
    return response
  }
}
