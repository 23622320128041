import ROUTES from 'routing/adminRoutes'
import { AsyncChangePassword } from 'ui/pages'
import AdminRouteLayout from '../../../Admin/AdminRouteLayout'
import AdminRoute from '../../AdminRoute'

const changePasswordRoutes = [
  <AdminRoute
    exact
    path={ROUTES.CHANGE_PASSWORD}
    layout={AdminRouteLayout}
    component={AsyncChangePassword}
    key={ROUTES.CHANGE_PASSWORD}
  />,
]

export default changePasswordRoutes
