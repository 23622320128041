import { action, payload } from 'ts-action'
import { IError, IPayload } from 'models/Global'
import { IProperty } from 'models/Property'

export const GetSelectedShiftProperty = action(
  'GetSelectedShiftProperty',
  payload<IPayload<IProperty>>()
)

export const UpdateSelectedShiftProperty = action(
  'UpdateSelectedShiftProperty',
  payload<IPayload<IProperty>>()
)

export const DeleteSelectedShiftProperty = action('DeleteSelectedShiftProperty')

export const SelectedShiftPropertyFetching = action(
  'SelectedShiftPropertyFetching',
  payload<boolean>()
)

export const ResetSelectedShiftProperty = action('ResetSelectedShiftProperty')

export const GetSelectedShiftPropertyErrors = action(
  'GetSelectedShiftPropertyErrors',
  payload<IError>()
)
