import { useState, useEffect, useCallback } from 'react'
import { Flex, Text } from '@pinchenterprisesnpm/friday-ui'
import {
  TPowerSearchRecentData,
  POWER_SEARCH_LOCAL_STORAGE_ITEM,
  removePowerSearchRecentData,
} from '../PowerSearch/utils'
import { RecentSearchHistoryItem } from './RecentSearchHistoryItem'
import { Wrapper } from './style'

export const RecentSearchHistory = () => {
  const [recentSearchHistoryData, setRecentSearchHistoryData] = useState<TPowerSearchRecentData[]>(
    []
  )

  const removeRecentSearchHistoryItem = useCallback((item: TPowerSearchRecentData) => {
    setRecentSearchHistoryData((prevState) =>
      prevState.filter((x) => !(x.search === item.search && x.resourceType === item.resourceType))
    )
    removePowerSearchRecentData(item)
  }, [])

  useEffect(() => {
    // Get the current list of recent searches from local storage
    const currentList = localStorage.getItem(POWER_SEARCH_LOCAL_STORAGE_ITEM)

    // If the list does not exist in local storage, create an empty list
    const list = (currentList ? JSON.parse(currentList) : []) as TPowerSearchRecentData[]
    setRecentSearchHistoryData(list)
  }, [])

  if (!recentSearchHistoryData || !recentSearchHistoryData.length) {
    return <Text ml={20}>No Recent searches</Text>
  }

  return (
    <Wrapper flexDirection='column'>
      <Text ml={20}>Recent searches</Text>
      <Flex flexDirection='column'>
        {recentSearchHistoryData?.map((item) => {
          return (
            <RecentSearchHistoryItem
              key={item.search + item.resourceType}
              removeRecentSearchHistoryItem={removeRecentSearchHistoryItem}
              {...item}
            />
          )
        })}
      </Flex>
    </Wrapper>
  )
}
