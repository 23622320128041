import React from 'react'
import MenuMobile from '../MenuMobile'
import { StyledHeader, PageTitleWrapper, PageTitle } from './style'
import { useHeaderTitle } from './utils'

const Header = () => {
  return (
    <StyledHeader>
      <MenuMobile />
      <PageTitleWrapper>
        <PageTitle>{useHeaderTitle()}</PageTitle>
      </PageTitleWrapper>
    </StyledHeader>
  )
}
export default Header
