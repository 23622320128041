import { action, payload } from 'ts-action'
import { IError, IPayload } from 'models/Global'
import { IAutoAcceptCertificate } from 'models/AutoAcceptCertificate'

export const AllBusinessAutoAcceptCertificatesFetching = action(
  'AllBusinessAutoAcceptCertificatesFetching',
  payload<boolean>()
)
export const GetAllBusinessAutoAcceptCertificates = action(
  'GetAllBusinessAutoAcceptCertificates',
  payload<IPayload<IAutoAcceptCertificate[]>>()
)
export const GetAllBusinessAutoAcceptCertificatesErrors = action(
  'GetAllBusinessAutoAcceptCertificatesErrors',
  payload<IError>()
)
export const RemoveAllBusinessAutoAcceptCertificate = action(
  'RemoveAllBusinessAutoAcceptCertificate',
  payload<number>()
)
export const AddAllBusinessAutoAcceptCertificate = action(
  'AddAllBusinessAutoAcceptCertificate',
  payload<IAutoAcceptCertificate>()
)

export const ClearAllBusinessAutoAcceptCertificates = action(
  'ClearAllBusinessAutoAcceptCertificates'
)
