import { IGigTypeExperience } from 'models/Experience'
import { action, payload } from 'ts-action'
import { IError, IPayload } from '../../models/Global'

export const GigTypeExperiencesFetching = action('GigTypeExperiencesFetching', payload<boolean>())

export const GetGigTypeExperiences = action(
  'GetGigTypeExperiences',
  payload<IPayload<IGigTypeExperience[]>>()
)

export const GetGigTypeExperiencesErrors = action('GetGigTypeExperiencesErrors', payload<IError>())
