import { Action } from 'redux'
import { isType } from 'ts-action'
import { GetBlockHistory, BlockHistoryFetching } from './actions'
import { IActivityLogReducer } from 'models/ActivityLog'
import { IBlockHistory } from 'models/Block'

const initialState: IActivityLogReducer<IBlockHistory> = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: [],
}

export const blockHistoryReducer = (
  state = initialState,
  action: Action
): IActivityLogReducer<IBlockHistory> => {
  if (isType(action, GetBlockHistory)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
    }
  }
  if (isType(action, BlockHistoryFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }
  return state
}
