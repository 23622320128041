import { PROPERTIES } from 'models/Permission'
import ROUTES from 'routing/adminRoutes'
import { AsyncAddLocation, AsyncLocation, AsyncLocations } from 'ui/pages'
import AdminRouteLayout from '../../../Admin/AdminRouteLayout'
import AdminRoute from '../../AdminRoute'

const locationsRoutes = [
  <AdminRoute
    exact
    path={ROUTES.LOCATIONS_LIST}
    layout={AdminRouteLayout}
    component={AsyncLocations}
    requiredPermissions={[PROPERTIES.READ_PROPERTIES]}
    key={ROUTES.LOCATIONS_LIST}
  />,
  <AdminRoute
    exact
    path={ROUTES.LOCATIONS_ADD}
    layout={AdminRouteLayout}
    component={AsyncAddLocation}
    requiredPermissions={[PROPERTIES.CREATE_PROPERTIES]}
    key={ROUTES.LOCATIONS_ADD}
  />,

  <AdminRoute
    exact
    path={ROUTES.LOCATION}
    layout={AdminRouteLayout}
    component={AsyncLocation}
    requiredPermissions={[PROPERTIES.READ_PROPERTIES, PROPERTIES.UPDATE_PROPERTIES]}
    key={ROUTES.LOCATION}
  />,
]

export default locationsRoutes
