import { action, payload } from 'ts-action'
import { IOnboardingWorkflow } from 'models/OnboardingWorkflow'
import { IError, IPayload } from 'models/Global'

export const OnboardingWorkflowsFetching = action('OnboardingWorkflowsFetching', payload<boolean>())

export const GetOnboardingWorkflows = action(
  'GetOnboardingWorkflows',
  payload<IPayload<IOnboardingWorkflow[]>>()
)

export const GetOnboardingWorkflow = action(
  'GetOnboardingWorkflow',
  payload<IPayload<IOnboardingWorkflow>>()
)

export const AddOnboardingWorkflow = action(
  'AddOnboardingWorkflow',
  payload<IPayload<IOnboardingWorkflow>>()
)

export const UpdateOnboardingWorkflow = action(
  'UpdateOnboardingWorkflow',
  payload<IPayload<IOnboardingWorkflow>>()
)

export const DeleteOnboardingWorkflow = action('DeleteOnboardingWorkflow', payload<number>())

export const GetOnboardingWorkflowErrors = action('GetOnboardingWorkflowErrors', payload<IError>())

export const ResetOnboardingWorkflow = action('ResetOnboardingWorkflow')
