import { action, payload } from 'ts-action'
import { ITransformedPendingDeletionPunchCards } from 'models/PendingDeletionPunchCard'
import { IError, IPayload } from 'models/Global'

export const PendingDeletionPunchCardsFetching = action(
  'PendingDeletionPunchCardsFetching',
  payload<boolean>()
)

export const GetPendingDeletionPunchCards = action(
  'GetPendingDeletionPunchCards',
  payload<IPayload<ITransformedPendingDeletionPunchCards>>()
)

export const RemovePendingDeletionPunchCard = action(
  'RemovePendingDeletionPunchCard',
  payload<{ key: string; punchCardId: number }>()
)

export const GetPendingDeletionPunchCardsErrors = action(
  'GetPendingDeletionPunchCardsErrors',
  payload<IError>()
)

export const ClearPendingDeletionPunchCardsReducer = action('ClearPendingDeletionPunchCardsReducer')
