import {
  IFeaturedShiftCampaignsReducer,
  IFeaturedShiftCampaign,
} from 'models/FeaturedShiftCampaign'
import { Action } from 'redux'
import { isType } from 'ts-action'
import { updateArray, deleteFromArray } from 'helpers/helperFunctions'
import {
  GetFeaturedShiftCampaigns,
  FeaturedShiftCampaignsFetching,
  UpdateFeaturedShiftCampaign,
  DeleteFeaturedShiftCampaign,
  AddFeaturedShiftCampaign,
} from './actions'

const initialState: IFeaturedShiftCampaignsReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: [],
}

export const FeaturedShiftCampaignsReducer = (
  state = initialState,
  action: Action
): IFeaturedShiftCampaignsReducer => {
  if (isType(action, GetFeaturedShiftCampaigns)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
    }
  }
  if (isType(action, AddFeaturedShiftCampaign)) {
    const { payload } = action
    const { data } = state
    const newArray = [payload.data, ...data]
    return {
      ...state,
      data: newArray,
    }
  }

  if (isType(action, UpdateFeaturedShiftCampaign)) {
    const { payload } = action
    const { data } = state
    const formattedArray = updateArray<IFeaturedShiftCampaign>(data, payload.data)
    return {
      ...state,
      data: formattedArray,
    }
  }

  if (isType(action, DeleteFeaturedShiftCampaign)) {
    const { payload } = action
    const { data } = state
    const formattedArray = deleteFromArray<IFeaturedShiftCampaign>(data, payload)
    return {
      ...state,
      data: formattedArray,
    }
  }

  if (isType(action, FeaturedShiftCampaignsFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }

  return state
}
