import styled from 'styled-components'

export const AddExtraPunchCardShiftDetailsWrapper = styled.div`
  border-radius: ${({ theme: { mp } }) => mp.xs};
  padding: ${({ theme: { mp } }) => mp.xs};
  border: 2px solid ${({ theme: { palette } }) => palette.light};
  box-shadow: ${({ theme: { shadow } }) => shadow.sm};
  margin-bottom: ${({ theme: { mp } }) => mp.md};

  & > div {
    margin-bottom: ${({ theme: { mp } }) => mp.xs};
  }
`
