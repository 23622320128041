import styled from 'styled-components'

export const FooterWrapper = styled.div`
  background: ${({ theme: { palette } }) => palette.white};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: ${({ theme: { mp } }) => `${mp.md}`};
`

export const BtnWrapper = styled.div`
  display: block;
  margin-left: ${({ theme: { mp } }) => `${mp.md}`};
`
