import { Action } from 'redux'
import { isType } from 'ts-action'
import {
  GetNotes,
  GetNotesErrors,
  NotesFetching,
  AddNote,
  UpdateNote,
  AddNoteLoading,
  UpdateNoteLoading,
  ResetNotesReducer,
  UpdateNoteStopLoading,
} from './actions'
import { INotesReducer, INote } from 'models/Note'
import { updateArray } from 'helpers/helperFunctions'

const initialState: INotesReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: [],
  addNoteLoading: false,
  updateNoteLoadingIds: [],
}

export const notesReducer = (state = initialState, action: Action): INotesReducer => {
  if (isType(action, GetNotes)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
    }
  }
  if (isType(action, AddNote)) {
    const { payload } = action
    return {
      ...state,
      data: [payload, ...state.data],
    }
  }
  if (isType(action, UpdateNote)) {
    const { payload } = action
    const updatedNotes = updateArray<INote>(state.data, payload)
    return {
      ...state,
      data: updatedNotes,
    }
  }
  if (isType(action, GetNotesErrors)) {
    const { payload } = action
    return {
      ...state,
      error: {
        code: payload.code,
        message: payload.message,
      },
    }
  }
  if (isType(action, NotesFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }
  if (isType(action, AddNoteLoading)) {
    const { payload } = action
    return {
      ...state,
      addNoteLoading: payload,
    }
  }
  if (isType(action, UpdateNoteLoading)) {
    const { payload } = action
    return {
      ...state,
      updateNoteLoadingIds: [...state.updateNoteLoadingIds, payload],
    }
  }
  if (isType(action, UpdateNoteStopLoading)) {
    const { payload } = action
    const updatedLoadingIds = state.updateNoteLoadingIds.filter((id) => id !== payload)
    return {
      ...state,
      updateNoteLoadingIds: updatedLoadingIds,
    }
  }
  if (isType(action, ResetNotesReducer)) {
    return initialState
  }
  return state
}
