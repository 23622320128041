import { AxiosService } from '../config/config'
import { Endpoints } from '../config/endpoints'
import { IPayload } from 'models/Global'
import {
  IIndustry,
  IGetIndustriesParams,
  IPostIndustryParams,
  IPutIndustryParams,
} from 'models/Industry'

export class IndustriesApi {
  constructor(private axios: AxiosService) {}

  getIndustries = async (params?: IGetIndustriesParams) => {
    const response = await this.axios.get<IPayload<IIndustry[]>>(Endpoints.getIndustries, params)
    return response
  }

  getIndustry = async (id: number) => {
    const response = await this.axios.get<IPayload<IIndustry>>(Endpoints.getIndustry(id))
    return response
  }

  postIndustry = async (params: IPostIndustryParams) => {
    const response = await this.axios.post<IPayload<IIndustry>>(Endpoints.postIndustry, params)
    return response
  }

  putIndustry = async (id: number, params: IPutIndustryParams) => {
    const response = await this.axios.put<IPayload<IIndustry>>(Endpoints.putIndustry(id), params)
    return response
  }

  deleteIndustry = async (id: number) => {
    const response = await this.axios.delete<{}>(Endpoints.deleteIndustry(id))
    return response
  }
}
