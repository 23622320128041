import { Action } from 'redux'
import { isType } from 'ts-action'
import { SetIsDisabledTimesheetHotkeys } from './actions'

interface ITimesheetHotkeysReducer {
  isDisabledTimesheetHotkeys: boolean
}

const initialState: ITimesheetHotkeysReducer = {
  isDisabledTimesheetHotkeys: false,
}

export const timesheetHotkeysReducer = (
  state = initialState,
  action: Action
): ITimesheetHotkeysReducer => {
  if (isType(action, SetIsDisabledTimesheetHotkeys)) {
    const { payload } = action
    return {
      ...state,
      isDisabledTimesheetHotkeys: payload,
    }
  }
  return state
}
