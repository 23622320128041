import { action, payload } from 'ts-action'
import { IFollow } from 'models/Follow'
import { IPayload, IError } from 'models/Global'
import { IProperty } from 'models/Property'

export const GetProperty = action('GetProperty', payload<IPayload<IProperty>>())

export const PropertyErrors = action('PropertyErrors', payload<IError>())

export const PropertyFetching = action('PropertyFetching', payload<boolean>())

export const UpdateProperty = action('UpdateProperty', payload<IPayload<IProperty>>())

export const UpdatePropertyFollowedByUser = action(
  'UpdatePropertyFollowedByUser',
  payload<IPayload<IFollow>>()
)

export const UpdatePropertyAttachment = action(
  'UpdatePropertyAttachment',
  payload<IPayload<IProperty>>()
)

export const ClearPropertyReducer = action('ClearPropertyReducer')
