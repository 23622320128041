import styled from 'styled-components'

export const Wrap = styled.div`
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial,
    sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`

export const Title = styled.h1`
  font-size: 40px;
  font-weight: 400;
  margin-bottom: 10px;
`

export const Subtitle = styled.h5`
  font-size: 16px;
  color: #aaa;
  font-weight: 400;
  margin: 0px;
`

export const Button = styled.a`
  margin-top: 20px;
  position: relative;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  border-radius: 4px;
  overflow: hidden;
  background: #03a9f4;
  color: white;
  padding: 10px 30px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 14px;
  text-transform: uppercase;
  transition-duration: 0.1s;
  border: 0;
  outline: 0;
  :hover {
    text-decoration: none;
    background: #0298e3;
  }
`
