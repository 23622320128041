import React, { Component } from 'react'
import {
  HeaderWrapper,
  HeaderLogo,
  HeaderUser,
  HeaderLogoImg,
  HeaderUserName,
  HeaderUserAvatar,
  HeaderMenu,
  HeaderMenuItem,
  MobileMenu,
  MenuImg,
  MobileMenuItem,
  HeaderTitle,
  UserInfoName,
  MenuButton,
  UserInfoEmail,
  DrawerContentWrapper,
  MenuIconWrapper,
} from './style'
// import Logo from '../../../../img/logo_blackblue_transparent.png'
// import UpshiftersImg from '../../../../img/Upshifter.svg'
// import LogoutImg from '../../../../img/Logout.svg'
import { Avatar, Drawer, Colors, Divider } from '@pinchenterprisesnpm/friday-ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons'
import { isMobile } from 'helpers/helperFunctions'
import AuthService from 'services/AuthService'
import { history } from 'helpers/history'
import { connect } from 'react-redux'
import { LoginActions } from 'data/Auth/thunks'
import { ThunkDispatch } from 'redux-thunk'
import { bindActionCreators } from 'redux'
import Auth from 'data/Auth'
import { AppState } from 'store/store'
import { selectAuthUserRole } from 'data/Auth/selectors'
import { isAdminUserByRoleName } from 'helpers/authHelpers'
import { faUserFriends } from '@fortawesome/pro-light-svg-icons'

const { Content: DrawerContent } = Drawer

const Logo =
  'https://upshift-staging-monday.s3.amazonaws.com/static-assets/logo_blackblue_transparent.png'
const UpshifterImg = 'https://upshift-staging-monday.s3.amazonaws.com/static-assets/Upshifter.svg'
const LogoutImg = 'https://upshift-staging-monday.s3.amazonaws.com/static-assets/Logout.svg'
type THeaderProps = ILinkDispatchProps & ILinkStateProps
interface THeaderState {
  isOpen: boolean
}
class Header extends Component<THeaderProps, THeaderState> {
  state = {
    isOpen: false,
  }

  userNameRef: HTMLDivElement | null = null

  toggle = () => {
    this.setState((state) => ({ isOpen: !state.isOpen }))
  }

  close = () => {
    this.setState({ isOpen: false })
  }

  mobile = () => {
    const { logout, userRole } = this.props
    const { isOpen } = this.state
    const loggedInUser = AuthService.getLoggedInUser()
    return (
      <>
        <HeaderLogo>
          <MenuButton onClick={this.toggle}>
            <FontAwesomeIcon icon={faBars as any} size='lg' />
          </MenuButton>
        </HeaderLogo>
        <HeaderTitle>
          <span>Upshifters</span>
        </HeaderTitle>
        <Drawer
          placement='left'
          backdropStyles={{ top: '50px' }}
          isOpen={isOpen}
          toggleDrawer={this.toggle}
          closeDrawer={this.close}
          width='300px'
        >
          <DrawerContent>
            <DrawerContentWrapper>
              <div>
                <UserInfoName>{loggedInUser?.full_name}</UserInfoName>
                <UserInfoEmail>{loggedInUser?.email}</UserInfoEmail>
                <Divider type='horizontal' />
              </div>
              <MobileMenu>
                <MobileMenuItem color={Colors['dark']} to='/onboarder/upshifter_list'>
                  <MenuImg src={UpshifterImg} role='presentation' alt='upshifters' />
                  Upshifters
                </MobileMenuItem>
                {isAdminUserByRoleName(userRole) ? (
                  <>
                    <Divider type='horizontal' />
                    <MobileMenuItem color={Colors['dark']} to='/shifts'>
                      <MenuIconWrapper>
                        <FontAwesomeIcon icon={faUserFriends} size='lg' />
                      </MenuIconWrapper>
                      Back to Admin
                    </MobileMenuItem>
                  </>
                ) : null}
                <Divider type='horizontal' />
                <MobileMenuItem color={Colors['danger']} to='/logout' onClick={logout}>
                  <MenuImg src={LogoutImg} role='presentation' alt='logout' />
                  Logout
                </MobileMenuItem>
              </MobileMenu>
            </DrawerContentWrapper>
          </DrawerContent>
        </Drawer>
      </>
    )
  }

  desktop = () => {
    const { logout, userRole } = this.props
    const { isOpen } = this.state
    const loggedInUser = AuthService.getLoggedInUser()
    const icon = isOpen ? faChevronUp : faChevronDown
    return (
      <>
        <HeaderLogo>
          <HeaderLogoImg
            src={Logo}
            alt='logo'
            onClick={() => history.push('/onboarder/upshifter_list')}
          />
        </HeaderLogo>
        <HeaderUser onClick={this.toggle} ref={(node) => (this.userNameRef = node)}>
          <HeaderUserName>
            {loggedInUser?.full_name} <FontAwesomeIcon icon={icon as any} size='xs' />
          </HeaderUserName>
          <HeaderUserAvatar>
            <Avatar
              src='https://i.pinimg.com/originals/51/f6/fb/51f6fb256629fc755b8870c801092942.png'
              size='small'
            />
          </HeaderUserAvatar>
          {isOpen && (
            <HeaderMenu>
              {isAdminUserByRoleName(userRole) ? (
                <HeaderMenuItem onClick={() => history.push('/shifts')}>
                  <MenuIconWrapper>
                    <FontAwesomeIcon icon={faUserFriends} size='lg' />
                  </MenuIconWrapper>
                  Back to Admin
                </HeaderMenuItem>
              ) : null}
              <HeaderMenuItem onClick={logout}>
                <MenuImg src={LogoutImg} role='presentation' alt='logout' />
                <span>Logout</span>
              </HeaderMenuItem>
            </HeaderMenu>
          )}
        </HeaderUser>
      </>
    )
  }

  closingListener = (e: Event) => {
    if (this.userNameRef) {
      if (!this.userNameRef.contains(e.target as Node)) {
        this.close()
      }
    }
  }

  componentDidMount() {
    document.addEventListener('click', this.closingListener)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.closingListener)
  }

  render() {
    return <HeaderWrapper>{isMobile() ? this.mobile() : this.desktop()}</HeaderWrapper>
  }
}

interface ILinkStateProps {
  userRole: string
}

interface ILinkDispatchProps {
  logout: typeof Auth.thunks.logout
}

const mapStateToProps = (state: AppState): ILinkStateProps => ({
  userRole: selectAuthUserRole(state),
})

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, LoginActions>
): ILinkDispatchProps => ({
  logout: bindActionCreators(Auth.thunks.logout, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(Header)
