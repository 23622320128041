import { Button, ColorTypes, Modal } from '@pinchenterprisesnpm/friday-ui'
import React from 'react'
import { MessageModalContentWrapper, MessageModalFooterWrapper } from './style'

const noop = () => {}

export type TConfirmModalConfig = {
  onConfirm: () => void
  onCancel: () => void
  onClose: () => void
  title: React.ReactNode
  message: React.ReactNode
  height: number | string
  width: number | string
  shouldWaitPromise: boolean
  cancelButtonText: string
  confirmButtonText: string
  isLoading: boolean
  primaryButtonType?: ColorTypes
  primaryFilledOutlined?: boolean
  secondaryButtonType?: ColorTypes
  secondaryFilledOutlined?: boolean
}

/**
 * Generic confirm modal
 *
 * @param config Config for the confirmation modal
 */
export const confirmModal = (config: Partial<TConfirmModalConfig> = {}) => {
  const {
    onConfirm = noop,
    onCancel = noop,
    onClose = noop,
    title = 'Attention required',
    message = "You will lose the changes you've made. Are you sure you want to continue?",
    height = 150,
    width = 'unset',
    shouldWaitPromise = true,
    cancelButtonText = 'Cancel',
    confirmButtonText = 'Confirm',
    isLoading = false,
    primaryButtonType = 'success',
    primaryFilledOutlined = false,
    secondaryButtonType = 'danger',
    secondaryFilledOutlined = false,
  } = config

  Modal.dispatch({
    onCancel: () => {},
    onConfirm: () => {},
    onClose,
    title,
    height,
    width,
    content: () => <MessageModalContentWrapper>{message}</MessageModalContentWrapper>,
    footer: ({ closeModal }) => (
      <MessageModalFooterWrapper>
        <Button
          onClick={() => {
            /* No guarantees of deterministic execution of the onCancel callback if its async now, refactor to support that as well */
            onCancel()
            closeModal()
          }}
          isLoading={isLoading}
          isDisabled={isLoading}
          type={secondaryButtonType}
          isFilledOutlined={secondaryFilledOutlined}
        >
          {cancelButtonText}
        </Button>
        <Button
          type={primaryButtonType}
          onClick={() => {
            /* No guarantees of deterministic execution of the onConfirm callback if its async now, refactor to support that as well */
            if (shouldWaitPromise) {
              const promise = (onConfirm as () => Promise<any>)()
              if (promise && promise.then) {
                promise.then(closeModal)
              } else {
                closeModal()
              }
            } else {
              onConfirm()
              closeModal()
            }
          }}
          isLoading={isLoading}
          isDisabled={isLoading}
          isFilledOutlined={primaryFilledOutlined}
        >
          {confirmButtonText}
        </Button>
      </MessageModalFooterWrapper>
    ),
  })
}
