import { AxiosService } from '../config/config'
import { Endpoints } from '../config/endpoints'
import { IPayload } from 'models/Global'
import {
  ICreditCard,
  IGetCreditCardsParams,
  IPutCreditCardParams,
  IPostCreditCardParams,
} from 'models/CreditCard'

export class CreditCardsApi {
  constructor(private axios: AxiosService) {}

  getCreditCards = async (params?: IGetCreditCardsParams) => {
    const response = await this.axios.get<IPayload<ICreditCard[]>>(Endpoints.getCreditCards, params)
    return response
  }

  getCreditCard = async (id: number, params?: IGetCreditCardsParams) => {
    const response = await this.axios.get<IPayload<ICreditCard>>(
      Endpoints.getCreditCard(id),
      params
    )
    return response
  }

  postCreditCard = async (params: IPostCreditCardParams) => {
    const response = await this.axios.post<IPayload<ICreditCard>>(Endpoints.postCreditCard, params)
    return response
  }

  putCreditCard = async (id: number, params?: IPutCreditCardParams) => {
    const response = await this.axios.put<IPayload<ICreditCard>>(
      Endpoints.putCreditCard(id),
      params
    )
    return response
  }

  deleteCreditCard = async (id: number) => {
    const response = await this.axios.delete<{}>(Endpoints.deleteCreditCard(id))
    return response
  }
}
