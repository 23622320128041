import { action, payload } from 'ts-action'
import { IGigDay } from 'models/GigDay'
import { IError, IPayload } from 'models/Global'

export const GetSelectedShiftGigDays = action(
  'GetSelectedShiftGigDays',
  payload<IPayload<IGigDay[]>>()
)

export const GetSelectedShiftGigDaysErrors = action(
  'GetSelectedShiftGigDaysErrors',
  payload<IError>()
)

export const SelectedShiftGigDaysFetching = action(
  'SelectedShiftGigDaysFetching',
  payload<boolean>()
)

export const ResetSelectedShiftGigDays = action('ResetSelectedShiftGigDays')
