import { action, payload } from 'ts-action'
import { IError, IPayload } from 'models/Global'
import { ITimesheet, IUpdateApplicationBlockPropertiesCountPayload } from 'models/Timesheet'
import { IProperty } from 'models/Property'
import { IShift } from 'models/Shift'
import { IPunchCard } from 'models/PunchCard'
import { IApplication } from 'models/Application'
import { IGigDayExtended } from 'models/GigDay'
import { IBonus, IDeleteBonusParams } from 'models/Bonus'
import { IInstruction } from 'models/Instruction'

// Get timesheet

export const GetTimesheet = action('GetTimesheet', payload<IPayload<ITimesheet>>())

export const GetTimesheetErrors = action('GetTimesheetErrors', payload<IError>())

export const TimesheetFetching = action('TimesheetFetching', payload<boolean>())

export const UpdateTimesheet = action('UpdateTimesheet', payload<IPayload<ITimesheet>>())

// Get property

export const GetProperty = action('GetProperty', payload<IPayload<IProperty>>())

export const GetPropertyErrors = action('GetPropertyErrors', payload<IError>())

export const PropertyFetching = action('PropertyFetching', payload<boolean>())

// Get shifts

export const GetTimesheetShifts = action('GetTimesheetShifts', payload<IPayload<IShift[]>>())

export const GetShiftsErrors = action('GetShiftsErrors', payload<IError>())

export const ShiftsFetching = action('ShiftsFetching', payload<boolean>())

// GET punchcards

export const GetPunchCardsForTimesheet = action(
  'GetPunchCardsForTimesheet',
  payload<IPayload<IPunchCard[]>>()
)

export const GetPunchCardsErrors = action('GetPunchCardsErrors', payload<IError>())

export const PunchCardsFetching = action('PunchCardFetching', payload<boolean>())

// POST

export const AddTimesheetPunchCardErrors = action('AddTimesheetPunchCardErrors', payload<IError>())

export const AddTimesheetPunchCard = action(
  'AddTimesheetPunchCard',
  payload<IPayload<IPunchCard>>()
)

// PUT

export const UpdateTimesheetPunchCard = action(
  'UpdateTimesheetPunchCard',
  payload<IPayload<IPunchCard>>()
)

export const UpdateTimesheetPunchCardErrors = action(
  'UpdateTimesheetPunchCardErrors',
  payload<IError>()
)

// DELETE

export const DeleteTimesheetPunchCard = action('DeleteTimesheetPunchCard', payload<number>())

export const DeleteTimesheetPunchCardErrors = action(
  'DeleteTimesheetPunchCardErrors',
  payload<IError>()
)

// GET bonuses

export const GetBonusesForTimesheet = action(
  'GetBonusesForTimesheet',
  payload<IPayload<IBonus[]>>()
)

export const GetBonusesErrors = action('GetBonusesErrors', payload<IError>())

export const BonusesFetching = action('BonusesFetching', payload<boolean>())

// POST

export const AddBonusErrors = action('AddBonusErrors', payload<IError>())

export const AddBonus = action('AddBonus', payload<IPayload<IBonus>>())

// PUT

export const UpdateBonus = action('UpdateBonus', payload<IPayload<IBonus>>())

export const UpdateBonusErrors = action('UpdateBonusErrors', payload<IError>())

// DELETE

export const DeleteBonus = action('DeleteBonus', payload<IDeleteBonusParams>())

// Get applications

export const GetApplications = action('GetApplications', payload<IPayload<IApplication[]>>())

export const GetApplicationsErrors = action('GetApplicationsErrors', payload<IError>())

export const ApplicationsFetching = action('ApplicationsFetching', payload<boolean>())

export const AddApplication = action('AddApplication', payload<IPayload<IApplication>>())

export const AddApplicationErrors = action('AddApplicationErrors', payload<IError>())

export const FullTimesheetFetching = action('ApplicationsFetching', payload<boolean>())

export const ClearTimesheet = action('ClearTimesheet')

export const GetGigDays = action('GetGigDays', payload<IPayload<IGigDayExtended[]>>())

export const GetGigDaysErrors = action('GetGigDaysErrors', payload<IError>())

export const GigDaysFetching = action('GigDaysFetching', payload<boolean>())

export const UpdateBusinessInstruction = action(
  'UpdateBusinessInstruction',
  payload<IInstruction>()
)

export const UpdateApplicationBlockPropertiesCount = action(
  'UpdateApplicationBlockPropertiesCount',
  payload<IUpdateApplicationBlockPropertiesCountPayload>()
)
