import { IPositionCategory } from '../../models/PositionCategory'
import { Action } from 'redux'
import { isType } from 'ts-action'
import { updateArray } from '../../helpers/helperFunctions'
import {
  GetPositionCategories,
  UpdatePositionCategory,
  PositionCategoriesFetching,
  AddPositionCategory,
} from './actions'
import { IPositionCategoriesReducer } from 'models/PositionCategory'

const initialState: IPositionCategoriesReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: [],
}

export const positionCategoriesReducer = (
  state = initialState,
  action: Action
): IPositionCategoriesReducer => {
  if (isType(action, GetPositionCategories)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
    }
  }
  if (isType(action, UpdatePositionCategory)) {
    const { payload } = action
    const { data } = state
    const formattedArray = updateArray<IPositionCategory>(data, payload.data)
    return {
      ...state,
      data: formattedArray,
    }
  }

  if (isType(action, AddPositionCategory)) {
    const { payload } = action
    return {
      ...state,
      data: [payload.data, ...state.data],
    }
  }

  if (isType(action, PositionCategoriesFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }
  return state
}
