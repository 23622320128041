import { IRoleData } from 'models/Auth'
import { IBaseReducer } from '../BaseReducer'
import { TypeOrNull } from '../Common'
import { IBaseGetParams } from '../Global'
import { IShift } from '../Shift'
import { IStrikeType } from '../StrikeType'

export enum STRIKE_STATUS {
  REVOKED,
  ACTIVE,
  EXPIRED,
  PENDING,
}

export enum STRIKE_SOURCE {
  OTHER,
  AUTOMATED,
  EMAIL,
  PHONE,
  INTERCOM,
  BLOCK,
}

export interface IStrikeIncludeParams {
  include?:
    | {
        revoking_challenge_completed_shifts_count?: Record<any, any>
        revoked_by_role?: Record<any, any>
      }
    | ('revoked_by_role' | 'revoking_challenge_completed_shifts_count')[]
}
export interface IStrike {
  id: number
  strike_type: IStrikeType
  reason_assigned: string
  reason_revoked: string | null
  gig: IShift
  status: STRIKE_STATUS
  created_at: string
  date_of_assignment: string
  revoked_at: string | null
  attachments: string[]
  source: STRIKE_SOURCE
  source_value: string
  revoked_by_role?: IRoleData
}

export interface IGetStrikesParams extends IGetStrikesParamsBase, IStrikeIncludeParams {
  revoked_by_role?: string
}

export interface IGetStrikesParamsBase extends IBaseGetParams {
  user_id?: number
  status?: STRIKE_STATUS
  gig_id?: number
  strike_type_ids?: number[]
  statuses?: STRIKE_STATUS[]
}

export interface IPostStrikeParams {
  user_id: number
  strike_type_id?: number
  date_of_assignment?: string
  reason_assigned?: string
  gig_id?: number
  attachment?: File
  strike_types?: IStrikeTypeForPost[]
  source?: STRIKE_SOURCE
  source_value?: string
}

export interface IPutStrikeParams {
  reason_assigned?: string
  reason_revoked?: string
  status?: STRIKE_STATUS
  date_of_assignment?: string
  gig_id?: TypeOrNull<number>
  attachment?: File
  source?: STRIKE_SOURCE
  source_value?: string
}

export interface IStrikeReducer extends IBaseReducer<IStrike[]> {}

export interface IStrikeTypeForPost {
  strike_type_id: number
  reason_assigned: string
  gig_id: TypeOrNull<number>
  date_of_assignment: string
  source: TypeOrNull<STRIKE_SOURCE>
  source_value: string
  attachment?: File
}

export interface IAssignStrikesResponseData {
  id: number
  strike_type: IStrikeType
  reason_assigned: string
  reason_revoked: string
  gig: TypeOrNull<IShift>
  status: STRIKE_STATUS
  created_at: string
  date_of_assignment: string
  revoked_at: TypeOrNull<string>
  attachments: string[]
  source: STRIKE_SOURCE
  source_value: string
}
