import { action, payload } from 'ts-action'
import { IError, IPayload } from 'models/Global'
import { IApplication } from 'models/Application'

export const GetSelectedShiftPendingApplications = action(
  'GetSelectedShiftPendingApplications',
  payload<IPayload<IApplication[]>>()
)

export const GetSelectedShiftPendingApplicationsErrors = action(
  'GetSelectedShiftPendingApplicationsErrors',
  payload<IError>()
)

export const SelectedShiftPendingApplicationsFetching = action(
  'SelectedShiftPendingApplicationsFetching',
  payload<boolean>()
)

export const AddSelectedShiftPendingApplication = action(
  'AddSelectedShiftPendingApplication',
  payload<IPayload<IApplication>>()
)

export const UpdateSelectedShiftPendingApplication = action(
  'UpdateSelectedShiftPendingApplication',
  payload<IPayload<IApplication>>()
)

export const DeleteSelectedShiftPendingApplication = action(
  'DeleteSelectedShiftPendingApplication',
  payload<number>()
)

export const ResetSelectedShiftPendingApplicationsReducer = action(
  'ResetSelectedShiftPendingApplicationsReducer'
)
