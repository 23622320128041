import { CERTIFICATION_REQUESTS } from 'models/Permission'
import ROUTES from 'routing/adminRoutes'
import { AsyncCertificationRequests } from 'ui/pages'
import AdminRouteLayout from '../../../Admin/AdminRouteLayout'
import AdminRoute from '../../AdminRoute'

const certificationRequestsRoutes = [
  <AdminRoute
    exact
    path={ROUTES.CERTIFICATION_REQUESTS}
    layout={AdminRouteLayout}
    component={AsyncCertificationRequests}
    requiredPermissions={[CERTIFICATION_REQUESTS.READ_CERTIFICATION_REQUESTS]}
    key={ROUTES.CERTIFICATION_REQUESTS}
  />,
]

export default certificationRequestsRoutes
