import { ISelectedShiftBusinessEntityReducer, IBusinessEntity } from 'models/BusinessEntity'
import { Action } from 'redux'
import {
  GetSelectedShiftBusinessEntityAction,
  SelectedShiftBusinessEntityFetching,
  GetSelectedShiftBusinessEntityErrors,
  UpdateSelectedShiftBusinessEntity,
  ClearSelectedShiftBusinessEntity,
  UpdateSelectedShiftBusinessEntitySetting,
} from './actions'
import { isType } from 'ts-action'

const initialState: ISelectedShiftBusinessEntityReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: {} as IBusinessEntity,
}

export const SelectedShiftBusinessEntityReducer = (
  state = initialState,
  action: Action
): ISelectedShiftBusinessEntityReducer => {
  if (isType(action, GetSelectedShiftBusinessEntityAction)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
    }
  }
  if (isType(action, UpdateSelectedShiftBusinessEntity)) {
    const { payload } = action
    return {
      ...state,
      data: payload.data,
    }
  }
  if (isType(action, GetSelectedShiftBusinessEntityErrors)) {
    const { payload } = action
    return {
      ...state,
      error: {
        code: payload.code,
        message: payload.message,
      },
    }
  }
  if (isType(action, SelectedShiftBusinessEntityFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }
  if (isType(action, ClearSelectedShiftBusinessEntity)) {
    return initialState
  }

  if (isType(action, UpdateSelectedShiftBusinessEntitySetting)) {
    const { payload } = action
    const { data } = state
    return {
      ...state,
      data: { ...data, business_setting: payload },
    }
  }
  return state
}
