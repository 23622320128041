export interface IModalConfig {
  title: string
  message: string
  onConfirm: () => void
  onCancel?: () => void
  isLoading?: boolean
  width?: string
}

interface IShowModalCallback {
  (config: IModalConfig): void
}

let showModalCallback: IShowModalCallback | null = null

export const registerShowModalCallback = (callback: IShowModalCallback): void => {
  showModalCallback = callback
}

export const showModal = (config: IModalConfig): void => {
  // Type check because showModalCallback can be null
  if (showModalCallback) {
    showModalCallback(config)
  } else {
    // eslint-disable-next-line no-console
    console.log('Show modal callback has not been registered.')
  }
}
