// Object of endpoints that are used across the app
export const Endpoints = {
  // Auth
  login: '/auth/login',
  logout: '/auth/logout',
  me: '/auth/me',
  validateToken: `/auth/validate`,
  refreshToken: `/auth/refresh`,

  // Password
  forgotPassword: '/password/forgot',
  verifyTwoFactorCode: (user_id: number) => `/auth/verify_two_factor/${user_id}`,
  resendVerifyCode: (user_id: number) => `/auth/resend_verify_code/${user_id}`,
  resetPassword: '/password/reset',
  changePassword: '/password/change',

  // Shifts
  getShifts: '/gigs',
  getShift: (id: number) => `/gigs/${id}`,
  postShift: '/gigs',
  putShift: (id: number) => `/gigs/${id}`,
  deleteShift: (id: number) => `/gigs/${id}`,

  // PunchCards
  getPunchCards: '/punch_cards',
  getPunchCard: (id: number) => `/punch_cards/${id}`,
  postPunchCard: `/punch_cards`,
  putPunchCard: (id: number) => `punch_cards/${id}`,
  deletePunchCard: (id: number) => `punch_cards/${id}`,
  transferPunchCard: (id: number) => `/punch_cards/${id}/transfer`,

  // Users
  getUsers: '/users',
  getUser: (id: number) => `/users/${id}`,
  postUser: '/users',
  putUser: (id: number) => `/users/${id}`,
  deleteUser: (id: number) => `/users/${id}`,
  getUserSickPayDayHourStats: (id: number) => `/users/${id}/sick_pay_day_hours`,
  updateUserAvatar: (id: number) => `/users/${id}/avatar`,
  checkUserPhoneType: `/users/phone_type`,

  // Properties
  getProperties: '/properties',
  getPropertyAddressInfo: '/properties/address_info',
  getProperty: (id: number) => `/properties/${id}`,
  postProperty: '/properties',
  putProperty: (id: number) => `/properties/${id}`,
  deleteProperty: (id: number) => `/properties/${id}`,

  // Business Entities
  getBusinessEntities: '/businesses',
  getBusinessEntity: (id: number) => `/businesses/${id}`,
  postBusinessEntity: '/businesses',
  updateBusinessEntity: (id: number) => `/businesses/${id}`,
  deleteBusinessEntity: (id: number) => `/businesses/${id}`,
  putBusinessEntitySalesReactivation: (id: number) => `/businesses/${id}/sales_reactivation`,

  // Regions
  getRegions: '/regions',
  getRegion: (id: number) => `/regions/${id}`,
  postRegion: '/regions',
  putRegion: (id: number) => `/regions/${id}`,
  deleteRegion: (id: number) => `/regions/${id}`,

  // Industries
  getIndustries: '/industries',
  getIndustry: (id: number) => `/industries/${id}`,
  postIndustry: '/industries',
  putIndustry: (id: number) => `/industries/${id}`,
  deleteIndustry: (id: number) => `/industries/${id}`,

  // Cities
  getCities: '/cities',
  getCity: (id: number) => `/cities/${id}`,
  postCity: '/cities',
  putCity: (id: number) => `/cities/${id}`,
  deleteCity: (id: number) => `/cities/${id}`,

  // States
  getStates: '/states',
  getState: (id: number) => `/states/${id}`,
  postState: '/states',
  putState: (id: number) => `/states/${id}`,
  deleteState: (id: number) => `/states/${id}`,

  // Countries
  getCountries: '/countries',
  getCountry: (id: number) => `/countries/${id}`,
  putCountry: (id: number) => `/countries/${id}`,
  postCountry: '/countries',
  deleteCountry: (id: number) => `/countries/${id}`,

  // Counties
  getCounties: '/counties',
  getCounty: (id: number) => `/counties/${id}`,
  postCounty: `/counties`,
  putCounty: (id: number) => `/counties/${id}`,
  deleteCounty: (id: number) => `/counties/${id}`,

  // Minimum Wages
  getMinimumWages: '/minimum_wages',
  getMinimumWage: (id: number) => `/minimum_wages/${id}`,
  postMinimumWage: '/minimum_wages',
  putMinimumWage: (id: number) => `/minimum_wages/${id}`,
  deleteMinimumWage: (id: number) => `/minimum_wages/${id}`,

  // Net Options
  getNetOptions: '/net_options',
  getNetOption: (id: number) => `/net_options/${id}`,
  postNetOption: '/net_options',
  putNetOption: (id: number) => `/net_options/${id}`,
  deleteNetOption: (id: number) => `/net_options/${id}`,

  // Notes
  getNotes: '/notes',
  getNote: (id: number) => `/notes/${id}`,
  postNote: '/notes',
  putNote: (id: number) => `/notes/${id}`,
  deleteNote: (id: number) => `notes/${id}`,

  // Timesheets
  getTimesheets: '/timesheets',
  getTimesheet: (id: number) => `/timesheets/${id}`,
  postTimesheet: '/timesheets',
  putTimesheet: (id: number) => `/timesheets/${id}`,
  deleteTimesheet: (id: number) => `/timesheets/${id}`,
  getTimesheetCSVReport: (id: number) => `/timesheets/generate_csv/${id}`,

  // Invoices
  getInvoices: '/invoices',
  getInvoice: (id: number) => `/invoices/${id}`,
  postInvoice: '/invoices',
  payInvoice: (id: number) => `/invoices/${id}/pay`,
  putInvoice: (id: number) => `/invoices/${id}`,
  deleteInvoice: (id: number) => `/invoices/${id}`,
  processAllInvoices: '/invoices/mark_all_processed',
  sendInvoiceByEmail: (id: number) => `/invoices/${id}`,
  sendBulkEmail: '/invoices/mark_all_sent',
  getTotalOpenBalance: '/invoices/total_open_balance',

  getInvoicesStatement: '/invoices/statement',
  // ExpenseTypes
  getExpenseTypes: '/expense_types',
  getExpenseType: (id: number) => `/expense_types/${id}`,
  postExpenseType: '/expense_types',
  putExpenseType: (id: number) => `/expense_types/${id}`,
  deleteExpenseType: (id: number) => `/expense_types/${id}`,

  // InvoiceExpenseTypes
  getInvoiceExpenseTypes: '/invoice_expense_types',
  getInvoiceExpenseType: (id: number) => `/invoice_expense_types/${id}`,
  postInvoiceExpenseType: '/invoice_expense_types',
  putInvoiceExpenseType: (id: number) => `/invoice_expense_types/${id}`,
  deleteInvoiceExpenseType: (id: number) => `/invoice_expense_types/${id}`,

  // Credits
  getCredits: '/credits',
  getCredit: (id: number) => `/credits/${id}`,
  postCredit: '/credits',
  putCredit: (id: number) => `/credits/${id}`,
  deleteCredit: (id: number) => `/credits/${id}`,

  // Invoice Groups
  getInvoiceGroups: '/invoice_groups',
  getInvoiceGroup: (id: number) => `/invoice_groups/${id}`,
  postInvoiceGroup: '/invoice_groups',
  putInvoiceGroup: (id: number) => `/invoice_groups/${id}`,
  deleteInvoiceGroup: (id: number) => `/invoice_groups/${id}`,

  // Reports
  getInvoiceZipReports: '/reports/invoices',
  getTimesheetsReport: '/reports/timesheets',
  getFinanceAgingReport: '/reports/finance_aging',
  getGrowthReport: '/reports/growth',
  getAMSuccessReport: '/reports/weekly_success',
  getWeeklyEmployeeReport: '/reports/weekly_employee',
  getPayrollReport: '/reports/payroll',
  postPayrollReport: '/reports/payroll',
  getAgingReport: '/reports/aging',
  getFakePunchCardsReport: '/reports/fake_punch_cards',
  getMonthlySalesReport: '/reports/sales_taxes',
  getAccountingReport: '/reports/accounting',
  getMultiplePunchCardReport: '/reports/multiple_punch_cards_for_the_same_day',
  getAccountManagersShiftsReport: '/reports/account_managers_shifts',
  getTerminatedAndReinstatedUpshiftersReport: '/reports/terminated_and_reinstated_upshifters',
  getPaymentApplicationReport: '/reports/payment_application',
  getAMKPIReport: '/reports/am_kpi',
  getARBillReport: '/reports/billed_ar',
  getCRBonusReport: '/reports/cr_bonus',
  getHighLevelStatsReport: '/reports/high_level_stats',
  getFoodbuyReport: '/reports/foodbuy_data_biweekly',
  getCompassApprovalReport: '/reports/compass_weekly_approval',
  getUpshifterHoursReport: '/reports/stats_by_date',
  getReturnRateReport: '/reports/return_rate',
  getExpiringDocumentsReport: '/reports/expiring_onboard_docs',
  getLocationsReport: '/reports/properties',
  getAccountBreakdownReport: '/reports/account_breakdown',
  getProcessedTimesheetReport: '/reports/processed_timesheet_report',
  getBusinessesReport: '/reports/businesses',
  getReviewsReport: '/reports/reviews_left_by_upshifters',
  getUpshiftersReport: '/reports/upshifters',

  // Attachments
  getAttachments: '/attachments',
  getAttachment: (id: number) => `/attachments/${id}`,
  postAttachment: '/attachments',
  putAttachment: (id: number) => `/attachments/${id}`,
  deleteAttachment: (id: number) => `/attachments/${id}`,

  // Attachment Titles
  getAttachmentTitles: '/attachment_titles',
  getAttachmentTitle: (id: number) => `/attachment_titles/${id}`,
  postAttachmentTitle: '/attachment_titles',
  putAttachmentTitle: (id: number) => `/attachment_titles/${id}`,
  deleteAttachmentTitle: (id: number) => `/attachment_titles/${id}`,

  // Documents
  getDocuments: '/documents',
  getDocument: (id: number) => `/documents/${id}`,
  postDocument: '/documents',
  putDocument: (id: number) => `/documents/${id}`,
  deleteDocument: (id: number) => `/documents/${id}`,

  // Acknowledged Documents
  getAcknowledgedDocuments: '/acknowledged_documents',
  getAcknowledgedDocument: (id: number) => `/acknowledged_documents/${id}`,
  getMultipleAcknowledgedDocuments: '/reports/download_acknowledged_docs',
  postAcknowledgedDocument: '/acknowledged_documents',
  putAcknowledgedDocument: (id: number) => `/acknowledged_documents/${id}`,
  deleteAcknowledgedDocument: (id: number) => `/acknowledged_documents/${id}`,

  // Sick Paid Leave Templates
  getSickLeaveTemplates: '/paid_sick_leave_templates',
  getSickLeaveTemplate: (id: number) => `/paid_sick_leave_templates/${id}`,
  postSickLeaveTemplate: '/paid_sick_leave_templates',
  putSickLeaveTemplate: (id: number) => `/paid_sick_leave_templates/${id}`,
  deleteSickLeaveTemplate: (id: number) => `/paid_sick_leave_templates/${id}`,

  // Favorites
  getFavorites: '/favorites',
  getFavorite: (id: number) => `/favorites/${id}`,
  postFavorite: '/favorites',
  putFavorite: (id: number) => `/favorites/${id}`,
  deleteFavorite: (id: number) => `/favorites/${id}`,

  // Accrued Sick Hours
  getAccruedSickHours: '/accrued_sick_hours',
  getAccruedSickHour: (id: number) => `accrued_sick_hours/${id}`,
  postAccruedSickHour: '/accrued_sick_hours',
  putAccruedSickHour: (id: number) => `accrued_sick_hours/${id}`,
  deleteAccruedSickHour: (id: number) => `accrued_sick_hours/${id}`,
  updateSickHoursForUser: '/accrued_sick_hours/update_sick_hours',

  // Applications
  getApplications: '/applications',
  getApplication: (id: number) => `/applications/${id}`,
  postApplication: '/applications',
  putApplication: (id: number) => `/applications/${id}`,
  deleteApplication: (id: number) => `/applications/${id}`,
  getConfirmedApplicationsPdf: '/applications/confirmed',

  // Group Applications
  postGroupApplicationAccept: '/groups/applications/accept',
  postGroupApplicationDecline: '/groups/applications/decline',

  // Gig days
  getGigDays: '/gig_days',

  // User Attachments
  getUserAttachments: '/user_attachments',
  getUserAttachment: (id: number) => `/user_attachments/${id}`,
  postUserAttachment: '/user_attachments',
  putUserAttachment: (id: number) => `/user_attachments/${id}`,
  deleteUserAttachment: (id: number) => `/user_attachments/${id}`,

  // Certificates
  getCertificates: '/certificates',
  getCertificate: (id: number) => `/certificates/${id}`,
  postCertificate: `/certificates`,
  putCertificate: (id: number) => `/certificates/${id}`,
  deleteCertificate: (id: number) => `/certificates/${id}`,

  // CertificateOrganizations
  getCertificateOrganizations: '/certificate_organizations',
  getCertificateOrganization: (id: number) => `/certificate_organizations/${id}`,
  postCertificateOrganization: `/certificate_organizations`,
  putCertificateOrganization: (id: number) => `/certificate_organizations/${id}`,
  deleteCertificateOrganization: (id: number) => `/certificate_organizations/${id}`,

  // Groups
  getGroups: '/groups',
  getGroup: (id: number) => `/groups/${id}`,
  putGroup: (id: number) => `/groups/${id}`,
  deleteGroup: (id: number) => `/groups/${id}`,
  postGroupInvitation: '/group_invites',
  deleteGroupMember: (id: number) => `/group_members/${id}`,
  putGroupInvite: (id: number) => `/group_invites/${id}`,

  // UserCertificates
  getUserCertificates: '/user_certificates',
  getUserCertificate: (id: number) => `/user_certificates/${id}`,
  postUserCertificate: `/user_certificates`,
  putUserCertificate: (id: number) => `/user_certificates/${id}`,
  deleteUserCertificate: (id: number) => `/user_certificates/${id}`,

  // PropertyCategories
  getPropertyCategories: '/property_categories',
  getPropertyCategory: (id: number) => `/property_categories/${id}`,
  postPropertyCategory: `/property_categories`,
  putPropertyCategory: (id: number) => `/property_categories/${id}`,
  deletePropertyCategory: (id: number) => `/property_categories/${id}`,

  // Templates
  getTemplates: '/templates',
  getTemplate: (id: number) => `/templates/${id}`,
  postTemplate: `/templates`,
  putTemplate: (id: number) => `/templates/${id}`,
  deleteTemplate: (id: number) => `/templates/${id}`,

  // OnboardDocumentCategories
  getOnboardDocumentCategories: '/onboard_document_categories',
  getOnboardDocumentCategory: (id: number) => `/onboard_document_categories/${id}`,
  postOnboardDocumentCategory: `/onboard_document_categories`,
  putOnboardDocumentCategory: (id: number) => `/onboard_document_categories/${id}`,
  deleteOnboardDocumentCategory: (id: number) => `/onboard_document_categories/${id}`,

  // OnboardDocuments
  getOnboardDocuments: '/onboard_documents',
  getOnboardDocument: (id: number) => `/onboard_documents/${id}`,
  postOnboardDocument: `/onboard_documents`,
  putOnboardDocument: (id: number) => `/onboard_documents/${id}`,
  deleteOnboardDocument: (id: number) => `/onboard_documents/${id}`,

  // OnboardingWorkflows
  getOnboardingWorkflows: '/onboarding_workflows',
  getOnboardingWorkflow: (id: number) => `/onboarding_workflows/${id}`,
  postOnboardingWorkflow: `/onboarding_workflows`,
  putOnboardingWorkflow: (id: number) => `/onboarding_workflows/${id}`,
  deleteOnboardingWorkflow: (id: number) => `/onboarding_workflows/${id}`,

  // ReliabilityRatings
  getReliabilityRatings: '/reliability_ratings',
  getReliabilityRating: (id: number) => `/reliability_ratings/${id}`,
  postReliabilityRating: `/reliability_ratings`,
  putReliabilityRating: `/reliability_ratings/user`,
  deleteReliabilityRating: (id: number) => `/reliability_ratings/${id}`,

  // ScreeningTypes
  getScreeningTypes: '/screening_types',
  getScreeningType: (id: number) => `/screening_types/${id}`,
  postScreeningType: `/screening_types`,
  putScreeningType: (id: number) => `/screening_types/${id}`,
  deleteScreeningType: (id: number) => `/screening_types/${id}`,

  // Screenings
  getScreenings: '/screenings',
  getScreening: (id: number) => `/screenings/${id}`,
  postScreening: `/screenings`,
  putScreening: (id: number) => `/screenings/${id}`,
  deleteScreening: (id: number) => `/screenings/${id}`,

  // NotificationPreferences
  getNotificationPreferences: '/notification_preferences',
  getNotificationPreference: (id: number) => `/notification_preferences/${id}`,
  postNotificationPreference: `/notification_preferences`,
  putNotificationPreference: (id: number) => `/notification_preferences/${id}`,
  deleteNotificationPreference: (id: number) => `/notification_preferences/${id}`,

  // DeductionTypes
  getDeductionTypes: '/deduction_types',
  getDeductionType: (id: number) => `/deduction_types/${id}`,
  postDeductionType: `/deduction_types`,
  putDeductionType: (id: number) => `/deduction_types/${id}`,
  deleteDeductionType: (id: number) => `/deduction_types/${id}`,

  // Notifications
  getNotifications: '/notifications',
  getNotification: (id: number) => `/notifications/${id}`,
  postNotification: `/notifications`,
  putNotification: (id: number) => `/notifications/${id}`,
  deleteNotification: (id: number) => `/notifications/${id}`,

  // Blocks
  getBlocks: '/blocks',
  getBlock: (id: number) => `/blocks/${id}`,
  postBlock: `/blocks`,
  putBlock: (id: number) => `/blocks/${id}`,
  deleteBlock: (id: number) => `/blocks/${id}`,

  // Block Properties
  getBlockProperties: '/block_properties',
  getBlockPropertiesUsers: '/block_properties/users',
  postBlockProperties: '/block_properties',
  putBlockProperty: (id: number) => `/block_properties/${id}`,
  deleteBlockProperties: '/block_properties',

  // BackgroundChecks
  getBackgroundChecks: '/background_checks',
  getBackgroundCheck: (id: number) => `/background_checks/${id}`,
  postBackgroundCheck: `/background_checks`,
  putBackgroundCheck: (id: number) => `/background_checks/${id}`,
  deleteBackgroundCheck: (id: number) => `/background_checks/${id}`,

  // StrikeTypes
  getStrikeTypes: '/strike_types',
  getStrikeType: (id: number) => `/strike_types/${id}`,
  postStrikeType: `/strike_types`,
  putStrikeType: (id: number) => `/strike_types/${id}`,
  deleteStrikeType: (id: number) => `/strike_types/${id}`,

  // Strikes
  getStrikes: '/strikes',
  getStrike: (id: number) => `/strikes/${id}`,
  postStrike: `/strikes`,
  putStrike: (id: number) => `/strikes/${id}`,
  deleteStrike: (id: number) => `/strikes/${id}`,

  // Reviews
  getReviews: '/reviews',
  getReview: (id: number) => `/reviews/${id}`,
  postReview: `/reviews`,
  putReview: (id: number) => `/reviews/${id}`,
  deleteReview: (id: number) => `/reviews/${id}`,

  // Deductions
  getDeductions: '/deductions',
  getDeduction: (id: number) => `/deductions/${id}`,
  postDeduction: `/deductions`,
  putDeduction: (id: number) => `/deductions/${id}`,
  deleteDeduction: (id: number) => `/deductions/${id}`,

  // GigTypes
  getGigTypes: '/gig_types',
  getGigType: (id: number) => `/gig_types/${id}`,
  postGigType: `/gig_types`,
  putGigType: (id: number) => `/gig_types/${id}`,
  deleteGigType: (id: number) => `/gig_types/${id}`,

  // PropertyTypes
  getPropertyTypes: '/property_types',
  getPropertyType: (id: number) => `/property_types/${id}`,
  postPropertyType: `/property_types`,
  putPropertyType: (id: number) => `/property_types/${id}`,
  deletePropertyType: (id: number) => `/property_types/${id}`,

  // AutoAcceptCertificates
  getAutoAcceptCertificates: '/auto_accept_certificates',
  getAutoAcceptCertificate: (id: number) => `/auto_accept_certificates/${id}`,
  postAutoAcceptCertificate: `/auto_accept_certificates`,
  putAutoAcceptCertificate: (id: number) => `/auto_accept_certificates/${id}`,
  deleteAutoAcceptCertificate: (id: number) => `/auto_accept_certificates/${id}`,

  // SuggestedPayRates
  getSuggestedPayRates: '/suggested_pay_rates',
  getSuggestedPayRate: (id: number) => `/suggested_pay_rates/${id}`,
  postSuggestedPayRate: `/suggested_pay_rates`,
  putSuggestedPayRate: (id: number) => `/suggested_pay_rates/${id}`,
  deleteSuggestedPayRate: (id: number) => `/suggested_pay_rates/${id}`,

  // CreditCards
  getCreditCards: '/credit_cards',
  getCreditCard: (id: number) => `/credit_cards/${id}`,
  postCreditCard: `/credit_cards`,
  putCreditCard: (id: number) => `/credit_cards/${id}`,
  deleteCreditCard: (id: number) => `/credit_cards/${id}`,

  // CreditCategoryTypes
  getCreditCategoryTypes: '/credit_category_types',
  getCreditCategoryType: (id: number) => `/credit_category_types/${id}`,
  postCreditCategoryType: `/credit_category_types`,
  putCreditCategoryType: (id: number) => `/credit_category_types/${id}`,
  deleteCreditCategoryType: (id: number) => `/credit_category_types/${id}`,

  // FeaturedShiftCampaigns
  getFeaturedShiftCampaigns: '/featured_gigs_campaigns',
  getFeaturedShiftCampaign: (id: number) => `/featured_gigs_campaigns/${id}`,
  postFeaturedShiftCampaign: `/featured_gigs_campaigns`,
  putFeaturedShiftCampaign: (id: number) => `/featured_gigs_campaigns/${id}`,
  deleteFeaturedShiftCampaign: (id: number) => `/featured_gigs_campaigns/${id}`,

  // ActivityLog
  getActivityLogs: `/activity_logs`,
  getActivityLog: (id: number) => `/activity_logs/${id}`,
  getShiftEditHistoryActivityLogs: (id: number) => `/activity_logs/gig_edit_history/${id}`,

  // Experiences
  getExperiences: '/experiences',

  // GigType Experiences
  getGigTypeExperiences: '/experiences/gig_type_categories',

  // GigAutoAcceptCertificates
  getGigAutoAcceptCertificates: '/gig_auto_accept_certificates',
  getGigAutoAcceptCertificate: (id: number) => `/gig_auto_accept_certificates/${id}`,
  postGigAutoAcceptCertificate: `/gig_auto_accept_certificates`,
  putGigAutoAcceptCertificate: (id: number) => `/gig_auto_accept_certificates/${id}`,
  deleteGigAutoAcceptCertificate: (id: number) => `/gig_auto_accept_certificates/${id}`,

  // Blasts
  postBlast: `/blasts`,

  // TemplateAutoAcceptCertificates
  getTemplateAutoAcceptCertificates: '/template_auto_accept_certificates',
  getTemplateAutoAcceptCertificate: (id: number) => `/template_auto_accept_certificates/${id}`,
  postTemplateAutoAcceptCertificate: `/template_auto_accept_certificates`,
  putTemplateAutoAcceptCertificate: (id: number) => `/template_auto_accept_certificates/${id}`,
  deleteTemplateAutoAcceptCertificate: (id: number) => `/template_auto_accept_certificates/${id}`,

  // ShortLinks
  postShortLink: '/short_links',
  // PointsOfContact
  getPointsOfContact: '/point_of_contacts',
  getPointOfContact: (id: number) => `/point_of_contacts/${id}`,
  postPointOfContact: `/point_of_contacts`,
  putPointOfContact: (id: number) => `/point_of_contacts/${id}`,
  deletePointOfContact: (id: number) => `/point_of_contacts/${id}`,

  // UserPointsOfContact
  getUserPointsOfContact: '/user_point_of_contacts',
  getUserPointOfContact: (id: number) => `/user_point_of_contacts/${id}`,
  postUserPointOfContact: `/user_point_of_contacts`,
  putUserPointOfContact: (id: number) => `/user_point_of_contacts/${id}`,
  deleteUserPointOfContact: `/user_point_of_contacts`,

  // VaccinationForms
  getVaccinationForms: '/vaccination_forms',
  getVaccinationForm: (id: number) => `/vaccination_forms/${id}`,
  postVaccinationForm: `/vaccination_forms`,
  putVaccinationForm: (id: number) => `/vaccination_forms/${id}`,
  deleteVaccinationForm: (id: number) => `/vaccination_forms/${id}`,

  // VaccinationTypes
  getVaccinationTypes: '/vaccination_types',
  getVaccinationType: (id: number) => `/vaccination_types/${id}`,
  postVaccinationType: `/vaccination_types`,
  putVaccinationType: (id: number) => `/vaccination_types/${id}`,
  deleteVaccinationType: (id: number) => `/vaccination_types/${id}`,

  // Bonuses
  getBonuses: '/bonuses',
  getBonus: (id: number) => `/bonuses/${id}`,
  postBonus: `/bonuses`,
  putBonus: (id: number) => `/bonuses/${id}`,
  deleteBonus: (id: number) => `/bonuses/${id}`,

  // ExpenseTypeTax
  getExpenseTypeTaxes: '/expense_type_taxes',
  getExpenseTypeTax: (id: number) => `/expense_type_taxes/${id}`,
  postExpenseTypeTax: '/expense_type_taxes',
  putExpenseTypeTax: (id: number) => `/expense_type_taxes/${id}`,
  deleteExpenseTypeTax: (id: number) => `/expense_type_taxes/${id}`,

  // Timetracker
  getTimetrackers: '/timetracker_devices',
  getTimetracker: (id: number) => `/timetracker_devices/${id}`,
  putTimetracker: (id: number) => `/timetracker_devices/${id}`,
  deleteTimetracker: (id: number) => `/timetracker_devices/${id}`,
  generateTimetrackerCredentials: (id: number) =>
    `/timetracker_devices/generate_client_credentials/${id}`,
  getTimetrackersProperties: '/timetracker_device_properties',
  getTimetrackerProperties: (id: number) => `/timetracker_device_properties/${id}`,
  putTimetrackerProperties: (id: number) => `/timetracker_device_properties/${id}`,

  // PendingVerificationPunchCards
  getPendingVerificationPunchCards: '/pending_verification_punch_cards',
  getDeniedPendingVerificationPunchCards: '/pending_verification_punch_cards/denied',
  getPendingVerificationPunchCard: (id: number) => `/pending_verification_punch_cards/${id}`,
  postPendingVerificationPunchCard: `/pending_verification_punch_cards`,
  putPendingVerificationPunchCard: (id: number) => `/pending_verification_punch_cards/${id}`,
  deletePendingVerificationPunchCard: (id: number) => `/pending_verification_punch_cards/${id}`,
  reinstatePendingVerificationPunchCard: (id: number) =>
    `/pending_verification_punch_cards/reinstate/${id}`,

  // Follows
  getFollows: '/follows',
  getFollow: (id: number) => `/follows/${id}`,
  postFollow: `/follows`,
  putFollow: (id: number) => `/follows/${id}`,
  deleteFollow: (id: number) => `/follows/${id}`,

  // Shift Identifier Policies
  getShiftIdentifierPolicies: '/gig_identifier_policies',
  getShiftIdentifierPolicy: (id: number) => `/gig_identifier_policies/${id}`,
  postShiftIdentifierPolicy: `/gig_identifier_policies`,
  putShiftIdentifierPolicy: (id: number) => `/gig_identifier_policies/${id}`,
  deleteShiftIdentifierPolicy: (id: number) => `/gig_identifier_policies/${id}`,

  // Instruction
  getInstructions: '/instructions',
  getInstruction: (id: number) => `/instructions/${id}`,
  postInstruction: '/instructions',
  putInstruction: (id: number) => `/instructions/${id}`,
  deleteInstruction: (id: number) => `/instructions/${id}`,

  // Universal Templates
  getUniversalTemplates: '/universal_templates',
  getUniversalTemplate: (id: number) => `/universal_templates/${id}`,
  postUniversalTemplate: `/universal_templates`,
  putUniversalTemplate: (id: number) => `/universal_templates/${id}`,
  deleteUniversalTemplate: (id: number) => `/universal_templates/${id}`,
  assignUniversalTemplate: (id: number) => `/universal_templates/assign/${id}`,

  // Challenges
  getChallenges: '/challenges',
  getChallenge: (id: number) => `/challenges/${id}`,
  postChallenge: `/challenges`,
  putChallenge: (id: number) => `/challenges/${id}`,
  deleteChallenge: (id: number) => `/challenges/${id}`,

  // Job Categorization
  getPositionCategories: '/gig_type_categories',
  putPositionCategory: (id: number) => `/gig_type_categories/${id}`,
  postPositionCategory: '/gig_type_categories',
  //Achievements
  getAchievements: '/achievements',
  getAchievement: (id: number) => `/achievements/${id}`,
  postAchievement: '/achievements',
  putAchievement: (id: number) => `/achievements/${id}`,
  deleteAchievement: (id: number) => `/achievements/${id}`,

  // WorkerCompensationRates
  getWorkerCompensationRates: '/worker_compensation_rates',
  getWorkerCompensationRate: (id: number) => `/worker_compensation_rates/${id}`,
  postWorkerCompensationRate: `/worker_compensation_rates`,
  putWorkerCompensationRate: (id: number) => `/worker_compensation_rates/${id}`,
  deleteWorkerCompensationRate: (id: number) => `/worker_compensation_rates/${id}`,

  // VendorManagementSystems
  getVendorManagementSystems: '/vendor_management_systems',
  getVendorManagementSystem: (id: number) => `/vendor_management_systems/${id}`,
  postVendorManagementSystem: `/vendor_management_systems`,
  putVendorManagementSystem: (id: number) => `/vendor_management_systems/${id}`,
  deleteVendorManagementSystem: (id: number) => `/vendor_management_systems/${id}`,
  getVendorManagementSystemsEnums: '/vendor_management_systems/enums',
  getVmsPunchCards: (id: number) => `/vendor_management_systems/${id}/punch_cards`,
  postSubmitVMSPunchCards: (id: number) => `/vendor_management_systems/${id}/punch_cards/submit`,
  postUnsubmitVMSPunchCards: (id: number) =>
    `/vendor_management_systems/${id}/punch_cards/unsubmit`,
  getExportVMSPunchCards: (id: number) => `vendor_management_systems/${id}/punch_cards/export`,
  getVMSUpcomingShiftUpshifters: (id: number) =>
    `/vendor_management_systems/${id}/upcoming_shift_upshifters`,
  getExportVMSUpcomingShiftUpshifters: (id: number) =>
    `vendor_management_systems/${id}/upcoming_shift_upshifters/export`,

  // VendorManagementSystemOnboardings
  getVendorManagementSystemOnboardings: (id: number) =>
    `/vendor_management_systems/${id}/onboarding_management`,
  postVMSOnboardingManagementOnboard: (id: number) =>
    `/vendor_management_systems/${id}/onboarding_management/onboard`,
  postVMSOnboardingManagementOffboard: (id: number) =>
    `/vendor_management_systems/${id}/onboarding_management/offboard`,

  // Ach Accounts
  postAchAccountInit: '/ach_accounts/init',
  postAchAccountCustomerDetails: '/ach_accounts/customer_details',
  postAchAccountBankDetails: '/ach_accounts/bank_details',
  postAchAccountConfirm: '/ach_accounts/confirm',
  getAchAccounts: '/ach_accounts',
  deleteAchAccount: (id: number) => `/ach_accounts/${id}`,

  //Magic Login Link
  postMagicLoginLink: '/magic_login_links',

  // Request Specific Upshifters
  getInviteApplicants: '/request/applications/users',
  postInviteApplicants: `/request/applications`,
  getInviteApplicantsPreselected: '/request/applications/preselected_users',

  // Upshifter Experience
  getUpshifterExperiences: '/upshifter_experiences',

  // Web Sockets Data
  getWebSocketData: '/websocket_data',
}
