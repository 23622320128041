import { Action } from 'redux'
import { isType } from 'ts-action'
import { sum } from 'lodash'
import { IAchievementReducer, IAchievement, FIRST_LEVEL_ORDER } from 'models/Achievement'
import {
  GetAchievement,
  UpdateAchievementData,
  AchievementFetching,
  ResetAchievement,
  AddAchievementLevel,
  UpdateAchievementLevel,
  DeleteAchievementLevel,
  ReorderAchievementLevels,
} from './actions'
import { ACHIEVEMENT_LEVELS_ORDER_DIRECTION } from 'helpers/constants/constants'

const initialState: IAchievementReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: {} as IAchievement,
}

const resetLevelsOrder = (lvls: IAchievement[]) => {
  return lvls.map((lvl, index) => ({ ...lvl, order: index + FIRST_LEVEL_ORDER }))
}

const sortLevelsByOrder = (levels: IAchievement[]) =>
  levels.sort((levelA: IAchievement, levelB: IAchievement) => {
    if (levelA.order === null || levelB.order === null) return 0
    return levelA.order - levelB.order
  })

const reorderLevels = (direction: ACHIEVEMENT_LEVELS_ORDER_DIRECTION, levels: IAchievement[]) => {
  switch (direction) {
    case ACHIEVEMENT_LEVELS_ORDER_DIRECTION.UP: {
      let levelsReordered = [...levels]
      levelsReordered = levels.map((level, index) => {
        const levelOrder = level.order === null ? index + FIRST_LEVEL_ORDER : level.order
        if (levelOrder === FIRST_LEVEL_ORDER) return { ...level, order: levels.length }
        return {
          ...level,
          order: levelOrder - 1,
        }
      })
      return sortLevelsByOrder(levelsReordered)
    }
    case ACHIEVEMENT_LEVELS_ORDER_DIRECTION.DOWN: {
      let levelsReordered = [...levels]
      levelsReordered = levels.map((level, index) => {
        const levelOrder = level.order === null ? index + FIRST_LEVEL_ORDER : level.order
        if (levelOrder === levels.length) return { ...level, order: FIRST_LEVEL_ORDER }
        return {
          ...level,
          order: levelOrder + 1,
        }
      })
      return sortLevelsByOrder(levelsReordered)
    }
    default:
      return levels
  }
}

export const achievementReducer = (state = initialState, action: Action): IAchievementReducer => {
  if (isType(action, GetAchievement)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
    }
  }

  if (isType(action, UpdateAchievementData)) {
    const { payload } = action
    return {
      ...state,
      data: { ...state.data, ...payload },
    }
  }

  if (isType(action, AddAchievementLevel)) {
    const { payload } = action
    const prevLevels = [...(state.data?.levels?.data ?? [])]
    const newLevelOrder = state.data?.levels?.data.length
      ? state.data?.levels?.data.length + FIRST_LEVEL_ORDER
      : FIRST_LEVEL_ORDER
    const newLevelWithOrder = { ...payload, order: newLevelOrder }
    const newLevels = [...prevLevels, newLevelWithOrder]
    // parent achievement total points are a sum of all levels total points
    // recalculate parent achievement total points
    const newTotalPoints = sum(newLevels.map((lvl) => lvl.total_points))
    return {
      ...state,
      data: {
        ...state.data,
        total_points: newTotalPoints,
        levels: { ...state.data.levels!, data: newLevels },
      },
    }
  }

  if (isType(action, UpdateAchievementLevel)) {
    const { payload } = action
    const prevLevels = [...(state.data?.levels?.data ?? [])]
    const newLevels = prevLevels.map((lvl) => {
      if (lvl.order === payload.order) return payload
      return lvl
    })
    // parent achievement total points are a sum of all levels total points
    // recalculate parent achievement total points
    const newTotalPoints = sum(newLevels.map((lvl) => lvl.total_points))
    return {
      ...state,
      data: {
        ...state.data,
        total_points: newTotalPoints,
        levels: { ...state.data.levels!, data: newLevels },
      },
    }
  }

  if (isType(action, DeleteAchievementLevel)) {
    const { payload } = action
    const prevLevels = [...(state.data?.levels?.data ?? [])]
    const newLevels = prevLevels.filter((lvl) => lvl.order !== payload.order)
    // parent achievement total points are a sum of all levels total points
    // recalculate parent achievement total points
    const newTotalPoints = sum(newLevels.map((lvl) => lvl.total_points))
    return {
      ...state,
      data: {
        ...state.data,
        total_points: newTotalPoints,
        levels: { ...state.data.levels!, data: resetLevelsOrder(newLevels) },
      },
    }
  }

  if (isType(action, ReorderAchievementLevels)) {
    const { payload } = action
    const prevLevels = [...(state.data?.levels?.data ?? [])]
    const newLevels = reorderLevels(payload, prevLevels)
    return {
      ...state,
      data: { ...state.data, levels: { ...state.data.levels!, data: newLevels } },
    }
  }

  if (isType(action, AchievementFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }

  if (isType(action, ResetAchievement)) {
    return initialState
  }

  return state
}
