import { AxiosService } from '../config/config'
import { Endpoints } from '../config/endpoints'
import { IPayload } from 'models/Global'
import {
  ISuggestedPayRate,
  IGetSuggestedPayRatesParams,
  IPutSuggestedPayRateParams,
  IPostSuggestedPayRateParams,
} from 'models/SuggestedPayRate'

export class SuggestedPayRatesApi {
  constructor(private axios: AxiosService) {}

  getSuggestedPayRates = async (params?: IGetSuggestedPayRatesParams) => {
    const response = await this.axios.get<IPayload<ISuggestedPayRate[]>>(
      Endpoints.getSuggestedPayRates,
      params
    )
    return response
  }

  getSuggestedPayRate = async (id: number, params?: IGetSuggestedPayRatesParams) => {
    const response = await this.axios.get<IPayload<ISuggestedPayRate>>(
      Endpoints.getSuggestedPayRate(id),
      params
    )
    return response
  }

  postSuggestedPayRate = async (params: IPostSuggestedPayRateParams) => {
    const response = await this.axios.post<IPayload<ISuggestedPayRate>>(
      Endpoints.postSuggestedPayRate,
      params
    )
    return response
  }

  putSuggestedPayRate = async (id: number, params?: IPutSuggestedPayRateParams) => {
    const response = await this.axios.put<IPayload<ISuggestedPayRate>>(
      Endpoints.putSuggestedPayRate(id),
      params
    )
    return response
  }

  deleteSuggestedPayRate = async (id: number) => {
    const response = await this.axios.delete<{}>(Endpoints.deleteSuggestedPayRate(id))
    return response
  }
}
