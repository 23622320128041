import { Modal, SidebarContext, Theme, defaultMenuConfig } from '@pinchenterprisesnpm/friday-ui'
import { GlobalStyles } from 'config/GlobalStyles.js'
import { SetIsAuthUser, SetUserData } from 'data/Auth/actions'
import { ModalProvider } from 'helpers/GenericModalV2/ModalContext'
import { isDevelopment } from 'helpers/helperFunctions'
import { history } from 'helpers/history'
import MonitoringService from 'helpers/monitoringService'
import { IUser } from 'models/User/index'
import React, { Component, createContext, useContext } from 'react'
import ReactDOM from 'react-dom'
import ReactNotification from 'react-notifications-component'
import { connect } from 'react-redux'
import { Redirect, Route, Router, Switch } from 'react-router-dom'
import ROUTES from 'routing/adminRoutes'
import AuthService from 'services/AuthService'
import store from 'store/store'
import { BaseCSS, GridThemeProvider } from 'styled-bootstrap-grid'
import { ThemeProvider } from 'styled-components'
import AdminRouteLayout from 'ui/components/Admin/AdminRouteLayout'
import AdminRoute from 'ui/components/common/AdminRoute'
import ErrorBoundary from 'ui/components/common/ErrorBoundary'
import { redirectOldBusinessEntityRoutes } from 'ui/components/common/RedirectUser'
import activeShiftsRoutes from 'ui/components/common/Routes/ActiveShiftsRoutes'
import businessesRoutes from 'ui/components/common/Routes/BusinessesRoutes'
import changePasswordRoutes from 'ui/components/common/Routes/ChangePasswordRoutes'
import featuredShiftsRoutes from 'ui/components/common/Routes/FeaturedShiftsRoutes'
import financeRoutes from 'ui/components/common/Routes/FinanceRoutes'
import followManagementRoutes from 'ui/components/common/Routes/FollowManagementRoutes'
import globalSettingsRoutes from 'ui/components/common/Routes/GlobalSettingsRoutes'
import locationsRoutes from 'ui/components/common/Routes/LocationsRoutes'
import onboarderRoutes from 'ui/components/common/Routes/OnboarderRoutes'
import reportRoutes from 'ui/components/common/Routes/ReportsRoutes'
import shiftRoutes from 'ui/components/common/Routes/ShiftsRoutes'
import usersRoutes from 'ui/components/common/Routes/UsersRoutes'
import { ScrollToTop } from 'ui/components/common/ScrollToTop'
import {
  AsyncLogin,
  AsyncLogout,
  AsyncPasswordRecovery,
  AsyncTwoFactorAuthentication,
} from 'ui/pages'
import { GenericModalV2 } from 'helpers/GenericModalV2'
import certificationRequestsRoutes from 'ui/components/common/Routes/CertificationRequestsRoutes'

Modal.setStartingZIndex(1200)

const gridTheme = {
  row: {
    padding: 10, // default 15
  },
  col: {
    padding: 5, // default 15
  },
  // container: {
  //   padding: 0,
  // },
}

const createNotificationsRoot = () => {
  const body = document.body
  const notificationsRoot = document.createElement('div')
  notificationsRoot.style.position = 'fixed'
  notificationsRoot.style.top = '0'
  notificationsRoot.style.left = '0'
  notificationsRoot.style.zIndex = '99999'
  body.appendChild(notificationsRoot)
  return notificationsRoot
}

export const routes = [
  { path: '/' },
  { path: '/timesheets' },
  { path: '/timesheets/:id' },
  { path: '/admin_users' },
  { path: '/admin_users/add' },
  { path: '/invoices' },
  { path: '/invoices/:id' },
  { path: '/aging_report' },
  { path: '/drug_testing' },
  { path: '/add_deduction_types' },
  { path: '/businesses' },
  { path: '/sick_pay_hours_stats/:id' },
  { path: '/add_deduction_types' },
  { path: '/sick_pay_hours_stats/:id/accrued_sick_hours' },
  { path: '/sick_leave_templates/add' },
  { path: '/sick_leave_templates' },
  { path: '/business-entity/create' },
  { path: '/business-entity/:id/areas' },
  { path: '/business-entity/:id/business' },
  { path: '/business-entity/:id/documents/:documentId/acknowledged' },
  { path: '/business-entity/:id/documents' },
  { path: '/business-entity/:id/locations' },
  { path: '/business-entity/:id/users' },
  { path: '/business-entity/:id/invoices' },
  { path: '/business-entity/:id/my-upshifters' },
  { path: '/business-entity/:id/templates' },
  { path: '/business-entity/:id/shifts' },
  { path: '/business-entity/:id/notes' },
  { path: '/business-entity/:id/edit' },
  { path: '/business-entity/:id/selective-claim' },
  { path: '/business-entity/:id/children_companies' },
  { path: '/business-entity/:id/acknowledgements' },
  { path: '/business-entity/:id/invoice-groups' },
  { path: '/business-entity/:id' },
  { path: '/businesses/create' },
  { path: '/businesses/:id/areas' },
  { path: '/businesses/:id/business' },
  { path: '/businesses/:id/documents/:documentId/acknowledged' },
  { path: '/businesses/:id/documents' },
  { path: '/businesses/:id/locations' },
  { path: '/businesses/:id/users' },
  { path: '/businesses/:id/invoices' },
  { path: '/businesses/:id/my-upshifters' },
  { path: '/businesses/:id/templates' },
  { path: '/businesses/:id/shifts' },
  { path: '/businesses/:id/notes' },
  { path: '/businesses/:id/edit' },
  { path: '/businesses/:id/selective-claim' },
  { path: '/businesses/:id/children_companies' },
  { path: '/businesses/:id/acknowledgements' },
  { path: '/businesses/:id/invoice-groups' },
  { path: '/businesses/:id' },
  { path: '/areas/countries' },
  { path: '/areas/countries/:id' },
  { path: '/areas/states/:id' },
  { path: '/areas/counties/:id' },
  { path: '/shifts/active_shifts' },
  { path: '/clocked_in/:id' },
  { path: '/onboarder/upshifter_view/:id' },
  { path: '/onboarder/upshifter_list' },
  { path: '/upshifter_view/:id' },
  { path: '/upshifter_list' },
  { path: '/business_users_list' },
  { path: '/locations' },
  { path: '/locations/add' },
  { path: '/locations/location_categories' },
  { path: '/locations/location_types' },
  { path: '/job_types' },
  { path: '/badges' },
  { path: '/certificate_organizations' },
  { path: '/strike_types' },
  { path: '/user_attachment_types' },
  { path: '/pay_rate_suggestions' },
  { path: '/logout' },
  { path: '/shifts' },
  { path: '/shifts/duplicate/:id' },
  { path: '/shifts/edit/:id' },
  { path: '/featured_shifts_campaigns' },
  { path: '/groups' },
  { path: '/groups/:id' },
  { path: '/reports' },
  { path: '/reports/payroll_report' },
  { path: '/reports/am_success_report' },
  { path: '/reports/monthly_sales_tax_report' },
  { path: '/reports/churn_report' },
  { path: '/reports/fake_punchcards_report' },
  { path: '/reports/growth_report' },
  { path: '/reports/accounting_report' },
  { path: '/reports/am_shifts_report' },
  { path: '/reports/multiple_punch_card_report' },
  { path: '/reports/cr_bonus_report' },
  { path: '/reports/high_level_stats_report' },
  { path: '/reports/compass_approval_report' },
  { path: '/reports/locations_report' },
  { path: '/reports/foodbuy_report' },
  { path: '/follow_management' },
  { path: '/change_password' },
  { path: '/shift_identifier_policies' },
  { path: '/shift_identifier_policy/add' },
  { path: '/shift_identifier_policy/:id' },
  { path: '/onboarding_workflows ' },
  { path: '/onboarding_workflows/add' },
  { path: '/onboarding_workflows/edit/:id' },
  { path: '/reports/return_rate_report' },
  { path: '/reports/am_kpi_report' },
  { path: '/reports/upshifter_hours_report' },
  { path: '/reports/expiring_documents_report' },
]

// tslint:disable-next-line: no-unused-expression
if (!isDevelopment()) {
  MonitoringService.initialization()
}

// Authentication
const loggedInUser = localStorage.getItem('loggedInUser')
const token = localStorage.getItem('token')

if (token && loggedInUser) {
  store.dispatch(SetUserData(JSON.parse(loggedInUser)))
  store.dispatch(SetIsAuthUser())
}

export const SidebarUpdateContext = createContext({
  forceUpdate: () => {},
})

export const useSidebarUpdateContext = () => useContext(SidebarUpdateContext)

type TAppProps = {}

type TAppState = { isCollapsed: boolean; menuWidth: string }

class App extends Component<TAppProps, TAppState> {
  constructor(props: any) {
    super(props)

    const menuState = localStorage.getItem('menuState')

    this.state = {
      isCollapsed: menuState && menuState === 'collapsed' ? true : false,
      menuWidth:
        menuState && menuState === 'collapsed'
          ? defaultMenuConfig.COLLAPSED
          : defaultMenuConfig.EXPANDED,
    }
  }

  notificationsRoot = createNotificationsRoot()

  insertNotificationsRoot = () => {
    const { render } = ReactDOM
    render(<ReactNotification />, this.notificationsRoot)
  }

  removeNotificationsRoot = () => {
    const { unmountComponentAtNode } = ReactDOM
    unmountComponentAtNode(this.notificationsRoot)
  }

  toggleMenuType = () => {
    this.setState((state) => ({
      isCollapsed: !state.isCollapsed,
      menuWidth:
        state.menuWidth === defaultMenuConfig.COLLAPSED
          ? defaultMenuConfig.EXPANDED
          : defaultMenuConfig.COLLAPSED,
    }))
  }

  componentDidMount() {
    this.insertNotificationsRoot()

    const menuState = localStorage.getItem('menuState')

    this.setState({
      isCollapsed: menuState && menuState === 'collapsed' ? true : false,
      menuWidth:
        menuState && menuState === 'collapsed'
          ? defaultMenuConfig.COLLAPSED
          : defaultMenuConfig.EXPANDED,
    })

    if (AuthService.getLoggedInUser()) {
      const loggedInUser = AuthService.getLoggedInUser() as IUser
      MonitoringService.setUser(loggedInUser)
    }
  }

  componentDidUpdate(_: TAppProps, prevState: TAppState) {
    const menuState = localStorage.getItem('menuState')
    const isNewCollapsed = menuState && menuState === 'collapsed' ? true : false

    if (prevState.isCollapsed !== isNewCollapsed) {
      this.setState({
        isCollapsed: isNewCollapsed,
        menuWidth: isNewCollapsed ? defaultMenuConfig.COLLAPSED : defaultMenuConfig.EXPANDED,
      })
    }
  }

  componentWillUnmount() {
    this.removeNotificationsRoot()
  }

  render() {
    const sidebarContextValue = {
      isCollapsed: this.state.isCollapsed,
      menuWidth: this.state.menuWidth,
      toggleMenuType: this.toggleMenuType,
    }

    return (
      <ThemeProvider theme={Theme}>
        <ModalProvider>
          <GenericModalV2 />
          <GridThemeProvider gridTheme={gridTheme}>
            <ErrorBoundary>
              <React.Fragment>
                <BaseCSS />
                <GlobalStyles />
                <SidebarUpdateContext.Provider
                  value={{
                    forceUpdate: () => this.forceUpdate(),
                  }}
                >
                  <SidebarContext.Provider value={sidebarContextValue}>
                    <Router history={history}>
                      <ScrollToTop />
                      <Switch>
                        {redirectOldBusinessEntityRoutes}
                        {shiftRoutes}
                        {activeShiftsRoutes}
                        {financeRoutes}
                        {usersRoutes}
                        {businessesRoutes}
                        {/* Location types and Categories are breaking if the routes  are placed under locations routes */}
                        {globalSettingsRoutes}
                        {locationsRoutes}
                        {reportRoutes}
                        {featuredShiftsRoutes}
                        {onboarderRoutes}
                        {followManagementRoutes}
                        {changePasswordRoutes}
                        {certificationRequestsRoutes}
                        <Route exact path='/2fa' component={AsyncTwoFactorAuthentication} />
                        <AdminRoute
                          exact
                          path={ROUTES.LOGOUT}
                          component={AsyncLogout}
                          layout={AdminRouteLayout}
                        />
                        <Route
                          exact
                          path={ROUTES.PASSWORD_RECOVERY}
                          component={AsyncPasswordRecovery}
                        />
                        <Route exact path={ROUTES.ROOT} component={AsyncLogin} />
                        <Redirect from='/' to={ROUTES.SHIFTS} />
                      </Switch>
                    </Router>
                  </SidebarContext.Provider>
                </SidebarUpdateContext.Provider>
              </React.Fragment>
            </ErrorBoundary>
          </GridThemeProvider>
        </ModalProvider>
      </ThemeProvider>
    )
  }
}

export default connect()(App)
