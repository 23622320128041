import { useCallback, useEffect, useState } from 'react'
import { Flex, Text } from '@pinchenterprisesnpm/friday-ui'
import { TypeOrNull } from '../../../../models/Common'
import {
  DEFAULT_POWER_SEARCH_PLACEHOLDER,
  POWER_SEARCH_RESOURCE_TYPE,
  redirectOnClick,
} from '../PowerSearch/utils'
import { FlexWrapper, FormattedText } from './style'
import { mappedResourceTypes, DEFAULT_HOVER_VALUE } from './utils'

type TPowerSearchFilteredListItemProps = {
  searchTerm: string
  inputValue: string
  resourceType: POWER_SEARCH_RESOURCE_TYPE
  setResourceType: React.Dispatch<React.SetStateAction<TypeOrNull<POWER_SEARCH_RESOURCE_TYPE>>>
  setPlaceholder: React.Dispatch<React.SetStateAction<string>>
  setInputValue: React.Dispatch<React.SetStateAction<string>>
  setErrorMessage: React.Dispatch<React.SetStateAction<boolean>>
}

const RESOURCE_TYPES_LENGTH = mappedResourceTypes.length

export const PowerSearchResourceTypes = ({
  searchTerm,
  resourceType,
  setResourceType,
  setPlaceholder,
  setInputValue,
  setErrorMessage,
  inputValue,
}: TPowerSearchFilteredListItemProps) => {
  const [hoveredItemIndex, setHoveredItemIndex] = useState<number>(DEFAULT_HOVER_VALUE)
  const [resourceTypes, setResourceTypes] =
    useState<TypeOrNull<POWER_SEARCH_RESOURCE_TYPE[]>>(mappedResourceTypes)

  useEffect(() => {
    if (!resourceType && inputValue) {
      if (
        mappedResourceTypes.some((item) => item.toLowerCase().startsWith(inputValue.toLowerCase()))
      ) {
        setResourceTypes(
          mappedResourceTypes.filter((item) =>
            item.toLowerCase().startsWith(inputValue.toLowerCase())
          )
        )
        setHoveredItemIndex(0)
      } else {
        setResourceTypes(null)
        setHoveredItemIndex(DEFAULT_HOVER_VALUE)
      }
    } else {
      setResourceTypes(mappedResourceTypes)
    }
  }, [resourceType, inputValue])

  useEffect(() => {
    if (resourceType && resourceTypes?.length) {
      setHoveredItemIndex(resourceTypes.indexOf(resourceType))
    }
  }, [resourceType, resourceTypes])

  const handleMouseOver = (index: number) => {
    setHoveredItemIndex(index)
    setPlaceholder(resourceTypes?.length ? resourceTypes[index] : DEFAULT_POWER_SEARCH_PLACEHOLDER)
  }

  const handleMouseOut = () => {
    setHoveredItemIndex(DEFAULT_HOVER_VALUE)
  }

  const handleKeyDown = useCallback(
    (event: any) => {
      const hoveredIndex = hoveredItemIndex === null ? 0 : hoveredItemIndex
      if (event.key === 'ArrowUp') {
        setHoveredItemIndex((hoveredIndex - 1 + RESOURCE_TYPES_LENGTH) % RESOURCE_TYPES_LENGTH)
      }
      if (event.key === 'ArrowDown') {
        setHoveredItemIndex((hoveredIndex + 1 + RESOURCE_TYPES_LENGTH) % RESOURCE_TYPES_LENGTH)
      }
      if (event.key === 'Tab' && !!resourceTypes) {
        const resourceTypeItem = resourceTypes[hoveredItemIndex]
        if (resourceTypeItem) {
          setInputValue(resourceTypeItem + '/')
          setResourceType(resourceTypeItem)
        }
      }
      if (event.key === 'Enter') {
        if (mappedResourceTypes[hoveredItemIndex]) {
          redirectOnClick(mappedResourceTypes[hoveredItemIndex], searchTerm)
        } else {
          setErrorMessage(true)
        }
      }
    },
    [hoveredItemIndex, setResourceType, setInputValue, searchTerm, setErrorMessage, resourceTypes]
  )

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [handleKeyDown])

  useEffect(() => {
    if (hoveredItemIndex !== DEFAULT_HOVER_VALUE && !!resourceTypes) {
      setPlaceholder(resourceTypes[hoveredItemIndex])
    }
  }, [hoveredItemIndex, setPlaceholder, resourceTypes])

  if (resourceType && !searchTerm) {
    return (
      <Flex justifyContent='center'>
        <Text ml={20} mt={60}>
          Enter a search term in order to filter by {resourceType} resource.
        </Text>
      </Flex>
    )
  }

  if (!resourceTypes) {
    return (
      <Flex ml={30} alignItems='center'>
        Resource type
        <Text fontWeight='bold' mx={4}>
          {inputValue}
        </Text>
        does not exist.
      </Flex>
    )
  }

  return (
    <Flex flexDirection='column'>
      {resourceTypes?.map((item, index) => (
        <FlexWrapper
          tabIndex={0}
          key={item}
          justifyContent='space-between'
          alignItems='center'
          onMouseOver={() => handleMouseOver(index)}
          onMouseOut={handleMouseOut}
          onClick={() => redirectOnClick(item, searchTerm)}
          isActive={hoveredItemIndex === index}
          onKeyDown={handleKeyDown}
        >
          <Text ml={30} fontWeight='bold'>
            {item}
          </Text>
          {hoveredItemIndex === index && (
            <Flex alignItems='center'>
              <FormattedText>
                <code>Enter</code>
              </FormattedText>
              <Text mx={5}>to jump to</Text>
              <FormattedText>
                <code>Tab</code>
              </FormattedText>
              <Text mr={30} ml={5}>
                to search
              </Text>
            </Flex>
          )}
        </FlexWrapper>
      ))}
    </Flex>
  )
}
