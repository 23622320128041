import { IBaseGetParams } from '../Global'
import { IUser } from '../User'

export enum BONUS_STATUS {
  IN_REVIEW,
  PROCESSED,
}

export enum BONUS_CATEGORY {
  DISCRETIONAL,
  PRODUCTION,
  ADDITIONAL_PAY_OWNED,
}

export interface IBonusIncludeParams {
  /**
   * Info for include is missing
   */
  include?: string[]
}

export interface IBonus {
  id: number
  timesheet_id: number
  application_id: number
  gig_day_id: number
  invoice_value: number
  total_invoice_value: number
  commission: number
  category: BONUS_CATEGORY
  memo: string
  status: BONUS_STATUS
  created_by_id: number
  edited_by_id: number
  created_at: string
  created_by: IUser
}

export interface IGetBonusesParamsBase extends IBaseGetParams {
  timesheet_id?: number
  application_id?: number
  gig_day_id?: number
  category?: BONUS_CATEGORY
  status?: BONUS_STATUS
  business_id?: number
  user_id?: number
}

export type IGetBonusesParams = IGetBonusesParamsBase & IBonusIncludeParams

export interface IPostBonusParamsBase {
  application_id: number
  gig_day_id?: number
  timesheet_id?: number
  invoice_value: number
  memo?: string
  category: BONUS_CATEGORY
}

export type IPostBonusParams = IPostBonusParamsBase & IBonusIncludeParams

export interface IPutBonusParamsBase {
  application_id?: number
  gig_day_id?: number
  timesheet_id?: number
  invoice_value?: number
  memo?: string
  category?: BONUS_CATEGORY
}

export type IPutBonusParams = IPutBonusParamsBase & IBonusIncludeParams

export interface IDeleteBonusParams {
  id: number
  gig_id: number
  application_id: number
  timesheet_id: number
}
