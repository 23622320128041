import AuthService from 'services/AuthService'
import { permissionsPerRole } from './permissionsPerRole'

/**
 * Checks whether the user role has the permissions provided
 * @param {string} currentUserRole - The role to be checked against
 * @param {string[]} requiredPermissions - Permissions required for a React Node
 */
export const checkPermissions = (
  requiredPermissions,
  currentUserRole = undefined,
  method = 'all'
) => {
  const role = currentUserRole || AuthService.getLoggedInUserRole()
  if (requiredPermissions.length === 0) {
    return true
  }

  // The current role is unknown and does not exist in our permissions file
  if (!permissionsPerRole[role]) {
    return false
  }

  switch (method) {
    case 'all':
      return requiredPermissions.every(
        (requiredPermission) => permissionsPerRole[role][requiredPermission]
      )
    case 'any': {
      return requiredPermissions.some(
        (requiredPermission) => permissionsPerRole[role][requiredPermission]
      )
    }

    default:
      return requiredPermissions.every(
        (requiredPermission) => permissionsPerRole[role][requiredPermission]
      )
  }
}
