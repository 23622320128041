import { Action } from 'redux'
import { isType } from 'ts-action'
import {
  GetUsers,
  GetUsersErrors,
  UsersFetching,
  UserUpdating,
  UserUpdated,
  PutUserErrors,
  PutUser,
  AddUser,
  ClearUsersReducer,
  DeleteUser,
} from './actions'
import { IUser, IUsersReducer } from 'models/User'
import {
  FavoriteButtonLoading,
  FavoriteButtonStopLoading,
  FavoriteUpshifter,
  UnfavoriteUpshifter,
} from '../Favorite/actions'
import { deleteFromArray } from 'helpers/helperFunctions'

const initialState: IUsersReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  isFavoriteButtonLoadingIds: [],
  isApproveButtonLoadingIds: [],
  isActivateSuspendButtonLoadingIds: [],
  data: [],
}

export const usersReducer = (state = initialState, action: Action): IUsersReducer => {
  const newUsers = [...state.data]
  if (isType(action, GetUsers)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
    }
  }
  if (isType(action, UsersFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }
  if (isType(action, GetUsersErrors)) {
    const { payload } = action
    return {
      ...state,
      error: {
        code: payload.code,
        message: payload.message,
      },
    }
  }
  // Put User
  if (isType(action, UserUpdating)) {
    const { payload } = action
    return {
      ...state,
      isApproveButtonLoadingIds: [...state.isApproveButtonLoadingIds, payload],
    }
  }
  if (isType(action, UserUpdated)) {
    const { payload } = action
    const modifiedIsApproveButtonLoadingIds = state.isApproveButtonLoadingIds.filter(
      (id) => id !== payload
    )
    return {
      ...state,
      isApproveButtonLoadingIds: [...modifiedIsApproveButtonLoadingIds],
    }
  }
  if (isType(action, PutUserErrors)) {
    const { payload } = action
    return {
      ...state,
      error: {
        code: payload.code,
        message: payload.message,
      },
    }
  }
  if (isType(action, PutUser)) {
    const { payload } = action
    const modifiedUsers = newUsers.map((upshifter) => {
      if (upshifter.id === payload.data.id) {
        return payload.data
      }
      return upshifter
    })
    return {
      ...state,
      data: [...modifiedUsers],
    }
  }
  // Add User
  if (isType(action, AddUser)) {
    const { data: user } = action.payload
    return {
      ...state,
      data: [user, ...state.data],
    }
  }
  // Favorite and Unfavorite Upshifter
  if (isType(action, FavoriteUpshifter)) {
    const { payload } = action
    const modifiedUsers = newUsers.map((upshifter) => {
      if (upshifter.id === payload.data.model_favored.id) {
        const modifiedUpshifter = {
          ...upshifter,
          favorite: true,
          favorite_id: payload.data.id,
        }
        return modifiedUpshifter
      }
      return upshifter
    })
    return {
      ...state,
      data: [...modifiedUsers],
    }
  }
  if (isType(action, UnfavoriteUpshifter)) {
    const { payload } = action
    const modifiedUsers = newUsers.map((upshifter) => {
      if (upshifter.favorite_id === payload) {
        const modifiedUpshifter = {
          ...upshifter,
          favorite: false,
          favorite_id: null,
        }
        return modifiedUpshifter
      }
      return upshifter
    })
    return {
      ...state,
      data: [...modifiedUsers],
    }
  }
  if (isType(action, FavoriteButtonLoading)) {
    const { payload } = action
    return {
      ...state,
      isFavoriteButtonLoadingIds: [...state.isFavoriteButtonLoadingIds, payload],
    }
  }
  if (isType(action, FavoriteButtonStopLoading)) {
    const { payload } = action
    const modifiedIsFavoriteButtonLoadingIds = state.isFavoriteButtonLoadingIds.filter(
      (id) => id !== payload
    )
    return {
      ...state,
      isFavoriteButtonLoadingIds: [...modifiedIsFavoriteButtonLoadingIds],
    }
  }
  if (isType(action, DeleteUser)) {
    const { payload } = action
    const { data } = state
    const formattedArray = deleteFromArray<IUser>(data, payload)
    return {
      ...state,
      data: formattedArray,
    }
  }
  if (isType(action, ClearUsersReducer)) {
    return initialState
  }

  return state
}
