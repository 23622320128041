import { AxiosService } from '../config/config'
import { Endpoints } from '../config/endpoints'
import { IPayload } from 'models/Global'
import {
  IAttachment,
  IGetAttachmentsParams,
  IPostAttachmentParams,
  IPutAttachmentParams,
} from 'models/Attachment'

export class AttachmentsApi {
  constructor(private axios: AxiosService) {}

  getAttachments = async (params?: IGetAttachmentsParams) => {
    const response = await this.axios.get<IPayload<IAttachment[]>>(Endpoints.getAttachments, params)
    return response
  }

  getAttachment = async (id: number) => {
    const response = await this.axios.get<IPayload<IAttachment>>(Endpoints.getAttachment(id))
    return response
  }

  postAttachment = async (params: IPostAttachmentParams) => {
    const response = await this.axios.post<IPayload<IAttachment>>(Endpoints.postAttachment, params)
    return response
  }

  putAttachment = async (id: number, params: IPutAttachmentParams) => {
    const response = await this.axios.put<IPayload<IAttachment>>(
      Endpoints.putAttachment(id),
      params
    )
    return response
  }

  deleteAttachment = async (id: number) => {
    const response = await this.axios.delete<{}>(Endpoints.deleteAttachment(id))
    return response
  }
}
