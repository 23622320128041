import { IAttachmentsReducer, IAttachment } from 'models/Attachment'
import { isType } from 'ts-action'
import { Action } from 'redux'
import {
  GetAttachments,
  AttachmentsFetching,
  GetAttachmentsErrors,
  AddAttachmentErrors,
  AddAttachment,
  AttachmentUploading,
  ClearAttachments,
  DeleteAttachment,
} from './actions'
import { deleteFromArray } from 'helpers/helperFunctions'

const initialState: IAttachmentsReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  isUploading: false,
  data: [],
}

export const attachmentsReducer = (state = initialState, action: Action): IAttachmentsReducer => {
  if (isType(action, GetAttachments)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
    }
  }

  if (isType(action, GetAttachmentsErrors)) {
    const { payload } = action
    return {
      ...state,
      error: {
        code: payload.code,
        message: payload.message,
      },
    }
  }

  if (isType(action, AddAttachmentErrors)) {
    const { payload } = action
    return {
      ...state,
      error: {
        code: payload.code,
        message: payload.message,
      },
    }
  }

  if (isType(action, AddAttachment)) {
    const { payload } = action
    return {
      ...state,
      data: [...state.data, payload.data],
    }
  }

  if (isType(action, DeleteAttachment)) {
    const { payload } = action
    const { data } = state
    const formattedArray = deleteFromArray<IAttachment>(data, payload)
    return {
      ...state,
      data: formattedArray,
    }
  }

  if (isType(action, ClearAttachments)) {
    return {
      meta: {
        code: 0,
        message: '',
      },
      isFetching: false,
      isUploading: false,
      data: [],
    }
  }

  if (isType(action, AttachmentsFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }

  if (isType(action, AttachmentUploading)) {
    const { payload } = action
    return {
      ...state,
      isUploading: payload,
    }
  }

  return state
}
