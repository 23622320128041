import { IPunchCard } from 'models/PunchCard'
import { IActivityLogReducer } from 'models/ActivityLog'
import { Action } from 'redux'
import { isType } from 'ts-action'
import { PunchCardsActivityLogsFetching, GetPunchCardsActivityLogs } from './actions'

const initialState: IActivityLogReducer<IPunchCard> = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: [],
}

export const punchCardActivityLogsReducer = (
  state = initialState,
  action: Action
): IActivityLogReducer<IPunchCard> => {
  if (isType(action, GetPunchCardsActivityLogs)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
    }
  }
  if (isType(action, PunchCardsActivityLogsFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }
  return state
}
