import { createSelector } from 'reselect'
import { AppState } from 'store/store'

/**
 * @param state - the application state
 * @returns timesheetHotkeys - the timesheetHotkeys reducer
 */
export const selectTimesheetHotkeysReducer = (state: AppState) => state.timesheetHotkeys

/**
 * Gets the disabled status for the timesheetHotkeys
 * @returns isDisabledTimesheetHotkeys - the timesheetHotkeys' isDisabledTimesheetHotkeys
 */
export const selectIsDisabledTimesheetHotkeys = createSelector(
  selectTimesheetHotkeysReducer,
  (statesReducer) => statesReducer.isDisabledTimesheetHotkeys
)
