import { action, payload } from 'ts-action'
import { ICertificate } from 'models/Certificate'
import { ICertificateOrganization } from 'models/CertificateOrganization'
import { TypeOrNull } from 'models/Common'

export const UpdateSelectedBadge = action(
  'UpdateSelectedBadge',
  payload<TypeOrNull<ICertificate>>()
)

export const UpdateSelectedCertificateOrganization = action(
  'UpdateSelectedCertificateOrganization',
  payload<TypeOrNull<ICertificateOrganization>>()
)

export const UpdateExpiryDate = action('UpdateExpiryDate', payload<TypeOrNull<string>>())

export const ResetCertificationRequestReviewFlow = action('ResetCertificationRequestReviewFlow')
