import { Action } from 'redux'
import { isType } from 'ts-action'
import {
  GetSelectedShiftGigAutoAcceptCertificates,
  SelectedShiftGigAutoAcceptCertificatesFetching,
  GetSelectedShiftGigAutoAcceptCertificatesErrors,
  ClearSelectedShiftGigAutoAcceptCertificates,
  RemoveSelectedShiftGigAutoAcceptCertificate,
  AddSelectedShiftGigAutoAcceptCertificate,
} from './actions'
import {
  IGigAutoAcceptCertificate,
  ISelectedShiftGigAutoAcceptCertificatesReducer,
} from 'models/GigAutoAcceptCertificate'
import { deleteFromArray } from 'helpers/helperFunctions'

const initialState: ISelectedShiftGigAutoAcceptCertificatesReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: [],
}

export const SelectedShiftGigAutoAcceptCertificatesReducer = (
  state = initialState,
  action: Action
): ISelectedShiftGigAutoAcceptCertificatesReducer => {
  if (isType(action, GetSelectedShiftGigAutoAcceptCertificates)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
    }
  }
  if (isType(action, SelectedShiftGigAutoAcceptCertificatesFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }
  if (isType(action, GetSelectedShiftGigAutoAcceptCertificatesErrors)) {
    const { payload } = action
    return {
      ...state,
      error: payload,
    }
  }

  if (isType(action, RemoveSelectedShiftGigAutoAcceptCertificate)) {
    const { payload } = action
    const filteredData = deleteFromArray<IGigAutoAcceptCertificate>(state.data, payload)
    return {
      ...state,
      data: filteredData,
    }
  }
  if (isType(action, AddSelectedShiftGigAutoAcceptCertificate)) {
    const { payload } = action
    return {
      ...state,
      data: [payload, ...state.data],
    }
  }
  if (isType(action, ClearSelectedShiftGigAutoAcceptCertificates)) {
    return initialState
  }
  return state
}
