import { Action } from 'redux'
import { isType } from 'ts-action'
import { GetUser, GetUserErrors, UserFetching, UpdateUser, ClearUserReducer } from './actions'
import { IUserReducer, IUser } from 'models/User'

const initialState: IUserReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: {} as IUser,
}

export const userReducer = (state = initialState, action: Action): IUserReducer => {
  if (isType(action, GetUser)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
    }
  }
  if (isType(action, UserFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }
  if (isType(action, UpdateUser)) {
    const { data } = action.payload
    return {
      ...state,
      data,
    }
  }
  if (isType(action, ClearUserReducer)) {
    return initialState
  }
  if (isType(action, GetUserErrors)) {
    const { payload } = action
    return {
      ...state,
      error: {
        code: payload.code,
        message: payload.message,
      },
    }
  }
  return state
}
