import { AxiosService } from '../config/config'
import { Endpoints } from '../config/endpoints'
import { IPayload } from 'models/Global'
import {
  INetOption,
  IGetNetOptionsParams,
  IPostNetOptionParams,
  IPutNetOptionParams,
} from 'models/NetOption'

export class NetOptionsApi {
  constructor(private axios: AxiosService) {}

  getNetOptions = async (params?: IGetNetOptionsParams) => {
    const response = await this.axios.get<IPayload<INetOption[]>>(Endpoints.getNetOptions, params)
    return response
  }

  getNetOption = async (id: number) => {
    const response = await this.axios.get<IPayload<INetOption>>(Endpoints.getNetOption(id))
    return response
  }

  postNetOption = async (params: IPostNetOptionParams) => {
    const response = await this.axios.post<IPayload<INetOption>>(Endpoints.postNetOption, params)
    return response
  }

  putNetOption = async (id: number, params: IPutNetOptionParams) => {
    const response = await this.axios.put<IPayload<INetOption>>(Endpoints.putNetOption(id), params)
    return response
  }

  deleteNetOption = async (id: number) => {
    const response = await this.axios.delete<{}>(Endpoints.deleteNetOption(id))
    return response
  }
}
