import { Dispatch } from 'redux'
import { API } from 'network'
import {
  GetPunchCards as GetPunchCardsAction,
  GetPunchCardsErrors,
  PunchCardsFetching,
  AddPunchCardErrors,
  AddPunchCard as AddPunchCardAction,
  UpdatePunchCard as UpdatePunchCardAction,
  UpdatePunchCardErrors,
  DeletePunchCard as DeletePunchCardAction,
  DeletePunchCardErrors,
  ResetPunchCardsState,
} from './actions'
import { IGetPunchCardsParams, IPostPunchCardParams, IPutPunchCardParams } from 'models/PunchCard'
import { NotificationManager } from 'helpers/NotificationsManager'
import { handleErrorNotification } from 'services/ErrorHandlingService'
import { renderRevokeStrikeModal } from '../Upshifter/utils'
import { CODE_481_ACTIONS } from 'network/config/error'

export type PunchCardsActions =
  | ReturnType<typeof GetPunchCardsAction>
  | ReturnType<typeof GetPunchCardsErrors>
  | ReturnType<typeof PunchCardsFetching>
  | ReturnType<typeof AddPunchCardAction>
  | ReturnType<typeof AddPunchCardErrors>
  | ReturnType<typeof UpdatePunchCardAction>
  | ReturnType<typeof UpdatePunchCardErrors>
  | ReturnType<typeof DeletePunchCardAction>
  | ReturnType<typeof DeletePunchCardErrors>
  | ReturnType<typeof ResetPunchCardsState>

export const getPunchCards =
  (params?: IGetPunchCardsParams) => async (dispatch: Dispatch<PunchCardsActions>) => {
    dispatch(PunchCardsFetching(true))
    try {
      const response = await API.PunchCards.getPunchCards(params)
      dispatch(GetPunchCardsAction(response))
    } catch (error) {
      dispatch(GetPunchCardsErrors({ code: error.code, message: error.message }))
    } finally {
      dispatch(PunchCardsFetching(false))
    }
  }

export const addPunchCard =
  (params: IPostPunchCardParams) => async (dispatch: Dispatch<PunchCardsActions>) => {
    dispatch(PunchCardsFetching(true))
    try {
      const response = await API.PunchCards.postPunchCard(params)
      dispatch(AddPunchCardAction(response))
      NotificationManager.success('Punchcard created successfully.')

      const punchCard = await API.PunchCards.getPunchCard(response.data.id, {
        include: ['no_call_no_show_strike'],
      })
      if (
        punchCard.data.no_call_no_show_strike &&
        punchCard.data.gig_id === punchCard.data.no_call_no_show_strike.gig.id
      ) {
        renderRevokeStrikeModal({
          strikeId: punchCard.data.no_call_no_show_strike.id,
          upshifterName: punchCard.data.application_full_name,
        })
      }
      return response
    } catch (error) {
      dispatch(AddPunchCardErrors({ code: error.code, message: error.message }))
      if (error.action === CODE_481_ACTIONS.OVERLAPPING_PUNCH_CARD) {
        throw error
      }
      if (error.action === CODE_481_ACTIONS.UPSHIFTER_NOT_ACCEPTED_FOR_GIG_DAY) {
        throw error
      }
      handleErrorNotification(error)
      return null
    } finally {
      dispatch(PunchCardsFetching(false))
    }
  }

export const updatePunchCard =
  (id: number, params: IPutPunchCardParams) => async (dispatch: Dispatch<PunchCardsActions>) => {
    dispatch(PunchCardsFetching(true))
    try {
      const response = await API.PunchCards.putPunchCard(id, params)
      dispatch(UpdatePunchCardAction(response))
    } catch (error) {
      dispatch(
        UpdatePunchCardErrors({
          code: error.code,
          message: error.message,
        })
      )
      handleErrorNotification(error)
    } finally {
      dispatch(PunchCardsFetching(false))
    }
  }

export const DeletePunchCard = (id: number) => async (dispatch: Dispatch<PunchCardsActions>) => {
  dispatch(PunchCardsFetching(true))
  try {
    await API.PunchCards.deletePunchCard(id)
    dispatch(DeletePunchCardAction(id))
  } catch (error) {
    dispatch(
      DeletePunchCardErrors({
        code: error.code,
        message: error.message,
      })
    )
    handleErrorNotification(error)
  } finally {
    dispatch(PunchCardsFetching(false))
  }
}
