import { IBusinessEntitiesReducer } from 'models/BusinessEntity'
import { Action } from 'redux'
import {
  GetFollowManagementBusinessEntities,
  FollowManagementBusinessEntitiesFetching,
  GetFollowManagementBusinessEntitiesErrors,
  UpdateFollowManagementBusinessEntity,
} from './actions'
import { isType } from 'ts-action'
import { FOLLOW_STATUS } from 'models/Follow'

const initialState: IBusinessEntitiesReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: [],
}

export const followManagementBusinessEntitiesReducer = (
  state = initialState,
  action: Action
): IBusinessEntitiesReducer => {
  if (isType(action, GetFollowManagementBusinessEntities)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
    }
  }

  if (isType(action, GetFollowManagementBusinessEntitiesErrors)) {
    const { payload } = action
    return {
      ...state,
      error: {
        code: payload.code,
        message: payload.message,
      },
    }
  }

  if (isType(action, FollowManagementBusinessEntitiesFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }

  if (isType(action, UpdateFollowManagementBusinessEntity)) {
    const {
      payload: { data },
    } = action

    if (data.status === FOLLOW_STATUS.UNFOLLOW) {
      const newBusinessEntities = state.data.filter((item) => item.id !== data.model_id)

      return {
        ...state,
        data: newBusinessEntities,
      }
    }
  }

  return state
}
