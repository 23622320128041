import { AxiosService } from '../config/config'
import { Endpoints } from '../config/endpoints'
import { IPayload } from 'models/Global'
import {
  IBlock,
  IGetBlocksParams,
  IPutBlockParams,
  IPostBlockParams,
  IDeleteBlockParams,
} from 'models/Block'

export class BlocksApi {
  constructor(private axios: AxiosService) {}

  getBlocks = async (params?: IGetBlocksParams) => {
    const response = await this.axios.get<IPayload<IBlock[]>>(Endpoints.getBlocks, params)
    return response
  }

  getBlock = async (id: number, params?: IGetBlocksParams) => {
    const response = await this.axios.get<IPayload<IBlock>>(Endpoints.getBlock(id), params)
    return response
  }

  postBlock = async (params: IPostBlockParams) => {
    const response = await this.axios.post<IPayload<IBlock>>(Endpoints.postBlock, params)
    return response
  }

  putBlock = async (id: number, params?: IPutBlockParams) => {
    const response = await this.axios.put<IPayload<IBlock>>(Endpoints.putBlock(id), params)
    return response
  }

  deleteBlock = async (id: number, params?: IDeleteBlockParams) => {
    const response = await this.axios.delete<{}>(Endpoints.deleteBlock(id), params)
    return response
  }
}
