import { AxiosService } from '../config/config'
import { Endpoints } from '../config/endpoints'
import { IPayload } from 'models/Global'
import { IGetNotesParams, INote, IPostNoteParams, IPutNoteParams } from 'models/Note'

export class NotesApi {
  constructor(private axios: AxiosService) {}

  getNotes = async (params?: IGetNotesParams) => {
    const response = await this.axios.get<IPayload<INote[]>>(Endpoints.getNotes, params)
    return response
  }

  getNote = async (id: number) => {
    const response = await this.axios.get<IPayload<INote>>(Endpoints.getNote(id))
    return response
  }

  postNote = async (params?: IPostNoteParams) => {
    const response = await this.axios.post<IPayload<INote>>(Endpoints.postNote, params)
    return response
  }

  putNote = async (id: number, params: IPutNoteParams) => {
    const response = await this.axios.put<IPayload<INote>>(Endpoints.putNote(id), params)
    return response
  }

  deleteNote = async (id: number) => {
    const response = await this.axios.delete<{}>(Endpoints.deleteNote(id))
    return response
  }
}
