import { AxiosService } from '../config/config'
import { Endpoints } from '../config/endpoints'
import { IPayload } from 'models/Global'
import {
  IUserPointOfContact,
  IGetUserPointsOfContactParams,
  IPutUserPointOfContactParams,
  IPostUserPointOfContactParams,
  IDeleteUserPointsOfContactParams,
} from 'models/UserPointOfContact'

export class UserPointsOfContactApi {
  constructor(private axios: AxiosService) {}

  getUserPointsOfContact = async (params?: IGetUserPointsOfContactParams) => {
    const response = await this.axios.get<IPayload<IUserPointOfContact[]>>(
      Endpoints.getUserPointsOfContact,
      params
    )
    return response
  }

  getUserPointOfContact = async (id: number, params?: IGetUserPointsOfContactParams) => {
    const response = await this.axios.get<IPayload<IUserPointOfContact>>(
      Endpoints.getUserPointOfContact(id),
      params
    )
    return response
  }

  postUserPointOfContact = async (params: IPostUserPointOfContactParams) => {
    const response = await this.axios.post<IPayload<IUserPointOfContact>>(
      Endpoints.postUserPointOfContact,
      params
    )
    return response
  }

  putUserPointOfContact = async (id: number, params?: IPutUserPointOfContactParams) => {
    const response = await this.axios.put<IPayload<IUserPointOfContact>>(
      Endpoints.putUserPointOfContact(id),
      params
    )
    return response
  }

  deleteUserPointOfContact = async (params: IDeleteUserPointsOfContactParams) => {
    const response = await this.axios.delete<{}>(Endpoints.deleteUserPointOfContact, undefined, {
      data: params,
    })
    return response
  }
}
