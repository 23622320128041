import { IGigTypeReducer, IGigType } from 'models/GigType'
import { Action } from 'redux'
import { isType } from 'ts-action'
import { updateArray, deleteFromArray } from 'helpers/helperFunctions'
import {
  GetGigTypes,
  GigTypesFetching,
  UpdateGigType,
  DeleteGigType,
  AddGigType,
  UpdateGigTypeLoadingId,
  GetGigTypesErrors,
} from './actions'

const initialState: IGigTypeReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: [],
  gigTypesLoadingIds: {},
}

export const gigTypesReducer = (state = initialState, action: Action): IGigTypeReducer => {
  if (isType(action, GetGigTypes)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
    }
  }
  if (isType(action, AddGigType)) {
    const { payload } = action
    const { data } = state
    const newArray = [payload.data, ...data]
    return {
      ...state,
      data: newArray,
    }
  }
  if (isType(action, UpdateGigType)) {
    const { payload } = action
    const { data } = state
    const currentStatus = data.find((element) => element.id === payload.data.id)
    let formattedArray
    if (currentStatus?.status !== payload.data.status) {
      // In this condition we're checking if the GigType status is changed
      // since in the current PositionCategorization table we're showing
      // gigTypes with only one status per tab
      // we want to remove gigTypes with different statuses
      formattedArray = deleteFromArray<IGigType>(data, payload.data.id)
    } else {
      formattedArray = updateArray<IGigType>(data, payload.data)
    }

    return {
      ...state,
      data: formattedArray,
      error: undefined,
    }
  }
  if (isType(action, DeleteGigType)) {
    const { payload } = action
    const { data } = state
    const formattedArray = deleteFromArray<IGigType>(data, payload)
    return {
      ...state,
      data: formattedArray,
    }
  }
  if (isType(action, UpdateGigTypeLoadingId)) {
    const { payload: gigTypeId } = action
    const newGigTypeLoadingIds = { ...state.gigTypesLoadingIds }
    if (newGigTypeLoadingIds[gigTypeId]) {
      delete newGigTypeLoadingIds[gigTypeId]
    } else {
      newGigTypeLoadingIds[gigTypeId] = true
    }
    return {
      ...state,
      gigTypesLoadingIds: newGigTypeLoadingIds,
    }
  }
  if (isType(action, GigTypesFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }
  if (isType(action, GetGigTypesErrors)) {
    const { payload } = action
    return {
      ...state,
      error: payload,
    }
  }
  return state
}
