import { ISelectedShiftApplicationsReducer, IApplication } from 'models/Application'
import { Action } from 'redux'
import { isType } from 'ts-action'
import { updateArray, deleteFromArray, maybeAddToArray } from 'helpers/helperFunctions'
import {
  GetSelectedShiftPendingApplications,
  GetSelectedShiftPendingApplicationsErrors,
  SelectedShiftPendingApplicationsFetching,
  AddSelectedShiftPendingApplication,
  UpdateSelectedShiftPendingApplication,
  DeleteSelectedShiftPendingApplication,
  ResetSelectedShiftPendingApplicationsReducer,
} from './actions'
import { IError } from 'models/Global'
import { FavoriteUpshifter, UnfavoriteUpshifter } from '../Favorite/actions'

const initialState: ISelectedShiftApplicationsReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  error: {} as IError,
  data: [],
}

export const selectedShiftPendingApplicationsReducer = (
  state = initialState,
  action: Action
): ISelectedShiftApplicationsReducer => {
  if (isType(action, GetSelectedShiftPendingApplications)) {
    const {
      payload: { meta, data },
    } = action
    return {
      ...state,
      meta,
      data,
    }
  }

  if (isType(action, AddSelectedShiftPendingApplication)) {
    const { payload } = action
    const { data } = state
    return {
      ...state,
      data: maybeAddToArray(data, payload.data),
    }
  }

  if (isType(action, UpdateSelectedShiftPendingApplication)) {
    const { payload } = action
    const { data } = state
    const formattedArray = updateArray<IApplication>(data, payload.data)
    return {
      ...state,
      data: formattedArray,
    }
  }

  if (isType(action, DeleteSelectedShiftPendingApplication)) {
    const { payload } = action
    const { data } = state
    const formattedArray = deleteFromArray<IApplication>(data, payload)
    return {
      ...state,
      data: formattedArray,
    }
  }

  // Favorite and Unfavorite Upshifter
  if (isType(action, FavoriteUpshifter)) {
    const newApplications = state.data.map((application) => {
      if (application.group_application) {
        application.group_application?.applications.data.map((groupApplication) => {
          if (groupApplication.applicant.id === action.payload.data.model_favored.id) {
            const modifiedApplication = groupApplication
            modifiedApplication.applicant.favorite = true
            modifiedApplication.applicant.favorite_id = action.payload.data.id
            return modifiedApplication
          }
          return groupApplication
        })
      } else if (application.applicant.id === action.payload.data.model_favored.id) {
        const modifiedApplication = application
        modifiedApplication.applicant.favorite = true
        modifiedApplication.applicant.favorite_id = action.payload.data.id
        return modifiedApplication
      }
      return application
    })
    return {
      ...state,
      data: newApplications,
    }
  }

  if (isType(action, UnfavoriteUpshifter)) {
    const { payload } = action
    const modifiedApplications = state.data.map((application) => {
      if (application.group_application) {
        application.group_application?.applications.data.map((groupApplication) => {
          if (groupApplication.applicant.favorite_id === action.payload) {
            const modifiedApplication = groupApplication
            modifiedApplication.applicant.favorite = false
            modifiedApplication.applicant.favorite_id = null
            return modifiedApplication
          }
          return groupApplication
        })
      } else if (application.applicant.favorite_id === payload) {
        const modifiedApplication = {
          ...application,
          applicant: {
            ...application.applicant,
            favorite: false,
            favorite_id: null,
          },
        }
        return modifiedApplication
      }
      return application
    })
    return {
      ...state,
      data: modifiedApplications,
    }
  }

  if (isType(action, SelectedShiftPendingApplicationsFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }

  if (isType(action, GetSelectedShiftPendingApplicationsErrors)) {
    const { payload } = action
    return {
      ...state,
      error: payload,
    }
  }

  if (isType(action, ResetSelectedShiftPendingApplicationsReducer)) {
    return initialState
  }

  return state
}
