import { ICountyReducer, ICounty } from 'models/County'
import { Action } from 'redux'
import { isType } from 'ts-action'
import { updateArray } from 'helpers/helperFunctions'
import { GetCounties, CountiesFetching, UpdateCounty } from './actions'

const initialState: ICountyReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: [],
}

export const countiesReducer = (state = initialState, action: Action): ICountyReducer => {
  if (isType(action, GetCounties)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
    }
  }
  if (isType(action, UpdateCounty)) {
    const { payload } = action
    const { data } = state
    const formattedArray = updateArray<ICounty>(data, payload.data)
    return {
      ...state,
      data: formattedArray,
    }
  }
  if (isType(action, CountiesFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }
  return state
}
