import { Action } from 'redux'
import { isType } from 'ts-action'
import {
  GetAllBusinessAutoAcceptCertificates,
  AllBusinessAutoAcceptCertificatesFetching,
  GetAllBusinessAutoAcceptCertificatesErrors,
  ClearAllBusinessAutoAcceptCertificates,
  RemoveAllBusinessAutoAcceptCertificate,
  AddAllBusinessAutoAcceptCertificate,
} from './actions'
import { deleteFromArray } from 'helpers/helperFunctions'
import {
  IAllBusinessAutoAcceptCertificatesReducer,
  IAutoAcceptCertificate,
} from 'models/AutoAcceptCertificate'

const initialState: IAllBusinessAutoAcceptCertificatesReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: [],
}

export const AllBusinessAutoAcceptCertificatesReducer = (
  state = initialState,
  action: Action
): IAllBusinessAutoAcceptCertificatesReducer => {
  if (isType(action, GetAllBusinessAutoAcceptCertificates)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
    }
  }
  if (isType(action, AllBusinessAutoAcceptCertificatesFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }
  if (isType(action, GetAllBusinessAutoAcceptCertificatesErrors)) {
    const { payload } = action
    return {
      ...state,
      error: payload,
    }
  }

  if (isType(action, RemoveAllBusinessAutoAcceptCertificate)) {
    const { payload } = action
    const filteredData = deleteFromArray<IAutoAcceptCertificate>(state.data, payload)
    return {
      ...state,
      data: filteredData,
    }
  }
  if (isType(action, AddAllBusinessAutoAcceptCertificate)) {
    const { payload } = action
    return {
      ...state,
      data: [payload, ...state.data],
    }
  }
  if (isType(action, ClearAllBusinessAutoAcceptCertificates)) {
    return initialState
  }
  return state
}
