import { AxiosService } from '../config/config'
import { Endpoints } from '../config/endpoints'
import { IPayload } from 'models/Global'
import {
  IVaccinationForm,
  IGetVaccinationFormsParams,
  IPutVaccinationFormParams,
} from 'models/VaccinationForm'

export class VaccinationFormsApi {
  constructor(private axios: AxiosService) {}

  getVaccinationForms = async (params?: IGetVaccinationFormsParams) => {
    const response = await this.axios.get<IPayload<IVaccinationForm[]>>(
      Endpoints.getVaccinationForms,
      params
    )
    return response
  }

  getVaccinationForm = async (id: number, params?: IGetVaccinationFormsParams) => {
    const response = await this.axios.get<IPayload<IVaccinationForm>>(
      Endpoints.getVaccinationForm(id),
      params
    )
    return response
  }

  postVaccinationForm = async (params: FormData) => {
    const response = await this.axios.post<IPayload<IVaccinationForm>>(
      Endpoints.postVaccinationForm,
      params
    )
    return response
  }

  putVaccinationForm = async (id: number, params?: IPutVaccinationFormParams) => {
    const response = await this.axios.post<IPayload<IVaccinationForm>>(
      Endpoints.putVaccinationForm(id),
      params
    )
    return response
  }

  deleteVaccinationForm = async (id: number) => {
    const response = await this.axios.delete<{}>(Endpoints.deleteVaccinationForm(id))
    return response
  }
}
