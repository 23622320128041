import { AxiosService } from '../config/config'
import { Endpoints } from '../config/endpoints'
import { IPayload } from 'models/Global'
import {
  IGetFavoritesParams,
  IFavorite,
  IPostFavoriteParams,
  IPutFavoriteParams,
} from 'models/Favorite'

export class FavoritesApi {
  constructor(private axios: AxiosService) {}

  getFavorites = async (params?: IGetFavoritesParams) => {
    const response = await this.axios.get<IPayload<IFavorite[]>>(Endpoints.getFavorites, params)
    return response
  }

  getFavorite = async (id: number, params?: IGetFavoritesParams) => {
    const response = await this.axios.get<IPayload<IFavorite>>(Endpoints.getFavorite(id), params)
    return response
  }

  postFavorite = async (params: IPostFavoriteParams) => {
    const response = await this.axios.post<IPayload<IFavorite>>(Endpoints.postFavorite, params)
    return response
  }

  putFavorite = async (id: number, params: IPutFavoriteParams) => {
    const response = await this.axios.put<IPayload<IFavorite>>(Endpoints.putFavorite(id), params)
    return response
  }

  deleteFavorite = async (id: number) => {
    const response = await this.axios.delete<{}>(Endpoints.deleteFavorite(id), {})
    return response
  }
}
