import loadable from '@loadable/component'
import { retry } from 'helpers/helperFunctions'
import FullScreenLoader from 'ui/components/common/FullScreenLoader'

export const AsyncBusinessEntity = loadable(
  () => retry(() => import(/* webpackChunkName: "BusinessEntity" , */ './Admin/BusinessEntity')),
  {
    fallback: <FullScreenLoader />,
  }
)
export const AsyncActiveShifts = loadable(
  () => retry(() => import(/* webpackChunkName: "ActiveShifts" , */ './Admin/ActiveShifts')),
  {
    fallback: <FullScreenLoader />,
  }
)
export const AsyncClockedInUpshifters = loadable(
  () =>
    retry(
      () => import(/* webpackChunkName: "ClockedInUpshifters" */ './Admin/ClockedInUpshifters')
    ),
  {
    fallback: <FullScreenLoader />,
  }
)
export const AsyncBusiness = loadable(
  () => retry(() => import(/* webpackChunkName: "Businesses" */ './Admin/Businesses')),
  {
    fallback: <FullScreenLoader />,
  }
)
export const AsyncBusinessEntityAreas = loadable(
  () =>
    retry(
      () => import(/* webpackChunkName: "BusinessEntityAreas" */ './Admin/BusinessEntityAreas')
    ),
  {
    fallback: <FullScreenLoader />,
  }
)
export const AsyncBusinessEntityAttachments = loadable(
  () =>
    retry(
      () =>
        import(
          /* webpackChunkName: "BusinessEntityAttachments" */ './Admin/BusinessEntityAttachments'
        )
    ),
  {
    fallback: <FullScreenLoader />,
  }
)
export const AsyncBusinessEntityReviews = loadable(
  () =>
    retry(
      () => import(/* webpackChunkName: "BusinessEntityReviews" */ './Admin/BusinessEntityReviews')
    ),
  {
    fallback: <FullScreenLoader />,
  }
)
export const AsyncBusinessEntityFinance = loadable(
  () =>
    retry(
      () => import(/* webpackChunkName: "BusinessEntityFinance" */ './Admin/BusinessEntityFinance')
    ),
  {
    fallback: <FullScreenLoader />,
  }
)
export const AsyncBusinessEntityBillingDetails = loadable(
  () => retry(() => import(/* webpackChunkName: "BillingDetails" */ './Admin/BillingDetails')),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncBusinessEntityShifts = loadable(
  () =>
    retry(
      () => import(/* webpackChunkName: "BusinessEntityShifts" */ './Admin/BusinessEntityShifts')
    ),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncTemplates = loadable(
  () => retry(() => import(/* webpackChunkName: "Templates" */ './Admin/Templates')),
  {
    fallback: <FullScreenLoader />,
  }
)
export const AsyncBusinessEntityAddInvoiceGroup = loadable(
  () =>
    retry(
      () =>
        import(
          /* webpackChunkName: "BusinessEntityAddInvoiceGroup" */ './Admin/AddEditBusinessEntityInvoiceGroup/AddBusinessEntityInvoiceGroup'
        )
    ),
  {
    fallback: <FullScreenLoader />,
  }
)
export const AsyncBusinessEntityLocations = loadable(
  () =>
    retry(
      () =>
        import(/* webpackChunkName: "BusinessEntityLocations" */ './Admin/BusinessEntityLocations')
    ),
  {
    fallback: <FullScreenLoader />,
  }
)
export const AsyncBusinessEntityEditInvoiceGroup = loadable(
  () =>
    retry(
      () =>
        import(
          /* webpackChunkName: "BusinessEntityEditInvoiceGroup" */ './Admin/AddEditBusinessEntityInvoiceGroup/EditBusinessEntityInvoiceGroup'
        )
    ),
  {
    fallback: <FullScreenLoader />,
  }
)
export const AsyncBusinessEntityTimesheets = loadable(
  () =>
    retry(
      () =>
        import(
          /* webpackChunkName: "BusinessEntityTimesheets" */ './Admin/FinanceModule/Timesheets'
        )
    ),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncShifts = loadable(
  () => retry(() => import(/* webpackChunkName: "Shifts" */ './Admin/Shifts')),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncBulkEditShifts = loadable(
  () => retry(() => import(/* webpackChunkName: "AsyncBulkEditShifts" */ './Admin/BulkEditShifts')),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncBusinessEntityUsers = loadable(
  () =>
    retry(
      () => import(/* webpackChunkName: "AsyncBusinessEntityUsers" */ './Admin/BusinessEntityUsers')
    ),
  {
    fallback: <FullScreenLoader />,
  }
)
export const AsyncNotes = loadable(() =>
  retry(() => import(/* webpackChunkName: "Notes" */ './Admin/Notes'))
)

export const AsyncBusinesses = loadable(() =>
  retry(() => import(/* webpackChunkName: "Businesses" */ './Admin/Businesses'))
)

export const AsyncEditBusinessEntity = loadable(
  () => retry(() => import(/* webpackChunkName: "Edit Info" */ './Admin/EditBusinessEntity')),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncCreateBusinessEntity = loadable(() =>
  retry(() => import(/* webpackChunkName: "CreateBusinessEntity" */ './Admin/CreateBusinessEntity'))
)

export const AsyncBusinessEntityChildrenCompanies = loadable(
  () =>
    retry(
      () =>
        import(
          /* webpackChunkName: "BusinessEntityChildrenCompanies" */ './Admin/ChildrenCompanies'
        )
    ),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncBusinessEntityAcknowledgements = loadable(
  () =>
    retry(
      () =>
        import(/* webpackChunkName: "BusinessEntityAcknowledgements" */ './Admin/Acknowledgements')
    ),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncDocumentsAcknowledged = loadable(
  () =>
    retry(
      () => import(/* webpackChunkName: "DocumentsAcknowledged" */ './Admin/AcknowledgedUpshifters')
    ),
  {
    fallback: <FullScreenLoader />,
  }
)
export const AsyncFinanceInvoices = loadable(
  () =>
    retry(() => import(/* webpackChunkName: "FinanceInvoices" */ './Admin/FinanceModule/Invoices')),
  {
    fallback: <FullScreenLoader />,
  }
)
export const AsyncFinanceInvoice = loadable(
  () =>
    retry(() => import(/* webpackChunkName: "FinanceInvoice" */ './Admin/FinanceModule/Invoice')),
  {
    fallback: <FullScreenLoader />,
  }
)
export const AsyncFinanceInvoiceSendMail = loadable(
  () =>
    retry(
      () =>
        import(
          /* webpackChunkName: "FinanceInvoiceSendMail" */ './Admin/FinanceModule/InvoiceSendMail'
        )
    ),
  {
    fallback: <FullScreenLoader />,
  }
)
export const AsyncDrugTesting = loadable(
  () => retry(() => import(/* webpackChunkName: "DrugTesting" */ './Admin/DrugTesting')),
  {
    fallback: <FullScreenLoader />,
  }
)
export const AsyncGamificationSystem = loadable(
  () =>
    retry(() => import(/* webpackChunkName: "GamificationSystem" */ './Admin/GamificationSystem')),
  {
    fallback: <FullScreenLoader />,
  }
)
export const AsyncCreateEditChallenge = loadable(
  () =>
    retry(
      () => import(/* webpackChunkName: "CreateEditChallenge" */ './Admin/CreateEditChallenge')
    ),
  {
    fallback: <FullScreenLoader />,
  }
)
export const AsyncCreateEditAchievement = loadable(
  () =>
    retry(
      () => import(/* webpackChunkName: "CreateEditAchievement" */ './Admin/CreateEditAchievement')
    ),
  {
    fallback: <FullScreenLoader />,
  }
)
export const AsyncTimesheets = loadable(
  () =>
    retry(() => import(/* webpackChunkName: "Timesheets" */ './Admin/FinanceModule/Timesheets')),
  {
    fallback: <FullScreenLoader />,
  }
)
export const AsyncTimesheet = loadable(
  () => retry(() => import(/* webpackChunkName: "Timesheet" */ './Admin/FinanceModule/Timesheet')),
  {
    fallback: <FullScreenLoader />,
  }
)
export const AsyncAreasCountries = loadable(
  () => retry(() => import(/* webpackChunkName: "AreasCountries" */ './Admin/Areas/Countries')),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncAreasState = loadable(
  () => retry(() => import(/* webpackChunkName: "AsyncAreasState" */ './Admin/Areas/State')),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncAreasCountry = loadable(
  () => retry(() => import(/* webpackChunkName: "AreasCountry" */ './Admin/Areas/Country')),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncAreasCounty = loadable(
  () => retry(() => import(/* webpackChunkName: "AsyncAreasCounty" */ './Admin/Areas/County')),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncSickLeaveTemplates = loadable(
  () =>
    retry(() => import(/* webpackChunkName: "SickLeaveTemplates" */ './Admin/SickLeaveTemplates')),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncBusinessUpshifters = loadable(
  () =>
    retry(() => import(/* webpackChunkName: "BusinessUpshifters" */ './Admin/BusinessUpshifters')),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncUserSickPayHours = loadable(
  () => retry(() => import(/* webpackChunkName: "UserSickPayHours" */ './Admin/UserSickPayHours')),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncUserAccruedSickHours = loadable(() =>
  retry(() => import(/* webpackChunkName: "UserAccruedSickHours" */ './Admin/UserAccruedSickHours'))
)

export const AsyncSickPaydayForm = loadable(
  () =>
    retry(() => import(/* webpackChunkName: "SickPaydayForm" */ './Admin/SickPaydayPolicyForm')),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncAddDeductionTypes = loadable(
  () =>
    retry(() => import(/* webpackChunkName: "AddDeductionTypes" */ './Admin/AddDeductionTypes')),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncLogin = loadable(
  () => retry(() => import(/* webpackChunkName: "Login" */ './Login')),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncLogout = loadable(
  () => retry(() => import(/* webpackChunkName: "Logout" */ './Logout')),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncTwoFactorAuthentication = loadable(
  () =>
    retry(
      () =>
        import(
          /* webpackChunkName: "AsyncTwoFactorAuthentication" */ './Admin/TwoFactorAuthentication'
        )
    ),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncOnboarderUpshifterView = loadable(
  () =>
    retry(
      () => import(/* webpackChunkName: "OnboarderUpshifterView" */ './Onboarder/UpshifterView')
    ),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncOnboarderUpshifterList = loadable(
  () =>
    retry(
      () => import(/* webpackChunkName: "OnboarderUpshifterList" */ './Onboarder/UpshiftersList')
    ),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncAdminUpshifterList = loadable(
  () => retry(() => import(/* webpackChunkName: "AdminUpshifterList" */ './Admin/UpshiftersList')),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncAddAdminUser = loadable(
  () => retry(() => import(/* webpackChunkName: "AsyncAddAdminUser" */ './Admin/AddAdminUser')),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncEditAdminUser = loadable(
  () => retry(() => import(/* webpackChunkName: "AsyncEditAdminUser" */ './Admin/EditAdminUser')),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncAdminUsers = loadable(
  () => retry(() => import(/* webpackChunkName: "AsyncAdminUsers" */ './Admin/AdminUsers')),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncUpshifterView = loadable(
  () => retry(() => import(/* webpackChunkName: "AsyncUpshifterView" */ './Admin/UpshifterView')),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncFinanceAgingReport = loadable(
  () =>
    retry(
      () => import(/* webpackChunkName: "AsyncAgingReport" */ './Admin/FinanceModule/AgingReport')
    ),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncReportsList = loadable(
  () =>
    retry(() => import(/* webpackChunkName: "AsyncReportsList" */ './Admin/Reports/ReportsList')),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncGrowthReport = loadable(
  () =>
    retry(() => import(/* webpackChunkName: "AsyncGrowthReport" */ './Admin/Reports/GrowthReport')),
  {
    fallback: <FullScreenLoader />,
  }
)
export const AsyncTerminatedAndReinstatedUpshiftersReport = loadable(
  () =>
    retry(
      () =>
        import(
          /* webpackChunkName: "AsyncTerminatedAndReinstatedUpshiftersReport" */ './Admin/Reports/TerminatedAndReinstatedUpshiftersReport'
        )
    ),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncMultiplePunchCardsReport = loadable(
  () =>
    retry(
      () =>
        import(
          /* webpackChunkName: "AsyncMultiplePunchCardsReport" */ './Admin/Reports/MultiplePunchCardReport'
        )
    ),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncARBillReport = loadable(
  () =>
    retry(() => import(/* webpackChunkName: "AsyncARBillReport" */ './Admin/Reports/ARBillReport')),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncPayrollReport = loadable(
  () =>
    retry(
      () => import(/* webpackChunkName: "AsyncPayrollReport" */ './Admin/Reports/PayrollReport')
    ),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncAMSuccessReport = loadable(
  () =>
    retry(
      () => import(/* webpackChunkName: "AsyncAMSuccessReport" */ './Admin/Reports/AMSuccessReport')
    ),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncWeeklyEmployeeReport = loadable(
  () =>
    retry(
      () =>
        import(
          /* webpackChunkName: "AsyncWeeklyEmployeeReport" */ './Admin/Reports/WeeklyEmployeeReport'
        )
    ),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncMonthlySalesTaxReport = loadable(
  () =>
    retry(
      () =>
        import(
          /* webpackChunkName: "AsyncMonthlySalesTaxReport" */ './Admin/Reports/MonthlySalesTaxReport'
        )
    ),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncAgingReport = loadable(
  () =>
    retry(() => import(/* webpackChunkName: "AsyncAgingReport" */ './Admin/Reports/AgingReport')),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncFakePunchCardsReport = loadable(
  () =>
    retry(
      () =>
        import(
          /* webpackChunkName: "AsyncFakePunchCardsReport" */ './Admin/Reports/FakePunchCardsReport'
        )
    ),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncPaymentApplicationReport = loadable(
  () =>
    retry(
      () =>
        import(
          /* webpackChunkName: "AsyncPaymentApplicationReport" */ './Admin/Reports/PaymentApplicationReport'
        )
    ),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncAMKPIReport = loadable(
  () =>
    retry(() => import(/* webpackChunkName: "AsyncAMKPIReport" */ './Admin/Reports/AMKPIReport')),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncAccountingReport = loadable(
  () =>
    retry(
      () =>
        import(/* webpackChunkName: "AsyncAccountingReport" */ './Admin/Reports/AccountingReport')
    ),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncAccountManagersShiftsReport = loadable(
  () =>
    retry(
      () =>
        import(
          /* webpackChunkName: "AsyncAccountManagersShiftsReport" */ './Admin/Reports/AccountManagersShiftsReport'
        )
    ),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncCRBonusReport = loadable(
  () =>
    retry(
      () => import(/* webpackChunkName: "AsyncCRBonusReport" */ './Admin/Reports/CRBonusReport')
    ),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncHighLevelStatsReport = loadable(
  () =>
    retry(
      () =>
        import(
          /* webpackChunkName: "AsyncHighLevelStatsReport" */ './Admin/Reports/HighLevelStatsReport'
        )
    ),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncFoodbuyReport = loadable(
  () =>
    retry(
      () => import(/* webpackChunkName: "AsyncFoodbuyReport" */ './Admin/Reports/FoodbuyReport')
    ),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncCompassApprovalReport = loadable(
  () =>
    retry(
      () =>
        import(
          /* webpackChunkName: "AsyncCompassApprovalReport" */ './Admin/Reports/CompassApprovalReport'
        )
    ),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncExpiringDocumentsReport = loadable(
  () =>
    retry(
      () =>
        import(
          /* webpackChunkName: "AsyncExpiringDocumentsReport" */ './Admin/Reports/ExpiringDocumentsReport'
        )
    ),
  {
    fallback: <FullScreenLoader />,
  }
)
export const AsyncLocationsReport = loadable(
  () =>
    retry(
      () => import(/* webpackChunkName: "AsyncLocationsReport" */ './Admin/Reports/LocationsReport')
    ),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncReturnRateReport = loadable(
  () =>
    retry(
      () =>
        import(/* webpackChunkName: "AsyncReturnRateReport" */ './Admin/Reports/ReturnRateReport')
    ),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncUpshifterHoursReport = loadable(
  () =>
    retry(
      () =>
        import(
          /* webpackChunkName: "AsyncUpshifterHoursReport" */ './Admin/Reports/UpshifterHoursReport'
        )
    ),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncAccountBreakdownReport = loadable(
  () =>
    retry(
      () =>
        import(
          /* webpackChunkName: "AsyncAccountBreakdownReport" */ './Admin/Reports/AccountBreakdownReport'
        )
    ),
  {
    resolveComponent: (components) => components.AccountBreakdownReport,
  }
)

export const AsyncProcessedTimesheetReport = loadable(() =>
  retry(
    () =>
      import(
        /* webpackChunkName: "AsyncProcessedTimesheetReport" */ './Admin/Reports/ProcessedTimesheetReport'
      )
  )
)

export const AsyncLocations = loadable(
  () => retry(() => import(/* webpackChunkName: "AsyncLocations" */ './Admin/Locations')),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncLocation = loadable(
  () => retry(() => import(/* webpackChunkName: "AsyncLocation" */ './Admin/Location')),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncAddLocation = loadable(
  () => retry(() => import(/* webpackChunkName: "AsyncAddLocation" */ './Admin/AddLocation')),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncLocationCategories = loadable(
  () =>
    retry(
      () => import(/* webpackChunkName: "AsyncLocationCategories" */ './Admin/LocationCategories')
    ),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncLocationTypes = loadable(
  () => retry(() => import(/* webpackChunkName: "AsyncLocationTypes" */ './Admin/LocationTypes')),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncBusinessUsersList = loadable(
  () =>
    retry(
      () => import(/* webpackChunkName: "AsyncBusinessUsersList" */ './Admin/BusinessUsersList')
    ),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncPositionCategorization = loadable(
  () =>
    retry(
      () =>
        import(
          /* webpackChunkName: "AsyncPositionCategorization" */ './Admin/PositionCategorization'
        )
    ),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncCertificates = loadable(
  () => retry(() => import(/* webpackChunkName: "AsyncCertificates" */ './Admin/Certificates')),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncStrikeTypes = loadable(
  () => retry(() => import(/* webpackChunkName: "AsyncStrikeTypes" */ './Admin/StrikeTypes')),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncCertificateOrganizations = loadable(
  () =>
    retry(
      () =>
        import(
          /* webpackChunkName: "AsyncCertificateOrganizations" */ './Admin/CertificateOrganizations'
        )
    ),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncUserAttachmentTypes = loadable(
  () =>
    retry(
      () => import(/* webpackChunkName: "AsyncUserAttachmentTypes" */ './Admin/UserAttachmentTypes')
    ),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncPayRateSuggestions = loadable(
  () =>
    retry(
      () => import(/* webpackChunkName: "AsyncPayRateSuggestions" */ './Admin/PayRateSuggestions')
    ),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncFeaturedShiftsCampaigns = loadable(
  () =>
    retry(
      () =>
        import(
          /* webpackChunkName: "AsyncFeaturedShiftsCampaigns" */ './Admin/FeaturedShiftsCampaigns'
        )
    ),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncEditFeaturedShiftsCampaign = loadable(
  () =>
    retry(
      () =>
        import(
          /* webpackChunkName: "AsyncEditFeaturedShiftsCampaign" */ './Admin/EditFeaturedShiftsCampaign'
        )
    ),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncFeaturedShiftsCampaignAdd = loadable(
  () =>
    retry(
      () =>
        import(
          /* webpackChunkName: "AsyncFeaturedShiftsCampaignAdd" */ './Admin/FeaturedShiftsCampaignAdd'
        )
    ),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncFeaturedShiftsCampaignCreate = loadable(
  () =>
    retry(
      () =>
        import(
          /* webpackChunkName: "AsyncFeaturedShiftsCampaignCreate" */ './Admin/FeaturedShiftsCampaignCreate'
        )
    ),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncFeaturedShiftsCampaignCreateStep = loadable(
  () =>
    retry(
      () =>
        import(
          /* webpackChunkName: "AsyncFeaturedShiftsCampaignCreateStep" */ './Admin/FeaturedShiftsCampaignCreateStep'
        )
    ),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncShiftFullDuplicate = loadable(
  () =>
    retry(() => import(/* webpackChunkName: "AsyncShiftFullDuplicate" */ './Admin/DuplicateShift')),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncShiftFullEdit = loadable(
  () => retry(() => import(/* webpackChunkName: "AsyncShiftFullEdit" */ './Admin/EditShift')),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncAddTemplate = loadable(
  () => retry(() => import(/* webpackChunkName: "AsyncAddTemplate" */ './Admin/AddTemplate')),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncEditTemplate = loadable(
  () => retry(() => import(/* webpackChunkName: "AsyncEditTemplate" */ './Admin/EditTemplate')),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncPendingDeletionPunchcards = loadable(
  () =>
    retry(
      () =>
        import(
          /* webpackChunkName: "AsyncPendingDeletionPunchcards" */ './Admin/FinanceModule/PendingDeletionPunchcards'
        )
    ),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncVaccinationForms = loadable(
  () =>
    retry(() => import(/* webpackChunkName: "AsyncVaccinationForms" */ './Admin/VaccinationForms')),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncAddVaccinationForm = loadable(
  () =>
    retry(
      () => import(/* webpackChunkName: "AsyncAddVaccinationForm" */ './Admin/AddVaccinationForm')
    ),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncEditBusinessUser = loadable(
  () =>
    retry(() => import(/* webpackChunkName: "AsyncEditBusinessUser" */ './Admin/EditBusinessUser')),
  {
    fallback: <FullScreenLoader />,
  }
)
export const AsyncFinanceAddEditExpenseType = loadable(
  () =>
    retry(
      () =>
        import(/* webpackChunkName: "ExpenseTypes" */ './Admin/FinanceModule/AddEditExpenseType')
    ),
  {
    fallback: <FullScreenLoader />,
  }
)
export const AsyncFinanceExpenseTypes = loadable(
  () =>
    retry(
      () => import(/* webpackChunkName: "ExpenseTypes" */ './Admin/FinanceModule/ExpenseTypes')
    ),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncPendingVerificationPunchcards = loadable(
  () =>
    retry(
      () =>
        import(
          /* webpackChunkName: "AsyncPendingVerificationPunchcards" */ './Admin/FinanceModule/PendingVerifications'
        )
    ),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncFollowManagement = loadable(
  () =>
    retry(() => import(/* webpackChunkName: "AsyncFollowManagement" */ './Admin/FollowManagement')),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncPendingSuspension = loadable(
  () =>
    retry(
      () => import(/* webpackChunkName: "AsyncPendingSuspension" */ './Admin/PendingSuspension')
    ),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncChangePassword = loadable(
  () => retry(() => import(/* webpackChunkName: "AsyncChangePassword" */ './Admin/ChangePassword')),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncPasswordRecovery = loadable(
  () => retry(() => import(/* webpackChunkName: "PasswordRecovery" */ './Login/PasswordRecovery')),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncShiftIdentifierPolicies = loadable(
  () =>
    retry(
      () =>
        import(/* webpackChunkName: "ShiftIdentifierPolicies" */ './Admin/ShiftIdentifierPolicies')
    ),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncAddShiftIdentifierPolicy = loadable(
  () =>
    retry(
      () =>
        import(
          /* webpackChunkName: "AsyncAddShiftIdentifierPolicy" */ './Admin/ShiftIdentifierPolicy'
        )
    ),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncEditShiftIdentifierPolicy = loadable(
  () =>
    retry(
      () =>
        import(/* webpackChunkName: "EditShiftIdentifierPolicy" */ './Admin/ShiftIdentifierPolicy')
    ),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncOnboardingWorkflows = loadable(
  () =>
    retry(
      () => import(/* webpackChunkName: "AsyncOnboardingWorkflows" */ './Admin/OnboardingWorkflows')
    ),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncAddEditOnboardingWorkflow = loadable(
  () =>
    retry(
      () =>
        import(
          /* webpackChunkName: "AsyncAddEditOnboardingWorkflow" */ './Admin/AddEditOnboardingWorkflow'
        )
    ),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncUniversalTemplates = loadable(
  () =>
    retry(() => import(/* webpackChunkName: "UniversalTemplates" */ './Admin/UniversalTemplates')),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncAddEditUniversalTemplate = loadable(
  () =>
    retry(
      () =>
        import(
          /* webpackChunkName: "AddEditUniversalTemplate" */ './Admin/AddEditUniversalTemplate'
        )
    ),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncGroups = loadable(
  () => retry(() => import(/* webpackChunkName: "WorkWithFriendsGroups" */ './Admin/Groups')),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncEditGroup = loadable(
  () =>
    retry(() => import(/* webpackChunkName: "ViewEditWorkWithFriendsGroup" */ './Admin/EditGroup')),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncPositionCategories = loadable(
  () =>
    retry(() => import(/* webpackChunkName: "PositionCategories" */ './Admin/PositionCategories')),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncBusinessEntitySettings = loadable(
  () =>
    retry(
      () =>
        import(/* webpackChunkName: "BusinessEntitySettings" */ './Admin/BusinessEntitySettings')
    ),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncWorkerCompensationRates = loadable(
  () =>
    retry(
      () =>
        import(/* webpackChunkName: "WorkerCompensationRates" */ './Admin/WorkerCompensationRates')
    ),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncVendorManagementSystems = loadable(
  () =>
    retry(
      () =>
        import(/* webpackChunkName: "VendorManagementSystems" */ './Admin/VendorManagementSystems')
    ),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncAddVendorManagementSystem = loadable(
  () =>
    retry(
      () =>
        import(
          /* webpackChunkName: "AddVendorManagementSystem" */ './Admin/AddVendorManagementSystem'
        )
    ),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncViewVendorManagementSystem = loadable(
  () =>
    retry(
      () =>
        import(
          /* webpackChunkName: "ViewVendorManagementSystem" */ './Admin/ViewVendorManagementSystem'
        )
    ),
  {
    fallback: <FullScreenLoader />,
  }
)

export const AsyncCertificationRequests = loadable(
  () =>
    retry(
      () => import(/* webpackChunkName: "CertificationRequests" */ './Admin/CertificationRequests')
    ),
  {
    fallback: <FullScreenLoader />,
  }
)
