import { AxiosService } from '../config/config'
import { Endpoints } from '../config/endpoints'
import { IPayload } from 'models/Global'
import {
  IDeduction,
  IGetDeductionsParams,
  IPutDeductionParams,
  IPostDeductionParams,
} from 'models/Deduction'

export class DeductionsApi {
  constructor(private axios: AxiosService) {}

  getDeductions = async (params?: IGetDeductionsParams) => {
    const response = await this.axios.get<IPayload<IDeduction[]>>(Endpoints.getDeductions, params)
    return response
  }

  getDeduction = async (id: number, params?: IGetDeductionsParams) => {
    const response = await this.axios.get<IPayload<IDeduction>>(Endpoints.getDeduction(id), params)
    return response
  }

  postDeduction = async (params: IPostDeductionParams) => {
    const response = await this.axios.post<IPayload<IDeduction>>(Endpoints.postDeduction, params)
    return response
  }

  putDeduction = async (id: number, params?: IPutDeductionParams) => {
    const response = await this.axios.put<IPayload<IDeduction>>(Endpoints.putDeduction(id), params)
    return response
  }

  deleteDeduction = async (id: number) => {
    const response = await this.axios.delete<{}>(Endpoints.deleteDeduction(id))
    return response
  }
}
