import { action, payload } from 'ts-action'
import { IVendorManagementSystem } from '../../models/VendorManagementSystem'
import { IError, IPayload } from '../../models/Global'

export const VendorManagementSystemsFetching = action(
  'VendorManagementSystemsFetching',
  payload<boolean>()
)

export const GetVendorManagementSystems = action(
  'GetVendorManagementSystems',
  payload<IPayload<IVendorManagementSystem[]>>()
)

export const AddVendorManagementSystem = action(
  'AddVendorManagementSystem',
  payload<IPayload<IVendorManagementSystem>>()
)

export const UpdateVendorManagementSystem = action(
  'UpdateVendorManagementSystem',
  payload<IPayload<IVendorManagementSystem>>()
)

export const GetVendorManagementSystemsErrors = action(
  'GetVendorManagementSystemsErrors',
  payload<IError>()
)
