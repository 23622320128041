import { AxiosService } from '../config/config'
import { Endpoints } from '../config/endpoints'
import { IPayload } from 'models/Global'
import {
  IGetOnboardingWorkflowsParamsBase,
  IOnboardingWorkflow,
  IPostOnboardingWorkflowParams,
  TGetOnboardingWorkflowParams,
} from 'models/OnboardingWorkflow'

export class OnboardingWorkflowsApi {
  constructor(private axios: AxiosService) {}

  getOnboardingWorkflows = async (params?: IGetOnboardingWorkflowsParamsBase) => {
    const response = await this.axios.get<IPayload<IOnboardingWorkflow[]>>(
      Endpoints.getOnboardingWorkflows,
      params
    )
    return response
  }

  getOnboardingWorkflow = async (id: number, params?: TGetOnboardingWorkflowParams) => {
    const response = await this.axios.get<IPayload<IOnboardingWorkflow>>(
      Endpoints.getOnboardingWorkflow(id),
      params
    )
    return response
  }

  postOnboardingWorkflow = async (params: IPostOnboardingWorkflowParams) => {
    const response = await this.axios.post<IPayload<IOnboardingWorkflow>>(
      Endpoints.postOnboardingWorkflow,
      params
    )
    return response
  }

  putOnboardingWorkflow = async (id: number, params?: IPostOnboardingWorkflowParams) => {
    const response = await this.axios.post<IPayload<IOnboardingWorkflow>>(
      Endpoints.putOnboardingWorkflow(id),
      params
    )
    return response
  }

  deleteOnboardingWorkflow = async (id: number) => {
    const response = await this.axios.delete<{}>(Endpoints.deleteOnboardingWorkflow(id))
    return response
  }
}
