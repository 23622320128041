import { action, payload } from 'ts-action'
import { IProperty } from 'models/Property'
import { IError, IPayload } from 'models/Global'

export const InvoiceGroupPropertiesFetching = action(
  'InvoiceGroupPropertiesFetching',
  payload<boolean>()
)

export const GetInvoiceGroupProperties = action(
  'GetInvoiceGroupProperties',
  payload<IPayload<IProperty[]>>()
)

export const AddInvoiceGroupProperty = action(
  'AddInvoiceGroupProperty',
  payload<IPayload<IProperty>>()
)

export const UpdateInvoiceGroupProperty = action(
  'UpdateInvoiceGroupProperty',
  payload<IPayload<IProperty>>()
)

export const DeleteInvoiceGroupProperty = action('DeleteInvoiceGroupProperty', payload<number>())

export const GetInvoiceGroupPropertiesErrors = action(
  'GetInvoiceGroupPropertiesErrors',
  payload<IError>()
)
