import { action, payload } from 'ts-action'
import { ICertificate } from 'models/Certificate'
import { IError, IPayload } from 'models/Global'

export const UnassignedCertificatesFetching = action(
  'UnassignedCertificatesFetching',
  payload<boolean>()
)
export const GetUnassignedCertificates = action(
  'GetUnassignedCertificates',
  payload<IPayload<ICertificate[]>>()
)
export const GetUnassignedCertificatesErrors = action(
  'GetUnassignedCertificatesErrors',
  payload<IError>()
)
export const RemoveUnassignedCertificate = action('RemoveUnassignedCertificate', payload<number>())
export const AddUnassignedCertificate = action('AddUnassignedCertificate', payload<ICertificate>())

export const AssignButtonLoading = action('AssignedButtonLoading', payload<number>())
export const AssignButtonStopLoading = action('AssignedButtonStopLoading', payload<number>())
export const ClearUnassignedCertificates = action('ClearUnassignedCertificates')
