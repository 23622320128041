import { action, payload } from 'ts-action'
import { IError, IPayload } from 'models/Global'
import { IProperty } from 'models/Property'

export const PropertiesFetching = action('PropertiesFetching', payload<boolean>())

export const GetProperties = action('GetProperties', payload<IPayload<IProperty[]>>())

export const AddProperty = action('AddProperty', payload<IPayload<IProperty>>())

export const DeleteProperty = action('DeleteProperty', payload<number>())

export const GetPropertiesErrors = action('GetPropertiesErrors', payload<IError>())
