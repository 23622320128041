import { IPayload } from 'models/Global'
import {
  IGetUniversalTemplatesParams,
  IPostUniversalTemplateParams,
  IPutUniversalTemplateParams,
  IUniversalTemplate,
} from 'models/UniversalTemplate'
import { AxiosService } from '../config/config'
import { Endpoints } from '../config/endpoints'

export class UniversalTemplatesApi {
  constructor(private axios: AxiosService) {}

  getUniversalTemplates = async (params?: IGetUniversalTemplatesParams) => {
    const response = await this.axios.get<IPayload<IUniversalTemplate[]>>(
      Endpoints.getUniversalTemplates,
      params
    )
    return response
  }

  getUniversalTemplate = async (id: number, params?: IGetUniversalTemplatesParams) => {
    const response = await this.axios.get<IPayload<IUniversalTemplate>>(
      Endpoints.getUniversalTemplate(id),
      params
    )
    return response
  }

  postUniversalTemplate = async (params: IPostUniversalTemplateParams) => {
    const response = await this.axios.post<IPayload<IUniversalTemplate>>(
      Endpoints.postUniversalTemplate,
      params
    )
    return response
  }

  putUniversalTemplate = async (id: number, params?: IPutUniversalTemplateParams) => {
    const response = await this.axios.put<IPayload<IUniversalTemplate>>(
      Endpoints.putUniversalTemplate(id),
      params
    )
    return response
  }

  deleteUniversalTemplate = async (id: number) => {
    const response = await this.axios.delete<{}>(Endpoints.deleteUniversalTemplate(id))
    return response
  }

  assignUniversalTemplate = async (templateId: number, params?: IPutUniversalTemplateParams) => {
    const response = await this.axios.put(Endpoints.assignUniversalTemplate(templateId), params)
    return response
  }
}
