import { USER_ROLE } from 'models/User'

/**
 * Determines if the user role is an Admin one.
 * @param userRole User role name (string)
 * @returns Boolean
 */
export const isAdminUserByRoleName = (userRole: string) => {
  if (
    userRole === USER_ROLE.ADMIN ||
    userRole === USER_ROLE.ACCOUNT_MANAGER_LEVEL_1 ||
    userRole === USER_ROLE.ACCOUNT_MANAGER_LEVEL_2 ||
    userRole === USER_ROLE.SALES
  )
    return true
  return false
}

export const isAccountManagerByRoleName = (userRole: string) => {
  if (
    userRole === USER_ROLE.ACCOUNT_MANAGER_LEVEL_1 ||
    userRole === USER_ROLE.ACCOUNT_MANAGER_LEVEL_2
  )
    return true
  return false
}

/**
 * Determines if the user role is allowed to login and use the application.
 * @param userRole User role name (string)
 * @returns Boolean
 */
export const isUserRoleAllowed = (userRole: string) => {
  if (isAdminUserByRoleName(userRole) || userRole === USER_ROLE.ONBOARDER) return true
  return false
}
