import { AxiosService } from '../config/config'
import { Endpoints } from '../config/endpoints'
import { IPayload } from 'models/Global'
import {
  IInvoiceExpenseType,
  IGetInvoiceExpenseTypesParams,
  IPostInvoiceExpenseTypeParams,
  IPutInvoiceExpenseTypeParams,
} from 'models/InvoiceExpenseType'

export class InvoiceExpenseTypesApi {
  constructor(private axios: AxiosService) {}

  getInvoiceExpenseTypes = async (params?: IGetInvoiceExpenseTypesParams) => {
    const response = await this.axios.get<IPayload<IInvoiceExpenseType[]>>(
      Endpoints.getInvoiceExpenseTypes,
      params
    )
    return response
  }

  getInvoiceExpenseType = async (id: number) => {
    const response = await this.axios.get<IPayload<IInvoiceExpenseType>>(
      Endpoints.getInvoiceExpenseType(id)
    )
    return response
  }

  postInvoiceExpenseType = async (params: IPostInvoiceExpenseTypeParams) => {
    const response = await this.axios.post<IPayload<IInvoiceExpenseType>>(
      Endpoints.postInvoiceExpenseType,
      params
    )
    return response
  }

  putInvoiceExpenseType = async (id: number, params: IPutInvoiceExpenseTypeParams) => {
    const response = await this.axios.put<IPayload<IInvoiceExpenseType>>(
      Endpoints.putInvoiceExpenseType(id),
      params
    )
    return response
  }

  deleteInvoiceExpenseType = async (id: number) => {
    const response = await this.axios.delete<{}>(Endpoints.deleteInvoiceExpenseType(id))
    return response
  }
}
