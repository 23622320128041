import { action, payload } from 'ts-action'
import { IBusinessEntity } from 'models/BusinessEntity'
import { IError, IPayload } from 'models/Global'

export const GetBusinessEntities = action(
  'GetBusinessEntities',
  payload<IPayload<IBusinessEntity[]>>()
)
export const GetBusinessEntitiesErrors = action('GetBusinessEntitiesErrors', payload<IError>())
export const BusinessEntitiesFetching = action('BusinessEntitiesFetching', payload<boolean>())
