import { IFeaturedShiftCampaignReducer, IFeaturedShiftCampaign } from 'models/FeaturedShiftCampaign'
import { Action } from 'redux'
import { isType } from 'ts-action'
import {
  GetFeaturedShiftCampaign,
  FeaturedShiftCampaignFetching,
  FeaturedShiftsCampaignRemoveGigId,
  FeaturedShiftsCampaignAddGigId,
  ResetFeaturedShiftCampaign,
} from './actions'

const initialState: IFeaturedShiftCampaignReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: true,
  data: {} as IFeaturedShiftCampaign,
}

export const FeaturedShiftCampaignReducer = (
  state = initialState,
  action: Action
): IFeaturedShiftCampaignReducer => {
  if (isType(action, GetFeaturedShiftCampaign)) {
    const { payload } = action

    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
    }
  }

  if (isType(action, FeaturedShiftCampaignFetching)) {
    const { payload } = action

    return {
      ...state,
      isFetching: payload,
    }
  }

  if (isType(action, FeaturedShiftsCampaignRemoveGigId)) {
    const { payload } = action
    return {
      ...state,
      data: {
        ...state.data,
        gig_ids: state.data.gig_ids.filter((gigId) => gigId !== payload),
      },
    }
  }

  if (isType(action, FeaturedShiftsCampaignAddGigId)) {
    const { payload } = action
    return {
      ...state,
      data: {
        ...state.data,
        gig_ids: [payload, ...state.data.gig_ids],
      },
    }
  }

  if (isType(action, ResetFeaturedShiftCampaign)) {
    return initialState
  }

  return state
}
