import { IPayload } from 'models/Global'
import {
  IGetPositionCategoriesParams,
  IPositionCategory,
  IPostPositionCategoryParams,
  IPutPositionCategoryParams,
} from 'models/PositionCategory'
import { AxiosService } from 'network/config/config'
import { Endpoints } from 'network/config/endpoints'

export class PositionCategoriesApi {
  constructor(private axios: AxiosService) {}

  getPositionCategories = async (params?: IGetPositionCategoriesParams) => {
    const response = await this.axios.get<IPayload<IPositionCategory[]>>(
      Endpoints.getPositionCategories,
      params
    )
    return response
  }

  putPositionCategory = async (id: number, params: IPutPositionCategoryParams) => {
    const response = await this.axios.put<IPayload<IPositionCategory>>(
      Endpoints.putPositionCategory(id),
      params
    )
    return response
  }

  postPositionCategory = async (params: IPostPositionCategoryParams) => {
    const response = await this.axios.post<IPayload<IPositionCategory>>(
      Endpoints.postPositionCategory,
      params
    )
    return response
  }
}
