import { action, payload } from 'ts-action'
import { IDeductionType } from 'models/DeductionType'
import { IError, IPayload } from 'models/Global'

export const DeductionTypesFetching = action('DeductionTypesFetching', payload<boolean>())

export const GetDeductionTypes = action('GetDeductionTypes', payload<IPayload<IDeductionType[]>>())

export const AddDeductionType = action('AddDeductionType', payload<IPayload<IDeductionType>>())

export const UpdateDeductionType = action(
  'UpdateDeductionType',
  payload<IPayload<IDeductionType>>()
)

export const DeleteDeductionType = action('DeleteDeductionType', payload<number>())

export const GetDeductionTypesErrors = action('GetDeductionTypesErrors', payload<IError>())
