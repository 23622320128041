import { ITemplateReducer, ITemplate } from 'models/Template'
import { Action } from 'redux'
import { isType } from 'ts-action'
import { updateArray, deleteFromArray } from 'helpers/helperFunctions'
import { GetTemplates, TemplatesFetching, UpdateTemplate, DeleteTemplate } from './actions'

const initialState: ITemplateReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: [],
}

export const templatesReducer = (state = initialState, action: Action): ITemplateReducer => {
  if (isType(action, GetTemplates)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
    }
  }
  if (isType(action, UpdateTemplate)) {
    const { payload } = action
    const { data } = state
    const formattedArray = updateArray<ITemplate>(data, payload.data)
    return {
      ...state,
      data: formattedArray,
    }
  }
  if (isType(action, DeleteTemplate)) {
    const { payload } = action
    const { data, meta } = state
    const formattedArray = deleteFromArray<ITemplate>(data, payload)
    return {
      ...state,
      data: formattedArray,
      meta: {
        ...meta,
        pagination: {
          ...meta.pagination,
          count: meta.pagination ? meta.pagination.count - 1 : 0,
          total: meta.pagination ? meta.pagination.total - 1 : 0,
          per_page: meta.pagination?.per_page || 50,
          current_page: meta.pagination?.current_page || 1,
          total_pages: meta.pagination?.total_pages || 1,
          links: meta.pagination?.links || [],
        },
      },
    }
  }

  if (isType(action, TemplatesFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }
  return state
}
