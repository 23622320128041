import {
  BillingRequestIdLoading,
  ResetAchDebitAuthorizationFlow,
  UpdateActiveStep,
  UpdateBankDetails,
  UpdateBillingRequestId,
  UpdateCustomerDetails,
  UpdatePreferredAuthorizationMethodDetails,
} from './actions'
import {
  ACH_DEBIT_AUTHORIZATION_STEP,
  IAchDebitAuthorizationFlowReducer,
  maskAccountNumber,
  maskRoutingNumber,
} from './utils'
import { ACH_ACCOUNT_ACCOUNT_TYPE } from 'models/AchAccount'
import { Action } from 'redux'
import { isType } from 'ts-action'

const initialState: IAchDebitAuthorizationFlowReducer = {
  activeStep: ACH_DEBIT_AUTHORIZATION_STEP.CUSTOMER_DETAILS,
  loadingBillingRequestId: false,
  billingRequestId: '',
  companyName: '',
  email: '',
  billingAddress: '',
  billingAddress2: '',
  city: '',
  zipCode: '',
  state: null,
  accountType: ACH_ACCOUNT_ACCOUNT_TYPE.CHECKING,
  accountHolderName: '',
  accountNumberMasked: '',
  routingNumberMasked: '',
  bankName: '',
  authorizationMethod: undefined,
  offlineAccountPdf: null,
}

export const achDebitAuthorizationFlowReducer = (
  state = initialState,
  action: Action
): IAchDebitAuthorizationFlowReducer => {
  if (isType(action, UpdateActiveStep)) {
    return { ...state, activeStep: action.payload }
  }

  if (isType(action, BillingRequestIdLoading)) {
    return { ...state, loadingBillingRequestId: action.payload }
  }

  if (isType(action, UpdateBillingRequestId)) {
    return { ...state, billingRequestId: action.payload }
  }

  if (isType(action, UpdateCustomerDetails)) {
    const { payload } = action
    return {
      ...state,
      companyName: payload.company_name,
      email: payload.email,
      billingAddress: payload.billing_address,
      billingAddress2: payload.billing_address2,
      city: payload.city,
      zipCode: payload.zip_code,
      state: payload.state,
    }
  }

  if (isType(action, UpdateBankDetails)) {
    const { payload } = action
    return {
      ...state,
      accountType: payload.account_type,
      accountHolderName: payload.account_holder_name,
      accountNumberMasked: maskAccountNumber(payload.account_number),
      routingNumberMasked: maskRoutingNumber(payload.routing_number),
      bankName: payload.bank_name,
    }
  }

  if (isType(action, UpdatePreferredAuthorizationMethodDetails)) {
    const { payload } = action
    return {
      ...state,
      authorizationMethod: payload.authorization_method,
      offlineAccountPdf: payload.offline_account_pdf,
    }
  }

  if (isType(action, ResetAchDebitAuthorizationFlow)) {
    return initialState
  }

  return state
}
