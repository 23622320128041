import { Action } from 'redux'
import { isType } from 'ts-action'
import { IIndustryReducer } from 'models/Industry'
import { GetIndustries, IndustriesFetching } from './actions'

const initialState: IIndustryReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: [],
}

export const industriesReducer = (state = initialState, action: Action): IIndustryReducer => {
  if (isType(action, GetIndustries)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
    }
  }

  if (isType(action, IndustriesFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }
  return state
}
