import { AxiosService } from '../config/config'
import { Endpoints } from '../config/endpoints'
import { IPayload } from 'models/Global'
import { IReview, IGetReviewsParams, IPutReviewParams, IPostReviewParams } from 'models/Review'

export class ReviewsApi {
  constructor(private axios: AxiosService) {}

  getReviews = async (params?: IGetReviewsParams) => {
    const response = await this.axios.get<IPayload<IReview[]>>(Endpoints.getReviews, params)
    return response
  }

  getReview = async (id: number, params?: IGetReviewsParams) => {
    const response = await this.axios.get<IPayload<IReview>>(Endpoints.getReview(id), params)
    return response
  }

  postReview = async (params: IPostReviewParams) => {
    const response = await this.axios.post<IPayload<IReview>>(Endpoints.postReview, params)
    return response
  }

  putReview = async (id: number, params?: IPutReviewParams) => {
    const response = await this.axios.put<IPayload<IReview>>(Endpoints.putReview(id), params)
    return response
  }

  deleteReview = async (id: number) => {
    const response = await this.axios.delete<{}>(Endpoints.deleteReview(id))
    return response
  }
}
