import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Divider, Flex } from '@pinchenterprisesnpm/friday-ui'
import { Form } from 'formik'
import { Link } from 'react-router-dom'
import { Container } from 'styled-bootstrap-grid'
import styled from 'styled-components'

export const LinkWrapper = styled(Link)<{ isDisabled?: boolean }>`
  text-decoration: none;
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'all')};
  &:focus,
  &:visited,
  &:link,
  &:active {
    color: inherit;
    text-decoration: none;
  }

  &:hover {
    color: ${({ theme: { palette } }) => palette.primary};
  }
`

export const PrimaryLinkWrapper = styled(Link)<{ isDisabled?: boolean }>`
  text-decoration: none;
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'all')};
  color: ${({ theme: { palette } }) => palette.primary};
`

export const CustomValidationError = styled.span<{ margin?: string }>`
  color: ${({ theme: { palette } }) => palette.danger};
  font-size: ${({
    theme: {
      font: { sizes },
    },
  }) => sizes.small};
  margin: ${({ margin }) => (margin ? margin : '0')};
`

export const Anchor = styled.a`
  color: ${({ theme: { palette } }) => palette.primary};
  text-decoration: none;
`

export const UnstyledAnchorLink = styled.a`
  text-decoration: none;
`

export const DefaultColorAnchorLink = styled.a`
  color: inherit;
  text-decoration: none;
`

export const FieldWrapper = styled.div`
  margin-bottom: ${({ theme: { mp } }) => mp.md};
`

/**
 * Custom footer wrapper, in case we need different buttons from the ones in the Modal Component
 */
export const FooterWrapper = styled.div<{ spaceBetween?: boolean }>`
  background: ${({ theme: { palette } }) => palette.white};
  display: flex;
  align-items: center;
  justify-content: ${({ spaceBetween }) => (spaceBetween ? 'space-between' : 'flex-end')};
  padding: ${({ theme: { mp } }) => `${mp.md}`};

  & *:not(:last-child) {
    margin-right: ${({ theme: { mp } }) => mp.xs};
  }
`

export const PageTitle = styled.h1`
  color: ${({ theme: { palette } }) => palette.primary};
  font-size: ${({
    theme: {
      font: { sizes },
    },
  }) => sizes.large};
  padding-left: ${({ theme: { mp } }) => mp.sm};
  display: flex;
  justify-content: space-between;

  & button {
    font-weight: normal;
  }

  & div {
    font-size: initial;
  }

  & > div {
    flex: 1;
  }
`

export const PageLayout = styled(Container)`
  padding: ${({ theme: { mp } }) => mp.sm};
`

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`

export const FlexWrapper = styled.div`
  width: 100%;
  display: flex;
`

export const EndFlexWrapper = styled(FlexWrapper)`
  justify-content: end;
`

export const SpaceBetweenFlexWrapper = styled(FlexWrapper)`
  justify-content: space-between;
`

export const TextWrap = styled.span`
  display: inline-block;
  width: 100%;
  white-space: normal;
  word-wrap: break-word;
`

export const PrimaryColorLabel = styled.label`
  color: ${({ theme: { palette } }) => palette.primary};
`

export const AlertWrapper = styled.div`
  & * {
    font-size: ${({
      theme: {
        font: { sizes },
      },
    }) => sizes.default};
  }

  & > div:not(:last-child) {
    margin-bottom: ${({ theme: { mp } }) => mp.xs};
  }

  margin-bottom: ${({ theme: { mp } }) => mp.md};
`

export const AlertIcon = styled(FontAwesomeIcon)`
  margin-right: ${({ theme: { mp } }) => mp.sm};
`

export const ScrollableModalContent = styled(Box)`
  flex: 15;
  background-color: ${({ theme: { palette } }) => palette.white};
  overflow-y: auto;
  padding: 0 ${({ theme: { mp } }) => mp.md};
`

export const TableActionButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;

  & > *:not(:last-child) {
    margin-right: ${({ theme: { mp } }) => mp.sm};
  }

  & > * {
    height: 100%;
  }
`

export const PdfPreviewLink = styled.a`
  color: ${({ theme: { palette } }) => palette.dark};
  &:hover {
    color: ${({ theme: { palette } }) => palette.primary};
  }
`
export const UnsetHeightTableWrapper = styled.div`
  width: 100%;
  /**
  TODO: Once all tables are mobile-friendly, we can set this rule only once
  with media query in the GlobalStyles.js file
 */
  .ReactTable .rt-tr {
    height: unset;
  }

  @media screen and (max-width: ${({ theme: { breakpoints } }) => `${breakpoints.sm}px`}) {
    .ReactTable .rt-th {
      display: none;
    }
  }
`

export const FullWidthWrapper = styled.div`
  width: 100%;
`

export const FullWidthFlex = styled(Flex)`
  width: 100%;
`
// TODO Tech Debt; hard-coded color; FND-3863
export const PageHeadingDivider = styled(Divider)`
  &:before {
    height: 33px;
    border: 1px solid #d5d8e1;
    opacity: 1;
  }
  width: 0;
  margin: 0 ${({ theme: { mp } }) => mp.md};
`

export const RedColorSpan = styled.span`
  color: ${({ theme: { palette } }) => palette.danger};
`

export const ExternalLink = styled.a`
  text-decoration: none;
  color: inherit;
  &:link,
  &:hover,
  &:active {
    color: ${({ theme: { palette } }) => palette.primary};
  }
`

export const FlexFormikForm = styled(Form)`
  display: flex;
  flex-direction: column;
  height: 100%;
`
export const SemiBoldSpan = styled.span`
  font-weight: ${({
    theme: {
      font: { weights },
    },
  }) => weights.semiBold};
`
