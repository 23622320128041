import { AxiosService } from '../config/config'
import { Endpoints } from '../config/endpoints'
import { IPayload } from 'models/Global'
import {
  ICertificateOrganization,
  IGetCertificateOrganizationsParams,
  IPutCertificateOrganizationParams,
  IPostCertificateOrganizationParams,
} from 'models/CertificateOrganization'

export class CertificateOrganizationsApi {
  constructor(private axios: AxiosService) {}

  getCertificateOrganizations = async (params?: IGetCertificateOrganizationsParams) => {
    const response = await this.axios.get<IPayload<ICertificateOrganization[]>>(
      Endpoints.getCertificateOrganizations,
      params
    )
    return response
  }

  getCertificateOrganization = async (id: number, params?: IGetCertificateOrganizationsParams) => {
    const response = await this.axios.get<IPayload<ICertificateOrganization>>(
      Endpoints.getCertificateOrganization(id),
      params
    )
    return response
  }

  postCertificateOrganization = async (params: IPostCertificateOrganizationParams) => {
    const response = await this.axios.post<IPayload<ICertificateOrganization>>(
      Endpoints.postCertificateOrganization,
      params
    )
    return response
  }

  putCertificateOrganization = async (id: number, params?: IPutCertificateOrganizationParams) => {
    const response = await this.axios.put<IPayload<ICertificateOrganization>>(
      Endpoints.putCertificateOrganization(id),
      params
    )
    return response
  }

  deleteCertificateOrganization = async (id: number) => {
    const response = await this.axios.delete<{}>(Endpoints.deleteCertificateOrganization(id))
    return response
  }
}
