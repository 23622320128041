import { action, payload } from 'ts-action'
import { IAchievement } from '../../models/Achievement'
import { IError, IPayload } from '../../models/Global'
import { ACHIEVEMENT_LEVELS_ORDER_DIRECTION } from 'helpers/constants/constants'

export const AchievementFetching = action('AchievementFetching', payload<boolean>())

export const GetAchievement = action('GetAchievement', payload<IPayload<IAchievement>>())

export const UpdateAchievementData = action(
  'UpdateAchievementData',
  payload<Partial<IAchievement>>()
)

export const AddAchievementLevel = action('AddAchievementLevel', payload<IAchievement>())

export const UpdateAchievementLevel = action('UpdateAchievementLevel', payload<IAchievement>())

export const DeleteAchievementLevel = action('DeleteAchievementLevel', payload<IAchievement>())

export const ReorderAchievementLevels = action(
  'ReorderAchievementLevels',
  payload<ACHIEVEMENT_LEVELS_ORDER_DIRECTION>()
)

export const GetAchievementErrors = action('GetAchievementErrors', payload<IError>())

export const ResetAchievement = action('ResetAchievement')
