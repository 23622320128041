import { IPendingDeletionPunchCardsReducer } from 'models/PendingDeletionPunchCard'
import { Action } from 'redux'
import { isType } from 'ts-action'
import {
  ClearPendingDeletionPunchCardsReducer,
  GetPendingDeletionPunchCards,
  PendingDeletionPunchCardsFetching,
  RemovePendingDeletionPunchCard,
} from './actions'
import { deleteFromArray, removeKeyFromObject } from 'helpers/helperFunctions'

const initialState: IPendingDeletionPunchCardsReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: null,
}

export const pendingDeletionPunchCardsReducer = (
  state = initialState,
  action: Action
): IPendingDeletionPunchCardsReducer => {
  if (isType(action, GetPendingDeletionPunchCards)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
    }
  }
  if (isType(action, PendingDeletionPunchCardsFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }
  if (isType(action, RemovePendingDeletionPunchCard)) {
    const { key, punchCardId } = action.payload
    if (state.data) {
      // If there is only one punchcard for the provided key, we need to remove the whole key: value pair, not just the punchcard from the array
      if (state.data[key].punchcards.length === 1) {
        const filteredData = removeKeyFromObject(key, state.data)
        return {
          ...state,
          data: filteredData,
        }
      } else {
        // We remove the punchcard from the existing array
        const filteredPunchCards = deleteFromArray(state.data[key].punchcards, punchCardId)
        return {
          ...state,
          data: {
            ...state.data,
            [key]: {
              ...state.data[key],
              punchcards: filteredPunchCards,
            },
          },
        }
      }
    }
    return state
  }
  if (isType(action, ClearPendingDeletionPunchCardsReducer)) {
    return initialState
  }
  return state
}
