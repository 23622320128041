import { IPropertiesReducer } from 'models/Property'
import { Action } from 'redux'
import { isType } from 'ts-action'
import {
  GetFollowManagementProperties,
  FollowManagementPropertiesFetching,
  UpdateFollowManagementProperty,
} from './actions'
import { FOLLOW_STATUS } from 'models/Follow'

const initialState: IPropertiesReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: [],
}

export const followManagementPropertiesReducer = (
  state = initialState,
  action: Action
): IPropertiesReducer => {
  if (isType(action, GetFollowManagementProperties)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
    }
  }

  if (isType(action, FollowManagementPropertiesFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }

  if (isType(action, UpdateFollowManagementProperty)) {
    const {
      payload: { data },
    } = action

    if (data.status === FOLLOW_STATUS.UNFOLLOW) {
      const newProperties = state.data.filter((item) => item.id !== data.model_id)

      return {
        ...state,
        data: newProperties,
      }
    }
  }

  return state
}
