import {
  ITimesheetReducer,
  ITimesheetData,
  ITimesheetShift,
  ITimesheetApplication,
  ITimesheetIPunchCard,
} from 'models/Timesheet'
import { Action } from 'redux'
import { isType } from 'ts-action'
import {
  GetTimesheet,
  TimesheetFetching,
  GetProperty,
  PropertyFetching,
  ShiftsFetching,
  GetPunchCardsForTimesheet,
  PunchCardsFetching,
  GetApplications,
  ApplicationsFetching,
  GetTimesheetShifts,
  AddTimesheetPunchCard,
  UpdateTimesheetPunchCard,
  DeleteTimesheetPunchCard,
  FullTimesheetFetching,
  AddApplication,
  UpdateTimesheet,
  AddBonus,
  UpdateBonus,
  GetBonusesForTimesheet,
  ClearTimesheet,
  DeleteBonus,
  GetTimesheetErrors,
  UpdateBusinessInstruction,
  UpdateApplicationBlockPropertiesCount,
} from './actions'
import { IShift } from 'models/Shift'
import { IApplication } from 'models/Application'
import { IPunchCard } from 'models/PunchCard'
import _ from 'lodash-es'
import { GetGigDays } from '../GigDays/actions'
import { IBonus } from 'models/Bonus'
import { IPayload } from 'models/Global'
import { IGigDayExtended } from 'models/GigDay'

const initialState: ITimesheetReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: {
    shifts: {},
  } as ITimesheetData,
}

const transformShifts: (shifts: IShift[]) => { [key: number]: ITimesheetShift } = (shifts) => {
  const timesheetShifts = shifts.reduce(
    (result: { [key: number]: ITimesheetShift }, current: IShift) => {
      return {
        ...result,
        [current.id]: {
          ...current,
          applications: {} as { [key: number]: ITimesheetApplication },
        },
      }
    },
    {} as { [key: number]: ITimesheetShift }
  )

  return timesheetShifts
}

const transformApplications: (applications: IApplication[]) => ITimesheetApplication[] = (
  applications
) => {
  const timesheetApplications = applications.map((application: IApplication) => {
    return {
      ...application,
      punchcards: {} as { [key: number]: ITimesheetIPunchCard },
      bonuses: {} as { [key: number]: IBonus },
    }
  })

  return timesheetApplications
}

const transformApplication = (application: IApplication) => {
  const transformedApplication = {
    ...application,
    punchcards: {} as { [key: number]: ITimesheetIPunchCard },
    bonuses: {} as { [key: number]: IBonus },
  }
  return transformedApplication
}

const connectShiftsAndApplications: (
  applications: ITimesheetApplication[],
  state: ITimesheetReducer
) => { [key: number]: ITimesheetShift } = (applications, state) => {
  const {
    data: { shifts },
  } = state

  const applicationsByShift = applications.reduce(
    (
      result: {
        [key: number]: {
          applications: { [key: number]: ITimesheetApplication }
        }
      },
      current: ITimesheetApplication
    ) => {
      const shiftId = current['gig_id']
      const applicationId = current.id

      if (!result[shiftId]) {
        result[shiftId] = {} as {
          applications: { [key: number]: ITimesheetApplication }
        }
      }
      if (!result[shiftId]['applications']) {
        result[shiftId]['applications'] = {}
      }
      result[shiftId]['applications'][applicationId] = current

      return result
    },
    {} as {
      [key: number]: {
        applications: { [key: number]: ITimesheetApplication }
        [key: string]: any
      }
    }
  )

  const shiftsWithApplications = { ...shifts }

  for (const key in shiftsWithApplications) {
    shiftsWithApplications[key] = {
      ...shiftsWithApplications[key],
      ...(applicationsByShift[key] && {
        applications: applicationsByShift[key]['applications'],
      }),
    }
  }

  return shiftsWithApplications
}

const connectApplicationsAndPunchCards: (
  punchcards: IPunchCard[],
  state: ITimesheetReducer
) => { [key: number]: ITimesheetShift } = (
  punchcards: ITimesheetIPunchCard[],
  state: ITimesheetReducer
) => {
  const {
    data: { shifts },
  } = state

  const punchcardsByShiftAndApplication = punchcards.reduce(
    (
      result: {
        [key: number]: {
          applications: {
            [key: number]: { punchcards: { [key: number]: IPunchCard } }
          }
        }
      },
      current: IPunchCard
    ) => {
      const shiftId = current['gig_id']
      const applicationId = current['application_id']
      const punchcardId = current.id as number

      if (!result[shiftId]) {
        result[shiftId] = {} as {
          applications: { [key: number]: ITimesheetApplication }
        }
      }
      if (!result[shiftId]['applications']) {
        result[shiftId]['applications'] = {}
      }
      if (!result[shiftId]['applications'][applicationId]) {
        result[shiftId]['applications'][applicationId] = {} as {
          punchcards: { [key: number]: IPunchCard }
        }
      }
      if (!result[shiftId]['applications'][applicationId]['punchcards']) {
        result[shiftId]['applications'][applicationId]['punchcards'] = {} as {
          [key: number]: IPunchCard
        }
      }

      result[shiftId]['applications'][applicationId]['punchcards'][punchcardId] = {
        ...current,
        isNoEndPunchCard: !current.timestamp_end,
      }

      return result
    },
    {} as {
      [key: number]: {
        applications: {
          [key: number]: { punchcards: { [key: number]: IPunchCard } }
        }
      }
    }
  )

  const shiftsWithApplicationsAndPunchCards = _.cloneDeep(shifts)

  for (const shiftKey in punchcardsByShiftAndApplication) {
    for (const appKey in punchcardsByShiftAndApplication[shiftKey].applications) {
      shiftsWithApplicationsAndPunchCards[shiftKey]['applications'][appKey]['punchcards'] =
        punchcardsByShiftAndApplication[shiftKey]['applications'][appKey]['punchcards']
    }
  }

  return shiftsWithApplicationsAndPunchCards
}

const connectApplicationsAndBonuses: (
  bonuses: IBonus[],
  state: ITimesheetReducer
) => { [key: number]: ITimesheetShift } = (bonuses: IBonus[], state: ITimesheetReducer) => {
  const {
    data: { shifts },
  } = state

  const bonusesByShiftAndApplication = bonuses.reduce(
    (
      result: {
        [key: number]: {
          applications: {
            [key: number]: { bonuses: { [key: number]: IBonus } }
          }
        }
      },
      current: IBonus
    ) => {
      const shiftId = (current as IBonus & { application: IApplication }).application.gig_id
      const applicationId = current['application_id']
      const bonusId = current.id as number

      if (!result[shiftId]) {
        result[shiftId] = {} as {
          applications: { [key: number]: ITimesheetApplication }
        }
      }
      if (!result[shiftId]['applications']) {
        result[shiftId]['applications'] = {}
      }
      if (!result[shiftId]['applications'][applicationId]) {
        result[shiftId]['applications'][applicationId] = {} as {
          bonuses: { [key: number]: IBonus }
        }
      }
      if (!result[shiftId]['applications'][applicationId]['bonuses']) {
        result[shiftId]['applications'][applicationId]['bonuses'] = {} as {
          [key: number]: IBonus
        }
      }

      result[shiftId]['applications'][applicationId]['bonuses'][bonusId] = current

      return result
    },
    {} as {
      [key: number]: {
        applications: {
          [key: number]: { bonuses: { [key: number]: IBonus } }
        }
      }
    }
  )

  const shiftsWithApplicationsAndBonuses = _.cloneDeep(shifts)

  for (const shiftKey in bonusesByShiftAndApplication) {
    for (const appKey in bonusesByShiftAndApplication[shiftKey].applications) {
      shiftsWithApplicationsAndBonuses[shiftKey]['applications'][appKey]['bonuses'] =
        bonusesByShiftAndApplication[shiftKey]['applications'][appKey]['bonuses']
    }
  }

  return shiftsWithApplicationsAndBonuses
}

const processGetTimesheet = (
  payload: ReturnType<typeof GetTimesheet>['payload'],
  state: ITimesheetReducer
) => {
  const { data: timesheet } = payload
  const { data: currentData } = state

  return {
    ...currentData,
    timesheet,
  }
}

const processUpdateTimesheet = (
  payload: ReturnType<typeof GetTimesheet>['payload'],
  state: ITimesheetReducer
) => {
  const { data: timesheet } = payload
  const { data: currentData } = state

  return {
    ...currentData,
    timesheet,
  }
}

const processGetProperty = (
  payload: ReturnType<typeof GetProperty>['payload'],
  state: ITimesheetReducer
) => {
  const { data } = payload
  const { data: currentData } = state

  const property = data

  return {
    ...currentData,
    property,
  }
}

const processGetShifts = (
  payload: ReturnType<typeof GetTimesheetShifts>['payload'],
  state: ITimesheetReducer
) => {
  const { data } = payload
  const { data: currentData } = state

  const shifts = transformShifts(data)

  return {
    ...currentData,
    shifts,
  }
}

const processGetPunchCards = (
  payload: ReturnType<typeof GetPunchCardsForTimesheet>['payload'],
  state: ITimesheetReducer
) => {
  const { data } = payload
  const { data: currentData } = state

  const punchcards = data
  const shifts = connectApplicationsAndPunchCards(punchcards, state)
  return {
    ...currentData,
    shifts,
  }
}

const processGetBonuses = (
  payload: ReturnType<typeof GetBonusesForTimesheet>['payload'],
  state: ITimesheetReducer
) => {
  const { data } = payload
  const { data: currentData } = state

  const bonuses = data
  const shifts = connectApplicationsAndBonuses(bonuses, state)
  return {
    ...currentData,
    shifts,
  }
}

const processAddBonus = (
  payload: ReturnType<typeof AddBonus>['payload'] &
    IPayload<IBonus & { application: IApplication }>,
  state: ITimesheetReducer
) => {
  const { data } = payload
  const {
    application: { gig_id: shiftId },
    id: bonusId,
    application_id: applicationId,
  } = data

  const { data: currentData } = state
  const { shifts: oldShifts } = currentData

  const shifts = _.cloneDeep(oldShifts)

  if (shifts[shiftId])
    shifts[shiftId]['applications'][applicationId]['bonuses'][bonusId as number] = data

  return {
    ...currentData,
    shifts,
  }
}

const processUpdateBonus = (
  payload: ReturnType<typeof UpdateBonus>['payload'] &
    IPayload<IBonus & { application: IApplication }>,
  state: ITimesheetReducer
) => {
  const { data } = payload
  const {
    application: { gig_id: shiftId },
    id: bonusId,
    application_id: applicationId,
  } = data

  const { data: currentData } = state
  const { shifts: oldShifts } = currentData

  const shifts = _.cloneDeep(oldShifts)

  if (shifts[shiftId])
    shifts[shiftId]['applications'][applicationId]['bonuses'][bonusId as number] = data

  return {
    ...currentData,
    shifts,
  }
}

const processDeleteBonus = (
  payload: ReturnType<typeof DeleteBonus>['payload'],
  state: ITimesheetReducer
) => {
  const { id: bonusId, gig_id: shiftId, application_id: applicationId } = payload

  const { data: currentData } = state
  const { shifts: oldShifts } = currentData

  const shifts = _.cloneDeep(oldShifts)

  if (shifts[shiftId]) delete shifts[shiftId]['applications'][applicationId]['bonuses'][bonusId]

  return {
    ...currentData,
    shifts,
  }
}

const processAddPunchCard = (
  payload: ReturnType<typeof AddTimesheetPunchCard>['payload'],
  state: ITimesheetReducer
) => {
  const { data } = payload
  const { gig_id: shiftId, id: punchcardId, application_id: applicationId } = data

  const { data: currentData } = state
  const { shifts: oldShifts } = currentData

  const shifts = _.cloneDeep(oldShifts)

  shifts[shiftId]['applications'][applicationId]['punchcards'][punchcardId as number] = data

  return {
    ...currentData,
    shifts,
  }
}

const processUpdatePunchCard = (
  payload: ReturnType<typeof UpdateTimesheetPunchCard>['payload'],
  state: ITimesheetReducer
) => {
  const { data } = payload
  const { gig_id: shiftId, id: punchcardId, application_id: applicationId } = data

  const { data: currentData } = state
  const { shifts: oldShifts } = currentData

  const shifts = _.cloneDeep(oldShifts)

  shifts[shiftId]['applications'][applicationId]['punchcards'][punchcardId as number] = data

  return {
    ...currentData,
    shifts,
  }
}

const processDeletePunchCard = (
  payload: ReturnType<typeof DeleteTimesheetPunchCard>['payload'],
  state: ITimesheetReducer
) => {
  const id = payload

  const { data: currentData } = state
  const { shifts: oldShifts } = currentData

  const shifts = _.cloneDeep(oldShifts)

  for (const shiftKey in shifts) {
    for (const appKey in shifts[shiftKey]['applications']) {
      for (const punchcardKey in shifts[shiftKey]['applications'][appKey]['punchcards']) {
        if (Number(punchcardKey) === id) {
          delete shifts[shiftKey]['applications'][appKey]['punchcards'][punchcardKey]
        }
      }
    }
  }

  return {
    ...currentData,
    shifts,
  }
}

const processGetApplications = (
  payload: ReturnType<typeof GetApplications>['payload'],
  state: ITimesheetReducer
) => {
  const { data } = payload
  const { data: currentData } = state

  const applications = transformApplications(data)
  const shifts = connectShiftsAndApplications(applications, state)
  return {
    ...currentData,
    shifts,
  }
}

const processAddApplication = (
  payload: ReturnType<typeof AddApplication>['payload'],
  state: ITimesheetReducer
) => {
  const { data } = payload
  const { gig_id: shiftId, id: applicationId } = data

  const { data: currentData } = state
  const { shifts: oldShifts } = currentData

  const shifts = _.cloneDeep(oldShifts)

  shifts[shiftId]['applications'][applicationId as number] = transformApplication(data)

  return {
    ...currentData,
    shifts,
  }
}

const processGetGigDays = (
  payload: ReturnType<typeof GetGigDays>['payload'],
  state: ITimesheetReducer
) => {
  const { data: gigDays } = payload

  const { data: currentData } = state
  const { shifts: oldShifts } = currentData

  const shifts = _.cloneDeep(oldShifts)
  let shiftId

  const gigDaysObject = gigDays.reduce(
    (result: { [key: number]: IGigDayExtended }, current: IGigDayExtended, index: number) => {
      const { shift_id } = current
      shiftId = shift_id

      result[index] = current

      return result
    },
    {} as { [key: number]: IGigDayExtended }
  )

  shifts[shiftId as unknown as number]['gigDays'] = gigDaysObject

  return {
    ...currentData,
    shifts,
  }
}

export const timesheetReducer = (state = initialState, action: Action): ITimesheetReducer => {
  if (isType(action, GetTimesheet)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: processGetTimesheet(payload, state),
    }
  }
  if (isType(action, GetTimesheetErrors)) {
    const { payload } = action
    return {
      ...state,
      error: payload,
    }
  }
  if (isType(action, UpdateTimesheet)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: processUpdateTimesheet(payload, state),
    }
  }

  if (isType(action, TimesheetFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }

  if (isType(action, GetProperty)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: processGetProperty(payload, state),
    }
  }

  if (isType(action, PropertyFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }

  if (isType(action, GetTimesheetShifts)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: processGetShifts(payload, state),
    }
  }

  if (isType(action, ShiftsFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }

  if (isType(action, GetPunchCardsForTimesheet)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: processGetPunchCards(payload, state),
    }
  }

  if (isType(action, GetBonusesForTimesheet)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: processGetBonuses(payload, state),
    }
  }

  if (isType(action, AddBonus)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: processAddBonus(payload as any, state),
    }
  }

  if (isType(action, UpdateBonus)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: processUpdateBonus(payload as any, state),
    }
  }

  if (isType(action, DeleteBonus)) {
    const { payload } = action
    return {
      ...state,
      data: processDeleteBonus(payload, state),
    }
  }

  if (isType(action, AddTimesheetPunchCard)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: processAddPunchCard(payload, state),
    }
  }

  if (isType(action, UpdateTimesheetPunchCard)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: processUpdatePunchCard(payload, state),
    }
  }

  if (isType(action, DeleteTimesheetPunchCard)) {
    const { payload } = action
    return {
      ...state,
      data: processDeletePunchCard(payload, state),
    }
  }

  if (isType(action, PunchCardsFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }

  if (isType(action, GetApplications)) {
    const { payload } = action

    return {
      ...state,
      meta: payload.meta,
      data: processGetApplications(payload, state),
    }
  }

  if (isType(action, AddApplication)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: processAddApplication(payload, state),
    }
  }

  if (isType(action, ApplicationsFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }

  if (isType(action, FullTimesheetFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }

  if (isType(action, GetGigDays)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: processGetGigDays(payload, state),
    }
  }

  if (isType(action, UpdateBusinessInstruction)) {
    const { payload } = action
    const { data } = state
    return {
      ...state,
      data: {
        ...data,
        timesheet: {
          ...data.timesheet,
          business: { ...data.timesheet.business, instruction: payload },
        },
      },
    }
  }

  if (isType(action, ClearTimesheet)) {
    return initialState
  }

  if (isType(action, UpdateApplicationBlockPropertiesCount)) {
    const { payload } = action
    const { data } = state
    const updatedData = { ...data }

    for (const shiftKey in updatedData.shifts) {
      const shift = updatedData.shifts[shiftKey]
      const updatedApplications = { ...shift.applications }
      for (const applicationKey in updatedApplications) {
        const application = updatedApplications[applicationKey]
        // Check if the applicant_id matches
        if (application.applicant.id === payload.applicantId) {
          // Update the block_properties_count
          updatedApplications[applicationKey] = {
            ...application,
            block_properties_count: payload.blockPropertiesCount,
          }
        }
      }

      // Update the applications in the shift
      updatedData.shifts[shiftKey] = {
        ...shift,
        applications: updatedApplications,
      }
    }

    return {
      ...state,
      data: updatedData,
    }
  }

  return state
}
