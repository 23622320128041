import {
  IShiftIdentifierPoliciesReducer,
  IShiftIdentifierPolicy,
} from 'models/ShiftIdentifierPolicy'
import { isType } from 'ts-action'
import { Action } from 'redux'
import {
  GetShiftIdentifierPolicies,
  ShiftIdentifierPoliciesFetching,
  GetShiftIdentifierPoliciesErrors,
  ClearShiftIdentifierPolicies,
  DeleteShiftIdentifierPolicy,
} from './actions'
import { deleteFromArray } from 'helpers/helperFunctions'

const initialState: IShiftIdentifierPoliciesReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: [],
}

export const shiftIdentifierPoliciesReducer = (
  state = initialState,
  action: Action
): IShiftIdentifierPoliciesReducer => {
  if (isType(action, GetShiftIdentifierPolicies)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
    }
  }

  if (isType(action, GetShiftIdentifierPoliciesErrors)) {
    const { payload } = action
    return {
      ...state,
      error: {
        code: payload.code,
        message: payload.message,
      },
    }
  }

  if (isType(action, DeleteShiftIdentifierPolicy)) {
    const { payload } = action
    const { data } = state
    const formattedArray = deleteFromArray<IShiftIdentifierPolicy>(data, payload)
    return {
      ...state,
      data: formattedArray,
    }
  }

  if (isType(action, ClearShiftIdentifierPolicies)) {
    return {
      meta: {
        code: 0,
        message: '',
      },
      isFetching: false,
      data: [],
    }
  }

  if (isType(action, ShiftIdentifierPoliciesFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }

  return state
}
