import { action, payload } from 'ts-action'
import { IVaccinationForm } from 'models/VaccinationForm'
import { IError, IPayload } from 'models/Global'

export const VaccinationFormsFetching = action('VaccinationFormsFetching', payload<boolean>())

export const GetVaccinationForms = action(
  'GetVaccinationForms',
  payload<IPayload<IVaccinationForm[]>>()
)

export const AddVaccinationForm = action(
  'AddVaccinationForm',
  payload<IPayload<IVaccinationForm>>()
)

export const UpdateVaccinationForm = action(
  'UpdateVaccinationForm',
  payload<IPayload<IVaccinationForm>>()
)

export const DeleteVaccinationForm = action('DeleteVaccinationForm', payload<number>())

export const GetVaccinationFormsErrors = action('GetVaccinationFormsErrors', payload<IError>())
