import { action, payload } from 'ts-action'
import { ICredit } from 'models/Credit'
import { IError, IPayload } from 'models/Global'

export const CreditsFetching = action('CreditsFetching', payload<boolean>())

export const GetCredits = action('GetCredits', payload<IPayload<ICredit[]>>())

export const AddCredit = action('AddCredit', payload<IPayload<ICredit>>())

export const UpdateCredit = action('UpdateCredit', payload<IPayload<ICredit>>())

export const DeleteCredit = action('DeleteCredit', payload<number>())

export const GetCreditsErrors = action('GetCreditsErrors', payload<IError>())
