import ROUTES from 'routing/adminRoutes'
import { AsyncOnboarderUpshifterList, AsyncOnboarderUpshifterView } from 'ui/pages'
import OnboarderRoute from '../../OnboarderRoute'

const onboarderRoutes = [
  <OnboarderRoute
    exact
    path={ROUTES.ONBOARDER_UPSHIFTERS_VIEW}
    component={AsyncOnboarderUpshifterView}
    key={ROUTES.ONBOARDER_UPSHIFTERS_VIEW}
  />,
  <OnboarderRoute
    exact
    path={ROUTES.ONBOARDER_UPSHIFTERS_LIST}
    component={AsyncOnboarderUpshifterList}
    key={ROUTES.ONBOARDER_UPSHIFTERS_LIST}
  />,
]

export default onboarderRoutes
