import { ITimetrackersReducer } from 'models/Timetracker'
import { isType } from 'ts-action'
import { Action } from 'redux'
import {
  GetPendingTimetrackers,
  PendingTimetrackersFetching,
  GetPendingTimetrackersErrors,
} from './actions'

const initialState: ITimetrackersReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: [],
}

export const pendingTimetrackersReducer = (
  state = initialState,
  action: Action
): ITimetrackersReducer => {
  if (isType(action, GetPendingTimetrackers)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
    }
  }

  if (isType(action, GetPendingTimetrackersErrors)) {
    const { payload } = action
    return {
      ...state,
      error: {
        code: payload.code,
        message: payload.message,
      },
    }
  }

  if (isType(action, PendingTimetrackersFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }

  return state
}
