import { AxiosService } from '../config/config'
import { Endpoints } from '../config/endpoints'
import { IPayload } from 'models/Global'
import {
  IOnboardDocument,
  IGetOnboardDocumentsParams,
  IPutOnboardDocumentParams,
  IPostOnboardDocumentParams,
} from 'models/OnboardDocument'

export class OnboardDocumentsApi {
  constructor(private axios: AxiosService) {}

  getOnboardDocuments = async (params?: IGetOnboardDocumentsParams) => {
    const response = await this.axios.get<IPayload<IOnboardDocument[]>>(
      Endpoints.getOnboardDocuments,
      params
    )
    return response
  }

  getOnboardDocument = async (id: number, params?: IGetOnboardDocumentsParams) => {
    const response = await this.axios.get<IPayload<IOnboardDocument>>(
      Endpoints.getOnboardDocument(id),
      params
    )
    return response
  }

  postOnboardDocument = async (params: IPostOnboardDocumentParams) => {
    const response = await this.axios.post<IPayload<IOnboardDocument>>(
      Endpoints.postOnboardDocument,
      params
    )
    return response
  }

  putOnboardDocument = async (id: number, params?: IPutOnboardDocumentParams) => {
    const response = await this.axios.put<IPayload<IOnboardDocument>>(
      Endpoints.putOnboardDocument(id),
      params
    )
    return response
  }

  deleteOnboardDocument = async (id: number) => {
    const response = await this.axios.delete<{}>(Endpoints.deleteOnboardDocument(id))
    return response
  }
}
