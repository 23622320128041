import { number, object, string, date, boolean } from 'yup'
import { IApplicantDropdownData } from 'models/Application'
import { IDropdownData, TypeOrNull, TypeOrUndefined } from 'models/Common'
import { IPunchCard } from 'models/PunchCard'

export type TAddExtraPunchCardModalValues = {
  shiftId: TypeOrUndefined<number>
  applicant: TypeOrNull<IApplicantDropdownData>
  date: TypeOrUndefined<Date>
  hour_start: string
  hour_end: string
  pay_value: TypeOrUndefined<number>
  break: TypeOrNull<IDropdownData>
  punchCard: IPunchCard
}

export const addExtraPunchCardValidationSchema = object().shape({
  shiftId: number().required('Required'),
  applicant: object()
    .required('Required')
    .shape({
      value: number(),
      label: string(),
      application_id: number(),
      isDefault: boolean(),
    })
    .nullable(),
  date: date().required('Required'),
  hour_start: string().required('Required'),
  hour_end: string().when('punchCard.isNoEndPunchCard', {
    is: true,
    then: string().nullable(),
    otherwise: string().required('Required'),
  }),
  break: object()
    .required('Required')
    .shape({
      value: number(),
      label: string(),
    })
    .nullable(),
})
