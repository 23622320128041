import { Text, Flex } from '@pinchenterprisesnpm/friday-ui'
import styled from 'styled-components'

export const PendingSuspensionText = styled(Text)`
  white-space: initial;
  word-break: break-word;
  margin: 0;
`
export const FlexWrapper = styled(Flex)<{ isCollapsed?: boolean }>`
  margin: ${({ isCollapsed, theme: { mp } }) =>
    isCollapsed ? `${mp.md} 0 50px ${mp.xs}` : `0 0 ${mp.sm} ${mp.xs}`};
  cursor: pointer;
`
