import { AxiosService } from '../config/config'
import { Endpoints } from '../config/endpoints'
import { IPayload } from '../../models/Global'
import { IGroup, IGetGroupsParams, IPutGroupParams, IGetGroupParams } from '../../models/Group'
import { IPutGroupInviteParams } from 'models/GroupInvite'

export class GroupsApi {
  constructor(private axios: AxiosService) {}

  getGroups = async (params?: IGetGroupsParams) => {
    const response = await this.axios.get<IPayload<IGroup[]>>(Endpoints.getGroups, params)
    return response
  }

  getGroup = async (id: number, params?: IGetGroupParams) => {
    const response = await this.axios.get<IPayload<IGroup>>(Endpoints.getGroup(id), params)
    return response
  }

  putGroup = async (id: number, params?: IPutGroupParams) => {
    const response = await this.axios.put<IPayload<IGroup>>(Endpoints.putGroup(id), params)
    return response
  }

  deleteGroup = async (id: number) => {
    const response = await this.axios.delete(Endpoints.deleteGroup(id))
    return response
  }

  deleteGroupMember = async (id: number) => {
    const response = await this.axios.delete(Endpoints.deleteGroupMember(id))
    return response
  }

  putGroupInvite = async (id: number, params: IPutGroupInviteParams) => {
    const response = await this.axios.put(Endpoints.putGroupInvite(id), params)
  }

  postGroupInvitation = async (params: any) => {
    const response = await this.axios.post(Endpoints.postGroupInvitation, params)
  }
}
