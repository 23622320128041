import { Action } from 'redux'
import { isType } from 'ts-action'
import {
  GetAdminUser,
  GetAdminUserErrors,
  AdminUserFetching,
  UpdateAdminUser,
  ClearAdminUserReducer,
} from './actions'
import { IUserReducer, IUser } from 'models/User'

const initialState: IUserReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: {} as IUser,
}

export const adminUserReducer = (state = initialState, action: Action): IUserReducer => {
  if (isType(action, GetAdminUser)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
    }
  }

  if (isType(action, AdminUserFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }

  if (isType(action, UpdateAdminUser)) {
    const { data } = action.payload
    return {
      ...state,
      data,
    }
  }

  if (isType(action, ClearAdminUserReducer)) {
    return initialState
  }

  if (isType(action, GetAdminUserErrors)) {
    const { payload } = action
    return {
      ...state,
      error: {
        code: payload.code,
        message: payload.message,
      },
    }
  }

  return state
}
