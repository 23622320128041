import { faBellExclamation } from '@fortawesome/pro-duotone-svg-icons'
import { Alert, Flex } from '@pinchenterprisesnpm/friday-ui'
import { MilestoneAlertWrapper, MilestoneAlertIcon, MilestoneAlertContent } from './style'

export const MilestoneAlert = () => {
  const hasSeenMilestoneAlert = localStorage.getItem('hide-milestone-alert')

  return !hasSeenMilestoneAlert ? (
    <MilestoneAlertWrapper>
      <Alert
        type='success'
        isClosable
        onClose={() => {
          localStorage.setItem('hide-milestone-alert', '1')
        }}
        content={() => (
          <Flex alignItems='center'>
            <MilestoneAlertIcon icon={faBellExclamation} size='lg' />
            <MilestoneAlertContent>
              A house cannot be built without a solid foundation and the same can be said about
              success and this milestone. Our drive and ambition are that solid base and our
              achievement and this milestone is one of the many bricks building that house.
              Congratulations, Champs! Back to the grind and on to the next one!
            </MilestoneAlertContent>
          </Flex>
        )}
      />
    </MilestoneAlertWrapper>
  ) : null
}
