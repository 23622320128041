import { Loader, useSidebarMenuContext } from '@pinchenterprisesnpm/friday-ui'
import { LoaderContainer } from './styles'

const FullScreenLoader = () => {
  const { menuWidth } = useSidebarMenuContext()

  return (
    <LoaderContainer offset={menuWidth}>
      <Loader />
    </LoaderContainer>
  )
}

export default FullScreenLoader
