import { INetOptionsReducer, INetOption } from 'models/NetOption'
import { Action } from 'redux'
import { isType } from 'ts-action'
import { updateArray, deleteFromArray } from 'helpers/helperFunctions'
import { GetNetOptions, NetOptionsFetching, UpdateNetOption, DeleteNetOption } from './actions'

const initialState: INetOptionsReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: [],
}

export const netOptionsReducer = (state = initialState, action: Action): INetOptionsReducer => {
  if (isType(action, GetNetOptions)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
    }
  }
  if (isType(action, UpdateNetOption)) {
    const { payload } = action
    const { data } = state
    const formattedArray = updateArray<INetOption>(data, payload.data)
    return {
      ...state,
      data: formattedArray,
    }
  }
  if (isType(action, DeleteNetOption)) {
    const { payload } = action
    const { data } = state
    const formattedArray = deleteFromArray<INetOption>(data, payload)
    return {
      ...state,
      data: formattedArray,
    }
  }

  if (isType(action, NetOptionsFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }
  return state
}
