import axios, { AxiosRequestConfig } from 'axios'
import { ClearAuthReducer, SetIsAuthUser, SetUserData } from 'data/Auth/actions'
import MonitoringService from 'helpers/monitoringService'
import AuthService from 'services/AuthService'
import store from 'store/store'
import { API } from '..'
import { CODE_481_ACTIONS } from './error'
import {
  BASE_PATH,
  addRequestInterceptors,
  addResponseInterceptors,
  errorHandling,
  largeRequestLogger,
  onRefreshed,
  removePending,
  subscribeTokenRefresh,
} from './utils'

let isRefreshing = false

export class AxiosService {
  instance = axios.create({
    baseURL: BASE_PATH,
  })

  constructor() {
    this.instance.interceptors.request.use(addRequestInterceptors)
    this.instance.interceptors.response.use(addResponseInterceptors, this.handleResponseError)
  }

  handleResponseError(error: any) {
    const {
      config,
      response: {
        status,
        data: {
          meta: { action },
        },
      },
    } = error
    largeRequestLogger(error.response)

    const showError = error.response
    const originalRequest = config
    removePending(error.config)

    if (status === 500) {
      MonitoringService.logError(error)
    }

    if (action === CODE_481_ACTIONS.TOKEN_EXPIRED) {
      if (!isRefreshing) {
        isRefreshing = true
        API.Auth.refresh()
          .then((response) => {
            const { token } = response.meta.auth
            AuthService.setAuthAndUserDataInLocalStorage(response)
            store.dispatch(SetUserData(response.data))
            store.dispatch(SetIsAuthUser())
            return token
          })
          .then((token) => {
            onRefreshed(token)
          })
          .then(() => {
            isRefreshing = false
          })
      }
      const retryOrigReq = new Promise((resolve, reject) => {
        subscribeTokenRefresh((token) => {
          // replace the expired token and retry
          originalRequest.headers.Authorization = `Bearer ${token}`
          resolve(this.instance(originalRequest))
        })
      })
      return retryOrigReq
    }
    if (action === CODE_481_ACTIONS.TOKEN_INVALID) {
      AuthService.ClearLocalStorage()
      store.dispatch(ClearAuthReducer())
      window.location.href = '/'
    }
    return Promise.reject(showError)
  }

  get<T>(url: string, params?: any, config: AxiosRequestConfig = {}): Promise<T> {
    return this.instance
      .get(url, { params, ...config })
      .then(({ data }) => data)
      .catch(errorHandling)
  }

  post<T>(url: string, params?: any, config: AxiosRequestConfig = {}): Promise<T> {
    return this.instance
      .post(url, params, config)
      .then(({ data }) => data)
      .catch(errorHandling)
  }

  delete<T>(url: string, params?: any, config: AxiosRequestConfig = {}): Promise<T> {
    return this.instance
      .delete(url, { data: params, ...config })
      .then(({ data }) => data)
      .catch(errorHandling)
  }

  put<T>(url: string, params: any, config: AxiosRequestConfig = {}): Promise<T> {
    return this.instance
      .put(url, params, config)
      .then(({ data }) => data)
      .catch(errorHandling)
  }
}
