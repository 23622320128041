import { action, payload } from 'ts-action'
import { IChallenge } from '../../models/Challenge'
import { IError, IPayload } from '../../models/Global'

export const ChallengeFetching = action('ChallengeFetching', payload<boolean>())

export const GetChallenge = action('GetChallenge', payload<IPayload<IChallenge>>())

export const GetChallengeErrors = action('GetChallengeErrors', payload<IError>())

export const ResetChallenge = action('ResetChallenge')
