import { AxiosService } from '../config/config'
import { Endpoints } from '../config/endpoints'
import { IPayload } from 'models/Global'
import {
  IUser,
  IGetUsersParams,
  IUserSickPayDayHourStats,
  IGetUserParams,
  IPostUserParams,
  IPutUserParams,
  IUpdateUserAvatarParams,
} from 'models/User'
import { IPostPhoneTypeParams, IPhoneType } from 'models/PhoneType'

export class UsersApi {
  constructor(private axios: AxiosService) {}

  getUsers = async (params?: IGetUsersParams) => {
    const response = await this.axios.get<IPayload<IUser[]>>(Endpoints.getUsers, params)
    return response
  }

  getUser = async (id: number, params?: IGetUserParams) => {
    const response = await this.axios.get<IPayload<IUser>>(Endpoints.getUser(id), params)
    return response
  }

  postUser = async (params: IPostUserParams) => {
    const response = await this.axios.post<IPayload<IUser>>(Endpoints.postUser, params)
    return response
  }

  putUser = async (id: number, params: IPutUserParams) => {
    const response = await this.axios.put<IPayload<IUser>>(Endpoints.putUser(id), params)
    return response
  }

  deleteUser = async (id: number) => {
    const response = await this.axios.delete<{}>(Endpoints.deleteUser(id))
    return response
  }

  getUserSickPayDayHourStats = async (id: number) => {
    const response = await this.axios.get<IPayload<IUserSickPayDayHourStats>>(
      Endpoints.getUserSickPayDayHourStats(id),
      {}
    )
    return response
  }

  updateUserAvatar = async (id: number, params: IUpdateUserAvatarParams) => {
    const response = await this.axios.post<IPayload<IUser>>(Endpoints.updateUserAvatar(id), params)
    return response
  }

  checkUserPhoneType = async (params: IPostPhoneTypeParams) => {
    const response = await this.axios.post<IPayload<IPhoneType>>(
      Endpoints.checkUserPhoneType,
      params
    )
    return response
  }
}
