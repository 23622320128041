import { AxiosService } from '../config/config'
import { Endpoints } from '../config/endpoints'
import { IPayload } from 'models/Global'
import {
  IDeductionType,
  IGetDeductionTypesParams,
  IPutDeductionTypeParams,
  IPostDeductionTypeParams,
} from 'models/DeductionType'

export class DeductionTypesApi {
  constructor(private axios: AxiosService) {}

  getDeductionTypes = async (params?: IGetDeductionTypesParams) => {
    const response = await this.axios.get<IPayload<IDeductionType[]>>(
      Endpoints.getDeductionTypes,
      params
    )
    return response
  }

  getDeductionType = async (id: number, params?: IGetDeductionTypesParams) => {
    const response = await this.axios.get<IPayload<IDeductionType>>(
      Endpoints.getDeductionType(id),
      params
    )
    return response
  }

  postDeductionType = async (params: IPostDeductionTypeParams) => {
    const response = await this.axios.post<IPayload<IDeductionType>>(
      Endpoints.postDeductionType,
      params
    )
    return response
  }

  putDeductionType = async (id: number, params?: IPutDeductionTypeParams) => {
    const response = await this.axios.put<IPayload<IDeductionType>>(
      Endpoints.putDeductionType(id),
      params
    )
    return response
  }

  deleteDeductionType = async (id: number) => {
    const response = await this.axios.delete<{}>(Endpoints.deleteDeductionType(id))
    return response
  }
}
