import { IUser, USER_ROLE } from 'models/User'
import { TypeOrNull } from 'models/Common'
import { IAuthPayload } from 'models/Auth'

export default class AuthService {
  static getBearerToken = (): TypeOrNull<string> => {
    const token = localStorage.getItem('token')
    return token
  }

  /**
   * Gets the upshiftLoggedInUser from the localStorage
   */
  static getLoggedInUser = (): TypeOrNull<IUser> => {
    const upshiftLoggedInUser = localStorage.getItem('loggedInUser')
    if (upshiftLoggedInUser) return JSON.parse(upshiftLoggedInUser)
    return null
  }

  /**
   * Gets the user roles from the upshiftLoggedInUser object in localStorage
   */
  static getLoggedInUserRole = (): TypeOrNull<USER_ROLE> => {
    const loggedInUser = AuthService.getLoggedInUser()
    return loggedInUser ? loggedInUser.roles[0].name : null
  }

  /**
   * @param data The full response returned from the Auth route
   */
  static setAuthAndUserDataInLocalStorage = async (data: IAuthPayload) => {
    localStorage.setItem('token', data.meta.auth.token)
    localStorage.setItem('token_expires_at', data.meta.auth.expires_at)
    localStorage.setItem('loggedInUser', JSON.stringify(data.data))
  }

  // Clear Local storage
  static ClearLocalStorage = () => {
    localStorage.removeItem('loggedInUser')
    localStorage.removeItem('token')
    localStorage.removeItem('token_expires_at')
    localStorage.removeItem('toggle-confetti')
    localStorage.removeItem('hide-milestone-alert')
  }
}
