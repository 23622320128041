import { titlePrefix, useTitle } from 'ui/components/common/HelmetTitle/utils'

/**
 * Reuse the 'useTitle' that we use for helmet for the header
 * @returns Title of the page
 */
export const useHeaderTitle = () => {
  const title = useTitle()
  const prefixlessTitle = title.replace(titlePrefix, '')

  return prefixlessTitle
}
