import { Redirect, Route } from 'react-router'
import { businessEntityRouteMappings } from './utils'

export const redirectOldBusinessEntityRoutes = Object.keys(businessEntityRouteMappings).map(
  (oldPath) => (
    <Route
      exact
      key={oldPath}
      path={oldPath}
      render={({ match }) => {
        const redirectLink = businessEntityRouteMappings[
          oldPath as keyof typeof businessEntityRouteMappings
        ]
          .replace(':id', String(match.params.id))
          .replace(':invoice_group_id', String(match.params.invoice_group_id))
          .replace(':documentId', String(match.params.documentId))
          .replace(':templateId', String(match.params.templateId))

        return <Redirect to={redirectLink} />
      }}
    />
  )
)
