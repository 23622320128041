import { POWER_SEARCH_RESOURCE_TYPE, redirectToPageById } from '../PowerSearch/utils'
export type TListItemNavigationProps = {
  onMouseOver: React.MouseEventHandler<HTMLDivElement>
  onMouseOut: React.MouseEventHandler<HTMLDivElement>
  onClick: React.MouseEventHandler<HTMLDivElement>
  isActive: boolean
  onKeyDown: (event: any) => void
}

export const DEFAULT_HOVER_VALUE = -1

export const redirectOnClick = (resourceType: POWER_SEARCH_RESOURCE_TYPE, id: string | number) => {
  window.location.assign(redirectToPageById(resourceType, id))
}
