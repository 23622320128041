import { Button, Subtitle, Title, Wrap } from './style'

export const SomethingWentWrong = () => {
  const reloadPage = () => {
    document.location.reload()
  }
  return (
    <Wrap>
      <Title>Oops... Something went wrong</Title>
      <Subtitle>
        It appears that an error has occurred.
        <br />
        We are sorry for the inconvenience.
      </Subtitle>
      <Button onClick={() => reloadPage()}>Try again</Button>
    </Wrap>
  )
}
