import { USER_ROLE } from 'models/User'
import { AxiosService } from '../config/config'
import { Endpoints } from '../config/endpoints'
import {
  IAuthData,
  IAuthLogin,
  IAuthPayload,
  InvalidCredentialsError,
  IVerifyTwoFactorCodeParams,
} from 'models/Auth'
import AuthService from 'services/AuthService'

export class AuthApi {
  constructor(private axios: AxiosService) {}

  login = async (params: IAuthLogin) => {
    const response = await this.axios.post<IAuthPayload>(Endpoints.login, params)
    if (
      response.data.roles.some(
        ({ name }) =>
          name === USER_ROLE.ADMIN ||
          name === USER_ROLE.ONBOARDER ||
          USER_ROLE.ACCOUNT_MANAGER_LEVEL_1 ||
          USER_ROLE.ACCOUNT_MANAGER_LEVEL_2 ||
          USER_ROLE.SALES
      )
    ) {
      if (response.meta.auth.token) {
        AuthService.setAuthAndUserDataInLocalStorage(response)
      }

      return response
    }
    throw new InvalidCredentialsError('Invalid permissions')
  }

  logout = async () => {
    const response = await this.axios.post(Endpoints.logout)
    return response
  }

  verifyTwoFactorCode = async (id: number, params: IVerifyTwoFactorCodeParams) => {
    const response = await this.axios.post<IAuthPayload>(Endpoints.verifyTwoFactorCode(id), params)
    return response
  }

  resendVerifyCode = async (id: number) => {
    const response = await this.axios.get<IAuthPayload>(Endpoints.resendVerifyCode(id))
    return response
  }

  me = async () => this.axios.get<IAuthData>(Endpoints.me)

  refresh = async () => this.axios.post<IAuthPayload>(Endpoints.refreshToken)
}
