import styled from 'styled-components'
import { Text, Flex } from '@pinchenterprisesnpm/friday-ui'

export const BoldText = styled(Text)`
  font-weight: ${({
    theme: {
      font: { weights },
    },
  }) => weights.semiBold};
`

export const ModalContentWrapper = styled(Flex)`
  width: 100%;
  height: 100%;
  background: ${({ theme: { palette } }) => palette.white};
  padding: ${({ theme: { mp } }) => `0 0 ${mp.md}`};
`
export const FormWrapper = styled.div`
  padding: ${({ theme: { mp } }) => `0 ${mp.md} `};
  margin-top: ${({ theme: { mp } }) => mp.xs};
  margin-bottom: ${({ theme: { mp } }) => mp.md};
`
export const LoaderWrapper = styled(Flex)`
  margin-top: ${({ theme: { mp } }) => mp.lg};
`
