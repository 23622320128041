import { AxiosService } from '../config/config'
import { Endpoints } from '../config/endpoints'
import { IPayload } from '../../models/Global'
import {
  IInviteApplicant,
  TGetInviteApplicantsParams,
  IPostInviteApplicantParams,
  IGetInviteApplicantsPreselectedParams,
  IPreselectedApplicants,
} from '../../models/InviteApplicants'

export class InviteApplicantsApi {
  constructor(private axios: AxiosService) {}

  getInviteApplicants = async (params?: TGetInviteApplicantsParams) => {
    const response = await this.axios.get<IPayload<IInviteApplicant[]>>(
      Endpoints.getInviteApplicants,
      params
    )
    return response
  }

  postInviteApplicants = async (params: IPostInviteApplicantParams) => {
    const response = await this.axios.post<IPayload<IInviteApplicant[]>>(
      Endpoints.postInviteApplicants,
      params
    )
    return response
  }

  getInviteApplicantsPreselected = async (params: IGetInviteApplicantsPreselectedParams) => {
    const response = await this.axios.get<IPayload<IPreselectedApplicants>>(
      Endpoints.getInviteApplicantsPreselected,
      params
    )
    return response
  }
}
