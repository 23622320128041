import React from 'react'
import { Label, Checkbox, Toggle } from './style'

type TGroupOptionProps = {
  valueName: React.ReactChild
  onClick: (...args: any[]) => void
  onChange: (...args: any[]) => void
  [key: string]: any
}

const GroupOption = (props: TGroupOptionProps) => {
  const { valueName, onClick, onChange, ...rest } = props

  return (
    <Label {...rest}>
      <Checkbox {...props} />
      <Toggle>{valueName}</Toggle>
    </Label>
  )
}
export default GroupOption
