import { AxiosService } from '../config/config'
import { Endpoints } from '../config/endpoints'
import { IPayload } from 'models/Global'
import {
  IExperience,
  IGetExperiencesParams,
  IGetGigTypeExperiencesParams,
  IGigTypeExperience,
} from 'models/Experience'

export class ExperiencesApi {
  constructor(private axios: AxiosService) {}

  getExperiences = async (params?: IGetExperiencesParams) => {
    const response = await this.axios.get<IPayload<IExperience[]>>(Endpoints.getExperiences, params)
    return response
  }

  getGigTypeExperiences = async (params?: IGetGigTypeExperiencesParams) => {
    const response = await this.axios.get<IPayload<IGigTypeExperience[]>>(
      Endpoints.getGigTypeExperiences,
      params
    )
    return response
  }
}
