import { AxiosService } from '../config/config'
import { Endpoints } from '../config/endpoints'
import { IPayload } from 'models/Global'
import {
  IVaccinationType,
  IGetVaccinationTypesParams,
  IPutVaccinationTypeParams,
  IPostVaccinationTypeParams,
} from 'models/VaccinationType'

export class VaccinationTypesApi {
  constructor(private axios: AxiosService) {}

  getVaccinationTypes = async (params?: IGetVaccinationTypesParams) => {
    const response = await this.axios.get<IPayload<IVaccinationType[]>>(
      Endpoints.getVaccinationTypes,
      params
    )
    return response
  }

  getVaccinationType = async (id: number, params?: IGetVaccinationTypesParams) => {
    const response = await this.axios.get<IPayload<IVaccinationType>>(
      Endpoints.getVaccinationType(id),
      params
    )
    return response
  }

  postVaccinationType = async (params: IPostVaccinationTypeParams) => {
    const response = await this.axios.post<IPayload<IVaccinationType>>(
      Endpoints.postVaccinationType,
      params
    )
    return response
  }

  putVaccinationType = async (id: number, params?: IPutVaccinationTypeParams) => {
    const response = await this.axios.put<IPayload<IVaccinationType>>(
      Endpoints.putVaccinationType(id),
      params
    )
    return response
  }

  deleteVaccinationType = async (id: number) => {
    const response = await this.axios.delete<{}>(Endpoints.deleteVaccinationType(id))
    return response
  }
}
