import { action, payload } from 'ts-action'
import { IGigType } from 'models/GigType'
import { IError, IPayload } from 'models/Global'
import { TypeOrUndefined } from 'models/Common'

export const GigTypesFetching = action('GigTypesFetching', payload<boolean>())

export const GetGigTypes = action('GetGigTypes', payload<IPayload<IGigType[]>>())

export const AddGigType = action('AddGigType', payload<IPayload<IGigType>>())

export const UpdateGigType = action('UpdateGigType', payload<IPayload<IGigType>>())

export const DeleteGigType = action('DeleteGigType', payload<number>())

export const GetGigTypesErrors = action('GetGigTypesErrors', payload<TypeOrUndefined<IError>>())

export const UpdateGigTypeLoadingId = action('UpdateGigTypeLoadingId', payload<number>())
