import { AxiosService } from '../config/config'
import { Endpoints } from '../config/endpoints'
import { IPayload } from 'models/Global'
import {
  ICertificate,
  IGetCertificatesParams,
  IPutCertificateParams,
  IPostCertificateParams,
  IPostCertificateFileParams,
} from 'models/Certificate'

export class CertificatesApi {
  constructor(private axios: AxiosService) {}

  getCertificates = async (params?: IGetCertificatesParams) => {
    const response = await this.axios.get<IPayload<ICertificate[]>>(
      Endpoints.getCertificates,
      params
    )
    return response
  }

  getCertificate = async (id: number, params?: IGetCertificatesParams) => {
    const response = await this.axios.get<IPayload<ICertificate>>(
      Endpoints.getCertificate(id),
      params
    )
    return response
  }

  postCertificate = async (params: IPostCertificateParams) => {
    const response = await this.axios.post<IPayload<ICertificate>>(
      Endpoints.postCertificate,
      params
    )
    return response
  }

  putCertificate = async (id: number, params?: IPutCertificateParams) => {
    const response = await this.axios.put<IPayload<ICertificate>>(
      Endpoints.putCertificate(id),
      params
    )
    return response
  }

  postCertificateFile = async (id: number, params?: IPostCertificateFileParams) => {
    const response = await this.axios.post<IPayload<ICertificate>>(
      Endpoints.putCertificate(id),
      params
    )
    return response
  }

  deleteCertificate = async (id: number) => {
    const response = await this.axios.delete<{}>(Endpoints.deleteCertificate(id))
    return response
  }
}
