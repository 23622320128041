import { AxiosService } from '../config/config'
import { Endpoints } from '../config/endpoints'
import { IPayload } from 'models/Global'
import { IGetGigDaysParams, IGigDay } from 'models/GigDay'

export class GigDaysApi {
  constructor(private axios: AxiosService) {}

  getGigDays = async (params?: IGetGigDaysParams) => {
    const response = await this.axios.get<IPayload<IGigDay[]>>(Endpoints.getGigDays, params)
    return response
  }
}
