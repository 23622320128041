import { action, payload } from 'ts-action'
import { ITimetracker } from 'models/Timetracker'
import { IError, IPayload } from 'models/Global'

export const GetPendingTimetrackers = action(
  'GetPendingTimetrackers',
  payload<IPayload<ITimetracker[]>>()
)

export const GetPendingTimetrackersErrors = action(
  'GetPendingTimetrackersErrors',
  payload<IError>()
)

export const PendingTimetrackersFetching = action('PendingTimetrackersFetching', payload<boolean>())
