import styled from 'styled-components'
import { Text, Flex } from '@pinchenterprisesnpm/friday-ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const InfoWrapper = styled(Flex)`
  margin-left: ${({ theme: { mp } }) => mp.md};
  padding: ${({ theme: { mp } }) => mp.xs} 0;
`
export const TextWrapper = styled(Text)<{ bold?: boolean; isPrimary?: boolean }>`
  font-weight: ${({
    bold,
    theme: {
      font: { weights },
    },
  }) => (bold ? weights.semiBold : weights.regular)};
  color: ${({ isPrimary, theme: { palette } }) => (isPrimary ? palette.primary : palette.dark)};
`
export const Wrapper = styled(Flex)`
  margin-left: ${({ theme: { mp } }) => mp.sm};
`

export const IconWrapper = styled(FontAwesomeIcon)`
  &:hover {
    cursor: pointer;
  }
`
export const FlexWrapper = styled(Flex)`
  cursor: pointer;
  padding-right: ${({ theme: { mp } }) => mp.md};
  &:hover {
    background: rgba(3, 169, 244, 0.1);
  }
`
export const FlexItemWrapper = styled(Flex)`
  padding: ${({ theme: { mp } }) => `0 ${mp.md}`};
  flex: 1;
`
