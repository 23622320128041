import { action, payload } from 'ts-action'
import { INotificationPreference } from 'models/NotificationPreference'
import { IError, IPayload } from 'models/Global'

export const NotificationPreferencesFetching = action(
  'NotificationPreferencesFetching',
  payload<boolean>()
)

export const GetNotificationPreferences = action(
  'GetNotificationPreferences',
  payload<IPayload<INotificationPreference[]>>()
)

export const AddNotificationPreference = action(
  'AddNotificationPreference',
  payload<IPayload<INotificationPreference>>()
)

export const UpdateNotificationPreference = action(
  'UpdateNotificationPreference',
  payload<IPayload<INotificationPreference>>()
)

export const DeleteNotificationPreference = action(
  'DeleteNotificationPreference',
  payload<number>()
)

export const GetNotificationPreferencesErrors = action(
  'GetNotificationPreferencesErrors',
  payload<IError>()
)

export const EnableTextNotificationPreferences = action('EnableTextNotificationPreferences')

export const ClearNotificationPreferencesReducer = action('ClearNotificationPreferencesReducer')
