import React, { useCallback } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'
import { selectAuthIsAuth, selectAuthUserRole } from 'data/Auth/selectors'
import { isAdminUserByRoleName } from 'helpers/authHelpers'
import { checkPermissions } from 'helpers/permissionsHelpers/permissionsChecker'
import { HelmetTitle } from '../HelmetTitle'

type TAdminRouteProps = {
  component: React.ComponentType<any>
  layout: React.ComponentType<any>
  [key: string]: any
  requiredPermissions?: string[]
  method?: 'all' | 'any'
}

const AdminRoute = (props: TAdminRouteProps) => {
  const { component: Component, layout: Layout, requiredPermissions, method, ...rest } = props
  const isAuth = useSelector(selectAuthIsAuth, shallowEqual)
  const userRole = useSelector(selectAuthUserRole, shallowEqual)
  const render = useCallback(
    (props) => {
      let permitted = isAuth && isAdminUserByRoleName(userRole)
      if (requiredPermissions) {
        permitted = permitted && checkPermissions(requiredPermissions, userRole, method)
      }
      if (permitted)
        return (
          <Layout>
            <HelmetTitle />
            <Component {...props} />
          </Layout>
        )
      return <Redirect to='/' />
    },
    [Component, Layout, isAuth, requiredPermissions, userRole, method]
  )

  return <Route {...rest} render={render} />
}

export default AdminRoute
