import { action, payload } from 'ts-action'
import { IShiftIdentifierPolicy } from 'models/ShiftIdentifierPolicy'
import { IError, IPayload } from 'models/Global'

export const GetShiftIdentifierPolicies = action(
  'GetShiftIdentifierPolicies',
  payload<IPayload<IShiftIdentifierPolicy[]>>()
)

export const GetShiftIdentifierPoliciesErrors = action(
  'GetShiftIdentifierPoliciesErrors',
  payload<IError>()
)

export const ClearShiftIdentifierPolicies = action('ClearShiftIdentifierPolicies')

export const ShiftIdentifierPoliciesFetching = action(
  'ShiftIdentifierPoliciesFetching',
  payload<boolean>()
)

export const DeleteShiftIdentifierPolicy = action('DeleteShiftIdentifierPolicy', payload<number>())
