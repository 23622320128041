import { Theme } from '@pinchenterprisesnpm/friday-ui'
import imageCompression from 'browser-image-compression'
import { ALLOWED_EMAILS_FOR_CREATING_CREDITS, ENV_NAME } from 'config/envVariables'
import { checkPermissions } from 'helpers/permissionsHelpers/permissionsChecker'
import { capitalize } from 'lodash'
import { IBusinessEntity } from 'models/BusinessEntity'
import { IEnumObject, TypeOrUndefined } from 'models/Common'
import { IError } from 'models/Global'
import { AUTO_ACCEPT_CERTIFICATES } from 'models/Permission'
import { IShift } from 'models/Shift'
import moment from 'moment'
import ROUTES from 'routing/adminRoutes'
import AuthService from 'services/AuthService'
import { NotificationManager } from './NotificationsManager'
import { URLManager } from './URLManager'
import { GAMIFICATION_TAB, SuperSecretAllowedUsers, UPSHIFTER_ROLES } from './constants/constants'
import { history } from './history'
import { USER_ROLE } from 'models/User'
import { CODE_481_ACTIONS, STATUS_CODES } from 'network/config/error'
import { IInvoice, INVOICE_PAID_STATUS } from 'models/Invoice'
import { PAYMENT_GATEWAY_ENUM } from 'models/Payment'

const WORD_SEPARATOR = ' '

/**
 * Retry loading on chunk
 */
type ImportFn = () => Promise<object>
type RetryFn = Promise<any>

export const retry = (func: ImportFn, retriesLeft = 5, interval = 1000): RetryFn =>
  new Promise((resolve, reject) => {
    func()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            reject(error)
            return
          }
          retry(func, retriesLeft - 1, interval).then(resolve, reject)
        }, interval)
      })
  })

/**
 *
 * @param urlParameters
 * @param pathname
 */

export const updateUrlState = (urlParameters: URLSearchParams, pathname: any) => {
  const query = `?${urlParameters.toString()}`
  const newUrl = `${pathname}${query}`
  window.history.pushState({ path: newUrl }, '', newUrl)
}

export const isObjectEmpty = (arg: object) => Object.entries(arg).length === 0

export const debounce = (callback: (...args: any[]) => any, wait = 250) => {
  let timer: any
  let last_call = 0
  return (...args: any[]) => {
    clearTimeout(timer)
    const now = Date.now(),
      time_from_last_call = now - last_call

    if (time_from_last_call > wait) {
      last_call = now
      callback(...args)
    } else {
      timer = setTimeout(() => {
        last_call = now
        callback(...args)
      }, wait)
    }
  }
}

// Redux helper functions
export const updateArray = <T extends { id: number; [key: string]: any }>(
  data: T[],
  payload: T
): T[] => {
  const updatedArray = data.map((item) => {
    if (item.id !== payload.id) {
      return item
    }
    return payload
  })
  return updatedArray
}

export const deleteFromArray = <T extends { id: number; [key: string]: any }>(
  data: T[],
  id: number
): T[] => {
  const updatedArray = data.filter((item) => item.id !== id)
  return updatedArray
}

export const maybeAddToArray = <T extends { id: number; [key: string]: any }>(
  data: T[],
  item: T
): T[] => {
  const exists = data.find((element) => element.id === item.id)

  if (!exists) {
    return [item, ...data]
  } else return data
}

export const isMobile = () => {
  return window.innerWidth <= (Theme as any).breakpoints.sm
}

export const dropdownDataMapper = (data: { id: number; name: string; [key: string]: any }[]) => {
  const mappedData = data.map((item) => {
    return { value: item.id, label: item.name }
  })
  return mappedData
}

export const isUserUpshifter = (role: USER_ROLE): boolean => {
  return UPSHIFTER_ROLES.indexOf(role) >= 0
}

/**
 * Compresses an image in order to reduce its size
 * @param image The image to be compressed
 * @param maxImageSizeInMbs The maximum allowed size (in MBs) for the result image, defaults to 0.3 (300 Kbs)
 */
export const compressImage = async (image: File, maxImageSizeInMbs?: number) => {
  const options = {
    maxSizeMB: maxImageSizeInMbs || 0.3,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  }
  const compressedFile = await imageCompression(image, options)
  return compressedFile
}

/* This function receives an error object and returns the message. If there are validation (status 422) errors, then only the first one is displayed.
 * It is generally used to display an informative notification message when the promise is part of a Promise.all
 * Not good for usage outside of that, as it doesn't take into account more than one validation error.
 * @param error Error object returned from the Axios instance
 */
export const promiseErrorMsgHandler = (error: IError, validationErrorOnly?: boolean): string => {
  let errorMsg = error.message
  if (error.code === STATUS_CODES.UNPROCESSABLE_ENTITY && error.validationErrors) {
    const firstInvalidFieldKey = Object.keys(error.validationErrors)[0]
    errorMsg = error.validationErrors[firstInvalidFieldKey][0]
    return errorMsg
  }
  return validationErrorOnly ? '' : errorMsg
}

export const isDevelopment = () => ENV_NAME === 'development' || ENV_NAME === 'dev'

export const isIntegration = () => ENV_NAME === 'int'

export const isProduction = () => ENV_NAME === 'production'

export const isStaging = () => ENV_NAME === 'staging'

export const isRelease = () => ENV_NAME === 'release'

/**
 * Create valid form data from a simple or a complex/nested object
 * @param object
 * @param form
 * @param namespace
 */
export const createFormDataFromObject = (
  object: Object,
  form?: FormData,
  namespace?: string
): FormData => {
  const formData = form || new FormData()
  for (const property in object) {
    if (
      (object as any)[property] != 0 && //condition added so that property with values set to 0 do not fullfill this condition
      (!object.hasOwnProperty(property) || !(object as any)[property])
    ) {
      continue
    }
    const formKey = namespace ? `${namespace}[${property}]` : property
    if ((object as any)[property] instanceof Date) {
      formData.append(formKey, (object as any)[property].toISOString())
    } else if (
      typeof (object as any)[property] === 'object' &&
      !((object as any)[property] instanceof File)
    ) {
      createFormDataFromObject((object as any)[property], formData, formKey)
    } else {
      formData.append(formKey, (object as any)[property])
    }
  }
  return formData
}

/**
 * Converts Bytes to MegaBytes
 * @param bytes
 */
export const bytesToMegaBytes = (bytes: number) => {
  // converting in base 10 (decimal)
  // if you want to convert in base 2 (binary), then divide the bytes by 1048576
  const megabytes = bytes / 1000000
  return megabytes.toFixed(2)
}

/**
 * Copies a text to clipboard
 * @param textToCopy The text to copy to clipboard
 * @param notificationMessage Notification message to display on successful copy
 */
export const copyTextToClipboard = (
  textToCopy: string,
  notificationMessage = 'Copied to clipboard'
) => {
  const textField = document.createElement('textarea')
  textField.innerText = textToCopy
  document.body.appendChild(textField)
  textField.select()
  document.execCommand('copy')
  textField.remove()
  NotificationManager.success(notificationMessage)
}

/* Function that returns if some user has access to the Super Secret Reports in Reports section */
export const superSecretReportsAccessCheck = () => {
  if (isDevelopment() || isStaging()) {
    return true
  }
  const loggedInUser = AuthService.getLoggedInUser()
  if (loggedInUser !== null) {
    return SuperSecretAllowedUsers.includes(loggedInUser.email)
  }
  return false
}

/**
 * General Go back handler. Pushes history depending on if there is any historyState pushed or not.
 * Please note that if you'd like to use this function, make sure to push something to the history state when linking to the route where this functions is used.
 * @param pathname Pathname where the fallback redirect would happen.
 */
export const handleGoBack = (pathname: string) => {
  const historyState = history.location.state as object
  if (historyState) {
    if (isObjectEmpty(historyState)) {
      history.push(pathname)
    } else {
      history.go(-1)
    }
  } else {
    history.push(pathname)
  }
}

/**
 * Removes and object's key-value pair without mutating the original object.
 * @param key
 * @param param1 The object
 * @returns
 */
export const removeKeyFromObject = (key: string, { [key]: _, ...rest }) => rest

/**
 * Prepare bypass param object returned depending on the error status code
 * @param error
 * @returns
 */
export const getBypassParams = (error: IError) => {
  if (!error.action) return null

  switch (error.action) {
    case CODE_481_ACTIONS.OVERLAPPING_PUNCH_CARD:
      return { skip_overlapping_punch_card_update_validation: true }
    case CODE_481_ACTIONS.GIG_OVERTIME:
      return { skip_overtime_validation: true }
    case CODE_481_ACTIONS.UPSHIFTER_HAS_EXCUSED_ABSENCE:
      return { skip_upshifter_excused_absence_validation: true }
    case CODE_481_ACTIONS.PUNCH_CARD_TRANSFER_LEADS_TO_OVERTIME_MULTIPLE_BUSINESSES:
      return { skip_overtime_check: true }
    case CODE_481_ACTIONS.ACHIEVEMENT_HAS_ONE_LEVEL:
      return { skip_multi_level_validation: true }
    case CODE_481_ACTIONS.USER_ONE_BUSINESS_OVERTIME:
      return { skip_one_business_overtime_validation: true }
    case CODE_481_ACTIONS.BUSINESS_SUSPENDED:
      return { skip_business_suspended_validation: true }
    case CODE_481_ACTIONS.UPSHIFTER_NOT_ACCEPTED_FOR_GIG_DAY:
      return { skip_business_validation_for_accepted_gig_day: true }
    case CODE_481_ACTIONS.RESTRICT_LAST_MINUTE_ADDITIONS_OVERRIDE:
      return {
        skip_restrict_last_minute_additions: true,
      }
    case CODE_481_ACTIONS.USER_MULTIPLE_BUSINESS_OVERTIME:
      return { skip_multiple_business_overtime_validation: true }
    case CODE_481_ACTIONS.ADMIN_TRIES_TO_CREATE_APPLICATION_FOR_GIG_WITH_UNMET_SCREENING_REQUIREMENTS:
      return { assign_screening_requirement: true }
    case CODE_481_ACTIONS.WILL_NOT_PASS_BACKGROUND_CHECK:
      return { skip_will_not_pass_background_check: true }
    case CODE_481_ACTIONS.TRAINING_MODE:
      return { skip_training_mode_validation: true }
    case CODE_481_ACTIONS.ADMIN_TRIES_TO_CREATE_APPLICATION_FOR_UPSHIFTER_WITHOUT_ACKNOWLEDGED_DOCS:
      return { skip_acknowledged_docs_check: true }
    default:
      return null
  }
}

// Function to capitalize the first letter of each word in a given string
export const capitalizeString = (entityName: string) => {
  return entityName
    .split(WORD_SEPARATOR)
    .map((el) => capitalize(el))
    .join(WORD_SEPARATOR)
}

export const getUnsavedChangesMessage = (pageName: string) => {
  return `You will lose all changes made to the ${pageName}. You are trying to close without saving. Are you sure you want to leave the page?`
}

/**
 * Fetches a file from URL. It is converted to blob and the blob is used to construct a File object (ready to use in Dropzone).
 * We need the file in this format so we can preview the attachment within Dropzone component from the API request.
 * @param {*} attachmentUrl
 * @param {*} fileName
 * @returns File || null
 */
export const convertFromUrlToObject = async (attachmentUrl: string, fileName: string) => {
  try {
    const attachment = await fetch(attachmentUrl)
    const blob = await attachment.blob()
    const file = new File([blob], fileName, { type: blob.type })
    return file
  } catch (error) {
    return null
  }
}

export const goBackToGamificationSystemPage = (tab: GAMIFICATION_TAB) => {
  history.push(ROUTES.GAMIFICATION_SYSTEM)
  const urlManager = new URLManager()
  urlManager.setParam('tab', tab)
  urlManager.pushState()
}

// Function that transforms objects that have pairs of an ID and name to an array used for Dropdowns
export const transformObjectToArray = (obj: IEnumObject) => {
  return Object.entries(obj).map(([key, label]) => ({ value: parseInt(key), label }))
}

/**
 * Function that determines whether HideAutoAcceptCertificatesAlert should be shown or not
 * @param {IBusinessEntity} businessEntity
 * @param {IShift} [shift]
 * @returns {boolean}
 */
export const shouldShowHideAutoAcceptCertificatesAlert = (
  businessEntity: IBusinessEntity,
  shift: TypeOrUndefined<IShift>
): boolean => {
  // if the user does not have permission to read and update certificates,
  // we won't show the alert
  if (
    !checkPermissions([
      AUTO_ACCEPT_CERTIFICATES.READ_AUTO_ACCEPT_CERTIFICATES,
      AUTO_ACCEPT_CERTIFICATES.UPDATE_AUTO_ACCEPT_CERTIFICATES,
    ])
  )
    return false
  // if the business entity does not have auto_accept_by_certificates enabled
  // we won't show the alert
  if (!businessEntity.auto_accept_by_certificates) return false

  if (shift)
    // when this helper is used in shift modals, we should rely on the shift's hide_auto_accept_certificates value
    return shift.hide_auto_accept_certificates
  // for other scenarios, we should rely on business's hide_auto_accept_certificates value
  return businessEntity.business_setting?.hide_auto_accept_certificates ?? false
}

/**
 * Function that returns the base pathname
 * @param {string} [path] full pathname
 * @returns {string}
 */
export const getBasePathname = (path: string) => {
  const parts = path.split('/')
  if (parts.length > 2) {
    return parts.slice(0, 2).join('/')
  } else {
    return path
  }
}

export const trackingEnvName = ENV_NAME

/**
 * Function that if the given date is withing 24 hours. Requires the timezone of the given date.
 * @param {Date} [date]
 * @param {string} [timezone]
 * @returns {boolean}
 */
export const checkIfDateIsWithin24hours = (date: Date, timezone: string) => {
  const currentBrowserTimezone = moment.tz.guess()
  const currentBrowserTime = moment().format()

  const timeStartWithTimeZone = moment(date).tz(timezone)

  const timeStartInShiftTimeZone = moment(timeStartWithTimeZone).tz(currentBrowserTimezone)
  const duration = moment.duration(timeStartInShiftTimeZone.diff(currentBrowserTime))
  const hoursDifference = duration.asHours()
  return hoursDifference <= 24 && hoursDifference >= 0
}

/**
 * Function that transforms the selected rows from TableV8 to an array.
 * @param {Object} [rowSelection]
 * @returns {string[]}
 */
export const transformSelectedRowsToArray = (rowSelection: {}) => {
  return Object.keys(rowSelection)
}

/**
 * Returns the total of the Invoice depending on the paid status and the payment method used
 * @param invoice
 * @returns invoice total
 */
export const getInvoiceTotal = (invoice: IInvoice, paymentGatewayEnum?: PAYMENT_GATEWAY_ENUM) => {
  // When the user pays Invoice with NET payment type by Credit Card, he pays an additional fee (invoice.payment_fee).
  // Because of this, we are showing the invoice.total_with_payment_fee as Invoice total when Invoice was paid with NET-Credit Card.
  // Otherwise we are showing invoice.total as Invoice total on Invoices & View Invoice pages.
  if (
    invoice.paid_status !== INVOICE_PAID_STATUS.NOT_PAID &&
    paymentGatewayEnum === PAYMENT_GATEWAY_ENUM.CREDIT_CARD_WITH_FEE &&
    invoice.total_with_payment_fee
  ) {
    return invoice.total_with_payment_fee
  }
  return invoice.total
}

/**
 * Returns whether the logged in user can create credits
 * @returns boolean
 */
export const canUserCreateCredits = () => {
  const loggedInUser = AuthService.getLoggedInUser()
  if (!loggedInUser) return false

  return (ALLOWED_EMAILS_FOR_CREATING_CREDITS ?? '').split(',').includes(loggedInUser.email)
}
