import { IReviewsReducer, IReview } from 'models/Review'
import { Action } from 'redux'
import { isType } from 'ts-action'
import { updateArray, deleteFromArray } from 'helpers/helperFunctions'
import { GetReviews, ReviewsFetching, UpdateReview, DeleteReview } from './actions'

const initialState: IReviewsReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: [],
}

export const reviewsReducer = (state = initialState, action: Action): IReviewsReducer => {
  if (isType(action, GetReviews)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
    }
  }
  if (isType(action, UpdateReview)) {
    const { payload } = action
    const { data } = state
    const formattedArray = updateArray<IReview>(data, payload.data)
    return {
      ...state,
      data: formattedArray,
    }
  }
  if (isType(action, DeleteReview)) {
    const { payload } = action
    const { data } = state
    const formattedArray = deleteFromArray<IReview>(data, payload)
    return {
      ...state,
      data: formattedArray,
    }
  }

  if (isType(action, ReviewsFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }
  return state
}
