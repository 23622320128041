import { AxiosService } from '../config/config'
import { Endpoints } from '../config/endpoints'
import { IPayload } from 'models/Global'
import {
  IStrikeType,
  IGetStrikeTypesParams,
  IPutStrikeTypeParams,
  IPostStrikeTypeParams,
} from 'models/StrikeType'

export class StrikeTypesApi {
  constructor(private axios: AxiosService) {}

  getStrikeTypes = async (params?: IGetStrikeTypesParams) => {
    const response = await this.axios.get<IPayload<IStrikeType[]>>(Endpoints.getStrikeTypes, params)
    return response
  }

  getStrikeType = async (id: number, params?: IGetStrikeTypesParams) => {
    const response = await this.axios.get<IPayload<IStrikeType>>(
      Endpoints.getStrikeType(id),
      params
    )
    return response
  }

  postStrikeType = async (params: IPostStrikeTypeParams) => {
    const response = await this.axios.post<IPayload<IStrikeType>>(Endpoints.postStrikeType, params)
    return response
  }

  putStrikeType = async (id: number, params?: IPutStrikeTypeParams) => {
    const response = await this.axios.put<IPayload<IStrikeType>>(
      Endpoints.putStrikeType(id),
      params
    )
    return response
  }

  deleteStrikeType = async (id: number) => {
    const response = await this.axios.delete<{}>(Endpoints.deleteStrikeType(id))
    return response
  }
}
