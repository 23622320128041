import { IGroupReducer, IGroup } from '../../models/Group'
import { Action } from 'redux'
import { isType } from 'ts-action'
import { DeleteGroupMember, GetGroup, GroupFetching, ResetGroup, UpdateGroup } from './actions'
import { IGroupMember } from 'models/GroupMember'
import { deleteFromArray } from 'helpers/helperFunctions'

const initialState: IGroupReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: true,
  data: {} as IGroup,
}

export const GroupReducer = (state = initialState, action: Action): IGroupReducer => {
  if (isType(action, GetGroup)) {
    const { payload } = action

    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
    }
  }

  if (isType(action, UpdateGroup)) {
    const { payload } = action
    const { data } = state
    return {
      ...state,
      data: data,
    }
  }

  if (isType(action, GroupFetching)) {
    const { payload } = action

    return {
      ...state,
      isFetching: payload,
    }
  }

  if (isType(action, ResetGroup)) {
    return initialState
  }

  if (isType(action, DeleteGroupMember)) {
    const { data } = state
    const { payload } = action

    const formattedArray = deleteFromArray<IGroupMember>(data.group_members!.data, payload)
    return {
      ...state,
      data: {
        ...data,
        group_members: { data: formattedArray },
      },
    }
  }
  return state
}
