import { action, payload } from 'ts-action'
import { IError, IPayload } from 'models/Global'
import { IApplication } from 'models/Application'

export const GetSelectedShiftAcceptedApplications = action(
  'GetSelectedShiftAcceptedApplications',
  payload<IPayload<IApplication[]>>()
)

export const GetSelectedShiftAcceptedApplicationsErrors = action(
  'GetSelectedShiftAcceptedApplicationsErrors',
  payload<IError>()
)

export const SelectedShiftAcceptedApplicationsFetching = action(
  'SelectedShiftAcceptedApplicationsFetching',
  payload<boolean>()
)

export const AddSelectedShiftAcceptedApplication = action(
  'AddSelectedShiftAcceptedApplication',
  payload<IPayload<IApplication>>()
)

export const UpdateSelectedShiftAcceptedApplication = action(
  'UpdateSelectedShiftAcceptedApplication',
  payload<IPayload<IApplication>>()
)

export const DeleteSelectedShiftAcceptedApplication = action(
  'DeleteSelectedShiftAcceptedApplication',
  payload<number>()
)

export const ResetSelectedShiftAcceptedApplicationsReducer = action(
  'ResetSelectedShiftAcceptedApplicationsReducer'
)
