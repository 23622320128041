import { action, payload } from 'ts-action'
import { IFollow } from 'models/Follow'
import { IError, IPayload } from 'models/Global'
import { IProperty } from 'models/Property'

export const FollowManagementPropertiesFetching = action(
  'FollowManagementPropertiesFetching',
  payload<boolean>()
)

export const GetFollowManagementProperties = action(
  'GetFollowManagementProperties',
  payload<IPayload<IProperty[]>>()
)

export const GetFollowManagementPropertiesErrors = action(
  'GetFollowManagementPropertiesErrors',
  payload<IError>()
)

export const UpdateFollowManagementProperty = action(
  'UpdateFollowManagementProperty',
  payload<IPayload<IFollow>>()
)
