import { action, payload } from 'ts-action'
import {
  ACH_DEBIT_AUTHORIZATION_STEP,
  TBankDetailsStepFormValuesExtended,
  TCustomerDetailsStepFormValues,
  TPreferredAuthorizationMethodFormValues,
} from './utils'

export const UpdateActiveStep = action('UpdateActiveStep', payload<ACH_DEBIT_AUTHORIZATION_STEP>())

export const BillingRequestIdLoading = action('BillingRequestIdLoading', payload<boolean>())

export const UpdateBillingRequestId = action('UpdateBillingRequestId', payload<string>())

export const UpdateCustomerDetails = action(
  'UpdateCustomerDetails',
  payload<TCustomerDetailsStepFormValues>()
)

export const UpdateBankDetails = action(
  'UpdateBankDetails',
  payload<TBankDetailsStepFormValuesExtended>()
)

export const UpdatePreferredAuthorizationMethodDetails = action(
  'UpdatePreferredAuthorizationMethodDetails',
  payload<TPreferredAuthorizationMethodFormValues>()
)

export const ResetAchDebitAuthorizationFlow = action('ResetAchDebitAuthorizationFlow')
