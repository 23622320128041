import { Action, isType } from 'ts-action'
import { ILoadingsReducer, SHIFT_FOLLOW_TYPE_MODEL } from 'models/Loading'
import {
  ResetLoadingsReducer,
  SetApprovePendingVerificationsLoadingId,
  SetShiftFollowLoadingIds,
  SetFavoriteLoadingId,
  SetPendingSuspensionLoadingId,
} from './actions'

const initialState: ILoadingsReducer = {
  approvePendingVerificationLoadingIds: {},
  shiftFollowLoadingIds: {
    [SHIFT_FOLLOW_TYPE_MODEL.SHIFT]: 0,
    [SHIFT_FOLLOW_TYPE_MODEL.LOCATION]: 0,
    [SHIFT_FOLLOW_TYPE_MODEL.BUSINESS]: 0,
  },
  favoriteLoadingIds: {},
  pendingSuspensionLoadingIds: {},
}

export const loadingsReducer = (state = initialState, action: Action): ILoadingsReducer => {
  if (isType(action, SetApprovePendingVerificationsLoadingId)) {
    const {
      payload: { id, isLoading },
    } = action
    return {
      ...state,
      approvePendingVerificationLoadingIds: {
        ...state.approvePendingVerificationLoadingIds,
        [id]: isLoading,
      },
    }
  }
  if (isType(action, SetShiftFollowLoadingIds)) {
    const { payload } = action
    return {
      ...state,
      shiftFollowLoadingIds: {
        ...state.shiftFollowLoadingIds,
        ...payload,
      },
    }
  }
  if (isType(action, SetFavoriteLoadingId)) {
    const {
      payload: { id, isLoading },
    } = action
    return {
      ...state,
      favoriteLoadingIds: {
        ...state.favoriteLoadingIds,
        [id]: isLoading,
      },
    }
  }
  if (isType(action, SetPendingSuspensionLoadingId)) {
    const {
      payload: { id, isLoading },
    } = action
    return {
      ...state,
      pendingSuspensionLoadingIds: {
        ...state.pendingSuspensionLoadingIds,
        [id]: isLoading,
      },
    }
  }

  if (isType(action, ResetLoadingsReducer)) {
    return initialState
  }
  return state
}
