import { store } from 'react-notifications-component'

export enum NOTIFICATION_TYPE {
  SUCCESS = 'success',
  DANGER = 'danger',
  INFO = 'info',
  DEFAULT = 'default',
  WARNING = 'warning',
}

export enum NOTIFICATION_CONTAINER {
  BOTTOM_LEFT = 'bottom-left',
  BOTTOM_RIGHT = 'bottom-right',
  BOTTOM_CENTER = 'bottom-center',
  TOP_LEFT = 'top-left',
  TOP_RIGHT = 'top-right',
  TOP_CENTER = 'top-center',
  CENTER = 'center',
}

export enum NOTIFICATION_INSERTION {
  TOP = 'top',
  BOTTOM = 'bottom',
}

interface ITransition {
  duration?: number
  timingFunction?: string
  delay?: number
}

interface IDismiss {
  duration?: number
  onScreen?: boolean
  click?: boolean
  touch?: boolean
  showIcon?: boolean
  pauseOnHover?: boolean
}

interface INotificationConfig {
  id?: string
  type?: NOTIFICATION_TYPE
  insert?: NOTIFICATION_INSERTION
  container: NOTIFICATION_CONTAINER
  onRemoval?: void
  animationIn?: string[]
  animationOut?: string[]
  slidingEnter?: ITransition
  slidingExit?: ITransition
  touchRevert?: ITransition
  touchSlidingExit?: ITransition
  width?: number
  dismiss?: IDismiss
}

type TNotificationTitleMessage = string | React.ReactNode | React.FunctionComponent

const defaultConfig: INotificationConfig = {
  insert: NOTIFICATION_INSERTION.TOP,
  container: NOTIFICATION_CONTAINER.TOP_RIGHT,
  dismiss: {
    duration: 3000,
    onScreen: true,
    pauseOnHover: true,
  },
}

class NotificationManager {
  static success(
    message: TNotificationTitleMessage,
    title?: string,
    config: INotificationConfig = {
      type: NOTIFICATION_TYPE.SUCCESS,
      ...defaultConfig,
    }
  ) {
    store.addNotification({
      title: title || `Success`,
      message,
      ...config,
    })
  }
  static error(
    message: TNotificationTitleMessage,
    title?: string,
    config: INotificationConfig = {
      type: NOTIFICATION_TYPE.DANGER,
      ...defaultConfig,
    }
  ) {
    store.addNotification({
      title: title || `Error`,
      message,
      ...config,
    })
  }
  static info(
    message: TNotificationTitleMessage,
    title?: string,
    config: INotificationConfig = {
      type: NOTIFICATION_TYPE.INFO,
      ...defaultConfig,
    }
  ) {
    store.addNotification({
      title: title || `Info`,
      message,
      ...config,
    })
  }
  static default(
    message: TNotificationTitleMessage,
    title?: string,
    config: INotificationConfig = {
      type: NOTIFICATION_TYPE.DEFAULT,
      ...defaultConfig,
    }
  ) {
    store.addNotification({
      title: title || `Notification`,
      message,
      ...config,
    })
  }
  static warning(
    message: TNotificationTitleMessage,
    title?: string,
    config: INotificationConfig = {
      type: NOTIFICATION_TYPE.WARNING,
      ...defaultConfig,
    }
  ) {
    store.addNotification({
      title: title || `Warning`,
      message,
      ...config,
    })
  }

  static removeNotification(id: number) {
    store.removeNotification(id)
  }
}

export { NotificationManager }
