import { useState, useEffect } from 'react'
import { debounce } from 'lodash'
import { handleErrorNotification } from '../services/ErrorHandlingService'

// Generic useDebouncedSearch hook
export const useDebouncedSearch = <T>(searchFunction: (input: string) => Promise<T>) => {
  const [inputText, setInputText] = useState('')
  const [data, setData] = useState<T>()
  const [isLoading, setIsLoading] = useState(false)

  const debouncedSearchFunction = debounce(async () => {
    if (inputText) {
      setIsLoading(true)
      try {
        // Call the searchFunction and pass the inputText as an argument
        const response = await searchFunction(inputText)
        // Update the data state with the response from the searchFunction
        setData(response)
      } catch (error) {
        handleErrorNotification(error)
      } finally {
        setIsLoading(false)
      }
    }
  }, 300)

  useEffect(
    () => {
      // Invoke the debounced search function
      debouncedSearchFunction()

      // Clean up the debounced search function on unmount
      return () => {
        debouncedSearchFunction.cancel()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [inputText, searchFunction]
  )

  return {
    inputText,
    setInputText,
    data,
    setData,
    isLoading,
  }
}
