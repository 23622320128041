import React from 'react'
import { Loader } from '@pinchenterprisesnpm/friday-ui'
import { LoaderContainer } from './style'

const ComponentFullSizeLoader = () => {
  return (
    <LoaderContainer>
      <Loader />
    </LoaderContainer>
  )
}
export default ComponentFullSizeLoader
