import { AxiosService } from '../config/config'
import { Endpoints } from '../config/endpoints'
import { IPayload } from 'models/Global'
import { IBlast, IPostBlastParams } from 'models/Blast'

export class BlastsApi {
  constructor(private axios: AxiosService) {}

  postBlast = async (params: IPostBlastParams) => {
    const response = await this.axios.post<IPayload<IBlast>>(Endpoints.postBlast, params)
    return response
  }
}
