import { IExpenseTypeReducer, IExpenseType } from 'models/ExpenseType'
import { Action } from 'redux'
import { isType } from 'ts-action'
import { updateArray, deleteFromArray } from 'helpers/helperFunctions'
import {
  GetExpenseTypes,
  ExpenseTypesFetching,
  UpdateExpenseType,
  DeleteExpenseType,
  GetExpenseType,
  ResetExpenseType,
} from './actions'

const initialState: IExpenseTypeReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: [],
  expenseType: undefined,
}

export const expenseTypesReducer = (state = initialState, action: Action): IExpenseTypeReducer => {
  if (isType(action, GetExpenseTypes)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
    }
  }
  if (isType(action, GetExpenseType)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      expenseType: payload.data,
    }
  }
  if (isType(action, UpdateExpenseType)) {
    const { payload } = action
    const { data } = state
    const formattedArray = updateArray<IExpenseType>(data, payload.data)
    return {
      ...state,
      data: formattedArray,
    }
  }
  if (isType(action, DeleteExpenseType)) {
    const { payload } = action
    const { data } = state
    const formattedArray = deleteFromArray<IExpenseType>(data, payload)
    return {
      ...state,
      data: formattedArray,
    }
  }

  if (isType(action, ExpenseTypesFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }
  if (isType(action, ResetExpenseType)) {
    return {
      ...state,
      isFetching: false,
      data: [],
      expenseType: undefined,
    }
  }
  return state
}
