import { AxiosService } from '../config/config'
import { Endpoints } from '../config/endpoints'
import { IPayload } from 'models/Global'
import {
  IExpenseTypeTax,
  IPutExpenseTypeTaxParams,
  IPostExpenseTypeTaxParams,
  IGetExpenseTypeTaxesParams,
} from 'models/ExpenseTypeTax'

export class ExpenseTypeTaxesApi {
  constructor(private axios: AxiosService) {}

  getExpenseTypeTaxes = async (params?: IGetExpenseTypeTaxesParams) => {
    const response = await this.axios.get<IPayload<IExpenseTypeTax[]>>(
      Endpoints.getExpenseTypeTaxes,
      params
    )
    return response
  }

  getExpenseTypeTax = async (id: number) => {
    const response = await this.axios.get<IPayload<IExpenseTypeTax>>(
      Endpoints.getExpenseTypeTax(id)
    )
    return response
  }

  postExpenseTypeTax = async (params: IPostExpenseTypeTaxParams) => {
    const response = await this.axios.post<IPayload<IExpenseTypeTax>>(
      Endpoints.postExpenseTypeTax,
      params
    )
    return response
  }

  putExpenseTypeTax = async (id: number, params?: IPutExpenseTypeTaxParams) => {
    const response = await this.axios.put<IPayload<IExpenseTypeTax>>(
      Endpoints.putExpenseTypeTax(id),
      params
    )
    return response
  }

  deleteExpenseTypeTax = async (id: number) => {
    const response = await this.axios.delete<{}>(Endpoints.deleteExpenseTypeTax(id))
    return response
  }
}
