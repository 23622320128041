import { AxiosService } from '../config/config'
import { Endpoints } from '../config/endpoints'
import { IPayload } from 'models/Global'
import {
  IReliabilityRating,
  IGetReliabilityRatingsParams,
  IPutReliabilityRatingParams,
  IPostReliabilityRatingParams,
} from 'models/ReliabilityRating'

export class ReliabilityRatingsApi {
  constructor(private axios: AxiosService) {}

  getReliabilityRatings = async (params?: IGetReliabilityRatingsParams) => {
    const response = await this.axios.get<IPayload<IReliabilityRating[]>>(
      Endpoints.getReliabilityRatings,
      params
    )
    return response
  }

  getReliabilityRating = async (id: number, params?: IGetReliabilityRatingsParams) => {
    const response = await this.axios.get<IPayload<IReliabilityRating>>(
      Endpoints.getReliabilityRating(id),
      params
    )
    return response
  }

  postReliabilityRating = async (params: IPostReliabilityRatingParams) => {
    const response = await this.axios.post<IPayload<IReliabilityRating>>(
      Endpoints.postReliabilityRating,
      params
    )
    return response
  }

  putReliabilityRating = async (params?: IPutReliabilityRatingParams) => {
    const response = await this.axios.put<IPayload<IReliabilityRating>>(
      Endpoints.putReliabilityRating,
      params
    )
    return response
  }

  deleteReliabilityRating = async (id: number) => {
    const response = await this.axios.delete<{}>(Endpoints.deleteReliabilityRating(id))
    return response
  }
}
