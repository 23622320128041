import { action, payload } from 'ts-action'
import { IPropertyType } from 'models/PropertyType'
import { IError, IPayload } from 'models/Global'

export const PropertyTypesFetching = action('PropertyTypesFetching', payload<boolean>())

export const GetPropertyTypes = action('GetPropertyTypes', payload<IPayload<IPropertyType[]>>())

export const AddPropertyType = action('AddPropertyType', payload<IPayload<IPropertyType>>())

export const UpdatePropertyType = action('UpdatePropertyType', payload<IPayload<IPropertyType>>())

export const DeletePropertyType = action('DeletePropertyType', payload<number>())

export const GetPropertyTypesErrors = action('GetPropertyTypesErrors', payload<IError>())

export const UpdatePropertyTypeLoadingIds = action(
  'UpdatePropertyTypeLoadingIds',
  payload<number>()
)
