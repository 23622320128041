import { action, payload } from 'ts-action'
import { ICertificateOrganization } from 'models/CertificateOrganization'
import { IError, IPayload } from 'models/Global'

export const CertificateOrganizationsFetching = action(
  'CertificateOrganizationsFetching',
  payload<boolean>()
)

export const GetCertificateOrganizations = action(
  'GetCertificateOrganizations',
  payload<IPayload<ICertificateOrganization[]>>()
)

export const AddCertificateOrganization = action(
  'AddCertificateOrganization',
  payload<IPayload<ICertificateOrganization>>()
)

export const UpdateCertificateOrganization = action(
  'UpdateCertificateOrganization',
  payload<IPayload<ICertificateOrganization>>()
)

export const DeleteCertificateOrganization = action(
  'DeleteCertificateOrganization',
  payload<number>()
)

export const GetCertificateOrganizationsErrors = action(
  'GetCertificateOrganizationsErrors',
  payload<IError>()
)
