import axios, { AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from 'axios'
import { API_HOST, API_VERSION, VERSION } from 'config/envVariables'
import { isProduction } from 'helpers/helperFunctions'
import AuthService from 'services/AuthService'
import { Error, STATUS_CODES } from './error'

export const BASE_PATH = `${API_HOST}${API_VERSION}/`
export const slackLongRequestsChannelWebHook = axios.create({
  baseURL: `https://hooks.slack.com/services/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`,
})

export interface ExtendedAxiosRequestConfig extends InternalAxiosRequestConfig {
  meta?: {
    requestStartedAt?: number
  }
}

export interface ExtendedAxiosResponse extends AxiosResponse {
  config: ExtendedAxiosRequestConfig
}

export const TOKEN = 'token'

type Pending = {
  [key: string]: string
}

const pending: Pending = {}
let subscribers: any = []

export const subscribeTokenRefresh = (cb: (token: any) => void) => {
  subscribers.push(cb)
}

export const onRefreshed = (token: string) => {
  subscribers.map((cb: any) => cb(token), 2000)
  subscribers = []
}

export const removePending = (config: AxiosRequestConfig, cancelToken?: any) => {
  // make sure the url is same for both request and response
  const url = (config.url as string).replace(config.baseURL as string, '/')
  // stringify whole RESTful request with URL params
  const flagUrl = url + '&' + config.method + '&' + JSON.stringify(config.params)
  if (flagUrl in pending) {
    if (cancelToken) {
      cancelToken() // abort the request
    } else {
      delete pending[flagUrl]
    }
  } else if (cancelToken) {
    pending[flagUrl] = cancelToken // store the cancel function
  }
}

/**
 * [GET] Log to Slack.
 * This is experimental logger that logs extra long requests ( more than 3s)
 */
const logLongRequestsToSlack = (title: string, color: string, text: string) => {
  const parameters = {
    attachments: [
      {
        title,
        color,
        text,
      },
    ],
    channel: '#logs_rct_prod_long_requests',
  }
  slackLongRequestsChannelWebHook
    .post('', parameters, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
    .then((response) => {})
    .catch((error) => {})
    .then(() => {})
}

export const addRequestInterceptors = (request: ExtendedAxiosRequestConfig) => {
  const currentToken = localStorage.getItem(TOKEN)
  // Initialize headers object if it's undefined to avoid "request.headers is possibly 'undefined'" error after updating axios version to 0.22.0
  request.headers = request.headers || {}
  request.headers['Authorization'] = `Bearer ${currentToken}`
  request.headers['x-upshift-application-version'] = `${VERSION}`
  request.headers['x-upshift-application-name'] = 'WEB_REACT_ADMIN'
  request.headers['Error-Action-Response'] = 'true'
  request.meta = request.meta || {}
  request.meta.requestStartedAt = new Date().getTime()

  return request
}

export const addResponseInterceptors = (response: ExtendedAxiosResponse) => {
  if (response.config.meta && response.config.meta.requestStartedAt) {
    largeRequestLogger(response)
  }
  return response
}

export const largeRequestLogger = (response: ExtendedAxiosResponse) => {
  // Only execute on production
  if (!isProduction()) {
    return
  }
  if (response.config.meta && response.config.meta.requestStartedAt) {
    const executionTime = new Date().getTime() - response.config.meta.requestStartedAt
    // Only execute when the request is larger than 4 seconds
    if (executionTime < 4000) {
      return
    }
    const loggedError = {
      status: response.status,
      AuthServiceGetLoggedInUser: AuthService.getLoggedInUser(),
      headers: {
        ...response.headers,
      },
      config: {
        ...response.config,
      },
    }
    if (
      response.config.url !== `${BASE_PATH}/auth/login` &&
      response.config.url !== `${BASE_PATH}/password/forgot`
    ) {
      logLongRequestsToSlack(
        `[Admin] Execution time for: ${response.config.url} - ${executionTime} ms`,
        'danger',
        JSON.stringify(loggedError, null, 2)
      )
    }
  }
}

export const errorHandling: (response: any) => Promise<Error> = (response) => {
  const { status, data } = response

  if (status >= 200 && status <= 299) {
    return data
  }

  const errorData = data.data
  const { message, code, action } = data.meta

  if (status === STATUS_CODES.UNPROCESSABLE_ENTITY) {
    handleUnprocessableEntity(errorData, code, message)
  }
  throw new Error(code, message, errorData, action)
}

const handleUnprocessableEntity = (errorData: any, code: any, message: any) => {
  if (errorData) {
    let errorMessage = ''
    if (errorData && Object.entries(errorData).length > 0) {
      Object.entries(errorData).forEach((data) => {
        if (data[1]) {
          errorMessage = `${errorMessage}${data[1]}`
        }
      })
      throw new Error(code, message, errorMessage)
    } else {
      throw new Error(code, message, errorMessage)
    }
  }
  const errorMessage = 'Oops, something went wrong. Try again.'
  throw new Error(code, message, errorMessage)
}
