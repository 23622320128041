import styled from 'styled-components'

export const SelectGroupWrapper = styled.div`
  display: inline-flex;
  max-width: 100%;
  flex-direction: row;
  align-items: center;
  color: ${(props) => props.theme.palette.dark};
  background-color: ${(props) => props.theme.palette.white};
  border: 1px solid ${(props) => props.theme.palette.dark};
  cursor: pointer;
  border-radius: 4px;
  position: relative;
  overflow: hidden;

  @media only screen and (min-width: ${(props) => props.theme.breakpoints.md + 'px'}) {
    flex-direction: row;
    display: inline-flex;
  }
`
