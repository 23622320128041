import { action, payload } from 'ts-action'
import { IInvoiceGroup } from 'models/InvoiceGroup'
import { IError, IPayload } from 'models/Global'

export const InvoiceGroupsFetching = action('InvoiceGroupsFetching', payload<boolean>())

export const GetInvoiceGroups = action('GetInvoiceGroups', payload<IPayload<IInvoiceGroup[]>>())

export const AddInvoiceGroup = action('AddInvoiceGroup', payload<IPayload<IInvoiceGroup>>())

export const UpdateInvoiceGroup = action('UpdateInvoiceGroup', payload<IPayload<IInvoiceGroup>>())

export const DeleteInvoiceGroup = action('DeleteInvoiceGroup', payload<number>())

export const GetInvoiceGroupsErrors = action('GetInvoiceGroupsErrors', payload<IError>())

export const ClearInvoiceGroups = action('ClearInvoiceGroups')
