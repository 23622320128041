import { faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Flex } from '@pinchenterprisesnpm/friday-ui'
import {
  POWER_SEARCH_RESOURCE_TYPE,
  redirectToPageById,
  TPowerSearchData,
  getResourceTypeProperty,
} from '../PowerSearch/utils'
import { TListItemNavigationProps } from './utils'
import { LinkWrapper } from '../style'
import { FlexWrapper, FlexItemWrapper, ResourceTypeInfoWrapper, InfoWrapper } from './style'

type TPowerSearchFilteredListItemProps = TListItemNavigationProps & {
  item: TPowerSearchData
  resourceType: POWER_SEARCH_RESOURCE_TYPE
}

export const PowerSearchFilteredListItem = ({
  item,
  resourceType,
  ...props
}: TPowerSearchFilteredListItemProps) => {
  return (
    <FlexWrapper tabIndex={0} justifyContent='space-between' {...props}>
      <FlexItemWrapper>
        <LinkWrapper to={redirectToPageById(resourceType, item.id)}>
          <Flex alignItems='center'>
            <FontAwesomeIcon size='lg' icon={faMagnifyingGlass} />
            <InfoWrapper flexDirection='column'>
              <ResourceTypeInfoWrapper m={0} mb={4} bold flexDirection='column'>
                {getResourceTypeProperty(item, resourceType)}
              </ResourceTypeInfoWrapper>
              <ResourceTypeInfoWrapper m={0} isPrimary>
                In {resourceType}
              </ResourceTypeInfoWrapper>
            </InfoWrapper>
          </Flex>
        </LinkWrapper>
      </FlexItemWrapper>
    </FlexWrapper>
  )
}
