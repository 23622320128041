import { Button, Modal } from '@pinchenterprisesnpm/friday-ui'
import { NotificationManager } from 'helpers/NotificationsManager'
import { API } from 'network'
import { handleErrorNotification } from 'services/ErrorHandlingService'
import {
  FooterWrapper,
  ContentText,
} from 'ui/components/Admin/FinanceModule/Timesheet/RevokeStrikeModal/style'
import _ from 'lodash-es'
import { STRIKE_STATUS } from 'models/Strike'

interface IOwnProps {
  strikeId: number
  upshifterName: string
}

export const renderRevokeStrikeModal = ({ strikeId, upshifterName }: IOwnProps) => {
  return Modal.dispatch({
    title: 'Revoke Strike',
    height: 160,
    content: () => (
      <ContentText>
        This Upshifter has no call, no show strikes assigned for this shift. Do you want to revoke
        them?
      </ContentText>
    ),
    footer: ({ closeModal }) => (
      <FooterWrapper justifyContent='center' alignItems='center'>
        <Button
          type='success'
          p='5px'
          onClick={() => {
            try {
              API.Strikes.putStrike(strikeId, {
                status: STRIKE_STATUS.REVOKED,
                reason_revoked: 'Business said Upshifter attended shift',
              })
              NotificationManager.success(
                `Strike for ${_.startCase(upshifterName)} has been revoked.`
              )
            } catch (error) {
              handleErrorNotification(error)
            }
            closeModal()
          }}
        >
          Yes, Revoke Strikes
        </Button>
      </FooterWrapper>
    ),
  })
}
