import { action, payload } from 'ts-action'
import { IActivityLog } from 'models/ActivityLog'
import { IBlockHistory } from 'models/Block'
import { IError, IPayload } from 'models/Global'

export const BlockHistoryFetching = action('BlockHistoryFetching', payload<boolean>())

export const GetBlockHistory = action(
  'GetBlockHistory',
  payload<IPayload<IActivityLog<IBlockHistory>[]>>()
)

export const GetBlockHistoryErrors = action('GetBlockHistoryErrors', payload<IError>())
