import { IBaseReducer } from '../BaseReducer'
import { IBaseGetParams } from '../Global'
import { IShift } from '../Shift'
import { ITimesheet } from '../Timesheet'
import { IApplication } from '../Application'
import { TypeOrNull } from '../Common'
import { IUser } from '../User'
import { IStrike } from '../Strike'

export enum CLOCK_IN_TYPE {
  MOBILE_APP = 'mobile_clock_in',
  WEB_APP = 'web_clock_in',
  TIMETRACKER = 'timetracker_clock_in',
  SMS = 'sms_clock_in',
  BUSINESS_MOBILE_APP = 'business_mobile_clock_in',
  BUSINESS_WEB_APP = 'business_web_clock_in',
  ADMIN = 'admin_clock_in',
}

export enum CLOCK_OUT_TYPE {
  MOBILE_APP = 'mobile_clock_out',
  WEB_APP = 'web_clock_out',
  TIMETRACKER = 'timetracker_clock_out',
  SMS = 'sms_clock_out',
  BUSINESS_MOBILE_APP = 'business_mobile_clock_out',
  BUSINESS_WEB_APP = 'business_web_clock_out',
  ADMIN = 'admin_clock_out',
}

export enum PUNCHCARD_REASON_HOW {
  EMAIL = 0,
  TEXT_MESSAGE = 1,
  INTERCOM = 2,
  CALL = 3,
  TIMESHEET = 4,
  INTERCOM_URL = 11,
}

export enum PUNCHCARD_REASON {
  REQUEST_BY_BUSINESS,
  REQUEST_BY_UPSHIFTER,
  MANUAL_BUSINESS,
}

export enum PUNCH_CARD_STATUS {
  CLOCKED_OUT,
  CLOCKED_IN,
  NO_TERM_ACTION,
}

export enum PUNCH_CARD_CLIENT_REQUEST_STATUS {
  DELETE_REQUESTED,
  DELETE_APPROVED,
  DELETE_DECLINED,
}

export enum PUNCH_CARD_REASON_FOR_DELETE {
  NO_CALL_NO_SHOW,
  DUPLICATE_PUNCH_CARD,
  OTHER,
  PUNCH_TIME,
  DISCIPLINARY_STRIKE,
}

export interface IPunchCardIncludeParams {
  include?:
    | {
        gig?: Record<any, any>
        property?: Record<any, any>
        applicant?: Record<any, any>
        created_by?: Record<any, any>
        edited_by?: Record<any, any>
        timesheet?: Record<any, any>
        no_call_no_show_strike?: Record<any, any>
        original_clocked_out_punch_card?: Record<any, any>
      }
    | (
        | 'gig'
        | 'property'
        | 'applicant'
        | 'created_by'
        | 'edited_by'
        | 'timesheet'
        | 'no_call_no_show_strike'
        | 'original_clocked_out_punch_card'
      )[]
}
export interface IPunchCard {
  id: number
  application_id: number
  timesheet_id: number
  created_by_id: number | null
  edited_by_id: number | null
  gig_day_id?: any
  timestamp_start: string
  timestamp_end: string | null
  hours: string
  hours_overtime: string
  hours_total: string
  price: string
  commission: string
  invoice_value: string
  break: number
  pay_value: string
  pay_value_overtime: string
  bill_value: string
  overtime: boolean
  fixed_time?: any
  status: number
  reason_for_edit: PUNCHCARD_REASON | null
  reason_for_edit_how: PUNCHCARD_REASON_HOW | null
  reason_for_create: PUNCHCARD_REASON | null
  reason_for_create_how: PUNCHCARD_REASON_HOW | null
  zendesk_id: string | null
  fake: boolean
  auto_generated: boolean
  created_at: string
  updated_at: string
  client_request_status: TypeOrNull<PUNCH_CARD_CLIENT_REQUEST_STATUS>
  reason_for_delete: TypeOrNull<PUNCH_CARD_REASON_FOR_DELETE>
  timesheet?: ITimesheet
  gig?: IShift
  application?: IApplication
  note: TypeOrNull<string>
  note_subject: TypeOrNull<string>
  applicant?: IUser
  no_call_no_show_strike?: IStrike
  original_clocked_out_punch_card?: this
  created_by?: IUser
  clock_in_type?: CLOCK_IN_TYPE
  clock_out_type?: TypeOrNull<CLOCK_OUT_TYPE>
  // This flag is used for implementation purposes. It is not part of any API request
  isNoEndPunchCard?: boolean
  clock_in_address?: TypeOrNull<string>
  clock_out_address?: TypeOrNull<string>

  /**
   * Start Deprecated
   */

  gig_id: number
  property_timezone?: string
  property_commission_percent?: string
  application_user_id: number
  application_full_name: string
  created_by_full_name: string | null
  updated_by_full_name: string | null

  /**
   * End Deprecated
   */
}

export interface IGetPunchCardsParamsBase extends IBaseGetParams {
  timesheet_id?: number
  application_id?: number
  applications_id?: number[]
  user_id?: number
  gig_id?: number
  gig_ids?: number[]
  status?: PUNCH_CARD_STATUS
  fake?: boolean
  client_request_status?: PUNCH_CARD_CLIENT_REQUEST_STATUS
}

export type IGetPunchCardsParams = IGetPunchCardsParamsBase & IPunchCardIncludeParams
export interface IGetPendingVerificationPunchCardsParam extends IGetPunchCardsParams {
  created_at_from?: string
  created_at_to?: string
  timestamp_start?: string
  timestamp_end?: string
  verified_hours?: boolean
  original_clocked_out_punch_card?: IPunchCard
}

export interface IPostPunchCardParamsBase {
  application_id: number
  gig_day_id?: number
  timestamp_start?: string
  timestamp_end?: string
  pay_value?: number
  break?: number
  overtime?: boolean
  auto_generated?: boolean
  fixed_time?: boolean
  reason_for_create?: PUNCHCARD_REASON | null
  reason_for_create_how?: PUNCHCARD_REASON_HOW | null
  zendesk_id?: string | null
  image?: string
  clock_in_image?: string
  clock_out_image?: string
  timetracker_code?: string
  status?: PUNCH_CARD_STATUS
  fake?: boolean
  note: string
  skip_overlapping_punch_card_update_validation?: boolean
  // This flag is used for implementation purposes. It is not part of the API request itself
  isNoEndPunchCard?: boolean
  skip_business_validation_for_accepted_gig_day?: boolean
}

export type IPostPunchCardParams = IPostPunchCardParamsBase & IPunchCardIncludeParams
export interface IPutPunchCardParamsBase {
  application_id?: number
  gig_day_id?: number
  timestamp_start?: string
  timestamp_end?: string | null
  pay_value?: number
  break?: number
  overtime?: boolean
  auto_generated?: boolean
  fixed_time?: boolean
  image?: string
  clock_in_image?: string
  clock_out_image?: string
  reason_for_edit?: PUNCHCARD_REASON | null
  reason_for_edit_how?: PUNCHCARD_REASON_HOW | null
  zendesk_id?: string | null
  timetracker_code?: string
  status?: PUNCH_CARD_STATUS
  fake?: boolean
  client_request_status?: TypeOrNull<PUNCH_CARD_CLIENT_REQUEST_STATUS>
  note?: string
  skip_overlapping_punch_card_update_validation?: boolean
  // This flag is used for implementation purposes. It is not part of the API request itself
  isNoEndPunchCard?: boolean
  reason_for_delete?: TypeOrNull<PUNCH_CARD_REASON_FOR_DELETE>
  skip_automatic_strike_revoke?: boolean
  skip_business_validation_for_accepted_gig_day?: boolean
}

export type IPutPunchCardParams = IPutPunchCardParamsBase & IPunchCardIncludeParams

export interface IPutPendingVerificationPunchCardParams extends IPutPunchCardParams {
  verified_hours?: boolean
  skip_overlapping_punch_card_update_validation?: boolean
}

export interface IDeletePendingVerificationPunchCardParams extends IPutPunchCardParams {
  note?: string
}

export interface IDeletePunchCardParams {
  note: string
  reason_for_delete?: number | string
}

export interface ITransferPunchCardParams {
  gig_id: number
  gig_day_id?: number // required for multiday and to hire shifts
  skip_overtime_check?: boolean
}

export interface IPunchCardsReducer extends IBaseReducer<IPunchCard[]> {}
