import { AxiosService } from '../config/config'
import { Endpoints } from '../config/endpoints'
import { IPayload } from 'models/Global'
import { IGetCountiesParams, ICounty, IPutCountyParams, IPostCountyParams } from 'models/County'

export class CountiesApi {
  constructor(private axios: AxiosService) {}
  getCounties = async (params?: IGetCountiesParams) => {
    const response = await this.axios.get<IPayload<ICounty[]>>(Endpoints.getCounties, params)
    return response
  }

  getCounty = async (id: number, params?: IGetCountiesParams) => {
    const response = await this.axios.get<IPayload<ICounty>>(Endpoints.getCounty(id), params)
    return response
  }

  postCounty = async (params?: IPostCountyParams) => {
    const response = await this.axios.get<IPayload<ICounty>>(Endpoints.postCounty, params)
    return response
  }

  putCounty = async (id: number, params?: IPutCountyParams) => {
    const response = await this.axios.put<IPayload<ICounty>>(Endpoints.putCounty(id), params)
    return response
  }

  deleteCounty = async (id: number) => {
    const response = await this.axios.delete<{}>(Endpoints.deleteCounty(id))
    return response
  }
}
