import styled from 'styled-components'
import { Form, Flex } from '@pinchenterprisesnpm/friday-ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const { Input } = Form

export const SearchInput = styled(Input)<{ width?: string }>`
  width: ${({ width }) => (width ? width : '250px')};
  background: ${({ theme: { palette } }) => palette.light};
  border-radius: ${({ theme: { mp } }) => mp.xs};
  padding-left: ${({ theme: { mp } }) => mp.xl};
`
export const InputWrapper = styled(Flex)`
  position: relative;
`
export const IconWrapper = styled(Flex)`
  position: absolute;
  padding: 0 ${({ theme: { mp } }) => mp.xs};
  background: rgba(227, 227, 227, 0.4);
  border-radius: 5px;
  height: 40px;
  width: 60px;
`

export const SearchInputIconWrapper = styled(FontAwesomeIcon)`
  position: absolute;
  padding: 0 ${({ theme: { mp } }) => mp.xs};
`
