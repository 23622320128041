import { ICreditsReducer, ICredit } from 'models/Credit'
import { Action } from 'redux'
import { isType } from 'ts-action'
import { updateArray, deleteFromArray } from 'helpers/helperFunctions'
import { GetCredits, CreditsFetching, UpdateCredit, DeleteCredit, AddCredit } from './actions'

const initialState: ICreditsReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: [],
}

export const creditsReducer = (state = initialState, action: Action): ICreditsReducer => {
  if (isType(action, GetCredits)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
    }
  }
  if (isType(action, AddCredit)) {
    const { data } = action.payload
    return {
      ...state,
      data: [data, ...state.data],
    }
  }
  if (isType(action, UpdateCredit)) {
    const { payload } = action
    const { data } = state
    const formattedArray = updateArray<ICredit>(data, payload.data)
    return {
      ...state,
      data: formattedArray,
    }
  }
  if (isType(action, DeleteCredit)) {
    const { payload } = action
    const { data } = state
    const formattedArray = deleteFromArray<ICredit>(data, payload)
    return {
      ...state,
      data: formattedArray,
    }
  }

  if (isType(action, CreditsFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }
  return state
}
