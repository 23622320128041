import { ISelectedShiftPropertyReducer, IProperty } from 'models/Property'
import { Action } from 'redux'
import { isType } from 'ts-action'
import {
  GetSelectedShiftProperty,
  UpdateSelectedShiftProperty,
  DeleteSelectedShiftProperty,
  SelectedShiftPropertyFetching,
  ResetSelectedShiftProperty,
  GetSelectedShiftPropertyErrors,
} from './actions'
import { IError } from 'models/Global'

const initialState: ISelectedShiftPropertyReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  error: {} as IError,
  data: {} as IProperty,
}

export const SelectedShiftPropertyReducer = (
  state = initialState,
  action: Action
): ISelectedShiftPropertyReducer => {
  if (isType(action, GetSelectedShiftProperty)) {
    const {
      payload: { data, meta },
    } = action

    return {
      ...state,
      meta,
      data,
      error: {} as IError,
    }
  }

  if (isType(action, UpdateSelectedShiftProperty)) {
    const {
      payload: { data, meta },
    } = action

    return {
      ...state,
      meta,
      data,
      error: {} as IError,
    }
  }

  if (isType(action, DeleteSelectedShiftProperty)) {
    return initialState
  }

  if (isType(action, SelectedShiftPropertyFetching)) {
    const { payload: isFetching } = action

    return {
      ...state,
      isFetching,
    }
  }

  if (isType(action, ResetSelectedShiftProperty)) {
    return initialState
  }

  if (isType(action, GetSelectedShiftPropertyErrors)) {
    const {
      payload: { code, message },
    } = action

    return {
      ...state,
      error: {
        code,
        message,
      },
    }
  }

  return state
}
