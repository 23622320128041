import { AxiosService } from '../config/config'
import { Endpoints } from '../config/endpoints'
import { IPayload } from 'models/Global'
import {
  ITimetracker,
  IGetTimetrackersParams,
  IPutTimetrackerParams,
  IGetTimetrackersPropertiesParams,
  ITimetrackerProperties,
  IGenerateTimetrackerCredentialsParams,
} from 'models/Timetracker'

export class TimetrackersApi {
  constructor(private axios: AxiosService) {}

  getTimetrackers = async (params?: IGetTimetrackersParams) => {
    const response = await this.axios.get<IPayload<ITimetracker[]>>(
      Endpoints.getTimetrackers,
      params
    )
    return response
  }

  getTimetrackersProperties = async (params?: IGetTimetrackersPropertiesParams) => {
    const response = await this.axios.get<IPayload<ITimetrackerProperties[]>>(
      Endpoints.getTimetrackersProperties,
      params
    )
    return response
  }

  getTimetracker = async (id: number) => {
    const response = await this.axios.get<IPayload<ITimetracker>>(Endpoints.getTimetracker(id))
    return response
  }

  putTimetracker = async (id: number, params: IPutTimetrackerParams) => {
    const response = await this.axios.put<IPayload<ITimetracker>>(
      Endpoints.putTimetracker(id),
      params
    )
    return response
  }

  generateTimetrackerCredentials = async (
    id: number,
    params: IGenerateTimetrackerCredentialsParams
  ) => {
    const response = await this.axios.post<IPayload<ITimetracker>>(
      Endpoints.generateTimetrackerCredentials(id),
      params
    )
    return response
  }

  deleteTimetracker = async (id: number) => {
    const response = await this.axios.delete<{}>(Endpoints.deleteTimetracker(id))
    return response
  }

  getTimetrackerProperties = async (id: number) => {
    const response = await this.axios.get<IPayload<ITimetrackerProperties>>(
      Endpoints.getTimetrackerProperties(id)
    )
    return response
  }
}
