import styled from 'styled-components'
import { Flex } from '@pinchenterprisesnpm/friday-ui'

export const InfoWrapper = styled(Flex)`
  margin-left: ${({ theme: { mp } }) => mp.md};
  padding: ${({ theme: { mp } }) => mp.xs} 0;
`
export const ResourceTypeInfoWrapper = styled(Flex)<{ bold?: boolean; isPrimary?: boolean }>`
  font-weight: ${({
    bold,
    theme: {
      font: { weights },
    },
  }) => (bold ? weights.semiBold : weights.regular)};
  color: ${({ isPrimary, theme: { palette } }) => (isPrimary ? palette.primary : palette.dark)};
`

export const FlexWrapper = styled(Flex)<{ isActive?: boolean }>`
  background: ${({ isActive }) => (isActive ? 'rgba(3, 169, 244, 0.1)' : 'white')};
  cursor: pointer;
`
export const FlexItemWrapper = styled(Flex)`
  padding: ${({ theme: { mp } }) => `0 ${mp.md}`};
  flex: 1;
`

export const NoResultsWrapper = styled(Flex)`
  margin-left: ${({ theme: { mp } }) => mp.lg};
`
