import { defaultMenuConfig, useSidebarMenuContext } from '@pinchenterprisesnpm/friday-ui'
import { useState, useEffect, useCallback } from 'react'

/**
 * Additional offset to the one mandatory
 */
const defaultStaticOffset = 30

/**
 * Hook that returns available width for the container next to the Sidebar menu within CodeIgniter app. Takes into consideration the offset needed.
 * @param minWidth Minimum width (initial width that will be returned as availableWidth if the calculated one is less or equal)
 */
export const useAvailableWidthForWebMenu = (minWidth = 1116) => {
  const [availableWidth, setAvailableWidth] = useState<number>(minWidth)
  const { menuWidth } = useSidebarMenuContext()

  const calculateAvailableWidth = useCallback(
    (offset: number) => {
      const availableWidth = window.innerWidth - offset
      if (availableWidth > minWidth) return availableWidth
      return minWidth
    },
    [minWidth]
  )

  const setInitialAvailableWidth = useCallback(() => {
    return calculateAvailableWidth(parseInt(menuWidth, 10) + defaultStaticOffset)
  }, [calculateAvailableWidth, menuWidth])

  useEffect(() => {
    setAvailableWidth(setInitialAvailableWidth())
  }, [setInitialAvailableWidth])

  return availableWidth
}

/**
 * Use this when the hook cannot be used
 * @returns menu width (offset)
 */
export const getOffset = () => {
  const menuState = localStorage.getItem('menuState')

  return menuState && menuState === 'collapsed'
    ? parseInt(defaultMenuConfig.COLLAPSED, 10) + defaultStaticOffset
    : parseInt(defaultMenuConfig.EXPANDED, 10) + defaultStaticOffset
}
