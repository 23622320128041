import { action, payload } from 'ts-action'
import { IError, IPayload } from 'models/Global'
import { IFeaturedShiftCampaign } from 'models/FeaturedShiftCampaign'

export const GetFeaturedShiftCampaign = action(
  'GetFeaturedShiftCampaign',
  payload<IPayload<IFeaturedShiftCampaign>>()
)

export const GetFeaturedShiftCampaignErrors = action(
  'GetFeaturedShiftCampaignErrors',
  payload<IError>()
)

export const FeaturedShiftCampaignFetching = action(
  'FeaturedShiftCampaignFetching',
  payload<boolean>()
)

export const FeaturedShiftsCampaignRemoveGigId = action(
  'FeaturedShiftsCampaignRemoveGigId',
  payload<number>()
)

export const FeaturedShiftsCampaignAddGigId = action(
  'FeaturedShiftsCampaignAddGigId',
  payload<number>()
)

export const ResetFeaturedShiftCampaign = action('ResetFeaturedShiftCampaign')
