import { TTimePickerValueObject, TTimePickerValue } from './TimePicker'
import TimePicker from './TimePicker'
import { createPortal } from 'react-dom'
import { createPopper } from '@popperjs/core'

/**
 * Create and return the popup
 */
const createPopupElement = () => {
  const popupElement = document.createElement('div')
  popupElement.classList.add('popper')
  popupElement.style.zIndex = '999999'

  return popupElement
}

/**
 * Append the popup to the document
 */
const appendPopupElement = (popupElement: HTMLDivElement) => {
  const { body } = document
  body.insertBefore(popupElement, body.firstChild)
}

/**
 * Creates, returns and appends the popup element to the document.
 */
export const createAndAppendPopupElement = () => {
  const popupElement = createPopupElement()
  appendPopupElement(popupElement)

  return popupElement
}

/**
 * Get only positive values when using modulo
 * @param n
 * @param m
 */
export const mod = (n: number, m: number) => ((n % m) + m) % m

const HOURS = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'] as const
const MINUTES = ['00', '15', '30', '45'] as const

export type HOURS_TYPE = typeof HOURS[number]
export type MINUTES_TYPE = typeof MINUTES[number]

const HOURS_SIZE = HOURS.length
const MINUTES_SIZE = MINUTES.length

/**
 * Move a certain hour by an offset
 *
 * @param config Config bag
 * @returns Moved hour by a certain offset
 */
const moveHour = (config: { hour: string; offset: number }) => {
  const { hour, offset } = config
  const hourIndex = HOURS.findIndex((h) => h === hour)
  const movedHourIndex = mod(hourIndex + offset, HOURS_SIZE)

  const movedHour = HOURS[movedHourIndex]

  return movedHour
}

/**
 * Move a given minute from the allowed ones to another given an offset
 *
 * @param config Config bag
 * @returns Moved minute by a certain offset
 */
const moveMinute = (config: { minute: string; offset: number }) => {
  const { minute, offset } = config
  const minuteIndex = MINUTES.findIndex((m) => m === minute)
  const movedMinuteIndex = mod(minuteIndex + offset, MINUTES_SIZE)

  const movedMinute = MINUTES[movedMinuteIndex]

  return movedMinute
}

/**
 * Handler for hours when 'UP' arrow is pressed
 * @param value
 * @param changePeriod
 * @returns
 */
export const handleHourUp = (value: TTimePickerValueObject) => {
  const { hour, minute, period } = value

  const nextHour = moveHour({ hour, offset: 1 })
  const nextMinute = minute
  let nextPeriod = period

  const boundaryHour = '11'

  /**
   * Switch the period if on boundary
   */
  if (hour === boundaryHour) {
    nextPeriod = period === 'AM' ? 'PM' : 'AM'
  }

  return {
    hour: nextHour,
    minute: nextMinute,
    period: nextPeriod,
  }
}

/**
 * Handler for hours when 'DOWN' arrow is pressed
 * @param value
 * @param changePeriod
 * @returns
 */
export const handleHourDown = (value: TTimePickerValueObject) => {
  const { hour, minute, period } = value

  const nextHour = moveHour({ hour, offset: -1 })
  const nextMinute = minute
  let nextPeriod = period

  const boundaryHour = '12'

  /**
   * Switch the period if on boundary
   */
  if (hour === boundaryHour) {
    nextPeriod = period === 'AM' ? 'PM' : 'AM'
  }

  return {
    hour: nextHour,
    minute: nextMinute,
    period: nextPeriod,
  }
}

/**
 * Handler for minute when 'UP' is pressed
 * @param value
 * @param changePeriod
 * @returns
 */
export const handleMinuteUp = (value: TTimePickerValueObject) => {
  const { hour, minute, period } = value

  let nextHour = hour
  const nextMinute = moveMinute({ minute, offset: 1 })
  let nextPeriod = period

  const boundaryMinute = '45'

  /**
   *
   */
  if (minute === boundaryMinute) {
    const { hour: h, period: p } = handleHourUp({ hour, minute, period, second: '' })
    nextHour = h
    nextPeriod = p
  }

  return {
    hour: nextHour,
    minute: nextMinute,
    period: nextPeriod,
  }
}

/**
 * Handler for minute when 'DOWN' is pressed
 * @param value
 * @param changePeriod
 * @returns
 */
export const handleMinuteDown = (value: TTimePickerValueObject) => {
  const { hour, minute, period } = value

  let nextHour = hour
  const nextMinute = moveMinute({ minute, offset: -1 })
  let nextPeriod = period

  const boundaryMinute = '00'

  /**
   *
   */
  if (minute === boundaryMinute) {
    const { hour: h, period: p } = handleHourDown({ hour, minute, period, second: '' })
    nextHour = h
    nextPeriod = p
  }

  return {
    hour: nextHour,
    minute: nextMinute,
    period: nextPeriod,
  }
}

/**
 * Value type guard
 * @param value Time picker general value
 */
const isValueObject = (value: TTimePickerValue): value is TTimePickerValueObject =>
  Boolean(value) && (value as TTimePickerValueObject).hour !== undefined

/**
 * Convert provided string value to an object one
 * @param value Time picker string value
 */
const convertStringValueToObject = (value: string): TTimePickerValueObject => {
  let valueObject = getDefault()

  if (value) {
    const [hour, remainder] = value.split(':')
    const other = remainder || ''
    const [minute, period] = other.split(' ')

    valueObject = {
      hour: hour as HOURS_TYPE,
      minute: minute as MINUTES_TYPE,
      second: '',
      period: period,
    }
  }

  return valueObject
}

/**
 * Normalize the value to an object
 * @param value Time picker general value
 */
export const normalizeValue = (value: TTimePickerValue) => {
  if (isValueObject(value)) return value
  else {
    return merge(convertStringValueToObject(value), getDefault())
  }
}

/**
 * Use provided values where possible, if not use default ones
 * @param obtainedValue Object containing computed values
 * @param defaultValue Object with default values
 */
const merge = (obtainedValue: TTimePickerValueObject, defaultValue: TTimePickerValueObject) => {
  return {
    hour: obtainedValue.hour || defaultValue.hour,
    minute: obtainedValue.minute || defaultValue.minute,
    second: obtainedValue.second || defaultValue.second,
    period: obtainedValue.period || defaultValue.period,
  }
}

/**
 * Get default values for time picker
 */
const getDefault = () => ({
  hour: '12' as HOURS_TYPE,
  minute: '00' as MINUTES_TYPE,
  second: '',
  period: 'AM',
})

/**
 * Portal for the modal
 * @param props
 */
export const Portal = (props: { children: any; to: Element }) => {
  const { children, to } = props
  return createPortal(children, to)
}

export const noop = () => {}

const destroy = () => {
  TimePicker.popperInstance && TimePicker.popperInstance.destroy()
  TimePicker.popperInstance = null
}

const closeLast = () => {
  TimePicker.last()
  TimePicker.last = noop
}

export const create = (trigger: HTMLDivElement, popup: HTMLDivElement) => {
  TimePicker.popperInstance = createPopper(trigger, popup, {
    placement: 'bottom-start',
    strategy: 'fixed',
  })
}

export const destroyAndCloseLast = () => {
  destroy()
  closeLast()
}

export const setupListeners = () => {
  document.addEventListener('click', (e) => {
    const target = e.target
    if (
      (target as HTMLElement).classList &&
      !(target as HTMLElement).classList.contains('timePickerTrigger') &&
      (target as Node).nodeName !== 'path'
    )
      destroyAndCloseLast()
  })
}
