import styled from 'styled-components'

export const Label = styled.label<{ disabled?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${(props) => (props.disabled ? props.theme.palette.light : props.theme.palette.dark)};
  cursor: pointer;
  position: relative;
  overflow: hidden;
  font-size: ${(props) => props.theme.font.sizes.small};

  @media only screen and (min-width: ${(props) => props.theme.breakpoints.md + 'px'}) {
    display: inline-flex;
    font-size: ${(props) => props.theme.font.sizes.default};
  }
`

export const Toggle = styled.span`
  position: relative;
  display: block;
  text-align: center;
  width: auto;
  padding: 7px 15px;
  transform-origin: center;
  transition: background-color ease 200ms;

  @media only screen and (min-width: ${(props) => props.theme.breakpoints.sm + 'px'}) {
    padding: ${(props) => `${7}px ${props.theme.mp.lg}`};
  }
`

export const Checkbox = styled.input`
  display: none;
  outline: 0;
  &:checked + ${Toggle} {
    background-color: ${({ color, theme: { palette } }) =>
      color ? palette[color as keyof typeof palette] : palette.primary};
    color: ${(props) => props.theme.palette.white};
  }
`
