import { action, payload } from 'ts-action'
import { ILoading, TShiftFollowLoadingIds } from 'models/Loading'

export const SetApprovePendingVerificationsLoadingId = action(
  'SetApprovePendingVerificationsLoadingId',
  payload<ILoading>()
)

export const SetShiftFollowLoadingIds = action(
  'SetShiftFollowLoadingIds',
  payload<Partial<TShiftFollowLoadingIds>>()
)
export const SetFavoriteLoadingId = action('SetFavoriteLoadingId', payload<ILoading>())
export const SetPendingSuspensionLoadingId = action(
  'SetPendingSuspensionLoadingId',
  payload<ILoading>()
)

export const ResetLoadingsReducer = action('ResetLoadingsReducer')
