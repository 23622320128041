import { confirmModal } from 'helpers/GenericConfirmModal'
import { history } from 'helpers/history'
import ROUTES from 'routing/adminRoutes'

export const dispatchLogoutConfirmationModal = () =>
  confirmModal({
    onConfirm: () => history.push(ROUTES.LOGOUT),
    title: 'Log out confirmation',
    message: 'Are you sure you want to log out from the Upshift platform?',
    primaryButtonType: 'primary',
    secondaryButtonType: 'dark',
    secondaryFilledOutlined: true,
  })
