import { IGroupsReducer, IGroup } from '../../models/Group'
import { Action } from 'redux'
import { isType } from 'ts-action'
import { deleteFromArray } from '../../helpers/helperFunctions'
import { DeleteGroup, GetGroups, GroupsFetching } from './actions'

const initialState: IGroupsReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: [],
}

export const GroupsReducer = (state = initialState, action: Action): IGroupsReducer => {
  if (isType(action, GetGroups)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
    }
  }

  if (isType(action, GroupsFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }

  if (isType(action, DeleteGroup)) {
    const { payload } = action
    const { data, meta } = state
    const formattedArray = deleteFromArray<IGroup>(data, payload)
    return {
      ...state,
      data: formattedArray,
      meta: {
        ...meta,
        pagination: {
          ...meta.pagination,
          count: meta.pagination ? meta.pagination.count - 1 : 0,
          total: meta.pagination ? meta.pagination.total - 1 : 0,
          per_page: meta.pagination?.per_page || 50,
          current_page: meta.pagination?.current_page || 1,
          total_pages: meta.pagination?.total_pages || 1,
          links: meta.pagination?.links || [],
        },
      },
    }
  }

  return state
}
