import styled from 'styled-components'
import { FooterWrapper } from 'ui/components/common/style'

export const FieldWrapper = styled.div`
  margin: ${({ theme }) => theme.mp.md} 0;

  & > div:first-child {
    margin-bottom: ${({ theme }) => theme.mp.xs};
  }
`

export const DropdownWrapper = styled.div`
  width: 130px;
`

export const AddExtraPunchCardFooter = styled(FooterWrapper)`
  padding: ${(props) => props.theme.mp.md} 0;
`

export const ColWrapper = styled(FieldWrapper)`
  width: 130px;
  margin-right: 10rem;
`
