import { Button, Modal, Tooltip } from '@pinchenterprisesnpm/friday-ui'
import { Formik, FormikHelpers } from 'formik'
import { useCallback, useMemo, useState } from 'react'
import { useModal } from 'hooks/useModal'
import { IShift, SHIFT_TYPE } from 'models/Shift'
import { API } from 'network'
import { handleErrorNotification } from 'services/ErrorHandlingService'
import { ScrollableModalContent } from 'ui/components/common/style'
import { addExtraPunchCardValidationSchema, TAddExtraPunchCardModalValues } from './utils'
import { faAlarmPlus } from '@fortawesome/pro-light-svg-icons'
import { DATE_AND_TIME_FORMAT_STRING, DATE_FORMAT_STRING } from 'helpers/constants/constants'
import { breakDropdownData } from 'helpers/shiftHelpers'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { APPLICATION_STATUS, IApplicantDropdownData } from 'models/Application'
import AddExtraPunchCardForm from './AddExtraPunchCardForm'
import { IPunchCard } from 'models/PunchCard'
import { AddExtraPunchCardFooter } from './style'
import { IDropdownData } from 'models/Common'
import { punchCardCheckerHandler } from '../Timesheet/ApplicationPunchCards/utils'
import { useDispatch, useSelector } from 'react-redux'
import { getSelectedShiftGigDays } from 'data/SelectedShiftGigDays/thunks'
import { addDays, format, isBefore } from 'date-fns'
import { selectSelectedShiftGigDays } from 'data/SelectedShiftGigDays/selectors'
import { FEATURE_TOGGLE_MULTIDAY_REVAMP } from 'config/featureToggles'

const { Header } = Modal

type TAddExtraPunchCardModalProps = {
  /**
   * Render props for the component
   */
  render?: (toggleModal: () => void, rest: Record<string, any>) => JSX.Element
  shiftId?: number
}

const AddExtraPunchCardModal = ({ render, shiftId, ...rest }: TAddExtraPunchCardModalProps) => {
  const [isOpen, toggleModal, closeModal] = useModal()
  const [isLoading, setIsLoading] = useState(false)
  const [isCreatePunchCardModalOpen, setIsCreatePunchCardModalOpen] = useState(false)
  const [shift, setShift] = useState<IShift>()
  const [applicants, setApplicants] = useState<IApplicantDropdownData[]>([])
  const gigDays = useSelector(selectSelectedShiftGigDays)

  const dispatch = useDispatch()

  const handleCloseModal = useCallback(() => {
    setShift(undefined)
    closeModal()
  }, [closeModal])

  const initialAddExtraPunchCardValues: TAddExtraPunchCardModalValues = useMemo(
    () => ({
      shiftId: shiftId,
      applicant: null,
      date: undefined,
      hour_start: '',
      hour_end: '',
      pay_value: undefined,
      break: null,
      punchCard: { isNoEndPunchCard: false } as IPunchCard,
    }),
    [shiftId]
  )

  const findShiftHandler = useCallback(
    async (
      values: TAddExtraPunchCardModalValues,
      setValues: (
        values: React.SetStateAction<TAddExtraPunchCardModalValues>,
        shouldValidate?: boolean | undefined
      ) => void
    ) => {
      try {
        setIsLoading(true)
        const shiftResponse = await API.Shifts.getShift(Number(values.shiftId), {
          include: ['timesheets'],
        })
        const applicationsResponse = await API.Applications.getApplications({
          gig_id: values.shiftId,
          status: APPLICATION_STATUS.ACCEPTED,
          confirmed: true,
          show_group_applications: true,
        })
        Number(shiftResponse.data.type) === SHIFT_TYPE.MULTIDAY &&
          dispatch(getSelectedShiftGigDays({ gig_id: values.shiftId || 0 }))
        const { data: shiftData } = shiftResponse
        setShift(shiftData)
        setApplicants(
          applicationsResponse.data.map(({ id, applicant }) => ({
            label: applicant.full_name,
            value: applicant.id,
            application_id: id,
            isDefault: false,
          }))
        )
        setValues({
          ...values,
          applicant: null,
          date: new Date(shiftData.date_start),
          hour_start: shiftData.hour_start,
          hour_end: shiftData.hour_end,
          pay_value: Number(shiftData.pay_value),
          break:
            breakDropdownData.find((breakOption) => breakOption.value === shiftData.break) ||
            breakDropdownData[0],
          punchCard: {
            gig_id: shiftData.id,
            break: shiftData.break === null ? 0 : shiftData.break,
            pay_value: shiftData.pay_value,
            id: -1,
            timesheet_id: -1,
            timestamp_end: shiftData.time_end,
            timestamp_start: shiftData.time_start,
            created_at: '/',
            updated_at: '/',
            hours: '/',
            hours_overtime: '/',
            hours_total: '/',
            invoice_value: '/',
            application_id: 0,
            application_user_id: 0,
            application_full_name: '',
            created_by_full_name: null,
            created_by_id: null,
            updated_by_full_name: null,
            edited_by_id: null,
            price: '0',
            commission: '0',
            pay_value_overtime: '0',
            overtime: false,
            bill_value: '0',
            status: 1,
            reason_for_edit: null,
            reason_for_edit_how: null,
            reason_for_create: null,
            reason_for_create_how: null,
            zendesk_id: null,
            fake: false,
            auto_generated: false,
            reason_for_delete: null,
            client_request_status: null,
            note_subject: null,
            note: '',
          },
        })
      } catch (error) {
        setShift(undefined)
        handleErrorNotification(error)
      } finally {
        setIsLoading(false)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const openCreatePunchCardModal = useCallback(
    (
      values: TAddExtraPunchCardModalValues,
      { setFieldValue }: FormikHelpers<TAddExtraPunchCardModalValues>
    ) => {
      // When we open the reason modal, we need to transfer the formik values
      // from 'values/' to 'values/punchCard'
      const formattedDate = format(
        values.date ? new Date(values.date) : new Date(),
        DATE_FORMAT_STRING.MM_dd_yyyy
      )
      const timestampStart = `${formattedDate} ${values.hour_start}`
      let timestampEnd = `${formattedDate} ${values.hour_end}`
      if (isBefore(new Date(timestampEnd), new Date(timestampStart))) {
        timestampEnd = format(
          addDays(new Date(timestampEnd), 1),
          DATE_AND_TIME_FORMAT_STRING.MM_dd_yyyy_hh_mm_a
        )
      }

      // All the casts are safe here because of yup validation
      const newPunchCard: IPunchCard = {
        ...values.punchCard,
        gig_id: Number(values.shiftId),
        application_id: (values.applicant as IApplicantDropdownData).application_id,
        pay_value: values.pay_value + '',
        break: (values.break as IDropdownData).value,
        timestamp_start: timestampStart,
        timestamp_end: timestampEnd,
        ...(FEATURE_TOGGLE_MULTIDAY_REVAMP &&
          Number(shift?.type) === SHIFT_TYPE.MULTIDAY && {
            gig_day_id: values.punchCard.gig_day_id,
          }),
      }
      punchCardCheckerHandler({
        punchCard: newPunchCard,
        savePunchCard: () => {
          setFieldValue('punchCard', newPunchCard)
          setIsCreatePunchCardModalOpen(true)
        },
      })
    },
    [shift]
  )

  return (
    <>
      {/* If there is a render prop, use it. Otherwise -> use Add Extra Punch Card button as a default */}
      {render ? (
        render(toggleModal, rest)
      ) : (
        <Tooltip text='Add Punch Card'>
          <Button isFilledOutlined onClick={toggleModal}>
            <FontAwesomeIcon icon={faAlarmPlus} />
          </Button>
        </Tooltip>
      )}
      <Modal closeModal={handleCloseModal} isOpen={isOpen} height='auto' width={1000}>
        {({ closeModal: closeModalProvided }: { closeModal: () => void }) => (
          <Formik
            initialValues={initialAddExtraPunchCardValues}
            validationSchema={addExtraPunchCardValidationSchema}
            onSubmit={async (values, formikHelpers) =>
              openCreatePunchCardModal(values, formikHelpers)
            }
            enableReinitialize
          >
            {(formikProps) => {
              const { submitForm } = formikProps
              return (
                <>
                  <Header title='Add Punch Card' closeModal={closeModalProvided} />
                  <ScrollableModalContent>
                    <AddExtraPunchCardForm
                      formikProps={formikProps}
                      closeModal={closeModalProvided}
                      shift={shift}
                      handleCloseModal={handleCloseModal}
                      isLoading={isLoading}
                      setIsLoading={setIsLoading}
                      isCreatePunchCardModalOpen={isCreatePunchCardModalOpen}
                      setIsCreatePunchCardModalOpen={setIsCreatePunchCardModalOpen}
                      applicants={applicants}
                      findShiftHandler={findShiftHandler}
                      shiftId={shiftId}
                    />
                    {!!shift && (
                      <AddExtraPunchCardFooter>
                        <Button
                          isDisabled={isLoading}
                          isLoading={isLoading}
                          onClick={closeModalProvided}
                          isFilledOutlined
                          type='danger'
                        >
                          Cancel
                        </Button>
                        <Button
                          isDisabled={isLoading}
                          isLoading={isLoading}
                          onClick={submitForm}
                          ml={10}
                        >
                          Add
                        </Button>
                      </AddExtraPunchCardFooter>
                    )}
                  </ScrollableModalContent>
                </>
              )
            }}
          </Formik>
        )}
      </Modal>
    </>
  )
}

export default AddExtraPunchCardModal
