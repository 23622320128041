import { IPunchCard, IPunchCardsReducer } from 'models/PunchCard'
import { isType } from 'ts-action'
import { Action } from 'redux'
import {
  GetPunchCards,
  PunchCardsFetching,
  GetPunchCardsErrors,
  ResetPunchCardsState,
  DeletePunchCard,
  UpdatePunchCard,
} from './actions'
import { deleteFromArray, updateArray } from 'helpers/helperFunctions'

const initialState: IPunchCardsReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: [],
}

export const punchCardsReducer = (state = initialState, action: Action): IPunchCardsReducer => {
  if (isType(action, GetPunchCards)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
    }
  }
  if (isType(action, GetPunchCardsErrors)) {
    const { payload } = action
    return {
      ...state,
      error: {
        code: payload.code,
        message: payload.message,
      },
    }
  }
  if (isType(action, DeletePunchCard)) {
    const { payload } = action
    const { data } = state
    const formattedArray = deleteFromArray<IPunchCard>(data, payload)
    return {
      ...state,
      data: formattedArray,
    }
  }
  if (isType(action, PunchCardsFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }
  if (isType(action, UpdatePunchCard)) {
    const { payload } = action
    const { data } = state
    const formattedArray = updateArray<IPunchCard>(data, payload.data)
    return {
      ...state,
      data: formattedArray,
    }
  }
  if (isType(action, DeletePunchCard)) {
    const { payload: id } = action
    return {
      ...state,
      data: state.data.filter((punchCard) => punchCard.id !== id),
    }
  }
  if (isType(action, ResetPunchCardsState)) {
    return {
      ...initialState,
    }
  }
  return state
}
