import { action, payload } from 'ts-action'
import { INote } from 'models/Note'
import { IError, IPayload } from 'models/Global'

export const GetNotes = action('GetNotes', payload<IPayload<INote[]>>())
export const GetNotesErrors = action('GetErrors', payload<IError>())
export const NotesFetching = action('NotesFetching', payload<boolean>())

export const AddNote = action('AddNote', payload<INote>())
export const AddNoteLoading = action('AddNoteLoading', payload<boolean>())

export const UpdateNote = action('UpdateNote', payload<INote>())
export const UpdateNoteLoading = action('UpdateNoteLoading', payload<number>())
export const UpdateNoteStopLoading = action('UpdateNoteStopLoading', payload<number>())

export const ResetNotesReducer = action('ResetNotesReducer')
