import { IShiftsReducer } from 'models/Shift'
import { Action } from 'redux'
import { isType } from 'ts-action'
import {
  GetFollowManagementShifts,
  FollowManagementShiftsFetching,
  ResetFollowManagementShiftsReducer,
  UpdateFollowManagementShift,
} from './actions'
import { FOLLOW_STATUS } from 'models/Follow'

const initialState: IShiftsReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: [],
}

export const followManagementShiftsReducer = (
  state = initialState,
  action: Action
): IShiftsReducer => {
  if (isType(action, GetFollowManagementShifts)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
    }
  }

  if (isType(action, FollowManagementShiftsFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }

  if (isType(action, ResetFollowManagementShiftsReducer)) {
    return initialState
  }

  if (isType(action, UpdateFollowManagementShift)) {
    const {
      payload: { data },
    } = action

    if (data.status === FOLLOW_STATUS.UNFOLLOW) {
      const newShifts = state.data.filter((item) => item.id !== data.model_id)

      return {
        ...state,
        data: newShifts,
      }
    }
  }

  return state
}
