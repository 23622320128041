import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Milestone
export const MilestoneAlertWrapper = styled.div`
  width: ${() => {
    const menuState = localStorage.getItem('menuState')
    if (menuState === null || menuState === 'expanded') return `calc(100% - 260px);`
    return `calc(100% - 70px);`
  }};
  top: 0;
  right: 0;
  position: absolute;
`

export const MilestoneAlertContent = styled.div`
  margin: 0 ${({ theme }) => theme.mp.sm};
  padding: ${({ theme }) => theme.mp.sm} 0;
  font-size: ${({ theme }) => theme.font.sizes.medium};
`

export const MilestoneAlertIcon = styled(FontAwesomeIcon)`
  margin-left: ${({ theme }) => theme.mp.sm};
`
