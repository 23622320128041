import {
  Alert,
  Button,
  DropdownV5,
  Form,
  Loader,
  DatePicker,
  Flex,
  Text,
  Tooltip,
} from '@pinchenterprisesnpm/friday-ui'
import { FormikProps } from 'formik'
import {
  AlertIcon,
  AlertWrapper,
  CustomValidationError,
  LoaderWrapper,
} from 'ui/components/common/style'
import { TAddExtraPunchCardModalValues } from '../utils'
import { faInfoSquare, faClock as faClockDuotone } from '@fortawesome/pro-duotone-svg-icons'
import { faClock } from '@fortawesome/pro-light-svg-icons'
import { DropdownWrapper, FieldWrapper } from '../style'
import NumberFormat, { NumberFormatValues } from 'react-number-format'
import AddExtraPunchCardShiftDetails from '../AddExtraPunchCardShiftDetails'
import { IApplicantDropdownData } from 'models/Application'
import { IPostPunchCardParams } from 'models/PunchCard'
import { useCallback, useEffect } from 'react'
import { IShift, SHIFT_TYPE } from 'models/Shift'
import { formatTimestampUtc } from '../../Timesheet/ApplicationPunchCards/utils'
import { addPunchCard, PunchCardsActions } from 'data/PunchCard/thunks'
import { useDispatch, useSelector } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'
import { IDropdownData, TypeOrUndefined } from 'models/Common'
import { TimePickerStyle } from '../../../Shifts/ShiftEditDuplicate/common/style'
import { TimePicker } from 'ui/components/common/TimePicker'
import { breakDropdownData } from 'helpers/shiftHelpers'
import ROUTES from 'routing/adminRoutes'
import CreatePunchCardReasonModal from '../../Timesheet/CreatePunchCardReasonModal'
import { DATE_FORMAT_STRING } from 'helpers/constants/constants'
import { selectSelectedShiftGigDays } from 'data/SelectedShiftGigDays/selectors'
import { format } from 'date-fns'
import { StyledText } from './style'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getBypassParams } from 'helpers/helperFunctions'
import { confirmModal } from 'helpers/GenericConfirmModal'
import { TIMESHEET_STATUS } from 'models/Timesheet'
import { FEATURE_TOGGLE_MULTIDAY_REVAMP } from 'config/featureToggles'
import { useState } from 'react'
import { faClose } from '@fortawesome/pro-duotone-svg-icons'

const { Input } = Form
const { DatePickerSingle } = DatePicker

// TODO: Refactor to named export

type TAddExtraPunchCardFormProps = {
  formikProps: FormikProps<TAddExtraPunchCardModalValues>
  closeModal: () => void
  shift: TypeOrUndefined<IShift>
  handleCloseModal: () => void
  isLoading: boolean
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  isCreatePunchCardModalOpen: boolean
  setIsCreatePunchCardModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  applicants: IApplicantDropdownData[]
  findShiftHandler: (
    values: TAddExtraPunchCardModalValues,
    setValues: (
      values: React.SetStateAction<TAddExtraPunchCardModalValues>,
      shouldValidate?: boolean | undefined
    ) => void
  ) => void
  shiftId?: number
}

const AddExtraPunchCardForm = ({
  formikProps: { handleSubmit, errors, setFieldValue, setValues, values, touched },
  closeModal,
  shift,
  handleCloseModal,
  isLoading,
  setIsLoading,
  isCreatePunchCardModalOpen,
  setIsCreatePunchCardModalOpen,
  applicants,
  findShiftHandler,
  shiftId,
}: TAddExtraPunchCardFormProps) => {
  const dispatch = useDispatch<ThunkDispatch<any, any, PunchCardsActions>>()
  const shiftDays = useSelector(selectSelectedShiftGigDays)
  const selectedShiftDate = format(
    values.date ? new Date(values.date) : new Date(),
    DATE_FORMAT_STRING.MM_dd_yyyy
  )
  const selectedShiftDay = shiftDays.find((shiftDay) => shiftDay.date_start === selectedShiftDate)
  const [hourEndValue, setHourEndValue] = useState(values.hour_end)

  useEffect(() => {
    if (values.hour_end) setHourEndValue(values.hour_end)
  }, [values.hour_end])

  const onConfirmBypassParamsPunchCard = useCallback(
    async (params: IPostPunchCardParams) => {
      try {
        const response = await dispatch(addPunchCard(params))
        handleCloseModal()
        // We want to open new tab with the timesheet id
        window.open(`${ROUTES.TIMESHEETS_LIST}/${response?.data.timesheet_id}`, '_blank') //to open new page
      } catch (error) {
        const bypassParams = getBypassParams(error)
        if (bypassParams) {
          const bypassParams = getBypassParams(error)
          const newParams: IPostPunchCardParams = {
            ...params,
            ...bypassParams,
          }
          confirmModal({
            onConfirm: async () => onConfirmBypassParamsPunchCard(newParams),
            message: error.message,
            width: 400,
          })
        }
      }
    },
    [dispatch, handleCloseModal]
  )

  // If shift ID is provided
  useEffect(() => {
    if (shiftId) {
      findShiftHandler(values, setValues)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (selectedShiftDay) {
      setFieldValue('hour_start', selectedShiftDay.hour_start)
      setFieldValue('hour_end', selectedShiftDay.hour_end)
      setFieldValue('pay_value', selectedShiftDay.pay_value)
      setFieldValue(
        'break',
        breakDropdownData.find((breakOption) => breakOption.value === selectedShiftDay.break)
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.date])

  const saveSinglePunchCard = useCallback(
    async (params: IPostPunchCardParams, note: string) => {
      const newParams: IPostPunchCardParams = {
        ...params,
        timestamp_start: formatTimestampUtc(
          params.timestamp_start as string,
          (shift as IShift).property_timezone
        ),
        ...(!params.isNoEndPunchCard &&
          params.timestamp_end && {
            timestamp_end: formatTimestampUtc(
              params.timestamp_end as string,
              (shift as IShift).property_timezone
            ),
          }),
        note: note,
        ...(FEATURE_TOGGLE_MULTIDAY_REVAMP &&
          Number(shift?.type) === SHIFT_TYPE.MULTIDAY && {
            gig_day_id: selectedShiftDay?.id,
          }),
      }

      try {
        setIsLoading(true)
        const res = await dispatch(addPunchCard(newParams))
        if (res) {
          handleCloseModal()
          // We want to open new tab with the timesheet id
          window.open(`${ROUTES.TIMESHEETS_LIST}/${res.data.timesheet_id}`, '_blank') //to open new page
        }
      } catch (error) {
        const bypassParams = getBypassParams(error)
        if (bypassParams) {
          const bypassParams = getBypassParams(error)
          const params: IPostPunchCardParams = {
            ...newParams,
            ...bypassParams,
          }
          confirmModal({
            onConfirm: async () => onConfirmBypassParamsPunchCard(params),
            message: error.message,
            width: 400,
          })
        }
      } finally {
        setIsCreatePunchCardModalOpen(false)
        setIsLoading(false)
      }
    },
    [
      dispatch,
      handleCloseModal,
      onConfirmBypassParamsPunchCard,
      setIsCreatePunchCardModalOpen,
      setIsLoading,
      shift,
      selectedShiftDay,
    ]
  )

  const resetModalPredefinedFields = useCallback(() => {}, [])

  return (
    <Form onSubmit={handleSubmit}>
      <AlertWrapper>
        <Alert
          pb='sm'
          type='primary'
          content={() => (
            <Flex alignItems='center' height='20px'>
              <AlertIcon icon={faInfoSquare} />
              <Flex>
                <Text>
                  You will be redirected to the new timesheet after creating this punch card.
                </Text>
              </Flex>
            </Flex>
          )}
        />
      </AlertWrapper>
      {/* If shift ID is provided external we don't need to show the show Shift ID input */}
      {!shiftId ? (
        <>
          <FieldWrapper>
            <Flex>
              <NumberFormat
                width='100%'
                allowLeadingZeros={false}
                thousandSeparator={false}
                allowNegative={false}
                decimalScale={0}
                name='shiftId'
                placeholder='Shift ID'
                value={values.shiftId}
                onValueChange={(values: NumberFormatValues) => {
                  const { floatValue } = values
                  setFieldValue('shiftId', floatValue)
                }}
                customInput={Input}
                rightElement={
                  values.shiftId && (
                    <FontAwesomeIcon
                      icon={faClose}
                      size='lg'
                      onClick={() => setFieldValue('shiftId', '')}
                    />
                  )
                }
              />
              <Button
                isDisabled={!values.shiftId || isLoading}
                isLoading={isLoading}
                onClick={() => findShiftHandler(values, setValues)}
                ml='xs'
              >
                Search
              </Button>
            </Flex>
            {touched.shiftId && errors.shiftId ? (
              <CustomValidationError>{errors.shiftId}</CustomValidationError>
            ) : null}
          </FieldWrapper>
        </>
      ) : null}
      <Flex flexDirection='column'>
        {!!shift && (
          <AddExtraPunchCardShiftDetails
            shift={shift}
            upshifterName={values.applicant?.label || ''}
            selectedDate={values.date}
          />
        )}
      </Flex>
      {isLoading ? (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      ) : (
        !!shift && (
          <>
            <Flex>
              <Text fontWeight='bold' mr={5}>
                Accepted Applicants
              </Text>
              <StyledText fontWeight='bold' color='primary'>
                (The list below displays only the Accepted and Confirmed Upshifters)
              </StyledText>
            </Flex>

            <FieldWrapper>
              <div>Select Applicant</div>
              <DropdownV5
                placeholder='-Select Applicant-'
                options={applicants}
                value={values.applicant}
                onChange={(applicant: IApplicantDropdownData) =>
                  setFieldValue('applicant', applicant)
                }
                type='primary'
                isOutlined
                maxMenuHeight={200}
              />
              {errors.applicant && touched.applicant && (
                <CustomValidationError>{errors.applicant}</CustomValidationError>
              )}
            </FieldWrapper>

            <Flex alignItems='center' justifyContent='space-between'>
              <FieldWrapper>
                <Text fontWeight='bold'>Pick a Date</Text>
                <div>
                  <DatePickerSingle
                    onDayClick={(day) => {
                      setFieldValue('date', day)
                    }}
                    onReset={() => setFieldValue('date', undefined)}
                    selectedDay={values.date ?? undefined}
                    disabledDays={{
                      before: new Date(shift.date_start),
                      after: new Date(shift.date_end),
                    }}
                  />
                  {errors.date && touched.date && (
                    <CustomValidationError>{errors.date}</CustomValidationError>
                  )}
                </div>
              </FieldWrapper>
              <FieldWrapper>
                <Text fontWeight='bold'>Start Time</Text>
                <TimePickerStyle {...(errors.hour_start && touched.hour_start && { error: true })}>
                  <TimePicker
                    value={values.hour_start}
                    animated
                    width='auto'
                    height='auto'
                    right='auto'
                    left='auto'
                    onChange={(value: string) => setFieldValue('hour_start', value)}
                    headerText='Start Time'
                  />
                </TimePickerStyle>
              </FieldWrapper>
              <FieldWrapper>
                <Text fontWeight='bold' mr={10}>
                  End Time
                </Text>
                <Flex alignItems='center'>
                  <TimePickerStyle {...(errors.hour_end && touched.hour_end && { error: true })}>
                    <TimePicker
                      value={hourEndValue}
                      animated
                      width='auto'
                      height='auto'
                      right='auto'
                      left='auto'
                      onChange={(value: string) => {
                        setFieldValue('hour_end', value)
                        setHourEndValue(value)
                      }}
                      headerText='End Time'
                      isDisabled={values.punchCard.isNoEndPunchCard}
                    />
                  </TimePickerStyle>
                  <Tooltip
                    text={
                      values.punchCard.isNoEndPunchCard
                        ? 'Click on the icon button for Regular Punch Card'
                        : 'Click on the icon button for No End Time Punch Card'
                    }
                  >
                    <Button
                      onClick={() => {
                        if (values.punchCard.isNoEndPunchCard)
                          setFieldValue('hour_end', hourEndValue)
                        else setFieldValue('hour_end', null)
                        setFieldValue(
                          'punchCard.isNoEndPunchCard',
                          !values.punchCard.isNoEndPunchCard
                        )
                      }}
                      type={values.punchCard.isNoEndPunchCard ? 'primary' : 'dark'}
                      icon={
                        <FontAwesomeIcon
                          size='lg'
                          icon={values.punchCard.isNoEndPunchCard ? faClockDuotone : faClock}
                        />
                      }
                      mr={5}
                    />
                  </Tooltip>
                </Flex>
              </FieldWrapper>
              <FieldWrapper>
                <Text fontWeight='bold'>Hourly Pay Rate</Text>
                <NumberFormat
                  allowLeadingZeros={false}
                  allowNegative={false}
                  decimalScale={2}
                  thousandSeparator={true}
                  prefix={'$'}
                  name='pay_value'
                  placeholder='Hourly Pay Rate'
                  value={values.pay_value}
                  onValueChange={(values: NumberFormatValues) => {
                    const { floatValue } = values
                    setFieldValue('pay_value', floatValue)
                  }}
                  customInput={Input}
                />
                {touched.pay_value && errors.pay_value ? (
                  <CustomValidationError>{errors.pay_value}</CustomValidationError>
                ) : null}
              </FieldWrapper>
              <FieldWrapper>
                <Text fontWeight='bold'>Break</Text>
                <DropdownWrapper>
                  <DropdownV5
                    placeholder='Break'
                    options={breakDropdownData}
                    value={values.break}
                    onChange={(unpaidBreak: IDropdownData) => setFieldValue('break', unpaidBreak)}
                    closeMenuOnScroll={false}
                    type='primary'
                    isOutlined
                  />
                </DropdownWrapper>
              </FieldWrapper>
            </Flex>

            <CreatePunchCardReasonModal
              isOpen={isCreatePunchCardModalOpen}
              closeModal={() => setIsCreatePunchCardModalOpen(false)}
              isLoading={isLoading}
              saveSinglePunchCard={saveSinglePunchCard}
              punchCard={values.punchCard}
              predefinedValues={null}
              resetModalPredefinedFields={resetModalPredefinedFields}
              isTimesheetProcessed={shift.timesheets.data.some(
                (timesheet) => timesheet.status === TIMESHEET_STATUS.PROCESSED
              )}
              shiftTimezone={shift.property_timezone}
            />
          </>
        )
      )}
    </Form>
  )
}

export default AddExtraPunchCardForm
