import { ITimesheetsReducer } from 'models/Timesheet'
import { Action } from 'redux'
import { isType } from 'ts-action'
import { GetTimesheets, TimesheetsFetching, ClearTimesheets } from './actions'

const initialState: ITimesheetsReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: [],
}

export const timesheetsReducer = (state = initialState, action: Action): ITimesheetsReducer => {
  if (isType(action, GetTimesheets)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
    }
  }
  if (isType(action, TimesheetsFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }
  if (isType(action, ClearTimesheets)) {
    return initialState
  }
  return state
}
