import { IGetBonusesParams, IBonus, IPostBonusParams, IPutBonusParams } from 'models/Bonus'
import { IPayload } from 'models/Global'
import { AxiosService } from '../config/config'
import { Endpoints } from '../config/endpoints'

export class BonusesApi {
  constructor(private axios: AxiosService) {}

  getBonuses = async (params?: IGetBonusesParams) => {
    const response = await this.axios.get<IPayload<IBonus[]>>(Endpoints.getBonuses, params)
    return response
  }

  getBonus = async (id: number) => {
    const response = await this.axios.get<IPayload<IBonus>>(Endpoints.getBonus(id))
    return response
  }

  postBonus = async (params?: IPostBonusParams) => {
    const response = await this.axios.post<IPayload<IBonus>>(Endpoints.postBonus, params)
    return response
  }

  putBonus = async (id: number, params: IPutBonusParams) => {
    const response = await this.axios.put<IPayload<IBonus>>(Endpoints.putBonus(id), params)
    return response
  }

  deleteBonus = async (id: number) => {
    const response = await this.axios.delete<{}>(Endpoints.deleteBonus(id))
    return response
  }
}
