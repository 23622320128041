import { action, payload } from 'ts-action'
import { IBusinessEntity } from 'models/BusinessEntity'
import { IFollow } from 'models/Follow'
import { IError, IPayload } from 'models/Global'

export const GetFollowManagementBusinessEntities = action(
  'GetFollowManagementBusinessEntities',
  payload<IPayload<IBusinessEntity[]>>()
)

export const GetFollowManagementBusinessEntitiesErrors = action(
  'GetFollowManagementBusinessEntitiesErrors',
  payload<IError>()
)

export const FollowManagementBusinessEntitiesFetching = action(
  'FollowManagementBusinessEntitiesFetching',
  payload<boolean>()
)

export const UpdateFollowManagementBusinessEntity = action(
  'UpdateFollowManagementBusinessEntity',
  payload<IPayload<IFollow>>()
)
