import { action, payload } from 'ts-action'
import { ISuggestedPayRate } from 'models/SuggestedPayRate'
import { IError, IPayload } from 'models/Global'

export const SuggestedPayRatesFetching = action('SuggestedPayRatesFetching', payload<boolean>())

export const GetSuggestedPayRates = action(
  'GetSuggestedPayRates',
  payload<IPayload<ISuggestedPayRate[]>>()
)

export const AddSuggestedPayRate = action(
  'AddSuggestedPayRate',
  payload<IPayload<ISuggestedPayRate>>()
)

export const UpdateSuggestedPayRate = action(
  'UpdateSuggestedPayRate',
  payload<IPayload<ISuggestedPayRate>>()
)

export const DeleteSuggestedPayRate = action('DeleteSuggestedPayRate', payload<number>())

export const GetSuggestedPayRatesErrors = action('GetSuggestedPayRatesErrors', payload<IError>())

export const UpdateSuggestedPayRateLoadingIds = action(
  'UpdateSuggestedPayRateLoadingIds',
  payload<number>()
)
