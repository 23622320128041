import { IShiftsReducer, IShift } from 'models/Shift'
import { Action } from 'redux'
import { isType } from 'ts-action'
import { updateArray, deleteFromArray } from 'helpers/helperFunctions'
import {
  GetShifts,
  ShiftsFetching,
  UpdateShift,
  DeleteShift,
  ResetShiftsReducer,
  FollowShift,
  UnfollowShift,
} from './actions'
import { getUpdatedFollowShifts, getUpdatedUnfollowShifts } from './utils'

const initialState: IShiftsReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: [],
}

export const shiftsReducer = (state = initialState, action: Action): IShiftsReducer => {
  if (isType(action, GetShifts)) {
    const { payload } = action
    const currentShiftIds = state.data.map((shift) => shift.id)
    const filteredShifts = payload.data.filter((element) => !currentShiftIds.includes(element.id))
    return {
      ...state,
      meta: payload.meta,
      data: [...state.data, ...filteredShifts],
    }
  }
  if (isType(action, UpdateShift)) {
    const { payload } = action
    const { data } = state
    const formattedArray = updateArray<IShift>(data, payload.data)
    return {
      ...state,
      data: formattedArray,
    }
  }
  if (isType(action, DeleteShift)) {
    const { payload } = action
    const { data } = state
    const formattedArray = deleteFromArray<IShift>(data, payload)
    return {
      ...state,
      data: formattedArray,
      meta: {
        ...state.meta,
        pagination: {
          ...state.meta.pagination,
          count: state.meta.pagination ? state.meta.pagination.count - 1 : 0,
          total: state.meta.pagination ? state.meta.pagination.total - 1 : 0,
          per_page: state.meta.pagination?.per_page || 50,
          current_page: state.meta.pagination?.current_page || 1,
          total_pages: state.meta.pagination?.total_pages || 1,
          links: state.meta.pagination?.links || [],
        },
      },
    }
  }
  if (isType(action, FollowShift)) {
    const { payload } = action
    return {
      ...state,
      data: getUpdatedFollowShifts(payload, state.data),
    }
  }
  if (isType(action, UnfollowShift)) {
    const { payload } = action
    return {
      ...state,
      data: getUpdatedUnfollowShifts(payload, state.data),
    }
  }
  if (isType(action, ShiftsFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }
  if (isType(action, ResetShiftsReducer)) {
    return initialState
  }

  return state
}
