import { IBaseGetParams } from '../Global'
import { IBaseReducer } from '../BaseReducer'

export enum FOLLOW_STATUS {
  UNFOLLOW,
  FOLLOW,
}

export enum FOLLOW_TYPE {
  SHIFT,
  LOCATION,
  BUSINESS,
}

export enum FOLLOW_TYPE_MODEL {
  SHIFT = 'gig_id',
  LOCATION = 'property_id',
  BUSINESS = 'business_id',
}

export interface IFollow {
  id: number
  model_id: number
  followed_by_id: number
  status: FOLLOW_STATUS
  model_type: FOLLOW_TYPE
}

export interface IGetFollowsParams extends IBaseGetParams {
  status?: FOLLOW_STATUS
  followed_by_id?: number
}

export interface IPostFollowParams {
  gig_id?: number
  property_id?: number
  business_id?: number
  status: FOLLOW_STATUS
}

export interface IPutFollowParams {
  gig_id?: number
  property_id?: number
  business_id?: number
  status: FOLLOW_STATUS
}

export interface IFollowReducer extends IBaseReducer<IFollow[]> {}
