import { action, payload } from 'ts-action'
import { IError, IPayload } from 'models/Global'
import { IUserCertificate } from 'models/UserCertificate'

export const GetCertificationRequests = action(
  'GetCertificationRequests',
  payload<IPayload<IUserCertificate[]>>()
)

export const GetCertificationRequestsErrors = action(
  'GetCertificationRequestsErrors',
  payload<IError>()
)

export const CertificationRequestsFetching = action(
  'CertificationRequestsFetching',
  payload<boolean>()
)

export const UpdateUserCertificate = action(
  'UpdateUserCertificate',
  payload<IPayload<IUserCertificate>>()
)
