import { ISelectedShiftReducer, IShift } from 'models/Shift'
import { Action } from 'redux'
import { isType } from 'ts-action'
import {
  GetSelectedShift,
  UpdateSelectedShift,
  DeleteSelectedShift,
  SelectedShiftFetching,
  ResetSelectedShift,
  GetSelectedShiftErrors,
} from './actions'
import { IError } from 'models/Global'

const initialState: ISelectedShiftReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  error: {} as IError,
  data: {} as IShift,
}

export const selectedShiftReducer = (
  state = initialState,
  action: Action
): ISelectedShiftReducer => {
  if (isType(action, GetSelectedShift)) {
    const {
      payload: { data, meta },
    } = action

    return {
      ...state,
      meta,
      data,
      error: {} as IError,
    }
  }

  if (isType(action, UpdateSelectedShift)) {
    const {
      payload: { data, meta },
    } = action

    return {
      ...state,
      meta,
      data,
      error: {} as IError,
    }
  }

  if (isType(action, DeleteSelectedShift)) {
    return initialState
  }

  if (isType(action, SelectedShiftFetching)) {
    const { payload: isFetching } = action

    return {
      ...state,
      isFetching,
    }
  }

  if (isType(action, ResetSelectedShift)) {
    return initialState
  }

  if (isType(action, GetSelectedShiftErrors)) {
    const {
      payload: { code, message },
    } = action

    return {
      ...state,
      error: {
        code,
        message,
      },
    }
  }

  return state
}
