import { IBaseReducer } from 'models/BaseReducer'
import { IChallenge } from 'models/Challenge'
import { TypeOrNull } from 'models/Common'
import { IBaseGetParams, IPayload } from 'models/Global'

export const ACHIEVEMENT_DESCRIPTION_MAX_LENGTH = 140
export const ACHIEVEMENT_TITLE_MAX_LENGTH = 40
export const ACHIEVEMENT_IMAGE_ACCEPTED_FILES = ['image/png', 'image/jpg', 'image/jpeg']
export const FIRST_LEVEL_ORDER = 1

export enum LEVEL_TYPE {
  SINGLE = 'single',
  MULTI = 'multi',
}

export enum ACHIEVEMENT_STATUS {
  DISABLED,
  ONGOING,
  EXPIRED,
  DRAFT,
}

export interface IAchievement {
  id: number
  level_type: LEVEL_TYPE
  title: string // max 40
  challenge_ids: TypeOrNull<number[]> // null for multilevel
  total_points: number
  description: TypeOrNull<string> // max 140 // null for multilevel
  active: boolean
  image: TypeOrNull<string | File> // null for multilevel
  order: TypeOrNull<number> // null for multilevel
  include_historic_data: boolean
  challenges?: IPayload<IChallenge[]>
  levels?: IPayload<IAchievement[]>
  is_certificate?: boolean
  status: ACHIEVEMENT_STATUS
}

export interface IAchievementIncludeParams {
  include?:
    | {
        levels?: Record<any, any>
        challenges?: Record<any, any>
      }
    | ('levels' | 'challenges' | 'levels.challenges')[]
}

export interface IGetAchievementsParamsBase extends IBaseGetParams {}

export type IGetAchievementsParams = IGetAchievementsParamsBase & IAchievementIncludeParams

export interface IPostAchievementParamsBase {
  level_type: IAchievement['level_type']
  title: IAchievement['title']
  challenge_ids?: IAchievement['challenge_ids']
  total_points: IAchievement['total_points']
  description?: IAchievement['description']
  active: IAchievement['active']
  image?: IAchievement['image']
  order?: IAchievement['order']
  skip_multi_level_validation?: boolean
  include_historic_data?: IAchievement['include_historic_data']
}

export type IPostAchievementParams = IPostAchievementParamsBase & IAchievementIncludeParams

export interface IPutAchievementParamsBase {
  level_type?: IAchievement['level_type']
  title?: IAchievement['title']
  challenge?: IAchievement['challenge_ids']
  total_points?: IAchievement['total_points']
  description?: IAchievement['description']
  active?: IAchievement['active']
  image?: IAchievement['image']
  order?: IAchievement['order']
  include_historic_data?: IAchievement['include_historic_data']
}

export type IPutAchievementParams = IPutAchievementParamsBase & IAchievementIncludeParams

export interface IAchievementReducer extends IBaseReducer<IAchievement> {}

export interface IAchievementsReducer extends IBaseReducer<IAchievement[]> {}
