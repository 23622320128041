import styled from 'styled-components'

export const StyledMenu = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme: { palette } }) => palette.white};
`

export const ContentElement = styled.div`
  background: ${({ theme: { palette } }) => palette.white};
  flex: 15;
  backface-visibility: hidden;
  overflow: auto;
`

export const MobileMenuWrapper = styled.div`
  overflow: auto;
  height: calc(100vh - 120px);
  padding-top: ${({ theme: { mp } }) => mp.sm};
`

export const MenuHeader = styled.div`
  padding: ${({ theme: { mp } }) => mp.sm};
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${({ theme: { palette } }) => palette.light};
`

export const MenuHeaderLeft = styled.div``

export const MenuHeaderRight = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${(props) => props.theme.font.sizes.large};
`
