import { action, payload } from 'ts-action'
import { IPropertyCategory } from 'models/PropertyCategory'
import { IError, IPayload } from 'models/Global'

export const PropertyCategoriesFetching = action('PropertyCategoriesFetching', payload<boolean>())

export const GetPropertyCategories = action(
  'GetPropertyCategories',
  payload<IPayload<IPropertyCategory[]>>()
)

export const AddPropertyCategory = action(
  'AddPropertyCategory',
  payload<IPayload<IPropertyCategory>>()
)

export const UpdatePropertyCategory = action(
  'UpdatePropertyCategory',
  payload<IPayload<IPropertyCategory>>()
)

export const DeletePropertyCategory = action('DeletePropertyCategory', payload<number>())

export const GetPropertyCategoriesErrors = action('GetPropertyCategoriesErrors', payload<IError>())

export const UpdatePropertyCategoryLoadingIds = action(
  'UpdatePropertyCategoryLoadingIds',
  payload<number>()
)
