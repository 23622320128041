import { Action } from 'redux'
import { isType } from 'ts-action'
import {
  GetUserSickHourStats,
  GetUserSickHourStatsErrors,
  UserSickHourStatsFetching,
  ClearUserSickHourStats,
} from './actions'
import { IUserSickPayDayHoursReducer } from 'models/User'

const initialState: IUserSickPayDayHoursReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: [],
}

export const userSickHourStatsReducer = (
  state = initialState,
  action: Action
): IUserSickPayDayHoursReducer => {
  if (isType(action, GetUserSickHourStats)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: [...payload.data.cities, ...payload.data.counties, ...payload.data.states],
    }
  }
  if (isType(action, UserSickHourStatsFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }
  if (isType(action, GetUserSickHourStatsErrors)) {
    const { payload } = action
    return {
      ...state,
      error: {
        code: payload.code,
        message: payload.message,
      },
    }
  }
  if (isType(action, ClearUserSickHourStats)) {
    return {
      meta: {
        code: 0,
        message: '',
      },
      isFetching: false,
      data: initialState.data,
    }
  }
  return state
}
