import { ICitiesReducer, ICity } from 'models/City'
import { Action } from 'redux'
import { isType } from 'ts-action'
import { updateArray } from 'helpers/helperFunctions'
import { GetCities, CitiesFetching, UpdateCity } from './actions'

const initialState: ICitiesReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: [],
}

export const citiesReducer = (state = initialState, action: Action): ICitiesReducer => {
  if (isType(action, GetCities)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
    }
  }
  if (isType(action, UpdateCity)) {
    const { payload } = action
    const { data } = state
    const formattedArray = updateArray<ICity>(data, payload.data)
    return {
      ...state,
      data: formattedArray,
    }
  }
  if (isType(action, CitiesFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }
  return state
}
