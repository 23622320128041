import { Action } from 'redux'
import { isType } from 'ts-action'
import { GetInvoices, InvoicesError, InvoicesFetching, ResetInvoicesReducer } from './actions'
import { IInvoicesReducer } from 'models/Invoice'

const initialState: IInvoicesReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: [],
}

export const invoicesReducer = (state = initialState, action: Action): IInvoicesReducer => {
  if (isType(action, GetInvoices)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
    }
  }
  if (isType(action, InvoicesError)) {
    const { payload } = action
    return {
      ...state,
      error: {
        code: payload.code,
        message: payload.message,
      },
    }
  }
  if (isType(action, InvoicesFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }
  if (isType(action, ResetInvoicesReducer)) {
    return initialState
  }
  return state
}
