import { ITimetrackersReducer, ITimetracker } from 'models/Timetracker'
import { isType } from 'ts-action'
import { Action } from 'redux'
import {
  GetTimetrackers,
  TimetrackersFetching,
  GetTimetrackersErrors,
  ClearTimetrackers,
  DeleteTimetracker,
  UpdateTimetracker,
} from './actions'
import { deleteFromArray, updateArray } from 'helpers/helperFunctions'

const initialState: ITimetrackersReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: [],
}

export const timetrackersReducer = (state = initialState, action: Action): ITimetrackersReducer => {
  if (isType(action, GetTimetrackers)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
    }
  }

  if (isType(action, GetTimetrackersErrors)) {
    const { payload } = action
    return {
      ...state,
      error: {
        code: payload.code,
        message: payload.message,
      },
    }
  }

  if (isType(action, DeleteTimetracker)) {
    const { payload } = action
    const { data } = state
    const formattedArray = deleteFromArray<ITimetracker>(data, payload)
    return {
      ...state,
      data: formattedArray,
    }
  }

  if (isType(action, UpdateTimetracker)) {
    const { payload: timetracker } = action
    const { data } = state
    const existingTimetracker = data.find((item) => item.id === timetracker.id)

    const finalTimetracker = {
      ...existingTimetracker,
      ...timetracker,
    }

    const updatedArray = updateArray<ITimetracker>(data, finalTimetracker)

    return {
      ...state,
      data: updatedArray,
    }
  }

  if (isType(action, ClearTimetrackers)) {
    return {
      meta: {
        code: 0,
        message: '',
      },
      isFetching: false,
      data: [],
    }
  }

  if (isType(action, TimetrackersFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }

  return state
}
