/* eslint-disable no-console */

import { FEATURE_TOGGLE_SEGMENT } from 'config/featureToggles'
import { isProduction, trackingEnvName } from 'helpers/helperFunctions'

interface IIdentify {
  userId: string | number
  traits?: { [key: string]: any }
  options?: { [key: string]: string | number }
  callback?: void
}
interface ITrack {
  event: string
  properties?: { [key: string]: any }
  options?: { [key: string]: any }
  callback?: void
}
interface IPage {
  category?: string
  name?: string
  properties?: { [key: string]: any }
  options?: { [key: string]: any }
  callback?: void
}

interface ITrackLink {
  element: string[]
  event: string | void
  properties?: { [key: string]: any }
}

interface IGroup {
  groupId: string
  traits?: { [key: string]: any }
  options?: { [key: string]: any }
  callback?: void
}

export enum DOMAIN {
  MOBILE = 'mobile web',
  WEB = 'web',
}

export enum LOGIN_METHOD {
  PASSWORD_LOGIN = 'Password Login',
  MAGIC_LINK = 'Magic Link',
}

export interface ITrackingUserValues {
  id: number
  email: string
  region: string
}

class TrackingService {
  /**
   * Use the identify method to link your users and their actions, to a recognizable userId and traits.
   */
  static identify({ userId, callback, options, traits }: IIdentify) {
    // GroupId field on BND is mapped this way
    if (!isProduction()) {
      userId = `${trackingEnvName}-${userId}`
    }

    if (FEATURE_TOGGLE_SEGMENT) {
      window.analytics.identify(userId, traits, options, callback)
    } else {
      console.log({ userId, traits, options, callback })
    }
    return
  }
  /**
   * The Track method lets you record actions your users perform.
   */
  static track({ event, properties, callback, options }: ITrack) {
    if (FEATURE_TOGGLE_SEGMENT) {
      window.analytics.track(event, properties, options, callback)
    } else {
      console.log({ event, properties, callback, options })
    }
    return
  }
  /**
   * The Page method lets you record page views on your website, along with optional extra information about the page viewed by the user.
   */
  static page({ category, properties, name, callback, options }: IPage) {
    if (FEATURE_TOGGLE_SEGMENT) {
      window.analytics.page(category, name, properties, options, callback)
    } else {
      console.log({ category, name, properties, options, callback })
    }
    return
  }
  /**
   * The trackLink is a helper method that attaches the track call as a handler to a link. With trackLink, Analytics.js inserts a short timeout (300 ms) to give the track call more time.
   * This is useful when a page would redirect before the track method could complete all requests.
   */
  static trackLink({ element, event, properties }: ITrackLink) {
    if (FEATURE_TOGGLE_SEGMENT) {
      window.analytics.trackLink(element, event, properties)
    } else {
      console.log({ element, event, properties })
    }
    return
  }

  /**
   *
   * The Group method associates an identified user with a company, organization, project, workspace, team, tribe, platoon, assemblage, cluster, troop, gang, party, society or any other collective noun you come up with for the same concept.
   */
  static group({ groupId, options, traits, callback }: IGroup) {
    // GroupId field on BND is mapped this way
    if (!isProduction()) {
      groupId = `${trackingEnvName}-${groupId}`
    }

    if (FEATURE_TOGGLE_SEGMENT) {
      window.analytics.group(groupId as unknown as string[], traits, options, callback)
    } else {
      console.log({ groupId, traits, options, callback })
    }
    return
  }
  /**
   * Calling reset resets the id, including anonymousId, and clears traits for the currently identified user and group.
   */
  static reset() {
    window.analytics.reset()
  }
}
export { TrackingService }
