import { pick } from 'lodash'
import {
  FOLLOW_TYPE,
  FOLLOW_TYPE_MODEL,
  IFollow,
  IPostFollowParams,
  IPutFollowParams,
} from 'models/Follow'
import { SHIFT_FOLLOW_TYPE_MODEL } from 'models/Loading'
import { IShift, SHIFT_TYPE } from 'models/Shift'

/**
 * The message that is shown in the success notification when the user duplicates(creates) a shift
 */

const createdShiftMessage = ({
  id,
  fancy_time: fancyTime,
  hour_start: hourStart,
  hour_end: hourEnd,
  type,
}: IShift) => (
  <div>
    <div>
      <span style={{ fontWeight: 'bold' }}>ID</span>: {id}
    </div>
    <div>
      <span style={{ fontWeight: 'bold' }}>Type</span>:{' '}
      {Number(type) === SHIFT_TYPE.MULTIDAY ? 'Multiday' : 'Single'}
    </div>
    <div>
      <span style={{ fontWeight: 'bold' }}>Date</span>:{' '}
      {Number(type) === SHIFT_TYPE.MULTIDAY
        ? `${fancyTime}`
        : `${fancyTime} • ${hourStart} - ${hourEnd}`}
    </div>
  </div>
)

export const getKeyByModelType = (type: FOLLOW_TYPE) => {
  switch (type) {
    case FOLLOW_TYPE.SHIFT:
      return SHIFT_FOLLOW_TYPE_MODEL.SHIFT
    case FOLLOW_TYPE.LOCATION:
      return SHIFT_FOLLOW_TYPE_MODEL.LOCATION
    case FOLLOW_TYPE.BUSINESS:
      return SHIFT_FOLLOW_TYPE_MODEL.BUSINESS
    default:
      return SHIFT_FOLLOW_TYPE_MODEL.SHIFT
  }
}

export const getShiftFollowModelTypeFromParams = (params: IPostFollowParams | IPutFollowParams) => {
  const modelTypeAndId = pick(params, [
    FOLLOW_TYPE_MODEL.SHIFT,
    FOLLOW_TYPE_MODEL.LOCATION,
    FOLLOW_TYPE_MODEL.BUSINESS,
  ])
  const [modelType, modelId] = Object.entries(modelTypeAndId)[0]
  if (modelType === FOLLOW_TYPE_MODEL.SHIFT)
    return {
      modelType: SHIFT_FOLLOW_TYPE_MODEL.SHIFT,
      modelId,
    }
  return {
    modelType,
    modelId,
  }
}

/**
 * Function that updates all of the shifts follow status depending on the passed Follow object
 * @param follow Follow object returned from the API POST request
 * @param shifts Shifts array with included "follow"
 * @returns Updated shifts array
 */
export const getUpdatedFollowShifts = (follow: IFollow, shifts: IShift[]) => {
  const modelKey = getKeyByModelType(follow.model_type)
  const updatedShifts = shifts.map((shift) => {
    if (shift[modelKey] === follow.model_id) {
      return {
        ...shift,
        followed_by_user: follow,
      }
    }
    return shift
  })
  return updatedShifts
}

/**
 * Function that updates all of the shifts follow status depending on the passed Follow object
 * @param follow Follow object returned from the API PUT request
 * @param shifts Shifts array with included "follow"
 * @returns Updated shifts array
 */
export const getUpdatedUnfollowShifts = (follow: IFollow, shifts: IShift[]) => {
  const modelKey = getKeyByModelType(follow.model_type)
  const updatedShifts = shifts.map((shift) => {
    if (shift[modelKey] === follow.model_id) {
      return {
        ...shift,
        followed_by_user: null,
      }
    }
    return shift
  })
  return updatedShifts
}

export default createdShiftMessage
