import { IBusinessEntityReducer, IBusinessEntity } from 'models/BusinessEntity'
import { Action } from 'redux'
import {
  GetBusinessEntityAction,
  BusinessEntityFetching,
  GetBusinessEntityErrors,
  UpdateBusinessEntity,
  UpdateBusinessEntityFollowedByUser,
  ResetBusinessEntity,
  UpdateBusinessEntitySetting,
} from './actions'
import { isType } from 'ts-action'
import { FOLLOW_STATUS } from 'models/Follow'

const initialState: IBusinessEntityReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: {} as IBusinessEntity,
}

export const businessEntityReducer = (
  state = initialState,
  action: Action
): IBusinessEntityReducer => {
  if (isType(action, GetBusinessEntityAction)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
      error: undefined,
    }
  }
  if (isType(action, UpdateBusinessEntity)) {
    const { payload } = action
    return {
      ...state,
      data: payload.data,
    }
  }
  if (isType(action, UpdateBusinessEntityFollowedByUser)) {
    const {
      payload: { data },
    } = action

    return {
      ...state,
      data: {
        ...state.data,
        followed_by_user: data.status === FOLLOW_STATUS.FOLLOW ? data : null,
      },
    }
  }
  if (isType(action, GetBusinessEntityErrors)) {
    const { payload } = action
    return {
      ...state,
      error: {
        code: payload.code,
        message: payload.message,
      },
    }
  }
  if (isType(action, BusinessEntityFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }
  if (isType(action, ResetBusinessEntity)) {
    return initialState
  }
  if (isType(action, UpdateBusinessEntitySetting)) {
    const { payload } = action
    const { data } = state
    return {
      ...state,
      data: { ...data, business_setting: payload },
    }
  }
  return state
}
