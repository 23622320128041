import { AxiosService } from '../config/config'
import { Endpoints } from '../config/endpoints'
import { IPayload } from 'models/Global'
import {
  IBackgroundCheck,
  IGetBackgroundChecksParams,
  IPutBackgroundCheckParams,
  IPostBackgroundCheckParams,
} from 'models/BackgroundCheck'

export class BackgroundChecksApi {
  constructor(private axios: AxiosService) {}

  getBackgroundChecks = async (params?: IGetBackgroundChecksParams) => {
    const response = await this.axios.get<IPayload<IBackgroundCheck[]>>(
      Endpoints.getBackgroundChecks,
      params
    )
    return response
  }

  getBackgroundCheck = async (id: number, params?: IGetBackgroundChecksParams) => {
    const response = await this.axios.get<IPayload<IBackgroundCheck>>(
      Endpoints.getBackgroundCheck(id),
      params
    )
    return response
  }

  postBackgroundCheck = async (params: IPostBackgroundCheckParams) => {
    const response = await this.axios.post<IPayload<IBackgroundCheck>>(
      Endpoints.postBackgroundCheck,
      params
    )
    return response
  }

  putBackgroundCheck = async (id: number, params?: IPutBackgroundCheckParams) => {
    const response = await this.axios.put<IPayload<IBackgroundCheck>>(
      Endpoints.putBackgroundCheck(id),
      params
    )
    return response
  }

  deleteBackgroundCheck = async (id: number) => {
    const response = await this.axios.delete<{}>(Endpoints.deleteBackgroundCheck(id))
    return response
  }
}
