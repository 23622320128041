import { action, payload } from 'ts-action'
import { IError, IPayload } from 'models/Global'
import { IShift } from 'models/Shift'

export const GetFeaturedShiftCampaignShifts = action(
  'GetFeaturedShiftCampaignShifts',
  payload<IPayload<IShift[]>>()
)

export const AddFeaturedShiftCampaignShift = action(
  'AddFeaturedShiftCampaignShift',
  payload<IShift>()
)

export const DeleteFeaturedShiftCampaignShift = action(
  'DeleteFeaturedShiftCampaignShift',
  payload<number>()
)

export const FakeDeleteFeaturedShiftCampaignShifts = action('FakeDeleteFeaturedShiftCampaignShifts')

export const MarkFetchedFeaturedShiftCampaignShifts = action(
  'MarkFetchedFeaturedShiftCampaignShifts'
)

export const UpdateFeaturedShiftCampaignShift = action(
  'UpdateFeaturedShiftCampaignShift',
  payload<IPayload<IShift>>()
)

export const GetFeaturedShiftCampaignShiftsErrors = action(
  'GetFeaturedShiftCampaignShiftsErrors',
  payload<IError>()
)

export const FeaturedShiftCampaignShiftsFetching = action(
  'FeaturedShiftCampaignShiftsFetching',
  payload<boolean>()
)

export const ResetFeaturedShiftCampaignShifts = action('ResetFeaturedShiftCampaignShifts')
