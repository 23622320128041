import { IUpshifterExperienceReducer } from 'models/UpshifterExperience'
import { Action } from 'redux'
import { isType } from 'ts-action'
import {
  UpshifterExperiencesFetching,
  GetUpshifterExperiences,
  GetUpshifterExperiencesErrors,
} from './actions'

const initialState: IUpshifterExperienceReducer = {
  meta: {
    message: '',
    code: 0,
  },
  isFetching: false,
  data: [],
}

export const upshifterExperiencesReducer = (
  state = initialState,
  action: Action
): IUpshifterExperienceReducer => {
  if (isType(action, GetUpshifterExperiences)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
    }
  }

  if (isType(action, UpshifterExperiencesFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }
  if (isType(action, GetUpshifterExperiencesErrors)) {
    const { payload } = action
    return {
      ...state,
      error: {
        code: payload.code,
        message: payload.message,
      },
    }
  }

  return state
}
