import React from 'react'
import { Helmet } from 'react-helmet'
import { useTitle } from './utils'

export const HelmetTitle = () => {
  const title = useTitle()
  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  )
}
