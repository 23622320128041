import { AxiosService } from '../config/config'
import { Endpoints } from '../config/endpoints'
import { IPayload } from 'models/Global'
import {
  IScreening,
  IGetScreeningsParams,
  IPutScreeningParams,
  IPostScreeningParams,
} from 'models/Screening'

export class ScreeningsApi {
  constructor(private axios: AxiosService) {}

  getScreenings = async (params?: IGetScreeningsParams) => {
    const response = await this.axios.get<IPayload<IScreening[]>>(Endpoints.getScreenings, params)
    return response
  }

  getScreening = async (id: number, params?: IGetScreeningsParams) => {
    const response = await this.axios.get<IPayload<IScreening>>(Endpoints.getScreening(id), params)
    return response
  }

  postScreening = async (params: IPostScreeningParams) => {
    const response = await this.axios.post<IPayload<IScreening>>(Endpoints.postScreening, params)
    return response
  }

  putScreening = async (id: number, params?: IPutScreeningParams) => {
    const response = await this.axios.put<IPayload<IScreening>>(Endpoints.putScreening(id), params)
    return response
  }

  deleteScreening = async (id: number) => {
    const response = await this.axios.delete<{}>(Endpoints.deleteScreening(id))
    return response
  }
}
