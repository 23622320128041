import { AxiosService } from '../config/config'
import { Endpoints } from '../config/endpoints'
import { IPayload } from 'models/Global'
import { IFollow, IGetFollowsParams, IPutFollowParams, IPostFollowParams } from 'models/Follow'

export class FollowsApi {
  constructor(private axios: AxiosService) {}

  getFollows = async (params?: IGetFollowsParams) => {
    const response = await this.axios.get<IPayload<IFollow[]>>(Endpoints.getFollows, params)
    return response
  }

  getFollow = async (id: number, params?: IGetFollowsParams) => {
    const response = await this.axios.get<IPayload<IFollow>>(Endpoints.getFollow(id), params)
    return response
  }

  postFollow = async (params: IPostFollowParams) => {
    const response = await this.axios.post<IPayload<IFollow>>(Endpoints.postFollow, params)
    return response
  }

  putFollow = async (id: number, params?: IPutFollowParams) => {
    const response = await this.axios.put<IPayload<IFollow>>(Endpoints.putFollow(id), params)
    return response
  }

  deleteFollow = async (id: number) => {
    const response = await this.axios.delete<{}>(Endpoints.deleteFollow(id))
    return response
  }
}
