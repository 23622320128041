import { faChevronDown, faChevronUp } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { TimePickerPopup, TimePickerValues, TimePickerListValue, TimePickerArrow } from './style'
import { TTimePickerValueObject } from './TimePicker'
import { handleHourDown, handleHourUp, handleMinuteDown, handleMinuteUp } from './utils'
import { Button } from '@pinchenterprisesnpm/friday-ui'

type TTimePickerPopupProps = {
  value: TTimePickerValueObject
  changeValue: (value: string, type: string) => void
  togglePeriod: () => void
  changeValues: (values: Partial<TTimePickerValueObject>) => void
  modal?: boolean
}

const Popup = (props: TTimePickerPopupProps) => {
  const { value, changeValues, togglePeriod, modal } = props

  const { hour, minute, period } = value

  return (
    <TimePickerPopup className='timePickerTrigger timePickerPopup' modal={modal}>
      <TimePickerValues className='timePickerTrigger'>
        <TimePickerArrow
          className='timePickerTrigger'
          onClick={(e) => {
            const values = handleHourUp(value)
            changeValues(values)
          }}
          type='button'
          modal={modal}
        >
          <FontAwesomeIcon icon={faChevronUp} className='timePickerTrigger' />
        </TimePickerArrow>
        <TimePickerListValue type='button' className='timePickerTrigger' modal={modal}>
          {hour}
        </TimePickerListValue>
        <TimePickerArrow
          className='timePickerTrigger'
          onClick={(e) => {
            const values = handleHourDown(value)

            changeValues(values)
          }}
          type='button'
          modal={modal}
        >
          <FontAwesomeIcon icon={faChevronDown} className='timePickerTrigger' />
        </TimePickerArrow>
      </TimePickerValues>
      <TimePickerValues className='timePickerTrigger'>
        <TimePickerArrow
          className='timePickerTrigger'
          onClick={(e) => {
            const values = handleMinuteUp(value)
            changeValues(values)
          }}
          type='button'
          modal={modal}
        >
          <FontAwesomeIcon icon={faChevronUp} className='timePickerTrigger' />
        </TimePickerArrow>
        <TimePickerListValue type='button' className='timePickerTrigger' modal={modal}>
          {minute}
        </TimePickerListValue>
        <TimePickerArrow
          className='timePickerTrigger'
          onClick={(e) => {
            const values = handleMinuteDown(value)

            changeValues(values)
          }}
          type='button'
          modal={modal}
        >
          <FontAwesomeIcon icon={faChevronDown} className='timePickerTrigger' />
        </TimePickerArrow>
      </TimePickerValues>
      <TimePickerValues className='timePickerTrigger'>
        <TimePickerArrow
          type='button'
          className='timePickerTrigger'
          onClick={togglePeriod}
          modal={modal}
        >
          <FontAwesomeIcon icon={faChevronUp} className='timePickerTrigger' />
        </TimePickerArrow>
        <TimePickerListValue type='button' className='timePickerTrigger' modal={modal}>
          {period}
        </TimePickerListValue>
        <TimePickerArrow
          type='button'
          className='timePickerTrigger'
          onClick={togglePeriod}
          modal={modal}
        >
          <FontAwesomeIcon icon={faChevronDown} className='timePickerTrigger' />
        </TimePickerArrow>
      </TimePickerValues>
    </TimePickerPopup>
  )
}

export const PopupFooter = ({ closeModal }: { closeModal?: () => void }) => {
  return (
    <Button type='success' isBlock onClick={closeModal} shape='rectangle'>
      OK
    </Button>
  )
}
export default Popup
