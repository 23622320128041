import {
  IVendorManagementSystem,
  IVendorManagementSystemsReducer,
} from '../../models/VendorManagementSystem'
import { Action } from 'redux'
import { isType } from 'ts-action'
import { updateArray } from '../../helpers/helperFunctions'
import {
  GetVendorManagementSystems,
  VendorManagementSystemsFetching,
  UpdateVendorManagementSystem,
} from './actions'

const initialState: IVendorManagementSystemsReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: [],
}

export const vendorManagementSystemsReducer = (
  state = initialState,
  action: Action
): IVendorManagementSystemsReducer => {
  if (isType(action, GetVendorManagementSystems)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
    }
  }
  if (isType(action, UpdateVendorManagementSystem)) {
    const { payload } = action
    const { data } = state
    const formattedArray = updateArray<IVendorManagementSystem>(data, payload.data)
    return {
      ...state,
      data: formattedArray,
    }
  }

  if (isType(action, VendorManagementSystemsFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }
  return state
}
