import { AxiosService } from '../config/config'
import { Endpoints } from '../config/endpoints'
import { IPayload } from 'models/Global'
import {
  TNotificationType,
  TGetNotificationsParams,
  TInferredNotificationCodeType,
  TPostNotificationParams,
  TPutNotificationParams,
} from 'models/Notification'

export class NotificationsApi {
  constructor(private axios: AxiosService) {}

  getNotifications = async <T extends TGetNotificationsParams>(params?: T) => {
    const response = await this.axios.get<
      IPayload<TNotificationType<TInferredNotificationCodeType<T>>[]>
    >(Endpoints.getNotifications, params)
    return response
  }

  getNotification = async <T extends TGetNotificationsParams>(id: number, params?: T) => {
    const response = await this.axios.get<
      IPayload<TNotificationType<TInferredNotificationCodeType<T>>>
    >(Endpoints.getNotification(id), params)
    return response
  }

  postNotification = async <T extends TPostNotificationParams>(params: T) => {
    const response = await this.axios.post<
      IPayload<TNotificationType<TInferredNotificationCodeType<T>>>
    >(Endpoints.postNotification, params)
    return response
  }

  putNotification = async <T extends TPutNotificationParams>(id: number, params?: T) => {
    const response = await this.axios.put<
      IPayload<TNotificationType<TInferredNotificationCodeType<T>>>
    >(Endpoints.putNotification(id), params)
    return response
  }

  deleteNotification = async (id: number) => {
    const response = await this.axios.delete<{}>(Endpoints.deleteNotification(id))
    return response
  }
}
