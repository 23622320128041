import { action, payload } from 'ts-action'
import { IError, IPayload } from 'models/Global'
import { IShift } from 'models/Shift'

export const GetFeaturedShiftCampaignCreateSelectedShifts = action(
  'GetFeaturedShiftCampaignCreateSelectedShifts',
  payload<IPayload<(IShift & { selected: boolean })[]>>()
)

export const GetFeaturedShiftCampaignCreateSelectedShift = action(
  'GetFeaturedShiftCampaignCreateSelectedShift',
  payload<IPayload<IShift>>()
)

export const AddFeaturedShiftCampaignCreateSelectedShift = action(
  'AddFeaturedShiftCampaignCreateSelectedShift',
  payload<IShift>()
)

export const AddFeaturedShiftCampaignCreateAllSelectedShifts = action(
  'AddFeaturedShiftCampaignCreateAllSelectedShifts',
  payload<IShift[]>()
)

export const DeleteFeaturedShiftCampaignCreateSelectedShift = action(
  'DeleteFeaturedShiftCampaignCreateSelectedShift',
  payload<number>()
)

export const DeleteFeaturedShiftCampaignCreateSelectedShifts = action(
  'DeleteFeaturedShiftCampaignCreateSelectedShifts'
)

export const FakeDeleteFeaturedShiftCampaignCreateSelectedShift = action(
  'FakeDeleteFeaturedShiftCampaignCreateSelectedShift',
  payload<number>()
)

export const FakeDeleteFeaturedShiftCampaignCreateSelectedShifts = action(
  'FakeDeleteFeaturedShiftCampaignCreateSelectedShifts'
)

export const UpdateFeaturedShiftCampaignCreateSelectedShift = action(
  'UpdateFeaturedShiftCampaignCreateSelectedShift',
  payload<IPayload<IShift>>()
)

export const GetFeaturedShiftCampaignCreateSelectedShiftsErrors = action(
  'GetFeaturedShiftCampaignCreateSelectedShiftsErrors',
  payload<IError>()
)

export const FeaturedShiftCampaignCreateSelectedShiftsFetching = action(
  'FeaturedShiftCampaignCreateSelectedShiftsFetching',
  payload<boolean>()
)
