import styled from 'styled-components'
import { Colors, Form, Button } from '@pinchenterprisesnpm/friday-ui'
import { SelectGroup, GroupOption } from 'ui/components/common/SelectGroup'
import { Col, Row } from 'styled-bootstrap-grid'

const { Input } = Form

export const SelectGroupOverride = styled(SelectGroup)`
  border: 1px solid ${(props) => props.theme.palette.border};
`
export const GroupOptionOverride = styled(GroupOption)`
  border-right: 1px solid ${(props) => props.theme.palette.border};
  height: 100%;

  &:last-child {
    border-right: none;
  }
`
export const HolderCellWrapper = styled.div`
  margin: 0 -20px;
`

export const ContentWrapper = styled.div`
  padding: ${(props) => `${props.theme.mp.md}`};
  padding-top: ${(props) => `${props.theme.mp.xs}`};
  font-weight: ${(props) => props.theme.font.weights.regular};
`

export const ContentHead = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.palette.light};
  margin: 0 -20px;

  /* padding: 15px 0 5px 0; */
  padding: ${(props) => `15px ${props.theme.mp.md} ${props.theme.mp.xs} ${props.theme.mp.md}`};
`

export const SelectionDescription = styled.p`
  color: ${(props) => props.theme.palette.dark};
  font-size: ${(props) => props.theme.font.sizes.small};
  font-weight: ${(props) => props.theme.font.weights.light};
  width: 100%;
  text-align: left;
  line-height: 1.1;
`

export const SelectOption = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: ${(props) => props.theme.mp.sm};
`

export const ContentBody = styled.div`
  text-align: left;
  & > a {
    padding: ${(props) => `${props.theme.mp.sm} 0px`};
  }
`

export const FormLabel = styled.label<any>`
  color: ${(props) => props.color || props.theme.palette.dark};
  text-align: ${(props) => props.align || 'left'};
  padding: ${(props) => `${props.theme.mp.sm} 0px`};
  font-weight: ${(props) => props.theme.font.weights.regular};
  display: block;

  @media only screen and (min-width: ${(props) => `${props.theme.breakpoints.md}px`}) {
    text-align: left;
  }
`

export const FieldContent = styled.div<any>`
  display: flex;
  justify-content: ${(props) => (props.align === 'left' ? 'flex-start' : 'flex-end')};
  align-items: center;

  //  All components need to change in upshift-ui in order to avoid this overriding
  & button {
    margin: 0;
  }

  @media only screen and (min-width: ${(props) => `${props.theme.breakpoints.md}px`}) {
    justify-content: flex-start;
  }
`

export const TimePickerStyle = styled.div<any>`
  width: 100%;
  height: 36px;
  & .react-time-picker__wrapper,
  & .react-time-picker {
    height: 100%;
    width: 100%;
  }
  & .react-time-picker__wrapper,
  & .react-time-picker {
    width: 100%;
  }
  & .react-time-picker *,
  & .react-time-picker svg {
    color: ${(props) => props.theme.palette.dark};
    border-color: ${(props) => (props.error ? 'red' : props.theme.palette.light)};
    stroke: ${(props) => props.theme.palette.dark};
    font-weight: ${(props) => props.theme.font.weights.regular};
  }
  & .react-time-picker__inputGroup {
    height: 100%;
    justify-content: ${(props) => {
      switch (props.align) {
        case 'left':
          return 'flex-start'
        case 'center':
          return 'center'
        case 'right':
          return 'flex-end'
        default:
          return 'flex-start'
      }
    }};
  }

  @media only screen and (min-width: ${(props) => `${props.theme.breakpoints.md}px`}) {
    & .react-time-picker__inputGroup {
      justify-content: flex-start;
    }
  }
`

// PLEASE remove the unnecessary styling from upshift-ui components
export const ToggleWrapper = styled.div`
  & > label {
    margin-bottom: 0;
  }
`

export const LoaderWrapper = styled.div`
  width: 100%;
  display: flex;
  height: 200px;
  justify-content: center;
  align-items: center;
`

export const ErrorUnderline = styled.span<{ hasMarginLeft?: boolean }>`
  color: ${(props) => props.theme.palette.danger};
  font-size: ${(props) => props.theme.font.sizes.small};
  margin-left: ${(props) => (props.hasMarginLeft ? props.theme.mp.xs : 0)};
`

export const BusinessShiftCellWrapper = styled.div`
  margin: 0 -20px;
`

export const CalendarDay = styled.button<{ isSelected?: boolean }>`
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
  vertical-align: middle;

  &:focus,
  &:hover {
    outline: none;
  }

  /* Remove excess padding and border in Firefox 4+ */
  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  display: inline-block;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 26px;
  font-size: ${({
    theme: {
      font: { sizes },
    },
  }) => sizes.small};
  border-radius: 4px;
  margin-right: 3px !important;
  border: 1px solid ${Colors['primary']};
  background-color: ${({ isSelected }) => (isSelected ? Colors['primary'] : Colors['white'])};
  color: ${({ isSelected }) => (isSelected ? Colors['white'] : Colors['primary'])};
`

export const FooterElement = styled.div`
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: ${({ theme: { palette } }) => palette.white};
  flex: 1;
  border-top: 1px solid ${({ theme: { palette } }) => palette.light};

  @media only screen and (max-width: ${(props) => `${props.theme.breakpoints.sm}px`}) {
    flex-direction: column;
  }
`

export const ModalButtonContainer = styled.div`
  margin: 0 5px;

  & * {
    width: 100%;
  }

  @media only screen and (max-width: ${(props) => `${props.theme.breakpoints.sm}px`}) {
    display: flex;
    width: 100%;
    margin-bottom: ${(props) => props.theme.mp.sm};
  }
`

export const ModalButton = styled(Button)`
  flex: 1;
  justify-content: center;
`

export const CloseButton = styled.div`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
`

export const CloseIcon = styled.span`
  color: ${(props) => props.theme.palette.dark};
  display: inline-block;

  &:before {
    position: absolute;
    left: 50%;
    top: 50%;
    content: '';
    display: block;
    width: 20px;
    height: 1px;
    background: ${(props) => props.theme.palette.dark};
    transform-origin: center center;
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &:after {
    position: absolute;
    left: 50%;
    top: 50%;
    content: '';
    display: block;
    width: 20px;
    height: 1px;
    background: ${(props) => props.theme.palette.dark};
    transform-origin: center center;
    transform: translate(-50%, -50%) rotate(-45deg);
  }
`

export const CloseButtonElement = styled.button`
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
  vertical-align: middle;

  &:focus,
  &:hover {
    outline: none;
  }

  /* Remove excess padding and border in Firefox 4+ */
  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  width: 20px;
  height: 20px;
`

export const HeaderElement = styled.div`
  position: relative;
  padding: 5px 20px;
  flex: 1;
  background: ${({ theme: { palette } }) => palette.white};
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${({ theme: { palette } }) => palette.light};
`

export const RangeDaysWrapper = styled.div`
  margin-top: ${({ theme: { mp } }) => mp.md};
`

export const ContentElement = styled.div`
  padding: 20px;
  background: ${({ theme: { palette } }) => palette.white};
  flex: 15;
  overflow-y: auto;
  padding-top: 0;
`

export const MessageModalContentWrapper = styled.div`
  height: auto;
`

export const MessageModalFooterWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  background: ${(props) => props.theme.palette.white};
  padding: ${(props) => props.theme.mp.md};

  & > span:first-child {
    margin-right: 5px;
  }
`

export const FinalShiftModalContentWrapper = styled.div`
  height: auto;
`

export const FinalShiftModalFooterWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  background: ${(props) => props.theme.palette.white};
  padding: ${(props) => props.theme.mp.md};

  & > button:first-child {
    margin-right: 5px;
  }
`

export const CustomValidationError = styled.span<{ hasMarginLeft?: boolean }>`
  display: inline-block;
  color: ${(props) => props.theme.palette.danger};
  font-size: ${(props) => props.theme.font.sizes.small};
  ${(props) => props.hasMarginLeft && `margin-left: ${props.theme.mp.xs}`};
`

export const ExtendedInput = styled(Input)<{ hasError?: boolean }>`
  &:disabled {
    color: ${(props) => props.theme.palette.muted};
  }
  ${(props) =>
    props.hasError &&
    `
        &:{
            border-color: ${props.theme.palette.danger};
        }
    `}
`

export const TimepickerWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`

export const FormContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const PayRateBelowTheAverageWrapper = styled.div`
  min-width: 7em;
  margin-left: ${(props) => props.theme.mp.sm};
`

export const BadgesWrapper = styled.div`
  display: block;
  padding-left: ${(props) => props.theme.mp.lg};
  width: 100%;
`

export const BadgesTitle = styled.div`
  font-size: ${(props) => props.theme.font.sizes.default};
  font-weight: ${(props) => props.theme.font.weights.regular};
  display: block;
  text-align: left;
  padding: ${(props) => props.theme.mp.sm} 0;
  color: ${(props) => props.theme.palette.dark};
`

export const SCMWrapper = styled(Col)`
  margin-top: ${(props) => props.theme.mp.sm};
`

export const NoMarginRow = styled(Row)`
  margin-right: 0;
  margin-left: ${(props) => `-${props.theme.mp.xs}`};
`

export const PayValueErrorWrapper = styled.div`
  margin-left: ${(props) => props.theme.mp.xs};
  margin-right: 0 !important;
`

export const SuggestedPayRateWrapper = styled.div`
  margin-right: 0 !important;
`
