import styled from 'styled-components'

export const MessageModalContentWrapper = styled.div`
  height: auto;
`

export const MessageModalFooterWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  background: ${(props) => props.theme.palette.white};
  padding: ${(props) => props.theme.mp.md};

  & > span:first-child {
    margin-right: 5px;
  }
`
