import {
  ACCRUED_SICK_HOURS,
  ADMIN_USERS,
  BUSINESS_USERS,
  PENDING_SUSPENSION,
  UPSHIFTER_USERS,
  USERS,
} from 'models/Permission'
import ROUTES from 'routing/adminRoutes'
import {
  AsyncAddAdminUser,
  AsyncAdminUpshifterList,
  AsyncAdminUsers,
  AsyncBusinessUsersList,
  AsyncEditAdminUser,
  AsyncEditBusinessUser,
  AsyncPendingSuspension,
  AsyncUpshifterView,
  AsyncUserAccruedSickHours,
  AsyncUserSickPayHours,
} from 'ui/pages'
import AdminRouteLayout from '../../../Admin/AdminRouteLayout'
import AdminRoute from '../../AdminRoute'

const usersRoutes = [
  <AdminRoute
    exact
    path={ROUTES.UPSHIFTERS_LIST}
    layout={AdminRouteLayout}
    component={AsyncAdminUpshifterList}
    requiredPermissions={[UPSHIFTER_USERS.READ_UPSHIFTER_USERS]}
    key={ROUTES.UPSHIFTERS_LIST}
  />,
  <AdminRoute
    exact
    path={ROUTES.UPSHIFTERS_VIEW}
    layout={AdminRouteLayout}
    component={AsyncUpshifterView}
    requiredPermissions={[UPSHIFTER_USERS.UPDATE_UPSHIFTER_USERS]}
    key={ROUTES.UPSHIFTERS_VIEW}
  />,
  <AdminRoute
    exact
    path={ROUTES.SICK_PAY_HOUR_STATS}
    layout={AdminRouteLayout}
    component={AsyncUserSickPayHours}
    requiredPermissions={[USERS.SICK_PAY_DAY_HOURS]}
    key={ROUTES.SICK_PAY_HOUR_STATS}
  />,
  <AdminRoute
    exact
    layout={AdminRouteLayout}
    path={ROUTES.SICK_PAY_HOURS_STATS_ACCRUED_SICK_HOURS}
    component={AsyncUserAccruedSickHours}
    requiredPermissions={[ACCRUED_SICK_HOURS.READ_ACCRUED_SICK_HOURS]}
    key={ROUTES.SICK_PAY_HOURS_STATS_ACCRUED_SICK_HOURS}
  />,
  <AdminRoute
    exact
    path={ROUTES.BUSINESS_USERS_LIST}
    layout={AdminRouteLayout}
    component={AsyncBusinessUsersList}
    requiredPermissions={[BUSINESS_USERS.READ_BUSINESS_USERS]}
    key={ROUTES.BUSINESS_USERS_LIST}
  />,
  <AdminRoute
    exact
    path={ROUTES.EDIT_BUSINESS_USER}
    layout={AdminRouteLayout}
    component={AsyncEditBusinessUser}
    requiredPermissions={[BUSINESS_USERS.UPDATE_BUSINESS_USERS]}
    key={ROUTES.EDIT_BUSINESS_USER}
  />,
  <AdminRoute
    exact
    path={ROUTES.PENDING_SUSPENSION}
    layout={AdminRouteLayout}
    component={AsyncPendingSuspension}
    requiredPermissions={[PENDING_SUSPENSION.READ_PENDING_SUSPENSION]}
    key={ROUTES.PENDING_SUSPENSION}
  />,
  <AdminRoute
    exact
    path={ROUTES.ADMIN_USERS_LIST}
    layout={AdminRouteLayout}
    component={AsyncAdminUsers}
    requiredPermissions={[ADMIN_USERS.READ_ADMIN_USERS]}
    key={ROUTES.ADMIN_USERS_LIST}
  />,
  <AdminRoute
    exact
    path={ROUTES.ADMIN_USERS_ADD}
    layout={AdminRouteLayout}
    component={AsyncAddAdminUser}
    requiredPermissions={[ADMIN_USERS.CREATE_ADMIN_USERS]}
    key={ROUTES.ADMIN_USERS_ADD}
  />,
  <AdminRoute
    exact
    path={ROUTES.ADMIN_USERS_EDIT}
    layout={AdminRouteLayout}
    component={AsyncEditAdminUser}
    requiredPermissions={[ADMIN_USERS.UPDATE_ADMIN_USERS]}
    key={ROUTES.ADMIN_USERS_EDIT}
  />,
]

export default usersRoutes
