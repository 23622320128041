import { IPunchCard } from 'models/PunchCard'
import { action, payload } from 'ts-action'
import { IActivityLog } from 'models/ActivityLog'
import { IError, IPayload } from 'models/Global'

export const PunchCardsActivityLogsFetching = action('ActivityLogsFetching', payload<boolean>())

export const GetPunchCardsActivityLogs = action(
  'GetActivityLogs',
  payload<IPayload<IActivityLog<IPunchCard>[]>>()
)

export const GetPunchCardActivityLogsErrors = action('GetActivityLogsErrors', payload<IError>())
