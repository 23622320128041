import { AxiosService } from '../config/config'
import { Endpoints } from '../config/endpoints'
import { IPayload } from 'models/Global'
import {
  IStrike,
  IGetStrikesParams,
  IPutStrikeParams,
  IPostStrikeParams,
  IAssignStrikesResponseData,
} from 'models/Strike'

export class StrikesApi {
  constructor(private axios: AxiosService) {}

  getStrikes = async (params?: IGetStrikesParams) => {
    const response = await this.axios.get<IPayload<IStrike[]>>(Endpoints.getStrikes, params)
    return response
  }

  getStrike = async (id: number, params?: IGetStrikesParams) => {
    const response = await this.axios.get<IPayload<IStrike>>(Endpoints.getStrike(id), params)
    return response
  }

  postStrike = async (params: IPostStrikeParams | FormData) => {
    const response = await this.axios.post<IPayload<IStrike>>(Endpoints.postStrike, params)
    return response
  }

  // Assign multiple strikes with one API call
  postStrikes = async (params: FormData) => {
    const response = await this.axios.post<
      IPayload<IAssignStrikesResponseData | IAssignStrikesResponseData[]>
    >(Endpoints.postStrike, params)
    return response
  }

  putStrike = async (id: number, params?: IPutStrikeParams) => {
    const response = await this.axios.put<IPayload<IStrike>>(Endpoints.putStrike(id), params)
    return response
  }

  updateStrike = async (id: number, params?: IPutStrikeParams) => {
    const response = await this.axios.post<IPayload<IStrike>>(Endpoints.putStrike(id), params)
    return response
  }

  deleteStrike = async (id: number) => {
    const response = await this.axios.delete<{}>(Endpoints.deleteStrike(id))
    return response
  }
}
