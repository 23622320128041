import { action, payload } from 'ts-action'
import { IUpshifterExperience } from 'models/UpshifterExperience'
import { IError, IPayload } from 'models/Global'

export const UpshifterExperiencesFetching = action(
  'UpshifterExperiencesFetching',
  payload<boolean>()
)

export const GetUpshifterExperiences = action(
  'GetUpshifterExperiences',
  payload<IPayload<IUpshifterExperience[]>>()
)

export const GetUpshifterExperiencesErrors = action(
  'GetUpshifterExperiencesErrors',
  payload<IError>()
)
