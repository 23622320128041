import { action, payload } from 'ts-action'
import { IUser } from 'models/User'
import { IError, IPayload } from 'models/Global'

export const GetUsers = action('GetUsers', payload<IPayload<IUser[]>>())
export const ClearUsersReducer = action('ClearUsersReducer')
export const GetUsersErrors = action('GetErrors', payload<IError>())
export const UsersFetching = action('UsersFetching', payload<boolean>())

export const UserUpdating = action('UserUpdating', payload<number>())
export const UserUpdated = action('UserUpdated', payload<number>())
export const PutUser = action('PutUser', payload<IPayload<IUser>>())
export const PutUserErrors = action('PutUserErrors', payload<IError>())
export const DeleteUser = action('DeleteUser', payload<number>())

export const AddUser = action('AddUser', payload<IPayload<IUser>>())
