import { action, payload } from 'ts-action'
import { IStrikeType } from 'models/StrikeType'
import { IError, IPayload } from 'models/Global'

export const StrikeTypesFetching = action('StrikeTypesFetching', payload<boolean>())

export const GetStrikeTypes = action('GetStrikeTypes', payload<IPayload<IStrikeType[]>>())

export const AddStrikeType = action('AddStrikeType', payload<IPayload<IStrikeType>>())

export const UpdateStrikeType = action('UpdateStrikeType', payload<IPayload<IStrikeType>>())

export const DeleteStrikeType = action('DeleteStrikeType', payload<number>())

export const GetStrikeTypesErrors = action('GetStrikeTypesErrors', payload<IError>())
