import { action, payload } from 'ts-action'
import { IFeaturedShiftCampaignCreateShift } from 'models/FeaturedShiftCampaign'
import { IError, IPayload } from 'models/Global'
import { IShift } from 'models/Shift'

export const GetFeaturedShiftCampaignCreateShifts = action(
  'GetFeaturedShiftCampaignCreateShifts',
  payload<IPayload<IFeaturedShiftCampaignCreateShift[]>>()
)

export const GetFeaturedShiftCampaignCreateShift = action(
  'GetFeaturedShiftCampaignCreateShift',
  payload<IPayload<IShift>>()
)

export const AddFeaturedShiftCampaignCreateShift = action(
  'AddFeaturedShiftCampaignCreateShift',
  payload<IPayload<IShift>>()
)

export const DeleteFeaturedShiftCampaignCreateShift = action(
  'DeleteFeaturedShiftCampaignCreateShift',
  payload<number>()
)

export const FakeDeleteFeaturedShiftCampaignCreateShift = action(
  'FakeDeleteFeaturedShiftCampaignCreateShift',
  payload<number>()
)

export const FakeDeleteFeaturedShiftCampaignCreateShifts = action(
  'FakeDeleteFeaturedShiftCampaignCreateShifts'
)

export const UpdateFeaturedShiftCampaignCreateShift = action(
  'UpdateFeaturedShiftCampaignCreateShift',
  payload<IPayload<IShift>>()
)

export const UpdateFeaturedShiftCampaignCreateAllShifts = action(
  'UpdateFeaturedShiftCampaignCreateAllShifts',
  payload<boolean>()
)

export const GetFeaturedShiftCampaignCreateShiftsErrors = action(
  'GetFeaturedShiftCampaignCreateShiftsErrors',
  payload<IError>()
)

export const FeaturedShiftCampaignCreateShiftsFetching = action(
  'FeaturedShiftCampaignCreateShiftsFetching',
  payload<boolean>()
)
