import { IExpenseTypeTaxReducer, IExpenseTypeTax } from 'models/ExpenseTypeTax'
import { Action } from 'redux'
import { isType } from 'ts-action'
import { updateArray, deleteFromArray } from 'helpers/helperFunctions'
import {
  GetExpenseTypeTaxes,
  ExpenseTypeTaxesFetching,
  UpdateExpenseTypeTax,
  UpdateExpenseTypeTaxes,
  DeleteExpenseTypeTax,
  ResetExpenseTypeTaxes,
} from './actions'

const initialState: IExpenseTypeTaxReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: [],
}

export const expenseTypeTaxesReducer = (
  state = initialState,
  action: Action
): IExpenseTypeTaxReducer => {
  if (isType(action, GetExpenseTypeTaxes)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
    }
  }
  if (isType(action, UpdateExpenseTypeTax)) {
    const { payload } = action
    const { data } = state
    const formattedArray = updateArray<IExpenseTypeTax>(data, payload.data)
    return {
      ...state,
      data: formattedArray,
    }
  }
  if (isType(action, UpdateExpenseTypeTaxes)) {
    return {
      ...state,
    }
  }
  if (isType(action, DeleteExpenseTypeTax)) {
    const { payload } = action
    const { data } = state
    const formattedArray = deleteFromArray<IExpenseTypeTax>(data, payload)
    return {
      ...state,
      data: formattedArray,
    }
  }
  if (isType(action, ExpenseTypeTaxesFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }
  if (isType(action, ResetExpenseTypeTaxes)) {
    return {
      ...state,
      isFetching: false,
      data: [],
    }
  }
  return state
}
