import { IUpshifterReducer } from 'models/User/index'
import { IUser } from 'models/User'
import { Action } from 'redux'
import { isType } from 'ts-action'
import { updateArray, deleteFromArray, isObjectEmpty } from 'helpers/helperFunctions'
import {
  GetUpshifter,
  PutUpshifter,
  UpshifterFetching,
  GetUpshifterErrors,
  GetAssignedCertificates,
  GetUnassignedCertificates,
  AssignedCertificatesFetching,
  UnassignedCertificatesFetching,
  GetAssignedCertificatesErrors,
  GetUnassignedCertificatesErrors,
  ClearAssignedCertificates,
  ClearUnassignedCertificates,
  AssignButtonLoading,
  AssignButtonStopLoading,
  UnassignButtonLoading,
  UnassignButtonStopLoading,
  UpshifterBlocksFetching,
  GetUpshifterBlocksErrors,
  GetUpshifterBlocks,
  UpshifterUpdating,
  GetUpshifterStrikes,
  UpshifterStrikesFetching,
  UpdateUpshifterStrike,
  GetUpshifterReviews,
  UpshifterReviewsFetching,
  UpdateUpshifterReview,
  DeleteUpshifterReview,
  GetUpshifterShifts,
  UpdateUpshifterShift,
  DeleteUpshifterShift,
  UpshifterShiftsFetching,
  GetUpshifterFavorites,
  UpdateUpshifterFavorite,
  DeleteUpshifterFavorite,
  UpshifterFavoritesFetching,
  UpshifterFavoriteButtonLoading,
  UpshifterFavoriteButtonStopLoading,
  BlockButtonLoading,
  BlockButtonStopLoading,
  DeleteUpshifterBlock,
  ClearUpshifterBlocksData,
  ShouldBlocksDataRefresh,
  UpdateUpshifterBusinessesWithoutBlock,
  GetDeductions,
  DeductionsFetching,
  UpdateDeduction,
  DeleteDeduction,
  DeleteUpshifterStrike,
  GetUpshifterActiveStrikesTotal,
  UpshifterActiveStrikesTotalFetching,
  GetUpshifterActiveStrikesTotalErrors,
  GetExperiences,
  ExperiencesFetching,
  GetExperiencesError,
  ClearCurrentUpshifter,
  GetUpshifterVaccinationForms,
  GetUpshifterVaccinationFormsErrors,
  UpshifterVaccinationFormsFetching,
  UpdateUpshifterVaccinationForm,
} from './actions'
import { IReview } from 'models/Review'
import { IShift } from 'models/Shift'
import { IFavorite } from 'models/Favorite'
import { IBusinessEntity } from 'models/BusinessEntity'
import { IDeduction } from 'models/Deduction'
import { IStrike } from 'models/Strike'
import { FavoriteUpshifter, UnfavoriteUpshifter } from '../Favorite/actions'

const initialState: IUpshifterReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: true, // git blame says this has been changed to fix upshifter not found error display on Upshifter View. We need to be careful with inconsistencies like these as they cause some unexpected behavior.
  data: {} as IUser,
  isUpshifterUpdating: false,
  strikes: {
    data: [],
    isFetching: false,
    meta: {
      code: 0,
      message: '',
    },
    activeStrikesTotal: 0,
    isFetchingActiveStrikes: false,
    activeStrikesError: null,
  },
  blocks: {
    data: [],
    isFetching: false,
    meta: {
      code: 0,
      message: '',
    },
    blocksLoadingIds: [],
    shouldDataRefresh: false,
  },
  reviews: {
    data: [],
    isFetching: false,
    meta: {
      code: 0,
      message: '',
    },
  },
  shifts: {
    data: [],
    isFetching: false,
    meta: {
      code: 0,
      message: '',
    },
  },
  favorites: {
    data: [],
    isFetching: false,
    meta: {
      code: 0,
      message: '',
    },
    favoriteLoadingIds: [],
  },
  assignedCertificates: {
    data: [],
    isFetching: false,
    meta: {
      code: 0,
      message: '',
    },
    assignButtonLoadingIds: [],
  },
  unassignedCertificates: {
    data: [],
    isFetching: false,
    meta: {
      code: 0,
      message: '',
    },
    unassignButtonLoadingIds: [],
  },
  deductions: {
    data: [],
    isFetching: false,
    meta: {
      code: 0,
      message: '',
    },
  },
  experiences: {
    data: [],
    isFetching: false,
    meta: {
      code: 0,
      message: '',
    },
  },
  vaccinations: {
    data: [],
    isFetching: false,
    meta: {
      code: 0,
      message: '',
    },
  },
}

export const upshifterReducer = (state = initialState, action: Action): IUpshifterReducer => {
  //#region General Upshifter Actions
  if (isType(action, GetUpshifter)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
    }
  }

  if (isType(action, PutUpshifter)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
    }
  }

  if (isType(action, UpshifterFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }

  if (isType(action, UpshifterUpdating)) {
    const { payload } = action
    return {
      ...state,
      isUpshifterUpdating: payload,
    }
  }

  if (isType(action, GetUpshifterErrors)) {
    const { payload } = action
    return {
      ...state,
      error: payload,
    }
  }

  if (isType(action, ClearCurrentUpshifter)) {
    return {
      ...state,
      meta: {
        code: 0,
        message: '',
      },
      isFetching: true,
      data: {} as IUser,
    }
  }

  if (isType(action, FavoriteUpshifter)) {
    // if the state is empty, it should not be updated
    if (isObjectEmpty(state.data)) {
      return state
    }
    const newUpshifter = {
      ...state.data,
      favorite: true,
      favorite_id: action.payload.data.id,
    }
    return {
      ...state,
      data: newUpshifter,
    }
  }
  if (isType(action, UnfavoriteUpshifter)) {
    // if the state is empty, it should not be updated
    if (isObjectEmpty(state.data)) {
      return state
    }
    const newUpshifter = {
      ...state.data,
      favorite: false,
      favorite_id: null,
    }
    return {
      ...state,
      data: newUpshifter,
    }
  }
  //#endregion

  //#region Strikes Actions
  if (isType(action, GetUpshifterStrikes)) {
    const { payload } = action
    return {
      ...state,
      strikes: {
        ...state.strikes,
        meta: payload.meta,
        data: payload.data,
      },
    }
  }

  if (isType(action, GetUpshifterActiveStrikesTotal)) {
    const { payload } = action
    return {
      ...state,
      strikes: {
        ...state.strikes,
        activeStrikesTotal: payload,
        activeStrikesError: null,
      },
    }
  }

  if (isType(action, UpshifterStrikesFetching)) {
    const { payload } = action
    return {
      ...state,
      strikes: {
        ...state.strikes,
        isFetching: payload,
      },
    }
  }

  if (isType(action, UpshifterActiveStrikesTotalFetching)) {
    const { payload } = action
    return {
      ...state,
      strikes: {
        ...state.strikes,
        isFetchingActiveStrikes: payload,
      },
    }
  }

  if (isType(action, GetUpshifterActiveStrikesTotalErrors)) {
    const { payload } = action
    return {
      ...state,
      strikes: {
        ...state.strikes,
        activeStrikesError: payload,
      },
    }
  }

  if (isType(action, UpdateUpshifterStrike)) {
    const { payload } = action
    const newStrikes = state.strikes.data.map((strike) => {
      if (strike.id === payload.id) {
        return payload
      }
      return strike
    })
    return {
      ...state,
      strikes: {
        ...state.strikes,
        data: newStrikes,
      },
    }
  }

  if (isType(action, DeleteUpshifterStrike)) {
    const { payload } = action
    const { data } = state.strikes
    const newStrikes = deleteFromArray<IStrike>(data, payload)
    return {
      ...state,
      strikes: {
        ...state.strikes,
        data: newStrikes,
      },
    }
  }
  //#endregion

  //#region Blocks Actions
  if (isType(action, GetUpshifterBlocks)) {
    const { payload } = action
    return {
      ...state,
      blocks: {
        ...state.blocks,
        meta: payload.meta,
        data: [...(payload.data as any)],
      },
    }
  }

  if (isType(action, UpdateUpshifterBusinessesWithoutBlock)) {
    const { payload } = action
    const { data } = state.blocks
    const formattedArray = deleteFromArray<IBusinessEntity>(data as IBusinessEntity[], payload.id)
    return {
      ...state,
      blocks: {
        ...state.blocks,
        data: formattedArray,
      },
    }
  }

  if (isType(action, DeleteUpshifterBlock)) {
    const { payload } = action
    const { data } = state.blocks
    const formattedArray = deleteFromArray<any>(data as any, payload as unknown as any)
    return {
      ...state,
      blocks: {
        ...state.blocks,
        data: formattedArray,
      },
    }
  }

  if (isType(action, UpshifterBlocksFetching)) {
    const { payload } = action
    return {
      ...state,
      blocks: {
        ...state.blocks,
        isFetching: payload,
      },
    }
  }

  if (isType(action, BlockButtonLoading)) {
    const { payload } = action

    return {
      ...state,
      blocks: {
        ...state.blocks,
        blocksLoadingIds: [...state.blocks.blocksLoadingIds, payload],
      },
    }
  }

  if (isType(action, BlockButtonStopLoading)) {
    const { payload } = action
    const formattedArray = state.blocks.blocksLoadingIds.filter((id) => id !== payload)
    return {
      ...state,
      blocks: {
        ...state.blocks,
        blocksLoadingIds: formattedArray,
      },
    }
  }

  if (isType(action, GetUpshifterBlocksErrors)) {
    const { payload } = action
    return {
      ...state,
      blocks: {
        ...state.blocks,
        error: payload,
      },
    }
  }

  if (isType(action, ClearUpshifterBlocksData)) {
    return {
      ...state,
      blocks: {
        ...state.blocks,
        data: [],
        meta: {
          code: 0,
          message: '',
        },
      },
    }
  }

  if (isType(action, ShouldBlocksDataRefresh)) {
    const { payload } = action
    return {
      ...state,
      blocks: {
        ...state.blocks,
        shouldDataRefresh: payload,
      },
    }
  }
  //#endregion

  //#region Assigned Certificates Actions
  if (isType(action, GetAssignedCertificates)) {
    const { payload } = action
    return {
      ...state,
      assignedCertificates: {
        ...state.assignedCertificates,
        data: [...state.assignedCertificates.data, ...payload.data],
        meta: payload.meta,
      },
    }
  }

  if (isType(action, AssignedCertificatesFetching)) {
    const { payload } = action
    return {
      ...state,
      assignedCertificates: {
        ...state.assignedCertificates,
        isFetching: payload,
      },
    }
  }

  if (isType(action, AssignButtonLoading)) {
    const { payload } = action
    return {
      ...state,
      assignedCertificates: {
        ...state.assignedCertificates,
        assignButtonLoadingIds: [...state.assignedCertificates.assignButtonLoadingIds, payload],
      },
    }
  }

  if (isType(action, AssignButtonStopLoading)) {
    const { payload } = action
    const modifiedIsAssignButtonLoadingIds =
      state.assignedCertificates.assignButtonLoadingIds.filter((id) => id !== payload)
    return {
      ...state,
      assignedCertificates: {
        ...state.assignedCertificates,
        assignButtonLoadingIds: modifiedIsAssignButtonLoadingIds,
      },
    }
  }

  if (isType(action, GetAssignedCertificatesErrors)) {
    const { payload } = action
    return {
      ...state,
      assignedCertificates: {
        ...state.assignedCertificates,
        error: payload,
      },
    }
  }

  if (isType(action, ClearAssignedCertificates)) {
    return {
      ...state,
      assignedCertificates: {
        ...state.assignedCertificates,
        data: [],
        meta: {
          code: 0,
          message: '',
        },
      },
    }
  }
  //#endregion

  //#region Unassigned Certificates Actions
  if (isType(action, GetUnassignedCertificates)) {
    const { payload } = action
    return {
      ...state,
      unassignedCertificates: {
        ...state.unassignedCertificates,
        meta: payload.meta,
        data: [...state.unassignedCertificates.data, ...payload.data],
      },
    }
  }

  if (isType(action, UnassignedCertificatesFetching)) {
    const { payload } = action
    return {
      ...state,
      unassignedCertificates: {
        ...state.unassignedCertificates,
        isFetching: payload,
      },
    }
  }

  if (isType(action, UnassignButtonLoading)) {
    const { payload } = action
    return {
      ...state,
      unassignedCertificates: {
        ...state.unassignedCertificates,
        unassignButtonLoadingIds: [
          ...state.unassignedCertificates.unassignButtonLoadingIds,
          payload,
        ],
      },
    }
  }

  if (isType(action, UnassignButtonStopLoading)) {
    const { payload } = action
    const modifiedIsUnassignButtonLoadingIds =
      state.unassignedCertificates.unassignButtonLoadingIds.filter((id) => id !== payload)
    return {
      ...state,
      unassignedCertificates: {
        ...state.unassignedCertificates,
        unassignButtonLoadingIds: modifiedIsUnassignButtonLoadingIds,
      },
    }
  }

  if (isType(action, ClearUnassignedCertificates)) {
    return {
      ...state,
      unassignedCertificates: {
        ...state.unassignedCertificates,
        data: [],
        meta: {
          code: 0,
          message: '',
        },
      },
    }
  }

  if (isType(action, GetUnassignedCertificatesErrors)) {
    const { payload } = action
    return {
      ...state,
      unassignedCertificates: {
        ...state.unassignedCertificates,
        error: payload,
      },
    }
  }
  //#endregion

  //#region Reviews Actions
  if (isType(action, GetUpshifterReviews)) {
    const { payload } = action
    return {
      ...state,
      reviews: {
        ...state.reviews,
        meta: payload.meta,
        data: payload.data,
      },
    }
  }

  if (isType(action, UpdateUpshifterReview)) {
    const { payload } = action
    const { reviews } = state
    const formattedArray = updateArray<IReview>(reviews.data, payload.data)
    return {
      ...state,
      reviews: {
        ...state.reviews,
        data: formattedArray,
      },
    }
  }

  if (isType(action, DeleteUpshifterReview)) {
    const { payload } = action
    const { reviews } = state
    const formattedArray = deleteFromArray<IReview>(reviews.data, payload)
    return {
      ...state,
      reviews: {
        ...state.reviews,
        data: formattedArray,
      },
    }
  }

  if (isType(action, UpshifterReviewsFetching)) {
    const { payload } = action
    return {
      ...state,
      reviews: {
        ...state.reviews,
        isFetching: payload,
      },
    }
  }
  //#endregion

  //#region Shifts Actions
  if (isType(action, GetUpshifterShifts)) {
    const { payload } = action
    return {
      ...state,
      shifts: {
        ...state.shifts,
        meta: payload.meta,
        data: payload.data,
      },
    }
  }

  if (isType(action, UpdateUpshifterShift)) {
    const { payload } = action
    const { shifts } = state
    const formattedArray = updateArray<IShift>(shifts.data, payload.data)
    return {
      ...state,
      shifts: {
        ...state.shifts,
        data: formattedArray,
      },
    }
  }

  if (isType(action, DeleteUpshifterShift)) {
    const { payload } = action
    const { shifts } = state
    const formattedArray = deleteFromArray<IShift>(shifts.data, payload)
    return {
      ...state,
      shifts: {
        ...state.shifts,
        data: formattedArray,
      },
    }
  }

  if (isType(action, UpshifterShiftsFetching)) {
    const { payload } = action
    return {
      ...state,
      shifts: {
        ...state.shifts,
        isFetching: payload,
      },
    }
  }
  //#endregion

  //#region Favorites Actions
  if (isType(action, GetUpshifterFavorites)) {
    const { payload } = action
    return {
      ...state,
      favorites: {
        ...state.favorites,
        meta: payload.meta,
        data: payload.data,
      },
    }
  }

  if (isType(action, UpdateUpshifterFavorite)) {
    const { payload } = action
    const { favorites } = state
    const formattedArray = updateArray<IFavorite>(favorites.data, payload.data)
    return {
      ...state,
      favorites: {
        ...state.favorites,
        data: formattedArray,
      },
    }
  }

  if (isType(action, DeleteUpshifterFavorite)) {
    const { payload } = action
    const { favorites } = state
    const formattedArray = deleteFromArray<IFavorite>(favorites.data, payload)
    return {
      ...state,
      favorites: {
        ...state.favorites,
        data: formattedArray,
      },
    }
  }

  if (isType(action, UpshifterFavoritesFetching)) {
    const { payload } = action
    return {
      ...state,
      favorites: {
        ...state.favorites,
        isFetching: payload,
      },
    }
  }

  if (isType(action, UpshifterFavoriteButtonLoading)) {
    const { payload } = action

    return {
      ...state,
      favorites: {
        ...state.favorites,
        favoriteLoadingIds: [...state.favorites.favoriteLoadingIds, payload],
      },
    }
  }

  if (isType(action, UpshifterFavoriteButtonStopLoading)) {
    const { payload } = action
    const formattedArray = state.favorites.favoriteLoadingIds.filter((id) => id !== payload)
    return {
      ...state,
      favorites: {
        ...state.favorites,
        favoriteLoadingIds: formattedArray,
      },
    }
  }
  //#endregion

  //#region Deductions
  if (isType(action, GetDeductions)) {
    const { payload } = action
    return {
      ...state,
      deductions: {
        ...state.deductions,
        meta: payload.meta,
        data: payload.data,
      },
    }
  }
  if (isType(action, UpdateDeduction)) {
    const { payload } = action
    const { deductions } = state
    const formattedArray = updateArray<IDeduction>(deductions.data, payload.data)
    return {
      ...state,
      deductions: {
        ...state.deductions,
        data: formattedArray,
      },
    }
  }
  if (isType(action, DeleteDeduction)) {
    const { payload } = action
    const { deductions } = state
    const formattedArray = deleteFromArray<IDeduction>(deductions.data, payload)
    return {
      ...state,
      deductions: {
        ...state.deductions,
        data: formattedArray,
      },
    }
  }

  if (isType(action, DeductionsFetching)) {
    const { payload } = action
    return {
      ...state,
      deductions: {
        ...state.deductions,
        isFetching: payload,
      },
    }
  }
  //#endregion

  //#region Experiences
  if (isType(action, GetExperiences)) {
    const { payload } = action
    return {
      ...state,
      experiences: {
        ...state.experiences,
        meta: payload.meta,
        data: payload.data,
        error: undefined,
      },
    }
  }
  if (isType(action, GetExperiencesError)) {
    const { payload } = action
    return {
      ...state,
      experiences: {
        ...state.experiences,
        error: payload,
      },
    }
  }
  if (isType(action, ExperiencesFetching)) {
    const { payload } = action
    return {
      ...state,
      experiences: {
        ...state.experiences,
        isFetching: payload,
      },
    }
  }
  //#endregion

  //#region Vaccinations
  if (isType(action, GetUpshifterVaccinationForms)) {
    const { payload } = action
    return {
      ...state,
      vaccinations: {
        ...state.experiences,
        meta: payload.meta,
        data: payload.data,
        error: undefined,
      },
    }
  }
  if (isType(action, GetUpshifterVaccinationFormsErrors)) {
    const { payload } = action
    return {
      ...state,
      vaccinations: {
        ...state.vaccinations,
        error: payload,
      },
    }
  }
  if (isType(action, UpshifterVaccinationFormsFetching)) {
    const { payload } = action
    return {
      ...state,
      vaccinations: {
        ...state.vaccinations,
        isFetching: payload,
      },
    }
  }

  if (isType(action, UpdateUpshifterVaccinationForm)) {
    const { payload } = action
    const newVaccinationForms = state.vaccinations.data.map((vaccinationForm) => {
      if (vaccinationForm.id === payload.data.id) {
        return payload.data
      }
      return vaccinationForm
    })
    return {
      ...state,
      vaccinations: {
        ...state.vaccinations,
        data: newVaccinationForms,
      },
    }
  }
  //#endregion

  return state
}
