import { useState, useCallback } from 'react'

/**
 * Hook that takes care of basic Modal functionality. Exposes isOpen, toggleModal and closeModal.
 */
export const useModal = (defaultIsOpen: boolean = false): [boolean, () => void, () => void] => {
  const [isOpen, setIsOpen] = useState<boolean>(defaultIsOpen)

  const toggleModal = useCallback(() => {
    setIsOpen((isOpen) => !isOpen)
  }, [])

  const closeModal = useCallback(() => {
    setIsOpen(false)
  }, [])

  return [isOpen, toggleModal, closeModal]
}
