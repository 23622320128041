import { AxiosService } from '../config/config'
import { Endpoints } from '../config/endpoints'
import {
  IGetInvoicesZipReportParams,
  IGetTimesheetsReportParams,
  IGetFinanceAgingReportParams,
  IGetGrowthReportParams,
  IGetAMSuccessReportParams,
  IGetPayrollReportParams,
  IPostPayrollReportParams,
  IGetAgingReportParams,
  IGetFakePunchCardsReportParams,
  IGetMonthlyReportParams,
  IGetAccountingReportParams,
  IGetAccountManagersShiftsReportParams,
  IGetWeeklyEmployeeReportParams,
  IGetMultiplePunchCardReportParams,
  IGetTerminatedAndReinstatedUpshiftersReport,
  IGetPaymentApplicationReportParams,
  IGetARBillReport,
  IGetCRBonusReportParams,
  IGetHighLevelStatsReport,
  IGetFoodbuyReport,
  IGetCompassApprovalReport,
  IGetUpshifterHoursReport,
  IUpshifterHoursReport,
  IGetReturnRateReport,
  IGetAMKPIReportParams,
  IGetExpiringDocumentsReport,
  IGetLocationsReport,
  IGetAccountBreakdownReportParams,
  IGetProcessedTimesheetReportParams,
  IProcessedTimesheetReport,
  IGetBusinessesReport,
  IGetReviewsReportParams,
} from 'models/Report'
import { IPayload } from 'models/Global'
import { IGetUsersParams } from 'models/User'

export class ReportsApi {
  constructor(private axios: AxiosService) {}

  getInvoicesZip = async (params?: IGetInvoicesZipReportParams) => {
    const response = await this.axios.get<Blob>(Endpoints.getInvoiceZipReports, params, {
      responseType: 'blob',
    })
    return response
  }

  getTimesheetsReport = async (params?: IGetTimesheetsReportParams) => {
    const response = await this.axios.get<Blob>(Endpoints.getTimesheetsReport, params, {
      responseType: 'blob',
    })
    return response
  }

  getFinanceAgingReport = async (params?: IGetFinanceAgingReportParams) => {
    const response = await this.axios.get<Blob>(Endpoints.getFinanceAgingReport, params, {
      responseType: 'blob',
    })
    return response
  }

  getGrowthReport = async (params: IGetGrowthReportParams) => {
    const response = await this.axios.get<Blob>(Endpoints.getGrowthReport, params, {
      responseType: 'blob',
    })
    return response
  }

  getAMSuccessReport = async (params: IGetAMSuccessReportParams) => {
    const response = await this.axios.get<Blob>(Endpoints.getAMSuccessReport, params, {
      responseType: 'blob',
    })
    return response
  }

  getWeeklyEmployeeReport = async (params: IGetWeeklyEmployeeReportParams) => {
    const response = await this.axios.get<Blob>(Endpoints.getWeeklyEmployeeReport, params, {
      responseType: 'blob',
    })
    return response
  }

  getPayrollReport = async (params: IGetPayrollReportParams) => {
    const response = await this.axios.get<Blob>(Endpoints.getPayrollReport, params, {
      responseType: 'blob',
    })
    return response
  }

  postPayrollReport = async (params: IPostPayrollReportParams) => {
    const response = await this.axios.post<{}>(Endpoints.postPayrollReport, params)
    return response
  }

  getAgingReport = async (params: IGetAgingReportParams) => {
    const response = await this.axios.get<Blob>(Endpoints.getAgingReport, params, {
      responseType: 'blob',
    })
    return response
  }

  getFakePunchCardsReport = async (params?: IGetFakePunchCardsReportParams) => {
    const response = await this.axios.get<Blob>(Endpoints.getFakePunchCardsReport, params, {
      responseType: 'blob',
    })
    return response
  }

  getMonthlySalesTaxReport = async (params?: IGetMonthlyReportParams) => {
    const response = await this.axios.get<Blob>(Endpoints.getMonthlySalesReport, params, {
      responseType: 'blob',
    })
    return response
  }

  getAccountingReport = async (params?: IGetAccountingReportParams) => {
    const response = await this.axios.get<Blob>(Endpoints.getAccountingReport, params, {
      responseType: 'blob',
    })
    return response
  }

  getAccountManagersShiftsReport = async (params?: IGetAccountManagersShiftsReportParams) => {
    const response = await this.axios.get<Blob>(Endpoints.getAccountManagersShiftsReport, params, {
      responseType: 'blob',
    })
    return response
  }

  getPaymentApplicationReport = async (params?: IGetPaymentApplicationReportParams) => {
    const response = await this.axios.get<Blob>(Endpoints.getPaymentApplicationReport, params, {
      responseType: 'blob',
    })
    return response
  }

  getAMKPIReport = async (params?: IGetAMKPIReportParams) => {
    const response = await this.axios.get<Blob>(Endpoints.getAMKPIReport, params, {
      responseType: 'blob',
    })
    return response
  }

  getMultiplePunchCardReport = async (params?: IGetMultiplePunchCardReportParams) => {
    const response = await this.axios.get<Blob>(Endpoints.getMultiplePunchCardReport, params, {
      responseType: 'blob',
    })
    return response
  }

  getARBillReport = async (params?: IGetARBillReport) => {
    const response = await this.axios.get<Blob>(Endpoints.getARBillReport, params, {
      responseType: 'blob',
    })
    return response
  }

  getTerminatedAndReinstatedUpshiftersReport = async (
    params?: IGetTerminatedAndReinstatedUpshiftersReport
  ) => {
    const response = await this.axios.get<Blob>(
      Endpoints.getTerminatedAndReinstatedUpshiftersReport,
      params,
      {
        responseType: 'blob',
      }
    )
    return response
  }

  getCRBonusReport = async (params?: IGetCRBonusReportParams) => {
    const response = await this.axios.get<Blob>(Endpoints.getCRBonusReport, params, {
      responseType: 'blob',
    })
    return response
  }

  getHighLevelStatsReport = async (params?: IGetHighLevelStatsReport) => {
    const response = await this.axios.get<Blob>(Endpoints.getHighLevelStatsReport, params, {
      responseType: 'blob',
    })
    return response
  }

  getFoodbuyReport = async (params?: IGetFoodbuyReport) => {
    const response = await this.axios.get<Blob>(Endpoints.getFoodbuyReport, params, {
      responseType: 'blob',
    })
    return response
  }

  getCompassApprovalReport = async (params?: IGetCompassApprovalReport) => {
    const response = await this.axios.get<Blob>(Endpoints.getCompassApprovalReport, params, {
      responseType: 'blob',
    })
    return response
  }

  getReturnRateReport = async (params?: IGetReturnRateReport) => {
    const response = await this.axios.get<Blob>(Endpoints.getReturnRateReport, params, {
      responseType: 'blob',
    })
    return response
  }

  getUpshifterHoursReport = async (params?: IGetUpshifterHoursReport) => {
    const response = await this.axios.get<IPayload<IUpshifterHoursReport[]>>(
      Endpoints.getUpshifterHoursReport,
      params
    )
    return response
  }

  downloadUpshifterHoursReport = async (params?: IGetUpshifterHoursReport) => {
    const response = await this.axios.get<Blob>(Endpoints.getUpshifterHoursReport, params, {
      responseType: 'blob',
    })
    return response
  }

  getExpiringDocumentsReport = async (params?: IGetExpiringDocumentsReport) => {
    const response = await this.axios.get<Blob>(Endpoints.getExpiringDocumentsReport, params, {
      responseType: 'blob',
    })
    return response
  }

  getLocationsReport = async (params?: IGetLocationsReport) => {
    const response = await this.axios.get<Blob>(Endpoints.getLocationsReport, params, {
      responseType: 'blob',
    })
    return response
  }

  getAccountBreakdownReport = async (params?: IGetAccountBreakdownReportParams) => {
    const response = await this.axios.get<Blob>(Endpoints.getAccountBreakdownReport, params, {
      responseType: 'blob',
    })
    return response
  }

  getProcessedTimesheetReport = async (params?: IGetProcessedTimesheetReportParams) => {
    const response = await this.axios.get<IPayload<IProcessedTimesheetReport[]>>(
      Endpoints.getProcessedTimesheetReport,
      params
    )
    return response
  }

  downloadProcessedTimesheetReport = async (params?: IGetProcessedTimesheetReportParams) => {
    const response = await this.axios.get<Blob>(Endpoints.getProcessedTimesheetReport, params, {
      responseType: 'blob',
    })
    return response
  }

  getBusinessesReport = async (params?: IGetBusinessesReport) => {
    const response = await this.axios.get<Blob>(Endpoints.getBusinessesReport, params, {
      responseType: 'blob',
    })
    return response
  }

  getReviewsReport = async (params?: IGetReviewsReportParams) => {
    const response = await this.axios.get<Blob>(Endpoints.getReviewsReport, params, {
      responseType: 'blob',
    })
    return response
  }

  getUpshiftersReport = async (params?: IGetUsersParams) => {
    const response = await this.axios.get<Blob>(Endpoints.getUpshiftersReport, params, {
      responseType: 'blob',
    })
    return response
  }
}
