import { ICertificationRequestsReducer, IUserCertificate } from '../../models/UserCertificate'
import { Action } from 'redux'
import { isType } from 'ts-action'
import {
  GetCertificationRequests,
  CertificationRequestsFetching,
  GetCertificationRequestsErrors,
  UpdateUserCertificate,
} from './actions'
import { updateArray } from 'helpers/helperFunctions'

const initialState: ICertificationRequestsReducer = {
  meta: {
    code: 0,
    message: '',
  },
  isFetching: false,
  data: [],
}

export const certificationRequestsReducer = (
  state = initialState,
  action: Action
): ICertificationRequestsReducer => {
  if (isType(action, GetCertificationRequests)) {
    const { payload } = action
    return {
      ...state,
      meta: payload.meta,
      data: payload.data,
    }
  }

  if (isType(action, CertificationRequestsFetching)) {
    const { payload } = action
    return {
      ...state,
      isFetching: payload,
    }
  }

  if (isType(action, GetCertificationRequestsErrors)) {
    const { payload } = action
    return {
      ...state,
      error: {
        code: payload.code,
        message: payload.message,
      },
    }
  }

  if (isType(action, UpdateUserCertificate)) {
    const { payload } = action
    const { data } = state
    const formattedArray = updateArray<IUserCertificate>(data, payload.data)
    return {
      ...state,
      data: formattedArray,
    }
  }

  return state
}
