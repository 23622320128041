import { useParams, useRouteMatch } from 'react-router-dom'
import ROUTES from 'routing/adminRoutes'

export const titlePrefix = 'Upshift -  '

export const useTitle = (): string => {
  const { id } = useParams<{ id: string }>()
  const match = useRouteMatch()
  switch (match.path) {
    case ROUTES.ROOT:
      return `Upshift`

    //   Timesheets
    case ROUTES.TIMESHEETS_LIST:
      return `${titlePrefix}Timesheets `
    case ROUTES.TIMESHEETS_VIEW:
      return `${titlePrefix}#${id} - Timesheet`

    //   Admin users
    case ROUTES.ADMIN_USERS_LIST:
      return `${titlePrefix}Admin Users `
    case ROUTES.ADMIN_USERS_ADD:
      return `${titlePrefix}Add Admin User`
    case ROUTES.ADMIN_USERS_EDIT:
      return `${titlePrefix}#${id} Admin User`

    //   Invoices
    case ROUTES.INVOICES_LIST:
      return `${titlePrefix}Invoices `
    case ROUTES.INVOICE_VIEW:
      return `${titlePrefix}#${id} - Invoice`
    case ROUTES.INVOICE_SEND_EMAIL:
      return `${titlePrefix}#${id} - Invoice Send Email`

    // Shifts
    case ROUTES.SHIFTS:
      return `${titlePrefix}Shifts`
    case ROUTES.BULK_EDIT_SHIFTS:
      return `${titlePrefix}Bulk Edit`
    case ROUTES.EDIT_SHIFT:
      return `${titlePrefix}#${id} - Edit Shift`
    case ROUTES.DUPLICATE_SHIFT:
      return `${titlePrefix}#${id} - Duplicate Shift`

    // Business Entities
    case ROUTES.BUSINESSES_LIST:
      return `${titlePrefix}Businesses `
    case ROUTES.BUSINESS_ENTITY_AREAS:
      return `${titlePrefix}#${id} - Business Areas`
    case ROUTES.BUSINESS_ENTITY_BUSINESSES:
      return `${titlePrefix}#${id} - Business Businesses `
    case ROUTES.BUSINESS_ENTITY_DOCUMENTS_ACKNOWLEDGED:
      return `${titlePrefix}#${id} - Business Acknowledged Documents `
    case ROUTES.BUSINESS_ENTITY_DOCUMENTS:
      return `${titlePrefix}#${id} - Business Documents`
    case ROUTES.BUSINESS_ENTITY_LOCATIONS:
      return `${titlePrefix}#${id} - Business Locations`
    case ROUTES.BUSINESS_ENTITY_USERS:
      return `${titlePrefix}#${id} - Business Users`
    case ROUTES.BUSINESS_ENTITY_INVOICES:
      return `${titlePrefix}#${id} - Business Invoices`
    case ROUTES.BUSINESS_ENTITY_MY_UPSHIFTERS:
      return `${titlePrefix}#${id} - Business Upshifters`
    case ROUTES.BUSINESS_ENTITY_TEMPLATES:
      return `${titlePrefix}#${id} - Business Templates`
    case ROUTES.BUSINESS_ENTITY_TEMPLATES_ADD:
      return `${titlePrefix}#${id} - Business Add Template`
    case ROUTES.BUSINESS_ENTITY_SHIFTS:
      return `${titlePrefix}#${id} - Business Shifts`
    case ROUTES.BUSINESS_ENTITY_NOTES:
      return `${titlePrefix}#${id} - Business Notes`
    case ROUTES.BUSINESS_ENTITY_EDIT:
      return `${titlePrefix}#${id} - Business Edit`
    case ROUTES.BUSINESS_ENTITY_SELECTIVE_CLAIM_MODE:
      return `${titlePrefix}#${id} - Business SCM`
    case ROUTES.BUSINESS_ENTITY_CHILDREN_COMPANIES:
      return `${titlePrefix}#${id} - Business Children Companies`
    case ROUTES.BUSINESS_ENTITY_ACKNOWLEDGEMENTS:
      return `${titlePrefix}#${id} - Business Acknowledgements`
    case ROUTES.BUSINESS_ENTITY_INVOICE_GROUPS:
      return `${titlePrefix}#${id} - Business Invoice Groups`
    case ROUTES.BUSINESS_ENTITY_VIEW:
      return `${titlePrefix}#${id} - Business`
    case ROUTES.BUSINESS_ENTITY_ADD_INVOICE_GROUP:
      return `${titlePrefix}#${id} - Business Add Invoice Group`
    case ROUTES.BUSINESS_ENTITY_EDIT_INVOICE_GROUP:
      return `${titlePrefix}#${id} - Business Edit Invoice Group`
    case ROUTES.BUSINESS_ENTITY_FINANCE:
      return `${titlePrefix}#${id} - Business Finance`
    case ROUTES.BUSINESS_ENTITY_BILLING_DETAILS:
      return `${titlePrefix}#${id} - Business Billing Details`
    case ROUTES.BUSINESS_ENTITY_REVIEWS:
      return `${titlePrefix}#${id} - Business Reviews`
    case ROUTES.BUSINESS_ENTITY_ATTACHMENTS:
      return `${titlePrefix}#${id} - Business Attachments`
    case ROUTES.BUSINESS_ENTITY_TIMESHEETS:
      return `${titlePrefix}#${id} - Business Timesheets`
    case ROUTES.LOCATIONS_ADD_BUSINESS_ENTITY:
      return `${titlePrefix}#${id} - Business Add Location`
    case ROUTES.BUSINESS_ENTITY_SETTINGS:
      return `${titlePrefix}#${id} - Business Settings`
    case ROUTES.BUSINESS_ENTITY_ADD:
      return `${titlePrefix}Add Business`

    // Areas
    case ROUTES.AREAS_COUNTRIES:
      return `${titlePrefix}Areas Countries`
    case ROUTES.AREAS_COUNTRIES_VIEW:
      return `${titlePrefix}Areas - View Country`
    case ROUTES.AREAS_STATES_VIEW:
      return `${titlePrefix}Areas - View State`
    case ROUTES.AREAS_COUNTY_VIEW:
      return `${titlePrefix}Areas - View County`
    case ROUTES.ACTIVE_SHIFTS:
      return `${titlePrefix}Active Shifts`

    // Clocked in
    case ROUTES.CLOCKED_IN:
      return `${titlePrefix}Clocked In Upshifters`
    case ROUTES.ONBOARDER_LOGIN:
      return `Login`

    // Onboarder
    case ROUTES.ONBOARDER_UPSHIFTERS_VIEW:
      return `${titlePrefix}#${id} - Upshifter`
    case ROUTES.ONBOARDER_UPSHIFTERS_LIST:
      return `${titlePrefix}Upshifter `

    // Users
    case ROUTES.UPSHIFTERS_VIEW:
      return `${titlePrefix}#${id} - Upshifter`
    case ROUTES.UPSHIFTERS_LIST:
      return `${titlePrefix}Upshifters`
    case ROUTES.BUSINESS_USERS_LIST:
      return `${titlePrefix}Business Users `
    case ROUTES.EDIT_BUSINESS_USER:
      return `${titlePrefix}#${id} - Business User`

    // Locations
    case ROUTES.LOCATIONS_LIST:
      return `${titlePrefix}Locations `
    case ROUTES.LOCATIONS_ADD:
      return `${titlePrefix}Add Location`

    // Global settings
    case ROUTES.LOCATIONS_LOCATION_CATEGORIES:
      return `${titlePrefix}Location Categories`
    case ROUTES.LOCATIONS_LOCATION_TYPES:
      return `${titlePrefix}Location Types`
    case ROUTES.LOCATION:
      return `${titlePrefix}#${id} - Location`
    case ROUTES.POSITION_CATEGORIZATION:
      return `${titlePrefix}Position Categorization`
    case ROUTES.BADGES_LIST:
      return `${titlePrefix}Badges `
    case ROUTES.CERTIFICATE_ORGANIZATIONS_LIST:
      return `${titlePrefix}Certificate Organizations `
    case ROUTES.STRIKE_TYPES_LIST:
      return `${titlePrefix}Strike Types `
    case ROUTES.USER_ATTACHMENT_TYPES_LIST:
      return `${titlePrefix}User Attachments `
    case ROUTES.PAY_RATE_SUGGESTIONS_LIST:
      return `${titlePrefix}Pay Rate Suggestions `
    case ROUTES.AGING_REPORT_LIST:
      return `${titlePrefix}Aging Report `
    case ROUTES.DRUG_TESTING_LIST:
      return `${titlePrefix}Drug Testing `
    case ROUTES.DEDUCTION_TYPES_ADD:
      return `${titlePrefix}Deduction Types `
    case ROUTES.CERTIFICATES_LIST:
      return `${titlePrefix}Certificates `
    case ROUTES.EXPENSE_TYPES:
      return `${titlePrefix}Expense Types `
    case ROUTES.GROUPS:
      return `${titlePrefix}Work with Friends`
    case ROUTES.GAMIFICATION_SYSTEM:
      return `${titlePrefix}Gamification System `
    case ROUTES.GAMIFICATION_SYSTEM_CHALLENGES_CREATE:
      return `${titlePrefix}Create New Challenge`
    case ROUTES.GAMIFICATION_SYSTEM_CHALLENGES_VIEW:
      return `${titlePrefix}View Challenge`
    case ROUTES.POSITION_CATEGORIES:
      return `${titlePrefix}Position Categories`
    case ROUTES.GAMIFICATION_SYSTEM_ACHIEVEMENTS_CREATE:
      return `${titlePrefix}Create New Achievement`
    case ROUTES.GAMIFICATION_SYSTEM_ACHIEVEMENTS_VIEW:
      return `${titlePrefix}View Achievement`

    // Logout
    case ROUTES.LOGOUT:
      return `Upshift`

    // Reports
    case ROUTES.REPORTS:
      return `${titlePrefix}Reports `
    case ROUTES.REPORTS_PAYROLL_REPORT:
      return `${titlePrefix}Payroll Report`
    case ROUTES.REPORTS_AM_SUCCESS_REPORT:
      return `${titlePrefix}AM Success Report`
    case ROUTES.REPORTS_MONTHLY_SALES_TAX_REPORT:
      return `${titlePrefix}Monthly Sales Tax Report`
    case ROUTES.REPORTS_CHURN_REPORT:
      return `${titlePrefix}Churn Report`
    case ROUTES.REPORTS_FAKE_PUNCH_CARD_REPORT:
      return `${titlePrefix}Fake Punch Card Report`
    case ROUTES.REPORTS_GROWTH_REPORT:
      return `${titlePrefix}Growth Report`
    case ROUTES.REPORTS_ACCOUNTING_REPORT:
      return `${titlePrefix}Accounting Report`
    case ROUTES.REPORTS_AM_KPI_REPORT:
      return `${titlePrefix}AM KPI Report`
    case ROUTES.REPORTS_LOCATIONS_REPORT:
      return `${titlePrefix}Locations Report`
    case ROUTES.REPORTS_AM_SHIFTS_REPORT:
      return `${titlePrefix}AM Shifts Report`
    case ROUTES.REPORTS_WEEKLY_EMPLOYEE_REPORT:
      return `${titlePrefix}Weekly Employee Report`
    case ROUTES.REPORTS_MULTIPLE_PUNCH_CARD_REPORT:
      return `${titlePrefix}Multiple Punch Card Report`
    case ROUTES.REPORTS_TERMINATED_AND_REINSTATED_UPSHIFTERS_REPORT:
      return `${titlePrefix}Terminated And Reinstated Upshifters Report`
    case ROUTES.REPORTS_PAYMENT_APPLICATION_REPORT:
      return `${titlePrefix}Payment Application Report`
    case ROUTES.REPORTS_HIGH_LEVEL_STATS_REPORT:
      return `${titlePrefix}High Level Stats Report`
    case ROUTES.REPORTS_RETURN_RATE_REPORT:
      return `${titlePrefix}Return Report`
    case ROUTES.REPORTS_UPSHIFTER_HOURS_REPORT:
      return `${titlePrefix}Upshifter/Hours Report`
    case ROUTES.REPORTS_CR_BONUS_REPORT:
      return `${titlePrefix}CR Bonus Report`
    case ROUTES.REPORTS_EXPIRING_DOCUMENTS_REPORT:
      return `${titlePrefix}Expiring Documents Report`
    case ROUTES.REPORTS_ACCOUNT_BREAKDOWN_REPORT:
      return `${titlePrefix}Account Breakdown Report`
    case ROUTES.REPORTS_PROCESSED_TIMESHEET_REPORT:
      return `${titlePrefix}Processed Timesheet Report`

    // Featured Shifts
    case ROUTES.FEATURED_SHIFTS_CAMPAIGNS_LIST:
      return `${titlePrefix}Featured Shifts Campaign `
    case ROUTES.FEATURED_SHIFTS_CAMPAIGNS_STEP:
      return `${titlePrefix}Features Shifts Campaign`
    case ROUTES.FEATURED_SHIFTS_CAMPAIGNS_CREATE:
      return `${titlePrefix}Create Featured Shifts Campaign`
    case ROUTES.FEATURED_SHIFTS_CAMPAIGNS_VIEW:
      return `${titlePrefix}#${id} Featured Shifts Campaign `
    case ROUTES.FEATURED_SHIFTS_CAMPAIGNS_ADD_SHIFTS:
      return `${titlePrefix}Featured Shifts Campaign `

    // Sick Pay
    case ROUTES.SICK_PAY_HOUR_STATS:
      return `${titlePrefix}Sick Pay Hour Stats`
    case ROUTES.SICK_PAY_HOURS_STATS_ACCRUED_SICK_HOURS:
      return `${titlePrefix}Sick Pay Hour Stats`

    // Sick leave templates
    case ROUTES.SICK_LEAVE_TEMPLATES_ADD:
      return `${titlePrefix}Add Sick Leave Template`
    case ROUTES.SICK_LEAVE_TEMPLATES_LIST:
      return `${titlePrefix}Sick Leave Templates `

    // Pending deletion
    case ROUTES.PUNCHCARDS_PENDING_DELETION:
      return `${titlePrefix}Pending Deletion`

    // Pending deletion
    case ROUTES.PENDING_SUSPENSION:
      return `${titlePrefix}Pending Permanent Suspension`

    // Change Password
    case ROUTES.CHANGE_PASSWORD:
      return `${titlePrefix}Change Password`

    // Business Hour Verifications
    case ROUTES.PUNCHCARDS_PENDING_VERIFICATION:
      return `${titlePrefix}Business Hour Verifications`

    // Follow Management
    case ROUTES.FOLLOW_MANAGEMENT:
      return `${titlePrefix}Follow Management`

    // Shift Identifier Policies
    case ROUTES.SHIFT_IDENTIFIER_POLICIES:
      return `${titlePrefix}Shift Identifier Policies`

    // Onboarding Workflows
    case ROUTES.ONBOARDING_WORKFLOWS:
      return `${titlePrefix}In-App Onboarding`
    case ROUTES.ADD_ONBOARDING_WORKFLOW:
      return `${titlePrefix}Add In-App Onboarding`
    case ROUTES.EDIT_ONBOARDING_WORKFLOW:
      return `${titlePrefix}Edit In-App Onboarding`

    // Universal Templates
    case ROUTES.UNIVERSAL_TEMPLATES:
      return `${titlePrefix}Universal Templates `
    case ROUTES.ADD_UNIVERSAL_TEMPLATE:
      return `${titlePrefix}Create Universal Template`
    case ROUTES.EDIT_UNIVERSAL_TEMPLATE:
      return `${titlePrefix}#${id} - Edit Universal Template`

    // Worker Compensation Rates
    case ROUTES.WORKER_COMPENSATION_RATES:
      return `${titlePrefix}Worker Compensation Rates`

    default:
      return `${titlePrefix}Work Made Easy!`
  }
}
