import { action, payload } from 'ts-action'
import { IUser } from 'models/User'
import { IError, IPayload } from 'models/Global'

export const BusinessEntityUsersFetching = action('BusinessEntityUsersFetching', payload<boolean>())

export const GetBusinessEntityUsers = action('GetBusinessEntityUsers', payload<IPayload<IUser[]>>())

export const AddBusinessEntityUser = action('AddBusinessEntityUser', payload<IPayload<IUser>>())

export const UpdateBusinessEntityUser = action(
  'UpdateBusinessEntityUser',
  payload<IPayload<IUser>>()
)

export const UpdateBusinessEntityUsersActivateSuspendButtonLoadingIds = action(
  'UpdateBusinessEntityUsersActivateSuspendButtonLoadingIds',
  payload<number>()
)

export const DeleteBusinessEntityUser = action('DeleteBusinessEntityUser', payload<number>())

export const GetBusinessEntityUsersErrors = action(
  'GetBusinessEntityUsersErrors',
  payload<IError>()
)
